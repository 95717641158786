import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { QuestionSvg } from './UI/svg-icon/QuestionSvg';
import { Collapse } from 'antd';

type Props = {
  items: any;
  gridTemplateColumnsLeft: string;
  gridTemplateColumnsRight: string;
  paddingLeft: string;
  asideElement: any;
  slice: number;
};

export const FrameScroll = ({
  items,
  gridTemplateColumnsLeft,
  paddingLeft,
  gridTemplateColumnsRight,
  slice,
  asideElement,
}: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [balloon, setBalloon] = useState(false);

  const changeHeaderCollapse = () => {
    document
      .querySelectorAll('.ant-collapse-item .ant-collapse-header[aria-expanded] span.ant-collapse-header-text')
      .forEach(text => {
        document
          .querySelectorAll('.ant-collapse-item .ant-collapse-header[aria-expanded=true] span.ant-collapse-header-text')
          .forEach(item => {
            item.innerHTML = 'Скрыть информацию';
          });

        document
          .querySelectorAll(
            '.ant-collapse-item .ant-collapse-header[aria-expanded=false] span.ant-collapse-header-text',
          )
          .forEach(item => {
            item.innerHTML = 'Показать всю информацию';
          });
      });
  };

  return (
    <div className={'frame-scroll'}>
      {isMobile ? (
        <>
          {/*Мобильный чекбокс(по требованию)(1/2 часть)*/}
          {asideElement &&
              <div style={{ display:'flex', padding:'12px 0', marginBottom:'8px',borderBottom:'1px solid #E8EBF0' }}>
                <div style={{ display:'flex', alignItems:'center' }}>
                  {asideElement && asideElement('allCheck', 'all')}
                  <p>Выбрать все</p>
                </div>
              </div>
          }
          {items.map(item => (
            <div className={'frame-scroll-item'} key={item.id}>
              {/*Мобильный чекбокс(по требованию)(2/2 часть)*/}
              {asideElement &&
                  <div style={{ display:'flex', padding:'12px 0', marginBottom:'8px',borderBottom:'1px solid #E8EBF0' }}>
                    <div style={{ display:'flex', alignItems:'center' }}>
                      {asideElement && asideElement(Object.keys(item.item), item.id, item.active)}
                      <p>Выбрать рейс</p>
                    </div>
                  </div>
              }
              {Object.entries(item.item)
                .slice(0, 4)
                .map(([title, value]: any, index) => (
                  <div className={'frame-scroll-item__item'} key={index}>
                    <div className={'frame-scroll-item__title textMedium textMedium_medium'}>{title}</div>
                    <div className={'frame-scroll-item__value textMedium'}>{value.text}</div>
                  </div>
                ))}
              <Collapse
                items={[
                  {
                    key: item.id,
                    label: 'Показать всю информацию',
                    children: (
                      <div>
                        {Object.entries(item.item)
                          .slice(4)
                          .map(([title, value]: any, index) => (
                            <div className={'frame-scroll-item__item'} key={index}>
                              <div className={'frame-scroll-item__title textMedium textMedium_medium'}>{title}</div>
                              <div className={'frame-scroll-item__value textMedium'}>{value.text}</div>
                            </div>
                          ))}
                      </div>
                    ),
                  },
                ]}
                bordered={false}
                ghost
                onChange={changeHeaderCollapse}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={'frame-scroll-left'}>
            {!isMobile && (
              <div
                className={'frame-scroll-left__titles'}
                style={{ gridTemplateColumns: isMobile ? '100%' : gridTemplateColumnsLeft }}
              >
                {asideElement && asideElement('allCheck', 'all')}
                {items?.length ? (
                  Object.entries(items[0]?.item)
                    .slice(0, slice)
                    .map(([title, content]: any, index) => (
                      <div key={index} className={'frame-scroll-left__title textSmall textSmall_medium'}>
                        {title}
                        {content?.help && (
                          <div>
                            <QuestionSvg style={{ cursor: 'pointer' }} onClick={() => setBalloon(!balloon)} />
                            {content.contentHelp && balloon && <span>{content.contentHelp}</span>}
                          </div>
                        )}
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>
            )}
            {items.map(item => (
              <div style={{ backgroundColor:item?.active&&'#FEE2E2' }} className={'frame-scroll-left__item'} key={item.id}>
                {isMobile && (
                  <div
                    className={'frame-scroll-left__titles textSmall textSmall__medium'}
                    style={{ gridTemplateColumns: gridTemplateColumnsLeft }}
                  >
                    {asideElement && asideElement('allCheck', 'all')}
                    {Object.keys(item.item)
                      .slice(0, slice)
                      .map((title: any, index) => (
                        <div key={'left' + 'title' + index} className={'frame-scroll-left__title'}>
                          {title}
                        </div>
                      ))}
                  </div>
                )}
                <div className={'frame-scroll-left__contents'} style={{ gridTemplateColumns: gridTemplateColumnsLeft }}>
                  {asideElement && asideElement(Object.keys(item.item), item.id, item.active)}
                  {Object.entries(item.item)
                    .slice(0, slice)
                    .map(([title, value]: any, index) => (
                      <div
                        key={'left' + 'content' + index}
                        className={'frame-scroll-left__content textMedium textMedium__medium'}
                      >
                        {value.text}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div className={'frame-scroll-right'}>
            {!isMobile && (
              <div
                className={'frame-scroll-right__titles'}
                style={{
                  gridTemplateColumns: isMobile ? '100%' : gridTemplateColumnsRight,
                  paddingLeft: paddingLeft,
                }}
              >
                {items?.length ? (
                  Object.entries(items[0]?.item)
                    .slice(slice)
                    .map(([title, content]: any, index) => (
                      <div key={index} className={'frame-scroll-right__title textSmall textSmall_medium'}>
                        {title}
                        {content?.help && (
                          <div>
                            <QuestionSvg style={{ cursor: 'pointer' }} onClick={() => setBalloon(!balloon)} />
                            {content.contentHelp && balloon && <span>{content.contentHelp}</span>}
                          </div>
                        )}
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>
            )}
            {items.map(item => (
              <div className={'frame-scroll-right__item'} key={item.id}
                   style={{ paddingLeft: paddingLeft, backgroundColor: item?.active&&'#FEE2E2', width:'fit-content'  }}>
                {isMobile && (
                  <div
                    className={'frame-scroll-right__titles textSmall textSmall__medium'}
                    style={{ gridTemplateColumns: gridTemplateColumnsRight }}
                  >
                    {Object.keys(item.item)
                      .slice(slice)
                      .map((title: any, index) => (
                        <div key={'right' + 'title' + index} className={'frame-scroll-right__title'}>
                          {title}
                        </div>
                      ))}
                  </div>
                )}
                <div
                  className={'frame-scroll-right__contents'}
                  style={{ gridTemplateColumns: gridTemplateColumnsRight }}
                >
                  {Object.entries(item.item)
                    .slice(slice)
                    .map(([title, value]: any, index) => (
                      <div
                        key={'right' + 'content' + index}
                        className={'frame-scroll-right__content textMedium textMedium__medium'}
                      >
                        {value.text}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};