import React from 'react';
import NewEmptySvg from './UI/svg-icon/NewEmptySvg';

type EmptyType = {
  title: string;
  subTitle: string;
}

export const EmptyList = ({ title, subTitle }: EmptyType) => {
  return (
    <div className="EmptyList">
      <div className="EmptyList__box">
        <NewEmptySvg/>
          <div style={{ marginTop:'-50px' }}>
              <div className="EmptyList__title">{title}</div>
              <div className="EmptyList__subTitle">{subTitle}</div>
          </div>
      </div>
    </div>
  );
};