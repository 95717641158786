import React from 'react';
import { Button } from './UI/buttons/Button';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { WarningSvg } from './UI/svg-icon/WarningSvg';
import { CloseCircleRedSvg } from './UI/svg-icon/CloseCircleRedSvg';
import { SuccessGreenSvg } from './UI/svg-icon/SuccessGreenSvg';
import { closePopUp } from '../common/open-close-popup.function';

export const NotificationPopup = ({
  popUpActive,
  content,
  setPopUpActive,
  send,
  title,
  subTitle,
  titleError,
  subTitleError,
  titleSuccess,
  subTitleSuccess,
  textButtonSend,
  textButtonClose,
}) => {
  const contents = {
    send: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <WarningSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{title}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitle}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <Button
              onClick={send}
              svg={null}
              disabled={false}
              color={''}
              modifier={''}
              right={false}
              left={true}
              sending={false}
              colorSvg={''}
            >
              {textButtonSend}
            </Button>
          </div>
          <div className={'notification-popup-content__button'}>
            <ButtonTransparent
              modifier={''}
              color={''}
              disabled={false}
              svg={''}
              isSvg={false}
              onClick={() => setPopUpActive(false)}
              children={textButtonClose}
            />
          </div>
        </div>
      </div>
    ),
    error: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <CloseCircleRedSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{titleError}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitleError}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <Button
              onClick={send}
              svg={null}
              disabled={false}
              color={''}
              modifier={'red'}
              right={false}
              left={true}
              sending={false}
              colorSvg={''}
            >
              Повторить
            </Button>
          </div>
        </div>
      </div>
    ),
    success: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <SuccessGreenSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{titleSuccess}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitleSuccess}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <Button
              svg={null}
              disabled={false}
              color={'green'}
              modifier={''}
              right={false}
              left={true}
              sending={false}
              colorSvg={''}
              onClick={() => setPopUpActive(closePopUp)}
            >
              Хорошо
            </Button>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className={`notification-popup ${popUpActive ? 'active' : ''}`}>
      <div className={'notification-popup__black'} onClick={() => setPopUpActive(closePopUp)}></div>
      {contents[content]}
    </div>
  );
};
