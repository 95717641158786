import React, { useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { Tabs } from 'antd';
import PaymentProgress from './Components/PaymentProgress/PaymentProgress';
import PaymentPaid from './Components/PaymentPaid/PaymentPaid';
import TitleAndSubTitleMain from "../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain";

const PaymentPage = () => {
  const [tab, setTab] = useState<string>('1');

  const onChange = (key: string) => {
    setTab(key);
  };
  const tabs: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: '1',
      label: 'В обработке',
      children: (
        <>
          <PaymentProgress tab={tab} />
        </>
      ),
    },
    {
      key: '2',
      label: 'Оплачено',
      children: (
        <>
          <PaymentPaid tab={tab} />
        </>
      ),
    },
  ];

  return (
    <PageWrapper>
      <TitleAndSubTitleMain title={'Взаиморасчеты'} subTitle={'Детализация взаиморасчетов на текущую дату'}/>
      <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
    </PageWrapper>
  );
};

export default PaymentPage;
