import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './ShowcaseMainInfo.module.scss';
import { useMediaQuery } from 'react-responsive';
import { InputText } from 'src/components/UI/inputs/InputText';
import RadioGroup from 'src/components/newUI/RadioGroup/RadioGroup';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import CustomAddressInput from 'src/components/newUI/CustomAddressInput/CustomAddressInput';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { PhoneInputCountries } from 'src/components/UI/inputs/PhoneInputCountries';
import { validationPhoneNumberFunction } from 'src/common/validation-phone-number.function';
import { ShowcaseMainInfoField } from './ShowcaseMainInfoField/ShowcaseMainInfoField';
import { ShowcaseMainInfoTimePicker } from './ShowcaseMainInfoTimePicker/ShowcaseMainInfoTimePicker';
import { regularPhone } from 'src/common/regular-phone';

type TShowCaseMainInfo = {
  phoneNumber: string;
  address: DaDataSuggestion<DaDataAddress> | undefined;
  quarryType: string;
  workRange: string;
};

export const ShowcaseMainInfo = ({ phoneNumber, address, quarryType, workRange }: TShowCaseMainInfo) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const fields = [
    { name: 'Телефон', value: regularPhone('+' + phoneNumber) },
    { name: 'Адрес', value: address?.value || 'Нет данных' },
    { name: 'Тип', value: quarryType },
    {
      name: 'Часы работы',
      value: workRange,
    },
  ];

  return (
    <div className={styles.mainInfoContainer}>
      <div className={styles.columnContainer}>
        <div className={styles.list}>
          {fields.slice(0, 2).map(field => {
            return <ShowcaseMainInfoField name={field.name} value={field.value} />;
          })}
        </div>
      </div>

      {!isMobile && <div className={styles.line} />}

      <div className={styles.columnContainer}>
        <div className={styles.list}>
          {fields.slice(2, 4).map(field => {
            return <ShowcaseMainInfoField name={field.name} value={field.value} />;
          })}
        </div>
      </div>
    </div>
  );
};
