import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  title: string;
  quantity?: string | number;
  [key: string]: any;
};

export const TitleFlightsDriver = ({ title, quantity, ...props }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div
      {...props}
      className={`title-page ${isMobile ? 'textH5 textH5_medium' : 'textH4 textH4_medium'}`}
      style={isMobile ? { margin: '0 0 16px' } : { margin: '0 0 24px' }}
    >
      {title}
      <span className={isMobile ? 'textH4' : 'textH3'}>{quantity}</span>
    </div>
  );
};
