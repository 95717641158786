import React from 'react';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { Button } from './UI/buttons/Button';
import { useMediaQuery } from 'react-responsive';
import ButtonFilled from "./newUI/ButtonFilled/ButtonFilled";
import ButtonNotFilled from "./newUI/ButtonNotFilled/ButtonNotFilled";

export const AcceptDelete = ({ closePopUp, cancelFlight }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="AcceptDelete">
      <div className="AcceptDelete__content">
        <div>
          <div className="AcceptDelete__svg">
            <svg width="100%" height="100%" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.3334 7.33366H27.3334V0.666992H4.00008C2.15008 0.666992 0.666748 2.15033 0.666748 4.00033V22.3337H4.00008C4.00008 25.1003 6.23341 27.3337 9.00008 27.3337C11.7667 27.3337 14.0001 25.1003 14.0001 22.3337H24.0001C24.0001 25.1003 26.2334 27.3337 29.0001 27.3337C31.7667 27.3337 34.0001 25.1003 34.0001 22.3337H37.3334V14.0003L32.3334 7.33366ZM9.00008 24.8337C7.61675 24.8337 6.50008 23.717 6.50008 22.3337C6.50008 20.9503 7.61675 19.8337 9.00008 19.8337C10.3834 19.8337 11.5001 20.9503 11.5001 22.3337C11.5001 23.717 10.3834 24.8337 9.00008 24.8337ZM24.0001 19.0003H12.7334C11.8167 17.9837 10.4834 17.3337 9.00008 17.3337C7.51675 17.3337 6.18341 17.9837 5.26675 19.0003H4.00008V4.00033H24.0001V19.0003ZM29.0001 24.8337C27.6167 24.8337 26.5001 23.717 26.5001 22.3337C26.5001 20.9503 27.6167 19.8337 29.0001 19.8337C30.3834 19.8337 31.5001 20.9503 31.5001 22.3337C31.5001 23.717 30.3834 24.8337 29.0001 24.8337ZM27.3334 14.0003V9.83366H31.5001L34.7667 14.0003H27.3334ZM8.70008 13.617L11.6501 10.667L8.70008 7.71699L11.0501 5.36699L14.0001 8.31699L16.9501 5.36699L19.3001 7.71699L16.3501 10.667L19.3001 13.617L16.9501 15.967L14.0001 13.017L11.0501 15.967L8.70008 13.617Z" fill="currentColor"/>
            </svg>
          </div>
          <div className="AcceptDelete__title">Вы действительно хотите отказаться от рейса?</div>
          <div className="AcceptDelete__subTitle">В этом случае рейс исчезнет из общего списка и вы его не увидите</div>
        </div>
        <div >
          <ButtonFilled
              text={'Да, хочу отказаться'}
              onClick={cancelFlight}
              width={'100%'}
          />
        </div>
        <ButtonNotFilled
            text={'Нет, хочу продолжить выполнение'}
            onClick={() => closePopUp(false)}
            width={'100%'}
        />
        {!isMobile && <div onClick={() => closePopUp(false)} className="AcceptDelete__close"></div>}
        {isMobile && <div className="DriverPagePopup__line"></div>}
      </div>
    </div>
  );
};