import React, { useContext, useState } from 'react';
// @ts-ignore
import styles from './newNavItemMobile.module.scss';
import { NavLink } from 'react-router-dom';
import { Context } from '../../../context/context';

const NewNavItemMobile = ({
  link,
  disabled,
  svg,
  svgActive,
  svgProfileError,
  name,
  notActiveProfile,
  errorProfile,
  typeAccess,
  ...props
}) => {
  const [activeLink, setActiveLink] = useState(false);
  const { user } = useContext(Context);

  const changeState = state => {
    if (state) {
      setActiveLink(true);
      return styles.activeLink;
    }
    setActiveLink(false);
    return styles.noActiveLink;
  };

  const correctionStyleActiveSvg = () => {
    if (activeLink) {
      return styles.NavItemSvgActive;
    } else {
      return styles.NavItemSvg;
    }
  };

  const successfulAccess = typeAccess?.includes(user?.type);

  return (
    successfulAccess && (
      <div>
        <NavLink {...props} to={link} className={({ isActive }) => changeState(isActive)}>
          <div className={activeLink ? styles.NavLinkContainerDisplay : styles.NavLinkContainer}>
            <div className={correctionStyleActiveSvg()}>{activeLink ? svgActive : svg}</div>
            <div className={activeLink ? styles.NavItemTextDisplay : styles.NavItemText}>{name}</div>
          </div>
        </NavLink>
      </div>
    )
  );
};

export default NewNavItemMobile;
