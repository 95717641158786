import React, { Dispatch } from 'react';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import './CustomAddressInput.css';

interface IProps {
  placeholder?: string;
  valueAddress?: DaDataSuggestion<DaDataAddress> | undefined;
  setValueAddress: Dispatch<any>;
}

const CustomAddressInput: React.FC<IProps> = ({ valueAddress, setValueAddress, placeholder }) => {
  return (
    <div className={'form-item'}>
      <AddressSuggestions
        delay={300}
        inputProps={{ placeholder: placeholder }}
        containerClassName={'customAddressInput'}
        token="acd0b65bca29ba39870a3ced6049625e0e15405e"
        value={valueAddress}
        onChange={setValueAddress}
      />
    </div>
  );
};

export default CustomAddressInput;
