import React, { useState } from 'react';
import { HelpNewSvg } from './UI/svg-icon/HelpNewSvg';
import { HelpBalloon } from './UI/balloons/HelpBalloon';

type TextType = {
  color?: string;
  textHelp: string;
  style?: React.CSSProperties;
  isLeft?: boolean;
};

export const HelpTooltip = ({ color = '#6C779C', textHelp, isLeft = false, style = {}, ...props }: TextType) => {
  const [helpVisible, setHelpVisible] = useState(false);
  return (
    <div
      className="HelpTooltip___container"
      style={{ position: 'relative' }}
      onMouseEnter={() => setHelpVisible(true)}
      onMouseLeave={() => setHelpVisible(false)}
    >
      <HelpNewSvg color={color} />
      {helpVisible && <HelpBalloon children={textHelp} isLeft={isLeft} style={style} />}
    </div>
  );
};
