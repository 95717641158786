import React from 'react';
// @ts-ignore
import pdf from '../../assets/img/rulesOfRegistration.pdf'

export const helpItemsMapping = {
  supplier:[
      {
    title: 'Что такое Solber для поставщиков и для чего он нужен?',
    description: 'Solber для поставщиков – это часть экосистемы Solber,' +
        ' состоящая из нескольких личных кабинетов: перевозчик, водитель, покупатель, поставщик. \n' +
        'Личный кабинет поставщика – это простой инструмент для обмена данными между поставщиком (карьером) и покупателем.' +
        ' Личный кабинет поможет автоматизировать отгрузки материала с карьера,' +
        ' упростит учёт вывезенного материала и взаиморасчёты с покупателем. \n' +
        'В любое время доступна статистика отгрузок, доверенностей на погрузку, объёмы за указанный период и многое другое.\n' +
        'Платформа Solber непрерывно развивается, с течением времени будут доступны новые функции.\n',
    link: '',
    isLink: false,
    hrefLink: ''
  },
    {
      'title': 'В чем практическая польза вашего сервиса?',
      description: 'Сервис экономит время на погрузке, делает прозрачным учет материала.' +
          ' Все погрузки и доверенности от покупателя собраны в одном месте.' +
          ' Пользоваться сервисом возможно как со стационарного компьютера так и с мобильных устройств – смартфоны, планшеты.',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Зачем нужно заполнять личные данные и данные по карьеру?',
      description: 'Личные данные позволят убедиться, что регистрируется в системе именно то юр. Лицо,' +
          ' которое имеет право вести отгрузки и хозяйственную деятельность на карьере.\n' +
          'Данные по карьеру помогут перевозчику добраться до карьера,' +
          ' кроме того вы сможет получать уведомление о прибытии транспорта на погрузка с использованием геолокации.',
      link: '',
      isLink: false,
      hrefLink: ''
    }],

  driver:[{
    title: 'Что такое Solber для водителей и для чего он нужен?',
    description: 'Solber для водителей – это часть экосистемы Solber,' +
        ' состоящая из нескольких личных кабинетов: перевозчик, водитель, покупатель, продавец.' +
        ' Функционал личного кабинета водителя включается в себя: отслеживание статусов рейсов в режиме реального времени,' +
        ' контроль рейса на всех этапах доставки, просмотр и загрузка транспортных накладных.' +
        ' Цель данного микросервиса помочь и упростить работу для водителя с помощью современных технологий.',
    link: '',
    isLink: false,
    hrefLink: ''
    },
    {
      'title': 'В чем практическая польза вашего сервиса?',
      description: 'В первую очередь данный сервис призван экономить ваше время во время перевозки сыпучих материалов.' +
          ' Все ваши рейсы собраны и хранятся в одном месте, вся необходимая информация у вас перед глазами' +
          ' и вы контролируете каждый из этапов перевозки. От назначения машины на рейс до выполнения доставки.',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Почему у меня нет доступа к рейсам?',
      description: 'Прежде чем приступать к работе, пожалуйста, проверьте ваш профиль. Доступ будет ограничен пока вы ',
      link: 'не заполните все необходимые данные',
      isLink: true,
      hrefLink: '/profile'
    },
    {
      'title': 'Зачем нужно заполнять личные данные?',
      description: 'Это необходимо в целях безопасности в случае возникновения экстренных ситуаций',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Я заполнил данные в профиле, но все равно не вижу рейсы, почему?',
      description: 'Данные постоянно обновляются и для их обработки серверу необходимо время.' +
          ' Попробуйте немного подождать и обновить страницу.' +
          ' Если это не помогло, свяжитесь с диспетчером по телефону: +7 (999) 999 99 99, либо напишите нам на почту: support@solber.ru',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Я приехал на место загрузки, как мне поменять статус рейса?',
      description: 'Для этого вам необходимо найти ваш рейс в списке активных рейсов,' +
          ' нажать на карточку рейса, перейти на страницу подробнее и нажать на кнопку «Приехал на загрузку»',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Почему я не могу перейти к следующему шагу во время загрузки и выгрузки материала?',
      description: 'Вероятнее всего вы не заполнили обязательные поля.' +
          ' Пожалуйста, будьте внимательны на этих этапах,' +
          ' обратите внимание на единицу измерения и количество материала.' +
          'После заполнения необходимых полей и нажатия на кнопку вы уже не сможете вернуться к предыдущему шагу.',
      link: '',
      isLink: false,
      hrefLink: ''
    },
      {
          'title': 'Как правильно заполнять ТН?',
          description:
              <div style={{ listStyle:'none',display:'flex',flexDirection:'column',gap:'12px' }}>
                  <div>
                      <li>1. Должны быть заполнены: дата, данные грузоотправителя, данные грузополучателя и перевозчика, вид груза,  объем груза, размер провозной платы.
                          В разделах 6, 7, 15, 16 должны быть наименования должностей, подписи и расшифровки подписей.</li>
                      <li>2. Печать на транспортной накладной.</li>
                      <li>3. В строках можно ставить прочерки, если для их заполнения нет информации.
                          Разделы с отметкой «при необходимости» заполнять не обязательно (п. 10 Правил перевозок груза автомобильным транспортом,
                          Письмо Минтранса России от 26.03.2021 № Д3/6976-ИС).</li>
                      <li>4. Перевозчик заполняет шапку Транспортной накладной: дату, номер экземпляра (документ составляется в количестве трех штук)/</li>
                      <li>5. Также заполняются Перевозчиком:</li>
                  </div>
                  <p>
                      <strong>Раздел 2 «Грузополучатель».</strong> Укажите ФИО физлица или наименование организации, ИНН, адрес, телефон.
                  </p>
                  <p>
                      <strong>Раздел 3 «Груз».</strong> Заполняется - наименование, количество грузовых мест, масса и габариты. .
                  </p>
                  <p>
                      <strong>Раздел 4 «Сопроводительные документы на груз».</strong> В этом разделе надо привести реквизиты прилагаемых к накладной документов.
                      Сюда входят товарные накладные, УПД, сертификаты, паспорта, разрешения на груз и пр.
                      При их наличии у налоговой будет меньше причин сомневаться в сделке.
                  </p>
                  <p>
                      <strong>Раздел 5 «Указания грузоотправителя».</strong> В этом разделе нужно прописать указания, которые транспортная компания
                      должна соблюдать во время перевозки груза: сроки, температурный режим, санитарные и карантинные требования и пр.
                      Дополнительно указываются характеристики автомобиля, на котором повезут груз: тип, марка, грузоподъемность, вместимость и пр.
                  </p>
                  <p>
                      <strong>Раздел 6 «Прием груза».</strong> Впишите данные лица, от которого перевозчик забирает груз, адрес склада, дату и время получения груза.
                      При погрузке указываются уже фактические дата и время прибытия и отбытия машины, описывается состояние груза, его масса.
                      Раздел должен быть заверен подписями лица, у которого забирают груз, водителя и грузоотправителя (при необходимости) с проставлением печатей.
                  </p>
                  <p>
                      Не отражайте данные о лице, которому до погрузки передали груз по документам,
                      если фактически его не перемещали (Письмо Минтранса России от 26.03.2021 № Д3/6976-ИС).
                  </p>
                  <p>
                      <strong>Раздел 7 «Сдача груза».</strong> Тут отражается адрес места выгрузки (склад покупателя, терминал транспортной компании), дата и время доставки.
                  </p>
                  <p>
                      <strong>Раздел 15 «Подписи сторон» и раздел 16 «Стоимость услуг перевозчика и порядок расчета провозной платы».</strong> Должностное
                      лицо грузоотправителя ставит свою подпись, указывает ФИО и должность.
                  </p>
                  <p>
                      При погрузке водитель перевозчика ставит свою подпись в разделе 6 «Прием груза», а при разгрузке — в разделе 7 «Сдача груза».
                      Перевозчик самостоятельно заполняет разделы 8-11, 13, 15 и 16. При необходимости можно заполнить разделы 12, 14 и 17.
                  </p>
                  <p>
                      <strong>Раздел 8 «Условия перевозки».</strong> Здесь перевозчик прописывает, когда груз можно считать утраченным, размер платы за хранение в терминале,
                      размеры штрафов за невывоз груза, задержку транспорта и пр. Часто его оставляют пустым — это значит,
                      что все условия определяются по общим правилам Устава автомобильного транспорта.
                  </p>
                  <p>
                      <strong>Раздел 9 «Информация о принятии к исполнению».</strong> В разделе проставляется дата приема заказа, ФИО и должность принявшего и его подпись.
                      У перевозчика есть три дня на рассмотрение заявки грузоотправителя.
                  </p>
                  <p>
                      <strong>Раздел 10 «Перевозчик».</strong> Заполняется сведениями о перевозчике: наименование или ФИО, ИНН, адрес, номер телефона.
                      Дополнительно указываются информация о водителе и его контакты.
                  </p>
                  <p>
                      <strong>Раздел 11 «Транспортное средство».</strong> Перевозчик указывает сведения об автомобиле, на котором повезут груз:
                      тип, марку, грузоподъемность, вместимость, регномер. Дополнительно надо выбрать тип владения: собственность, лизинг или аренда.
                  </p>
                  <p>
                      Если транспорт не соответствует требованиям грузоотправителя, заявленным в разделе 5,
                      то он может отказаться от погрузки (п. 31 Правил, утв. постановлением Правительства от 21.12.2020 № 2200).
                  </p>
                  <p>
                      <strong>Раздел 12 «Оговорки и замечания перевозчика».</strong> Если при погрузке у перевозчика появились вопросы к состоянию самого груза,
                      упаковке, маркировке, таре и пр., их можно отметить в этом разделе.
                  </p>
                  <p>
                      <strong>Раздел 13 «Прочие условия».</strong> Здесь указывают номер, дату и срок действия разрешения на перевозку, данные о маршруте
                      при перевозке крупногабаритных или опасных грузов, режиме работы водителей.
                  </p>
                  <p>
                      <strong>Раздел 14 «Переадресовка».</strong> Этот раздел заполняет водитель, если во время поездки изменился адрес доставки.
                  </p>
                  <p>
                      <strong>Раздел 15 «Подписи сторон».</strong> должностное лицо перевозчика ставит свою подпись, указывает ФИО и должность.
                  </p>
                  <p>
                      <strong>Раздел 16 «Стоимость услуг перевозчика и порядок расчета провозной платы».</strong>
                      Перевозчик указывает стоимость услуги по перевозке, провозную плату и порядок ее расчета,
                      расходы перевозчика, НДС, наименование, адрес и банковские реквизиты грузоотправителя и перевозчика.
                      Также тут проставляются подписи должностных лиц с расшифровкой.
                  </p>
                  <p>
                      Важно! Транспортная накладная подтверждает расходы на перевозку, если в ней есть все необходимые реквизиты
                      и правильно заполнен раздел 16 (письма ФНС от 19.04.2021 № СД-4-2/5238@, от 20.02.2021 № СД-3-3/1280@).
                      Если нет раздела 16, к транспортной накладной должен прилагаться другой первичный документ, который определяет цену перевозки.
                      Например, универсальный передаточный документ.
                  </p>
                  <a href={pdf} target="_blank">Показать образец</a>
              </div>
          ,
          isLink: false,
          hrefLink: ''
      }
    ],

  buyer:[{
    title: 'Что такое цифровая платформа SOLBER для покупателей и для чего она нужна?',
    description: 'Цифровая платформа SOLBER для покупателей – онлайн-платформа, ' +
        'которая объединяет и состоит из нескольких кабинетов: перевозчиков, поставщиков, ' +
        'покупателей и водителей, обеспечивая удобное взаимодействие и упрощая процессы покупок. ' +
        'Она позволяет автоматизировать многие аспекты работы, такие как поиск клиентов, ' +
        'управление заказами и документооборот, удобно оформлять заказы на нерудные материалы и ' +
        'отслеживать их исполнение в режиме реального времени. Платформа упрощает процесс закупки и ' +
        'обеспечивает прозрачность и контроль на всех этапах поставки.',
    link: '',
    isLink: false,
    hrefLink: ''
  },
    {
      'title': 'Какова практическая польза от использования платформы SOLBER для покупателей?',
      description:
          <div>
            <p>Платформа предлагает множество преимуществ, включая:</p>
            <ul style={{ paddingLeft:'20px' }}>
              <li>удобное и быстрое оформление заказов</li>
              <li>возможность сравнения предложений от разных поставщиков</li>
              <li>отслеживание статуса заказа в реальном времени</li>
              <li>упрощение документооборота и управление счетами</li>
              <li>снижение рисков и повышение надежности поставок</li>
              <li>благодаря большой базе поставщиков, в любой момент можно сделать заказ необходимого объема</li>
            </ul>
          </div>,
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Почему необходимо заполнять личные данные и информацию о компании?',
      description:
          <div>
            <p>Заполнение личных данных и информации о компании необходимо для:</p>
            <ul style={{ paddingLeft:'20px' }}>
              <li>удостоверения личности и обеспечения безопасности на платформе</li>
              <li>повышения доверия со стороны поставщиков</li>
              <li>упрощения процесса заключения договоров и оформления заказов</li>
              <li>обеспечения точности и полноты информации для эффективного взаимодействия</li>
            </ul>
          </div>,
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Как платформа SOLBER помогает улучшить процесс закупок для покупателей?',
      description:
          <div>
            <p>Платформа предоставляет возможности для:</p>
            <ul style={{ paddingLeft:'20px' }}>
              <li>экономии времени за счет автоматизации процесса закупок</li>
              <li>получения доступа к широкой базе проверенных поставщиков</li>
              <li>улучшения условий закупок через конкурентное ценообразование</li>
              <li>отслеживания и анализа закупок для повышения эффективности</li>
            </ul>
          </div>,
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Какие меры безопасности предусмотрены на платформе Solber для защиты данных?',
      description:
          <div>
            <p>Solber для покупателей применяет современные технологии для защиты данных, включая:</p>
            <ul style={{ paddingLeft:'20px' }}>
              <li>шифрование данных</li>
              <li>многофакторную аутентификацию</li>
              <li>регулярные проверки безопасности</li>
              <li>политику конфиденциальности, соответствующую международным стандартам</li>
            </ul>
          </div>,
      link: '',
      isLink: false,
      hrefLink: ''
    }],

  carrier:[
    {
      'title': 'В чем практическая польза вашего сервиса?',
      description: 'В первую очередь данный сервис призван экономить ваше время во время перевозки сыпучих материалов.' +
          ' Все ваши рейсы собраны и хранятся в одном месте, вся необходимая информация у вас перед глазами' +
          ' и вы контролируете каждый из этапов перевозки. От назначения машины на рейс до выполнения доставки.',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Почему у меня нет доступа к рейсам?',
      description: 'Прежде чем приступать к работе, пожалуйста, проверьте ваш профиль. Доступ будет ограничен пока вы ',
      link: 'не заполните все необходимые данные',
      isLink: true,
      hrefLink: '/profile'
    },
    {
      'title': 'Зачем нужно заполнять личные данные?',
      description: 'Это необходимо в целях безопасности в случае возникновения экстренных ситуаций',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Я заполнил данные в профиле, но все равно не вижу рейсы, почему?',
      description: 'Данные постоянно обновляются и для их обработки серверу необходимо время.' +
          ' Попробуйте немного подождать и обновить страницу.' +
          ' Если это не помогло, свяжитесь с нашим диспетчером по телефону, либо напишите нам на почту: help@solber.ru',
      link: '',
      isLink: false,
      hrefLink: ''
    },
    {
      'title': 'Как правильно заполнять ТН?',
      description:
        <div style={{ listStyle:'none',display:'flex',flexDirection:'column',gap:'12px' }}>
          <div>
            <li>1. Должны быть заполнены: дата, данные грузоотправителя, данные грузополучателя и перевозчика, вид груза,  объем груза, размер провозной платы.
              В разделах 6, 7, 15, 16 должны быть наименования должностей, подписи и расшифровки подписей.</li>
            <li>2. Печать на транспортной накладной.</li>
            <li>3. В строках можно ставить прочерки, если для их заполнения нет информации.
              Разделы с отметкой «при необходимости» заполнять не обязательно (п. 10 Правил перевозок груза автомобильным транспортом,
              Письмо Минтранса России от 26.03.2021 № Д3/6976-ИС).</li>
            <li>4. Перевозчик заполняет шапку Транспортной накладной: дату, номер экземпляра (документ составляется в количестве трех штук)/</li>
            <li>5. Также заполняются Перевозчиком:</li>
          </div>
          <p>
            <strong>Раздел 2 «Грузополучатель».</strong> Укажите ФИО физлица или наименование организации, ИНН, адрес, телефон.
          </p>
          <p>
            <strong>Раздел 3 «Груз».</strong> Заполняется - наименование, количество грузовых мест, масса и габариты. .
          </p>
          <p>
            <strong>Раздел 4 «Сопроводительные документы на груз».</strong> В этом разделе надо привести реквизиты прилагаемых к накладной документов.
            Сюда входят товарные накладные, УПД, сертификаты, паспорта, разрешения на груз и пр.
            При их наличии у налоговой будет меньше причин сомневаться в сделке.
          </p>
          <p>
            <strong>Раздел 5 «Указания грузоотправителя».</strong> В этом разделе нужно прописать указания, которые транспортная компания
            должна соблюдать во время перевозки груза: сроки, температурный режим, санитарные и карантинные требования и пр.
            Дополнительно указываются характеристики автомобиля, на котором повезут груз: тип, марка, грузоподъемность, вместимость и пр.
          </p>
          <p>
            <strong>Раздел 6 «Прием груза».</strong> Впишите данные лица, от которого перевозчик забирает груз, адрес склада, дату и время получения груза.
            При погрузке указываются уже фактические дата и время прибытия и отбытия машины, описывается состояние груза, его масса.
            Раздел должен быть заверен подписями лица, у которого забирают груз, водителя и грузоотправителя (при необходимости) с проставлением печатей.
          </p>
          <p>
            Не отражайте данные о лице, которому до погрузки передали груз по документам,
            если фактически его не перемещали (Письмо Минтранса России от 26.03.2021 № Д3/6976-ИС).
          </p>
          <p>
            <strong>Раздел 7 «Сдача груза».</strong> Тут отражается адрес места выгрузки (склад покупателя, терминал транспортной компании), дата и время доставки.
          </p>
          <p>
            <strong>Раздел 15 «Подписи сторон» и раздел 16 «Стоимость услуг перевозчика и порядок расчета провозной платы».</strong> Должностное
            лицо грузоотправителя ставит свою подпись, указывает ФИО и должность.
          </p>
          <p>
            При погрузке водитель перевозчика ставит свою подпись в разделе 6 «Прием груза», а при разгрузке — в разделе 7 «Сдача груза».
            Перевозчик самостоятельно заполняет разделы 8-11, 13, 15 и 16. При необходимости можно заполнить разделы 12, 14 и 17.
          </p>
          <p>
            <strong>Раздел 8 «Условия перевозки».</strong> Здесь перевозчик прописывает, когда груз можно считать утраченным, размер платы за хранение в терминале,
            размеры штрафов за невывоз груза, задержку транспорта и пр. Часто его оставляют пустым — это значит,
            что все условия определяются по общим правилам Устава автомобильного транспорта.
          </p>
          <p>
            <strong>Раздел 9 «Информация о принятии к исполнению».</strong> В разделе проставляется дата приема заказа, ФИО и должность принявшего и его подпись.
            У перевозчика есть три дня на рассмотрение заявки грузоотправителя.
          </p>
          <p>
            <strong>Раздел 10 «Перевозчик».</strong> Заполняется сведениями о перевозчике: наименование или ФИО, ИНН, адрес, номер телефона.
            Дополнительно указываются информация о водителе и его контакты.
          </p>
          <p>
            <strong>Раздел 11 «Транспортное средство».</strong> Перевозчик указывает сведения об автомобиле, на котором повезут груз:
            тип, марку, грузоподъемность, вместимость, регномер. Дополнительно надо выбрать тип владения: собственность, лизинг или аренда.
          </p>
          <p>
            Если транспорт не соответствует требованиям грузоотправителя, заявленным в разделе 5,
            то он может отказаться от погрузки (п. 31 Правил, утв. постановлением Правительства от 21.12.2020 № 2200).
          </p>
          <p>
            <strong>Раздел 12 «Оговорки и замечания перевозчика».</strong> Если при погрузке у перевозчика появились вопросы к состоянию самого груза,
            упаковке, маркировке, таре и пр., их можно отметить в этом разделе.
          </p>
          <p>
            <strong>Раздел 13 «Прочие условия».</strong> Здесь указывают номер, дату и срок действия разрешения на перевозку, данные о маршруте
            при перевозке крупногабаритных или опасных грузов, режиме работы водителей.
          </p>
          <p>
            <strong>Раздел 14 «Переадресовка».</strong> Этот раздел заполняет водитель, если во время поездки изменился адрес доставки.
          </p>
          <p>
            <strong>Раздел 15 «Подписи сторон».</strong> должностное лицо перевозчика ставит свою подпись, указывает ФИО и должность.
          </p>
          <p>
            <strong>Раздел 16 «Стоимость услуг перевозчика и порядок расчета провозной платы».</strong>
            Перевозчик указывает стоимость услуги по перевозке, провозную плату и порядок ее расчета,
            расходы перевозчика, НДС, наименование, адрес и банковские реквизиты грузоотправителя и перевозчика.
            Также тут проставляются подписи должностных лиц с расшифровкой.
          </p>
          <p>
            Важно! Транспортная накладная подтверждает расходы на перевозку, если в ней есть все необходимые реквизиты
            и правильно заполнен раздел 16 (письма ФНС от 19.04.2021 № СД-4-2/5238@, от 20.02.2021 № СД-3-3/1280@).
            Если нет раздела 16, к транспортной накладной должен прилагаться другой первичный документ, который определяет цену перевозки.
            Например, универсальный передаточный документ.
          </p>
            <a href={pdf} target="_blank">Показать образец</a>
        </div>
      ,
      isLink: false,
      hrefLink: ''
    }]
};