import React from 'react';

const BurgerMenuSvg = () => {
  return (
    <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="2" rx="1" fill="#FF621A" />
      <rect y="6" width="20" height="2" rx="1" fill="#FF621A" />
      <rect y="12" width="16" height="2" rx="1" fill="#FF621A" />
    </svg>
  );
};

export default BurgerMenuSvg;
