import React from 'react';

export const DocumentSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="text-box-multiple-outline">
        <path
          id="Vector"
          d="M16 15H9V13H16V15ZM19 11H9V9H19V11ZM19 7H9V5H19V7ZM3 5V21H19V23H3C2.46957 23 1.96086 22.7893 1.58579 22.4142C1.21071 22.0391 1 21.5304 1 21V5H3ZM21 1C21.5304 1 22.0391 1.21071 22.4142 1.58579C22.7893 1.96086 23 2.46957 23 3V17C23 18.11 22.11 19 21 19H7C6.46957 19 5.96086 18.7893 5.58579 18.4142C5.21071 18.0391 5 17.5304 5 17V3C5 1.89 5.89 1 7 1H21ZM7 3V17H21V3H7Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
