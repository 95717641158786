import { fetchAuth } from './fetch/fetch-auth';
import { SUBSERVER } from './sub-server.constant';

const recordToParams = (data: Record<string, any>) => Object.entries(data).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

export const fetchGet = async (url: string, data: any) =>
    fetchAuth(`${SUBSERVER}/api${url}?${recordToParams({ ...data, time: +(new Date()) })}`, { method: 'get',
    credentials:'include', });

export const fetchPost = async (url: string, method: string, data: any) =>
  fetchAuth(`${SUBSERVER}/api${url}`, {
    method,
    credentials:'include',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data)
});