import React, { useEffect, useState } from 'react';
import Select from 'react-select';

type Props = {
  options: any;
  setValue: any;
  placeholder: string;
  isClearable: boolean;
  error: boolean;
  isSearchable: boolean;
  noOptionsMessage: string;
  height40px?:boolean;
  [key: string]: any
}

export const CustomSelect = ({
    value,updateDate=false,
                               errorAfterSend=false,
                               setErrorAfterSend,
    validateErrorWithSend=false,
                               error,
                               options,
                               setValue,
                               placeholder,
                               isClearable,
                               noOptionsMessage,
                               height40px,
                               isSearchable, ...props }: Props) => {
  const styles = {

  };
    const [valueError,setValueError]=useState(false);
useEffect(()=>{
    if(!errorAfterSend && !value){
        setValueError(false);
    }else{
        setValueError(true);
    }
},[updateDate]);
    return (
    <Select
        onFocus={()=>setValueError(false)}
      {...props}
        value={value}
      placeholder={placeholder}
      styles={styles}
      isClearable={isClearable}
      isSearchable={isSearchable}
      className={`${height40px ? `CustomSelectHeight40px` : `CustomSelect` } ${(validateErrorWithSend?(error && valueError):error) ? 'error' : ''}`}
      classNamePrefix={ `${height40px ? `CustomSelectHeight40px` : `CustomSelect` }` }
      options={options}
      noOptionsMessage={({ inputValue }) => !inputValue && noOptionsMessage}
      onChange={setValue}
    />
  );
};