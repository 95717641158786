// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TodayEarnings_container__1YoMz {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  height: 104px;\n  align-items: center;\n  border-radius: 12px;\n  padding-right: 16px;\n  border: 1px solid #EDF0F9;\n  background: #FFF;\n  box-shadow: 0px 4px 8px 0px rgba(149, 187, 232, 0.13);\n}\n\n.TodayEarnings_firstContentBlock__hpbi2 {\n  border-right: 1px solid #DBE7FF;\n  padding-right: 14px;\n}\n\n.TodayEarnings_ContentBlockTitle__6MUIt {\n  color: #9BA1B4;\n  font-family: LiberationSans;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/MainPages/MainPageDriver/Components/TodayEarnings/TodayEarnings.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,qDAAA;AACF;;AACA;EACE,+BAAA;EACA,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGF","sourcesContent":[".container{\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  height: 104px;\n  align-items: center;\n  border-radius: 12px;\n  padding-right: 16px;\n  border: 1px solid  #EDF0F9;\n  background: #FFF;\n  box-shadow: 0px 4px 8px 0px rgba(149, 187, 232, 0.13);\n}\n.firstContentBlock{\n  border-right: 1px solid #DBE7FF;\n  padding-right: 14px;\n}\n.ContentBlockTitle{\n  color: #9BA1B4;\n  font-family: LiberationSans;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TodayEarnings_container__1YoMz",
	"firstContentBlock": "TodayEarnings_firstContentBlock__hpbi2",
	"ContentBlockTitle": "TodayEarnings_ContentBlockTitle__6MUIt"
};
export default ___CSS_LOADER_EXPORT___;
