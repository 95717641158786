export type ErrorFields = {
  errorName:boolean;
  errorType:boolean;
  /*errorLatitude:boolean;
  errorLongitude:boolean;*/
  errorTimeTo:boolean;
  errorTimeFrom:boolean;
  errorNumberOfDispatcher:boolean;
  errorPerson:boolean;
  errorEmail:boolean;
  errorNumber:boolean;
  additionalEmployee:{
    errorPerson:boolean;
    errorEmail:boolean;
    errorNumber:boolean;
  }[]
}
export const errorFieldsData : ErrorFields = {
  errorName:true,
  errorType:true,
  /*errorLatitude:true,
  errorLongitude:true,*/
  errorNumberOfDispatcher:true,
  errorTimeFrom:false,
  errorTimeTo:false,
  errorPerson:true,
  errorEmail:true,
  errorNumber:true,
  additionalEmployee:[]
};

export type QuarryData = {
  name: string;
  quarryType: string;
  dispatcherPhone: string;
  time: string;
  address: {
    address: string;
    latitude: number;
    longitude: number;
  };
  workingHoursType: {
    name: string;
    workTo: any;
    defaultWorkTo?: any;
    workFrom: any;
    defaultWorkFrom?: any;
  };
  contactPersons: any;
  responsibleEmployee: {
    person: {
      person: string;
      error: boolean;
    };
    phone: {
      phone: string;
      error: boolean;
    };
    email: {
      email: string;
      error: boolean;
    };
  };
  files: [];
};

export type QuarryDataOptions = {
  [key: string]: string;
}[];

export type SupplierQuarriesData = {
  name: {
    name: string;
    error: boolean;
  };
  quarryType: {
    quarryType: any;
    error: boolean;
  };
  dispatcherPhone: {
    dispatcherPhone: string;
    error: boolean;
  };
  time: {
    time: string;
    error: boolean;
  };
  address: {
    address: {
      address: string;
      error: boolean;
    };
    latitude: number | null;
    longitude: number | null;
  };
  workingHoursType: {
    name: string;
    workTo: any;
    defaultWorkTo?: any;
    workFrom: any;
    defaultWorkFrom?: any;
  };
  responsibleEmployee: {
    person: {
      person: string;
      error: boolean;
    };
    phone: {
      phone: string;
      error: boolean;
    };
    email: {
      email: string;
      error: boolean;
    };
  };
  files: [];
};

export const supplierData: SupplierQuarriesData = {
  name: {
    name: '',
    error: false,
  },
  quarryType: {
    quarryType: '',
    error: false,
  },
  dispatcherPhone: {
    dispatcherPhone: '',
    error: false,
  },
  time: {
    time: '',
    error: false,
  },
  address: {
    address: {
      address: '',
      error: false,
    },
    latitude: null,
    longitude: null,
  },
  workingHoursType: {
    name: 'aroundTheClock',
    workTo: '',
    workFrom: '',
  },
  responsibleEmployee: {
    person: {
      person: '',
      error: false,
    },
    phone: {
      phone: '',
      error: false,
    },
    email: {
      email: '',
      error: false,
    },
  },
  files: [],
};

export const quarryData: QuarryData = {
  name: '',
  quarryType: '',
  time: '',
  dispatcherPhone: '',
  address: {
    address: '',
    latitude: null,
    longitude: null,
  },
  workingHoursType: {
    name: 'Круглосуточная',
    workTo: '',
    workFrom: '',
  },
  contactPersons: [],
  responsibleEmployee: {
    person: {
      person: '',
      error: false,
    },
    phone: {
      phone: '',
      error: false,
    },
    email: {
      email: '',
      error: false,
    },
  },
  files: [],
};

export const quarryDataOptions: QuarryDataOptions = [
  { value: 'quarry', label: 'Карьер' },
  { value: 'transshipment', label: 'Перевалка' },
];
