import React from 'react';
import styles from './TitleAndSubtitle.module.scss'
interface IProps {
    title:string
}
const TitleMain = ({title}:IProps) => {
    return (
        <div className={styles.title}>
            {title}
        </div>
    );
};

export default TitleMain;