import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const RequestItem = ({
  price,
  number,
  numberTn,
  date,
  nameLoading,
  addressLoading,
  nameUnloading,
  addressUnloading,
  volume,
  measurement,
  cargo,
  status,
  itemFlight,
  isCompleted,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  if (measurement === 'тонн') measurement = 'тн';
  return (
    <div {...props} className={`requestItem ${isCompleted ? 'completed' : ''}`}>
      {isMobile ? (
        <>
          <div className="requestItem__box requestItem__box-driver line">
            {isCompleted ? (
              <div className="requestItem__subBox">
                <div>
                  <div className="requestItem__text">{number ? `Рейс ${number}` : 'Номер рейса'}</div>
                  <div className="requestItem__text">{numberTn ? `№TH: ${numberTn}` : ''}</div>
                </div>
                <div className="requestItem__subTitle">{date || 'Дата поставки'}</div>
              </div>
            ) : (
              <div className="requestItem__subBox">
                <div>
                  <div className="requestItem__text">{number ? `Рейс ${number}` : 'Номер рейса'}</div>
                  <div className="requestItem__subTitle">{date || 'Дата поставки'}</div>
                  <div className="requestItem__subTitle">{numberTn ? `TH: ${numberTn}` : ''}</div>
                </div>
                {isCompleted ? <></> : <div className={`requestItem__title ${isMobile ? '' : 'line'}`}>{status}</div>}
              </div>
            )}
          </div>
          <div className={`requestItem__box requestItem__box-driver line`}>
            <div className="requestItem__container">
              <div className="requestItem__title">{`${volume ? volume : 0}`}</div>
              {volume !== 'Нет данных' && (
                <div className="requestItem__title">{`${measurement ? measurement : ''}`}</div>
              )}
            </div>
            <div className="requestItem__subTitle">
              {cargo || 'Описание (материал, тип, фракция, насыпная плотность и тд)'}
            </div>
          </div>
        </>
      ) : (
        <div className="requestItem__box requestItem__box-driver line">
          <div className="requestItem__title">{date || 'Дата поставки'}</div>
          <div className="requestItem__subTitle">{number ? `Рейс ${number}` : 'Номер рейса'}</div>
          <div className="requestItem__subTitle">{numberTn ? `TH: ${numberTn}` : ''}</div>
        </div>
      )}
      <div className="requestItem__box requestItem__box-driver line">
        <div className="requestItem__title">{nameLoading || 'Название компании А'}</div>
        <div className="requestItem__subTitle">{addressLoading || 'Адрес погрузки'}</div>
      </div>
      <div className="requestItem__box requestItem__box-driver line">
        <div className="requestItem__title">{nameUnloading || 'Название компании Б'}</div>
        <div className="requestItem__subTitle">{addressUnloading || 'Адрес выгрузки'}</div>
      </div>
      <div className="requestItem__box requestItem__box-driver line">
        <div className="requestItem__title">{`${itemFlight?.car?.carBrand?.brand || 'Нет марки ТС'}`}</div>
        <div className="requestItem__subTitle">{`${itemFlight?.car?.stateNumber || 'Нет номера ТС'}`}</div>
      </div>
      {!isMobile && (
        <div className={`requestItem__box requestItem__box-driver line`}>
          <div className="requestItem__container">
            <div className="requestItem__title">{`${volume ? volume : 0}`}</div>
            {volume !== 'Нет данных' && <div className="requestItem__title">{`${measurement ? measurement : ''}`}</div>}
          </div>
          <div className="requestItem__subTitle">
            {cargo || 'Описание (материал, тип, фракция, насыпная плотность и тд)'}
          </div>
        </div>
      )}

      <div
        className={`requestItem__box requestItem__box-driver`}
        style={{ marginLeft: `${!isMobile ? '8px' : ''}`, alignItems: `${!isMobile ? 'flex-start' : ''}` }}
      >
        {isCompleted || isMobile ? (
          <></>
        ) : (
          <div className={`requestItem__title ${isMobile ? '' : 'line'}`}>{status}</div>
        )}
        {isMobile ? (
          <div className="requestItem__subBox">
            <div className="requestItem__subTitle">Стоимость</div>
            <div className="requestItem__price-text">{price ? price + ' ' + '₽' : 'Цена не назначена'}</div>
          </div>
        ) : (
          <div className="requestItem__title">{price ? price + ' ' + '₽' : 'Цена не назначена'}</div>
        )}
      </div>
    </div>
  );
};
