import React from 'react';

type Props = {
    children: string;
    field: boolean;
    [key: string]: any;
}

export const TitleName = ({ children, field, ...props }: Props) => {
    return (
        <div {...props} className="titleName">
            {children}{field && <span>*</span>}
        </div>
    );
};