import React from 'react';

type Props = {
  children: any;
  gridTemplateColumns?: string;
  [key: string]: any;
};

export const SelectBox = ({ children, gridTemplateColumns, ...props }: Props) => {
  return (
    <div {...props} className={'select-box'} style={{ gridTemplateColumns: gridTemplateColumns }}>
      {children}
    </div>
  );
};
