import React from 'react';
import { SearchSVGCarrier } from '../svg-icon/SearchSVGCarrier';

export const InputSearch = ({ value, onInput, placeholder }) => {
  return (
    <div className="InputSearch">
      <div className="InputSearch__svg">
        <SearchSVGCarrier />
      </div>
      <input className="InputSearch__input" type="text" onInput={onInput} value={value} placeholder={placeholder} />
    </div>
  );
};
