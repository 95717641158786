import React from 'react';

const SuccessVer2Svg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148" fill="none">
            <path d="M79.7547 27.9766H53.6118C47.7492 27.9766 43.0006 32.5719 43.0006 38.2453V94.7078C43.0006 100.381 47.7492 104.977 53.6118 104.977H93.3894C99.252 104.977 104.001 100.381 104.001 94.7078V51.44C104.001 48.7173 102.88 46.1054 100.894 44.1774L87.2595 30.9826C85.2671 29.0546 82.5682 27.9766 79.7547 27.9766Z" fill="url(#paint0_linear_22086_112500)"/>
            <g filter="url(#filter0_b_22086_112500)">
                <path d="M78.7743 72.0829V67.1468C78.7743 63.7408 75.9948 60.9766 72.57 60.9766H37.205C33.7802 60.9766 31.0006 63.7408 31.0006 67.1468V110.968C31.0006 115.392 34.6054 118.977 39.0539 118.977H107.947C112.396 118.977 116.001 115.392 116.001 110.968V80.0919C116.001 75.6678 112.396 72.0829 107.947 72.0829H78.7743Z" fill="url(#paint1_linear_22086_112500)"/>
                <path d="M77.7743 72.0829V73.0829H78.7743H107.947C111.849 73.0829 115.001 76.2253 115.001 80.0919V110.968C115.001 114.834 111.849 117.977 107.947 117.977H39.0539C35.1524 117.977 32.0006 114.834 32.0006 110.968V67.1468C32.0006 64.2983 34.3272 61.9766 37.205 61.9766H72.57C75.4477 61.9766 77.7743 64.2983 77.7743 67.1468V72.0829Z" stroke="url(#paint2_linear_22086_112500)" stroke-width="2"/>
            </g>
            <defs>
                <filter id="filter0_b_22086_112500" x="13.0006" y="42.9766" width="121" height="94" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_22086_112500"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_22086_112500" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_22086_112500" x1="45.0439" y1="33.6746" x2="93.2068" y2="104.878" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFDC7C"/>
                    <stop offset="1" stop-color="#F78D1E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_22086_112500" x1="35.7994" y1="115.702" x2="83.7083" y2="45.4907" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.2"/>
                    <stop offset="1" stop-color="white" stop-opacity="0.49"/>
                </linearGradient>
                <linearGradient id="paint2_linear_22086_112500" x1="37.0007" y1="65.5382" x2="81.0713" y2="134.596" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SuccessVer2Svg;