import styles from './EmployeesPage.module.scss';
import React from 'react';
import { IEmployees } from './type';
import { regularPhone } from '../../common/regular-phone';

export const initStateEmployee: IEmployees = {
    name: '', 
    profile: {
        email:''
    },
    patronymic: '', 
    phone: '', 
    surname: '',
    type: {
        value: '',
        label: '',
  },
};

export const typeTranslations = {
  accountant: 'Бухгалтер',
  manager: 'Менеджер',
  admin: 'Администратор',
};
export const columnsEmployees = [
  {
    title: 'Фамилия Имя Отчество',
    dataIndex: 'fullName',
    width: 400,
    key: 'fullName',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '20px 0px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
          <p className={styles.text}>{text?.surname} {text?.name} {text?.patronymic}</p>
        </div>
        <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
      </div>
    ),
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    width: 150,
    key: 'phone',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <p className={styles.text}>{regularPhone(text)}</p>
        <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
      </div>
    ),
  },
  {
    title: 'Должность',
    dataIndex: 'type',
    width: 150,
    key: 'type',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <p className={styles.text}>{typeTranslations[text]}</p>
      </div>
    ),
  },
];
export const textTooltipByTypeOfUser = {
  carrier:
      <p>
        Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
        Менеджер – доступны разделы: Диспетчеризация, Рейсы и Профиль <br />
        Бухгалтер – доступны разделы: Взаиморасчеты, УПД и Профиль
      </p>,
  buyer:
      <p>
        Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
        Менеджер – доступны разделы: Заказы, Объекты, Договора и спецификации, Профиль <br />
        Бухгалтер – доступны разделы: Взаиморасчеты, УПД, Договора и спецификации, Профиль
      </p>,
  supplier:
      <p>
        Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
        Менеджер – доступны разделы: Отгрузки, Карьеры, Договора и спецификации, Профиль <br />
        Бухгалтер – доступны разделы: Взаиморасчеты, УПД, Договора и спецификации, Профиль
      </p>,
};
