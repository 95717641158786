import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import ArrowLeftSvg from '../../components/UI/svg-icon/ArrowLeftSvg';
import styles from './DispatchingItemPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgressLine from './Components/ProgressLine/ProgressLine';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { Drawer, Input, Modal } from 'antd';
import AddPlanModal from './Components/PlanModals/AddPlanModal';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { IItemData, IItemPageValueReason, IItemTableData, ISelect } from './types';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { columnsDispatchingItemTable } from './Constans/Constans';
import EditPlanModal from './Components/PlanModals/EditPlanModal';
import { toast } from 'react-toastify';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { reasonList } from './Components/DistributionAndSubmbitRequest/constans';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import FrameModalSvg from '../../components/UI/svg-icon/FrameModalSvg';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import InfoBlockOfDispatchingItem from './Components/InfoBlockOfDispatchingItem/InfoBlockOfDispatchingItem';
import InfoStatusOfRequest from './Components/InfoStatusOfRequest/InfoStatusOfRequest';
import MobileTableDispatching from './Components/MobileTableDispatching/MobileTableDispatching';
import { nomenclatureUnitMapping } from '../../common/nomenclature-unit.mapping';
const { TextArea } = Input;

export const DispatchingItemPage = () => {
  const [isRefuseOpen, setIsRefuseOpen] = useState(false);
  const [infoData, setInfoData] = useState<IItemData>();
  const [infoDataEdit, setInfoDataEdit] = useState<IItemData>();
  const [tableData, setTableData] = useState<IItemTableData[]>();
  const [openAddPlan, setOpenAddPlan] = useState(false);
  const [openEditPlan, setOpenEditPlan] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [valueReason, setValueReason] = useState<IItemPageValueReason>({ value: '', label: '' });
  const [commentValue, setCommentValue] = useState<string>('');
  const [driversList, setDriversList] = useState<ISelect[]>([]);
  const [carsList, setCarsList] = useState<ISelect[]>([]);
  const [focusedSelectDrawer, setFocusedSelectDrawer] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const getDrivers = async () => {
    try {
      const drivers = await fetchGet(`/drivers/list`, {});

      const driversListArray = [];
      for (const driver of drivers.response) {
        driversListArray.push({
          value: driver?.id,
          label: driver?.fullName,
        });
      }

      await setDriversList(
        driversListArray.filter(
          (value, index, array) => array.findIndex(value2 => value2?.label === value?.label) === index,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  };
  const getCars = async () => {
    try {
      const cars = await fetchGet(`/cars/list`, {});
      const currentCars = cars.response;
      const carsListArray = [];
      for (const car of currentCars) {
        carsListArray.push({
          value: car?.id,
          label: `${car?.carBrand?.brand || ''} ${car?.stateNumber}`,
          volumeBody: car?.volumeBody,
        });
      }
      await setCarsList(
        carsListArray.filter(
          (value, index, array) => array.findIndex(value2 => value2?.label === value?.label) === index,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDrivers();
    getCars();
  }, []);

  const location = useLocation();
  const validationRefuseButton = (): boolean => {
    if (valueReason?.value === 'Другая проблема (введите комментарий)' && commentValue) {
      return false;
    } else return !(valueReason?.value && valueReason?.value !== 'Другая проблема (введите комментарий)');
  };
  const sendRejection = async () => {
    try {
      const response = await fetchPost(
        `/dispatching/reject-the-application/${location.pathname.split('/')[2]}`,
        'PATCH',
        {
          comment: valueReason?.value === 'Другая проблема (введите комментарий)' ? commentValue : valueReason?.value,
        },
      );
      if (response?.error) {
        const errorMessages = response?.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setIsRefuseOpen(false);
        return;
      }
      setIsRefuseOpen(false);
      toast.success('Вы успешно отказались от заявки');
      navigate('/dispatching');
    } catch (e) {
      console.log(e);
    }
  };
  const sendRejectionRemain = async () => {
    try {
      const response = await fetchPost(
        `/dispatching/reject-unallocated-volume/${location.pathname.split('/')[2]}`,
        'PATCH',
        {
          comment: valueReason?.value === 'Другая проблема (введите комментарий)' ? commentValue : valueReason?.value,
        },
      );
      if (response?.error) {
        const errorMessages = response?.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setIsRefuseOpen(false);
        return;
      }
      setIsRefuseOpen(false);
      getData();
      toast.success('Вы успешно отказались от остатка');
    } catch (e) {
      console.log(e);
    }
  };
  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetchGet(`/dispatching/${location.pathname.split('/')[2]}`, {});
      const responseInfoData = response?.response;
      const responseTableData = response?.response?.flightsInfo;
      setInfoData(responseInfoData);
      setTableData(
        responseTableData?.map(item => {
          return {
            ...item,
            car: {
              carModel: item?.carModel,
              carStateNumber: item?.carStateNumber,
            },
            volumeOfMaterial: {
              flightsSumVolume: item?.flightsSumVolume,
              unit: correctVisionOfUnit(
                response?.response?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit,
              ),
            },
          };
        }),
      );
      if (response?.error) {
        const errorMessages = response?.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCommentValue('');
  }, [valueReason]);
  const getItemDataEdit = data => {
    setInfoDataEdit(data);
  };
  const correctVisionOfUnit = (unit: string): string => {
    if (unit === 'тонн') {
      return 'тн';
    } else {
      return 'м³';
    }
  };
  const showModal = () => {
    setOpenAddPlan(true);
  };
  const openRefuseModal = () => {
    setIsRefuseOpen(true);
  };
  const closeRefuseModal = () => {
    setIsRefuseOpen(false);
  };
  const onMenuClose = () => setFocusedSelectDrawer(false);
  const onMenuOpen = () => setFocusedSelectDrawer(true);
  const remainVolume = (): number => {
    return infoData?.volume - infoData?.distributedVolume;
  };
  const changeHeightDrawer = (): number => {
    if (valueReason?.value === 'Другая проблема (введите комментарий)' && !focusedSelectDrawer) {
      return 470;
    } else if (focusedSelectDrawer) {
      return 692;
    } else {
      return 410;
    }
  };
  const validationDisableByDate = (): boolean => {
    let now = new Date();
    let date = new Date(infoData?.acceptanceTime);
    const formatFullDateOfDisableOfRequest = date.setHours(date.getUTCHours() + 24);
    const formatNow = now.setHours(now.getUTCHours());
    return formatFullDateOfDisableOfRequest < formatNow;
  };
  const validationOfPlanning = !(infoData?.status?.title === 'Выполненная');

  return (
    <PageWrapper>
      {loading ? (
        <div className={styles.loading}>
          <div>
            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.backButtonContainer}>
            <div className={styles.backButtonContent} onClick={() => navigate('/dispatching')}>
              <div className={styles.iconBackButton}>
                <ArrowLeftSvg />
              </div>
              <p className={styles.textBackButton}>К списку заявок</p>
            </div>
          </div>
          <div className={styles.requestContainer}>
            <div style={{ display: isMobile ? 'block' : 'flex' }}>
              <div
                className={
                  infoData?.status?.title !== 'Выполненная'
                    ? styles.leftSideInfoRequestContainer
                    : styles.leftSideInfoRequestContainerNoButtons
                }
              >
                <div className={styles.titleAndInfoContainer}>
                  <p className={styles.textTitleContainer}>{infoData?.orderNumber}</p>
                  <InfoStatusOfRequest infoData={infoData} />
                </div>
                <div
                  className={
                    infoData?.status?.title === 'Выполненная' && isMobile
                      ? styles.distributionContainerAndProgressLineWithBorder
                      : styles.distributionContainerAndProgressLine
                  }
                >
                  <ProgressLine infoData={infoData} remainVolume={remainVolume} />
                </div>
                {infoData?.status?.title !== 'Выполненная' && (
                  <div className={styles.buttonsItem}>
                    <ButtonFilled
                      width={'100%'}
                      disabled={
                        infoData?.status?.title !== 'Активная' || validationDisableByDate() || remainVolume() === 0
                      }
                      onClick={showModal}
                      text={'Добавить планирование'}
                      svg={<NewPlusSvg />}
                    />
                    <ButtonNotFilled
                      width={'100%'}
                      text={tableData?.length ? 'Отказаться от остатка' : 'Отказаться от заявки'}
                      onClick={openRefuseModal}
                      disabled={
                        infoData?.distributedVolume === infoData?.volume || infoData?.status?.title === 'Отклонена'
                      }
                    />
                  </div>
                )}
                <AddPlanModal
                  setOpenAddPlan={setOpenAddPlan}
                  openAddPlan={openAddPlan}
                  infoData={infoData}
                  getData={getData}
                  driversList={driversList}
                  carsList={carsList}
                />
              </div>
              {!isMobile && <div className={styles.borderRequestContainer} />}
            </div>
            <InfoBlockOfDispatchingItem infoData={infoData} />

            {isMobile ? (
              /*-------------Мобильная версия-----------------------------*/
              <Drawer
                onClose={closeRefuseModal}
                open={isRefuseOpen}
                placement={`bottom`}
                closeIcon={false}
                zIndex={9500}
                height={changeHeightDrawer()}
                style={{ borderRadius: '12px 12px 0px 0px' }}
              >
                <div className={styles.mobileContainerDrawer}>
                  <div className={styles.drawerTriangleContainer}>
                    <div className={styles.drawerTriangle} />
                  </div>
                  <div className={styles.mobileCloseIconContainerDrawer}>
                    <div className={styles.mobileCloseIconDrawer} onClick={closeRefuseModal}>
                      <CloseSvg />
                    </div>
                  </div>
                  <div className={styles.mobileTitleAndSubtitleTextDrawerContainer}>
                    <div className={styles.mobileTitleTextDrawer}>
                      {tableData?.length ? 'Отказаться от остатка' : 'Отказаться от заявки'}
                    </div>
                    <div className={styles.mobileSubTitleTextDrawer}>
                      {tableData?.length ? (
                        <p>
                          Выберите причину, почему вы хотите отказаться от нераспределенного объема в {remainVolume()}{' '}
                          {
                            nomenclatureUnitMapping[
                              infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit
                            ]
                          }{' '}
                          ?
                        </p>
                      ) : (
                        <p>
                          Выберите причину, почему вы хотите отказаться от заказа&nbsp;
                          <span className={styles.mobileOrderTextDrawer}>{infoData?.orderNumber} ?</span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.mobileSelectDrawerContainer}>
                    <CustomSelect
                      minMenuHeight={300}
                      menuShouldScrollIntoView={false}
                      onMenuClose={onMenuClose}
                      onMenuOpen={onMenuOpen}
                      error={false}
                      options={reasonList}
                      placeholder={'Выберите из списка'}
                      noOptionsMessage={'Нет вариантов'}
                      setValue={value => setValueReason(value)}
                      isSearchable={false}
                      isClearable={false}
                    />
                    {valueReason.value === 'Другая проблема (введите комментарий)' && (
                      <TextArea
                        showCount
                        maxLength={300}
                        placeholder={'Напишите логичную причину для отказа в этом поле...'}
                        onChange={e => setCommentValue(e.target.value)}
                        disabled={false}
                        className={styles.mobileOtherReasonText}
                      />
                    )}
                  </div>
                  <div className={styles.mobileButtonsDrawer}>
                    <ButtonFilled
                      text={'Да, хочу отказаться'}
                      width={'100%'}
                      onClick={tableData?.length ? sendRejectionRemain : sendRejection}
                      disabled={validationRefuseButton()}
                    />
                    <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={closeRefuseModal} />
                  </div>
                </div>
              </Drawer>
            ) : (
              /*-------------ПК версия-----------------------------*/
              <Modal
                zIndex={9500}
                width={464}
                footer={null}
                open={isRefuseOpen}
                onCancel={closeRefuseModal}
                closeIcon={false}
                className={styles.modal}
              >
                <div className={styles.containerModal}>
                  <div className={styles.titleAndSubtitleTextModalContainer}>
                    <div className={styles.titleTextModal}>
                      {tableData?.length ? 'Отказаться от остатка' : 'Отказаться от заявки'}
                    </div>
                    <div style={{ marginBottom: '12px' }} className={styles.subTitleTextModal}>
                      {tableData?.length ? (
                        <p>
                          Выберите причину, почему вы хотите отказаться от нераспределенного объема в {remainVolume()}{' '}
                          {
                            nomenclatureUnitMapping[
                              infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit
                            ]
                          }{' '}
                          ?
                        </p>
                      ) : (
                        <p>
                          Выберите причину, почему вы хотите отказаться от заказа&nbsp;
                          <span className={styles.orderTextModal}>{infoData?.orderNumber} ?</span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div style={{ marginBottom: '32px' }}>
                    <CustomSelect
                      error={false}
                      options={reasonList}
                      placeholder={'Выберите из списка'}
                      noOptionsMessage={'Нет вариантов'}
                      setValue={value => setValueReason(value)}
                      isSearchable={false}
                      isClearable={false}
                    />

                    {valueReason?.value === 'Другая проблема (введите комментарий)' && (
                      <TextArea
                        showCount
                        maxLength={300}
                        placeholder={'Напишите логичную причину для отказа в этом поле...'}
                        onChange={e => setCommentValue(e.target.value)}
                        disabled={false}
                        className={styles.otherReasonTextModal}
                      />
                    )}
                  </div>
                  <div className={styles.buttonsModal}>
                    <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={closeRefuseModal} />
                    <ButtonFilled
                      disabled={validationRefuseButton()}
                      text={'Да, хочу отказаться'}
                      width={'100%'}
                      onClick={tableData?.length ? sendRejectionRemain : sendRejection}
                    />
                  </div>
                </div>
                <div className={styles.closeButton}>
                  <FrameModalSvg onClick={closeRefuseModal} />
                </div>
              </Modal>
            )}

            <EditPlanModal
              openEditPlan={openEditPlan}
              setOpenEditPlan={setOpenEditPlan}
              infoData={infoData}
              planData={infoDataEdit}
              getData={getData}
              driversList={driversList}
              carsList={carsList}
            />
          </div>
          {tableData?.length ? (
            <div>
              <div className={styles.containerTitleTable}>
                <p className={styles.textTitleTable}>Планирование</p>
              </div>
              {isMobile ? (
                <MobileTableDispatching
                  validationOfPlanning={validationOfPlanning}
                  setMobileOpenEditPlan={setOpenEditPlan}
                  getItemDataEdit={getItemDataEdit}
                  tableData={tableData}
                />
              ) : (
                <div className={styles.tableContainer}>
                  <CustomTable
                    isModal={validationOfPlanning}
                    setOpenModal={setOpenEditPlan}
                    getRowData={getItemDataEdit}
                    loading={loading}
                    link={false}
                    columns={columnsDispatchingItemTable}
                    data={tableData}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </PageWrapper>
  );
};
