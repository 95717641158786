import React, { useState } from 'react';
import { statuses, StatusTabsGSM } from '../Pages/GsmPages/GSMCardPage';

type TransactionsType = {
  firstItem: boolean;
  dataOil: Date;
  typeOil: string;
  typeOperation: string;
  addressGas: string;
  volume: string;
  priceForVolume: string;
  sum: string;
};

export const TransactionsItem = ({
  firstItem,
  dataOil,
  typeOil,
  typeOperation,
  addressGas,
  volume,
  priceForVolume,
  sum,
}: TransactionsType) => {
  const [statusTabs] = useState<StatusTabsGSM>(statuses);

  const className = `transactionsItem__box ${firstItem ? 'transactionsItem__box_sub' : ''}`;

  return (
    <>
      {statusTabs.Списание.active ? (
        <div className={'transactionsItem'}>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Дата</div>}
            <div className="transactionsItem__text">
              {new Date(dataOil).toLocaleDateString() +
                '(' +
                new Date(dataOil).getHours() +
                ':' +
                new Date(dataOil).getMinutes() +
                ')'}
            </div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Вид топлива</div>}
            <div className="transactionsItem__text">
              <span>{typeOil}</span>
            </div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Вид операции</div>}
            <div className="transactionsItem__text">
              <span>{typeOperation}</span>
            </div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Адрес заправки</div>}
            <div className="transactionsItem__text">
              <span>{addressGas}</span>
            </div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Объем, литры</div>}
            <div className="transactionsItem__text">{volume}</div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Цена за литр,&#32;&#8381;</div>}
            <div className="transactionsItem__text">{priceForVolume}</div>
          </div>
          <div className={className}>
            {firstItem && <div className="transactionsItem__title">Сумма,&#32;&#8381;</div>}
            <div className="transactionsItem__text transactionsItem__text_orange">{sum}</div>
          </div>
        </div>
      ) : (
        <div className={'transactionsItem transactionsItem_entrance'}>
          <div className={firstItem ? 'transactionsItem__box transactionsItem__box_sub' : 'transactionsItem__box'}>
            {firstItem && <div className="transactionsItem__title">Дата</div>}
            <div className="transactionsItem__text">
              {new Date(dataOil).toLocaleDateString() +
                '(' +
                new Date(dataOil).getHours() +
                ':' +
                new Date(dataOil).getMinutes() +
                ')'}
            </div>
          </div>
          <div className={firstItem ? 'transactionsItem__box transactionsItem__box_sub' : 'transactionsItem__box'}>
            {firstItem && <div className="transactionsItem__title">Вид операции</div>}
            <div className="transactionsItem__text">
              <span>{typeOperation}</span>
            </div>
          </div>
          <div className={firstItem ? 'transactionsItem__box transactionsItem__box_sub' : 'transactionsItem__box'}>
            {firstItem && <div className="transactionsItem__title">Зачислено,&#32;&#8381;</div>}
            <div className="transactionsItem__text transactionsItem__text_green">{sum}</div>
          </div>
        </div>
      )}
    </>
  );
};
