import React from 'react';

const AuthLineMobileSvg = () => {
    return (
        <svg width="243" height="301" viewBox="0 0 243 301" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_20061_136026)">
                        <path d="M144.702 24.8352V81.1797H142.677V24.8352C142.677 7.50881 128.568 -6.59984 111.242 -6.59984H68.4656V-8.625H111.264C129.693 -8.625 144.702 6.38372 144.702 24.8352Z" fill="#E5E5E5"/>
                        <path d="M139.975 24.8352V81.1797H137.95V24.8352C137.95 10.119 125.979 -1.85195 111.263 -1.85195H68.487V-3.87712H111.263C127.082 -3.89962 139.975 8.99394 139.975 24.8352Z" fill="#E5E5E5"/>
                        <path d="M135.249 24.8352V81.1797H133.224V24.8352C133.224 12.7292 123.369 2.87342 111.263 2.87342H68.4867V0.848264H111.263C124.471 0.848264 135.249 11.6041 135.249 24.8352Z" fill="#E5E5E5"/>
                        <path d="M130.525 24.8352V81.1797H128.5V24.8352C128.5 15.3394 120.76 7.59879 111.264 7.59879H68.4879V5.57362H111.264C121.885 5.57362 130.525 14.2143 130.525 24.8352Z" fill="#E5E5E5"/>
                        <path d="M125.777 24.8352V81.1797H123.752V24.8352C123.752 17.9496 118.149 12.3242 111.241 12.3242H68.4648V10.299H111.263C119.274 10.299 125.777 16.8245 125.777 24.8352Z" fill="#E5E5E5"/>
                        <path d="M121.051 24.8352V81.1797H119.026V24.8352C119.026 20.5598 115.538 17.0721 111.263 17.0721H68.487V15.0469H111.263C116.663 15.0244 121.051 19.4348 121.051 24.8352Z" fill="#E5E5E5"/>
                        <path d="M78.0735 83.2053C60.7471 83.2053 46.6384 97.314 46.6384 114.64C46.6384 131.967 60.7246 146.075 78.0735 146.075H160.655C166.056 146.075 170.443 150.463 170.443 155.864C170.443 161.264 166.056 165.674 160.655 165.674L160.655 163.649C164.93 163.649 168.418 160.162 168.418 155.886C168.418 151.611 164.93 148.123 160.655 148.123H78.0735C59.622 148.123 44.6133 133.114 44.6133 114.663C44.6133 96.2114 59.622 81.2027 78.0735 81.2027L154.557 81.2027V83.2279L78.0735 83.2279V83.2053Z" fill="#E5E5E5"/>
                        <path d="M78.0748 87.9543C63.3586 87.9543 51.3877 99.9253 51.3877 114.641C51.3877 129.358 63.3586 141.329 78.0748 141.329H160.656C168.667 141.329 175.193 147.854 175.193 155.865C175.193 163.875 168.667 170.401 160.656 170.401L160.656 168.376C167.542 168.376 173.167 162.773 173.167 155.865C173.167 148.979 167.564 143.354 160.656 143.354H78.0748C62.2335 143.354 49.3625 130.46 49.3625 114.641C49.3625 98.8002 62.256 85.9292 78.0748 85.9292L154.558 85.9292V87.9543L78.0748 87.9543Z" fill="#E5E5E5"/>
                        <path d="M78.0751 92.68C65.9691 92.68 56.1134 102.536 56.1134 114.642C56.1134 126.748 65.9691 136.604 78.0751 136.604H160.657C171.278 136.604 179.918 145.244 179.918 155.865C179.918 166.486 171.278 175.127 160.657 175.127L160.657 173.101C170.152 173.101 177.893 165.361 177.893 155.865C177.893 146.369 170.152 138.629 160.657 138.629H78.0751C64.844 138.629 54.0882 127.873 54.0882 114.642C54.0882 101.411 64.844 90.6549 78.0751 90.6549L154.559 90.6549V92.68L78.0751 92.68Z" fill="#E5E5E5"/>
                        <path d="M78.0739 97.405C68.5782 97.405 60.8376 105.146 60.8376 114.641C60.8376 124.137 68.5782 131.878 78.0739 131.878H160.656C173.887 131.878 184.642 142.634 184.642 155.865C184.642 169.096 173.887 179.852 160.656 179.852L160.656 177.826C172.761 177.826 182.617 167.971 182.617 155.865C182.617 143.759 172.762 133.903 160.656 133.903H78.0739C67.4531 133.903 58.8124 125.262 58.8124 114.641C58.8124 104.021 67.4531 95.3798 78.0739 95.3798L154.558 95.3798V97.405L78.0739 97.405Z" fill="#E5E5E5"/>
                        <path d="M78.0765 102.131C71.191 102.131 65.5655 107.734 65.5655 114.642C65.5655 121.528 71.1685 127.153 78.0765 127.153H160.658C176.499 127.153 189.37 140.047 189.37 155.866C189.37 171.707 176.477 184.578 160.658 184.578L160.658 182.553C175.374 182.553 187.345 170.582 187.345 155.866C187.345 141.15 175.374 129.179 160.658 129.179H78.0765C70.0659 129.179 63.5404 122.653 63.5404 114.642C63.5404 106.632 70.0659 100.106 78.0765 100.106L154.56 100.106V102.131L78.0765 102.131Z" fill="#E5E5E5"/>
                        <path d="M78.0748 106.878C73.7994 106.878 70.3117 110.365 70.3117 114.641C70.3117 118.916 73.7994 122.404 78.0748 122.404L160.656 122.404C179.108 122.404 194.117 137.413 194.117 155.864C194.117 174.316 179.108 189.324 160.656 189.324V187.299C177.983 187.299 192.091 173.19 192.091 155.864C192.091 138.538 177.983 124.429 160.656 124.429L78.0748 124.429C72.6744 124.429 68.2865 120.041 68.2865 114.641C68.2865 109.24 72.6744 104.83 78.0748 104.83L154.558 104.83V106.855L78.0748 106.855V106.878Z" fill="#E5E5E5"/>
                        <path d="M151.073 165.641C133.747 165.641 119.638 179.75 119.638 197.076C119.638 214.402 133.725 228.511 151.073 228.511H193.25V230.559H151.073C132.622 230.559 117.613 215.55 117.613 197.098C117.613 178.647 132.622 163.638 151.073 163.638H160.999V165.663H151.073V165.641Z" fill="#E5E5E5"/>
                        <path d="M151.075 170.39C136.359 170.39 124.388 182.361 124.388 197.077C124.388 211.793 136.359 223.764 151.075 223.764H193.251C193.251 223.764 193.251 217.779 193.251 225.789V223.764C193.251 216.879 193.251 225.789 193.251 225.789H151.075C135.234 225.789 122.362 212.896 122.362 197.077C122.362 181.236 135.256 168.365 151.075 168.365H161.001V170.39H151.075Z" fill="#E5E5E5"/>
                        <path d="M151.075 175.116C138.969 175.116 129.113 184.971 129.113 197.077C129.113 209.183 138.969 219.039 151.075 219.039H193.251V221.064H151.075C137.844 221.064 127.088 210.308 127.088 197.077C127.088 183.846 137.844 173.09 151.075 173.09H161.001V175.116H151.075Z" fill="#E5E5E5"/>
                        <path d="M151.074 179.841C141.578 179.841 133.838 187.581 133.838 197.077C133.838 206.573 141.578 214.313 151.074 214.313H193.25V216.338H151.074C140.453 216.338 131.812 207.698 131.812 197.077C131.812 186.456 140.453 177.815 151.074 177.815H161V179.841H151.074Z" fill="#E5E5E5"/>
                        <path d="M151.077 184.567C144.191 184.567 138.566 190.17 138.566 197.078C138.566 203.964 144.168 209.589 151.077 209.589H193.253C193.253 225.43 193.253 211.614 193.253 211.614H151.077C143.066 211.614 136.54 205.089 136.54 197.078C136.54 189.067 143.066 182.542 151.077 182.542H161.002V184.567H151.077Z" fill="#E5E5E5"/>
                        <path d="M151.075 189.313C146.799 189.313 143.312 192.801 143.312 197.076C143.312 201.352 146.799 204.839 151.075 204.839H193.251V206.865C193.251 224.191 193.253 206.568 193.251 206.865L151.075 206.865C145.674 206.865 141.286 202.477 141.286 197.076C141.286 191.676 145.674 187.265 151.075 187.265H161.001V189.291H151.075V189.313Z" fill="#E5E5E5"/>
                        <path d="M134.912 -31.7336C134.912 -14.4072 149.021 -0.2986 166.347 -0.2986L237.368 -0.2986V1.72656L166.347 1.72656C147.895 1.72656 132.887 -13.2821 132.887 -31.7336C132.887 -32.7462 132.932 -49.945 133.022 -50.9351H135.047C134.957 -49.945 134.912 -32.7462 134.912 -31.7336Z" fill="#E5E5E5"/>
                        <path d="M139.637 -31.7349C139.637 -17.0187 151.608 -5.04778 166.324 -5.04778L237.368 -5.04778V-3.02262L166.347 -3.02262C150.506 -3.02262 137.635 -15.9161 137.635 -31.7349C137.635 -32.7475 137.679 -49.9463 137.792 -50.9364H139.84C139.705 -49.9463 139.637 -32.7475 139.637 -31.7349Z" fill="#E5E5E5"/>
                        <path d="M144.363 -31.736C144.363 -19.63 154.218 -9.77425 166.324 -9.77425L237.368 -9.77425V-7.74909L166.347 -7.74909C153.116 -7.74909 142.36 -18.5049 142.36 -31.736C142.36 -32.7486 142.427 -49.9474 142.562 -50.9375H144.61C144.452 -49.9474 144.363 -32.7711 144.363 -31.736Z" fill="#E5E5E5"/>
                        <path d="M149.088 -31.7363C149.088 -22.2406 156.829 -14.5 166.324 -14.5L237.368 -14.5V-12.4748L166.347 -12.4748C155.726 -12.4748 147.085 -21.1155 147.085 -31.7363C147.085 -32.7489 147.175 -49.9477 147.333 -50.9378H149.403C149.2 -49.9702 149.088 -32.7714 149.088 -31.7363Z" fill="#E5E5E5"/>
                        <path d="M153.836 -31.7336C153.836 -24.8481 159.439 -19.2226 166.347 -19.2226L237.368 -19.2226V-17.1975L166.347 -17.1975C158.336 -17.1975 151.811 -23.723 151.811 -31.7336C151.811 -32.7687 151.923 -49.9675 152.126 -50.9351H154.218C153.971 -49.9675 153.836 -32.7687 153.836 -31.7336Z" fill="#E5E5E5"/>
                        <path d="M158.561 -31.7357C158.561 -27.4604 162.049 -23.9726 166.324 -23.9726L237.368 -23.9726V-21.9474L166.347 -21.9474C160.946 -21.9474 156.559 -26.3353 156.559 -31.7357C156.559 -32.7933 156.738 -49.9921 157.031 -50.9372H159.191C158.786 -50.0146 158.561 -32.8158 158.561 -31.7357Z" fill="#E5E5E5"/>
                        <path d="M68.4871 -57.2968L68.4871 35.9041C68.4871 40.1794 71.9749 43.6672 76.2502 43.6672C80.5255 43.6672 84.0133 40.1794 84.0133 35.9041V17.0701H86.0385V35.9041C86.0385 41.3045 81.6506 45.6923 76.2277 45.6923C70.8273 45.6923 66.4394 41.3045 66.4394 35.9041L66.4394 -57.2968H68.4871Z" fill="#E5E5E5"/>
                        <path d="M49.5631 -53.2254L49.5631 35.9041C49.5631 50.6203 61.534 62.5912 76.2502 62.5912C90.9664 62.5912 102.937 50.6203 102.937 35.9041V17.0026H104.963V35.9041C104.963 51.7453 92.069 64.6164 76.2502 64.6164C60.409 64.6164 47.5379 51.7228 47.5379 35.9041L47.5379 -53.2254H49.5631Z" fill="#E5E5E5"/>
                        <path d="M44.8142 -53.4193L44.8142 35.9031C44.8142 53.2295 58.9229 67.3381 76.2492 67.3381C93.5756 67.3381 107.684 53.2295 107.684 35.9031V17.0691H109.709V35.9031C109.709 54.3546 94.7007 69.3633 76.2492 69.3633C57.7978 69.3633 42.7891 54.3546 42.7891 35.9031L42.7891 -53.4193H44.8142Z" fill="#E5E5E5"/>
                        <path d="M63.739 35.9038C63.739 42.7894 69.3419 48.4148 76.25 48.4148C83.1355 48.4148 88.761 42.8119 88.761 35.9038V17.0698H90.7861V35.9038C90.7861 43.9145 84.2606 50.44 76.25 50.44C68.2393 50.44 61.7138 43.9145 61.7138 35.9038L61.7138 -57.4907H63.7165L63.7165 35.9038H63.739Z" fill="#E5E5E5"/>
                        <path d="M59.0132 35.9034C59.0132 45.3992 66.7538 53.1398 76.2496 53.1398C85.7453 53.1398 93.4859 45.3992 93.4859 35.9034V17.0694H95.5111V35.9034C95.5111 46.5243 86.8704 55.165 76.2496 55.165C65.6287 55.165 56.988 46.5243 56.988 35.9034L56.988 -53.0316H59.0132L59.0132 35.9034Z" fill="#E5E5E5"/>
                        <path d="M54.2889 35.9045C54.2889 48.0105 64.1447 57.8663 76.2506 57.8663C88.3566 57.8663 98.2124 48.0105 98.2124 35.9045V17.0705H100.238V35.9045C100.238 49.1356 89.4817 59.8914 76.2506 59.8914C63.0196 59.8914 52.2637 49.1356 52.2637 35.9045L52.2637 -54.0009H54.2889L54.2889 35.9045Z" fill="#E5E5E5"/>
                        <path d="M31.7085 -77.2829L31.7085 -16.3255C31.7085 -10.9251 36.0964 -6.53725 41.4968 -6.53725H43.072V-8.56242H41.4968C37.2215 -8.56242 33.7337 -12.0502 33.7337 -16.3255L33.7337 -77.2829C33.7337 -81.0407 33.1037 -84.641 31.9561 -87.9938H29.8184C31.0335 -84.641 31.7085 -81.0407 31.7085 -77.2829Z" fill="#E5E5E5"/>
                        <path d="M26.9587 -77.2824L26.9587 -16.325C26.9587 -8.31435 33.4842 -1.78883 41.4948 -1.78883H43.07V-3.81399H41.4948C34.6093 -3.81399 28.9838 -9.41693 28.9838 -16.325L28.9838 -77.2824C28.9838 -81.0627 28.2413 -84.6854 26.8912 -87.9932H24.686C26.1486 -84.708 26.9587 -81.0852 26.9587 -77.2824Z" fill="#E5E5E5"/>
                        <path d="M22.2368 -77.2835L22.2368 -16.3262C22.2368 -5.70532 30.8775 2.93536 41.4984 2.93536H43.0735V0.910202H41.4984C32.0026 0.910202 24.262 -6.83041 24.262 -16.3262L24.262 -77.2835C24.262 -81.1314 23.3394 -84.7766 21.7193 -87.9944H19.4241C21.2243 -84.8216 22.2368 -81.1539 22.2368 -77.2835Z" fill="#E5E5E5"/>
                        <path d="M17.5096 -77.2825L17.5096 -16.3251C17.5096 -3.09403 28.2654 7.66183 41.4965 7.66183H43.0716V5.63667H41.4965C29.3905 5.63667 19.5347 -4.21912 19.5347 -16.3251L19.5347 -77.2825C19.5347 -81.2428 18.3196 -84.9331 16.272 -87.9933H13.7517C16.0919 -85.0456 17.5096 -81.3328 17.5096 -77.2825Z" fill="#E5E5E5"/>
                        <path d="M12.7839 -77.2844L12.7839 -16.327C12.7839 -0.48576 25.6774 12.3853 41.4962 12.3853H43.0713V10.3601H41.4962C26.78 10.3601 14.8091 -1.61085 14.8091 -16.327L14.8091 -77.2844C14.8091 -81.5373 12.9639 -85.3401 10.0612 -87.9953H6.68591C10.3087 -85.8126 12.7839 -81.8298 12.7839 -77.2844Z" fill="#E5E5E5"/>
                        <path d="M8.03623 -77.2808L8.03623 -16.3234C8.03623 2.12803 23.0449 17.1367 41.4964 17.1367H43.0715V15.1116H41.4964C24.17 15.1116 10.0614 1.00294 10.0614 -16.3234L10.0614 -77.2808C10.0614 -82.6137 5.76355 -86.9791 0.453125 -87.0691L0.453125 -85.0439C4.66096 -84.9314 8.03623 -81.4887 8.03623 -77.2808Z" fill="#E5E5E5"/>
                        <path d="M218.767 229.9H222.795C224.82 229.9 225.923 229.9 226.553 229.968V229.9C231.953 229.9 236.364 234.288 236.364 239.688V267.546C236.364 272.946 231.976 277.356 226.553 277.356C221.153 277.356 216.765 272.969 216.765 267.546V205.913C216.765 200.513 221.153 196.102 226.553 196.102H303.191V198.128H226.553C222.278 198.128 218.79 201.615 218.79 205.891V267.546C218.79 271.821 222.278 275.309 226.553 275.309C230.828 275.309 234.316 271.821 234.316 267.546V239.711C234.316 235.436 230.828 231.948 226.553 231.948H225.878C224.033 231.948 218.79 231.948 218.79 231.948" fill="#E5E5E5"/>
                        <path d="M214.042 205.914V267.546C214.042 274.432 219.645 280.057 226.553 280.057C233.461 280.057 239.064 274.454 239.064 267.546V239.711C239.064 232.826 233.461 227.2 226.553 227.2H225.878C224.033 227.2 219.06 227.2 219.06 227.2H218.79V225.175H222.818C224.843 225.175 225.945 225.175 226.576 225.243V225.175C234.586 225.175 241.112 231.701 241.112 239.711V267.546C241.112 275.557 234.586 282.082 226.576 282.082C218.565 282.082 212.039 275.557 212.039 267.546V205.914C212.039 197.903 218.565 191.377 226.576 191.377H303.191V193.403H226.553C219.645 193.425 214.042 199.028 214.042 205.914Z" fill="#E5E5E5"/>
                        <path d="M209.317 205.911V267.543C209.317 277.039 217.057 284.78 226.553 284.78C236.049 284.78 243.789 277.039 243.789 267.543V239.709C243.789 230.213 236.049 222.472 226.553 222.472H225.878C224.01 222.472 218.925 222.472 218.925 222.472H218.79V220.447H222.75C224.82 220.447 225.945 220.447 226.575 220.515V220.447C237.196 220.447 245.837 229.088 245.837 239.709V267.543C245.837 278.164 237.196 286.805 226.575 286.805C215.955 286.805 207.314 278.164 207.314 267.543V205.911C207.314 195.29 215.955 186.649 226.575 186.649H303.191V188.674H226.553C217.035 188.674 209.317 196.415 209.317 205.911Z" fill="#E5E5E5"/>
                        <path d="M204.592 205.913V267.545C204.592 279.651 214.448 289.507 226.554 289.507C238.66 289.507 248.516 279.651 248.516 267.545V239.71C248.516 227.604 238.66 217.749 226.554 217.749H225.879C224.011 217.749 218.948 217.749 218.948 217.749H218.791V215.723L222.751 215.701C224.821 215.701 225.946 215.701 226.576 215.768V215.701C239.807 215.701 250.563 226.457 250.563 239.688V267.545C250.563 280.776 239.807 291.532 226.576 291.532C213.345 291.532 202.589 280.776 202.589 267.545V205.913C202.589 192.682 213.345 181.926 226.576 181.926H303.192V183.951H226.554C214.425 183.951 204.592 193.807 204.592 205.913Z" fill="#E5E5E5"/>
                        <path d="M199.843 205.912V267.544C199.843 282.26 211.814 294.231 226.53 294.231C241.247 294.231 253.24 282.26 253.24 267.544V239.709C253.24 224.993 241.269 213.022 226.553 213.022H225.878C224.01 213.022 218.925 213.022 218.925 213.022H218.79V210.997H222.75C224.82 210.997 225.945 210.997 226.576 211.064V210.997C242.417 210.997 255.288 223.89 255.288 239.709V267.544C255.288 283.385 242.394 296.256 226.576 296.256C210.757 296.256 197.863 283.363 197.863 267.544V205.912C197.863 190.07 210.757 177.199 226.576 177.199H303.191V179.224H226.553C211.837 179.224 199.843 191.195 199.843 205.912Z" fill="#E5E5E5"/>
                        <path d="M195.118 205.911V267.544C195.118 284.87 209.227 298.979 226.553 298.979C243.879 298.979 257.966 284.87 257.966 267.544V239.709C257.966 222.383 243.857 208.274 226.53 208.274H225.855C223.943 208.274 218.767 208.296 218.767 208.296V206.271L225.293 206.249C225.855 206.249 226.238 206.249 226.53 206.271V206.249C244.982 206.249 259.991 221.258 259.991 239.709V267.544C259.991 285.995 244.982 301.004 226.53 301.004C208.079 301.004 193.07 285.995 193.07 267.544V205.911C193.07 187.46 208.079 172.451 226.53 172.451H303.191V174.476H226.553C209.227 174.499 195.118 188.585 195.118 205.911Z" fill="#E5E5E5"/>
                        <path d="M221.478 44.664V127.921C221.478 132.196 224.966 135.684 229.241 135.684C233.516 135.684 237.004 132.196 237.004 127.921V68.5127H239.029V127.943C239.029 133.344 234.642 137.731 229.219 137.731C223.818 137.731 219.43 133.344 219.43 127.943V44.6865C219.43 27.3601 205.322 13.2515 187.995 13.2515C170.669 13.2515 156.56 27.3601 156.56 44.6865V122.813H154.535V44.6865C154.535 26.235 169.544 11.2263 187.995 11.2263C206.469 11.2038 221.478 26.2125 221.478 44.664Z" fill="#E5E5E5"/>
                        <path d="M241.752 66.7678V127.9C241.752 134.808 236.127 140.411 229.241 140.411C222.333 140.411 216.73 134.785 216.73 127.9V44.6431C216.73 28.8243 203.859 15.9308 188.018 15.9308C172.199 15.9308 159.306 28.8018 159.306 44.6431V122.769H160.656C160.768 122.769 160.881 122.775 160.993 122.781C161.106 122.786 161.218 122.792 161.331 122.792V44.6431C161.331 29.9269 173.302 17.956 188.018 17.956C202.734 17.956 214.705 29.9269 214.705 44.6431V127.9C214.705 135.91 221.231 142.436 229.241 142.436C237.252 142.436 243.778 135.91 243.778 127.9V66.1862L241.752 66.7678Z" fill="#E5E5E5"/>
                        <path d="M246.478 64.2475V127.921C246.478 137.416 238.737 145.157 229.241 145.157C219.746 145.157 212.005 137.416 212.005 127.921V44.6639C212.005 31.4328 201.249 20.677 188.018 20.677C174.787 20.677 164.031 31.4328 164.031 44.6639V122.948C164.706 123.015 165.381 123.105 166.056 123.218V44.6639C166.056 32.5579 175.912 22.7021 188.018 22.7021C200.124 22.7021 209.98 32.5579 209.98 44.6639V127.921C209.98 138.541 218.621 147.182 229.241 147.182C239.862 147.182 248.503 138.541 248.503 127.921V63.0842L246.478 64.2475Z" fill="#E5E5E5"/>
                        <path d="M251.203 60.9516V127.92C251.203 140.026 241.347 149.882 229.241 149.882C217.135 149.882 207.28 140.026 207.28 127.92V44.6633C207.28 34.0424 198.639 25.4017 188.018 25.4017C177.397 25.4017 168.757 34.0424 168.757 44.6633H168.779V123.757C169.454 123.937 170.129 124.14 170.804 124.342V44.6407C170.804 35.145 178.545 27.4044 188.041 27.4044C197.536 27.4044 205.277 35.145 205.277 44.6407V127.897C205.277 141.128 216.033 151.884 229.264 151.884C242.495 151.884 253.251 141.128 253.251 127.897V59.4006L251.203 60.9516Z" fill="#E5E5E5"/>
                        <path d="M255.928 55.5231V127.922C255.928 142.638 243.957 154.609 229.241 154.609C214.525 154.609 202.554 142.638 202.554 127.922V44.6653C202.554 36.6547 196.028 30.1291 188.018 30.1291C180.007 30.1291 173.482 36.6547 173.482 44.6653V125.357C174.157 125.627 174.832 125.942 175.507 126.279V44.6653C175.507 37.7573 181.132 32.1543 188.018 32.1543C194.926 32.1543 200.529 37.7798 200.529 44.6653V127.922C200.529 143.741 213.4 156.634 229.241 156.634C245.06 156.634 257.953 143.763 257.953 127.922V52.8088L255.928 55.5231Z" fill="#E5E5E5"/>
                        <path d="M260.699 45.8293V127.919C260.699 145.245 246.59 159.354 229.264 159.354C211.938 159.354 197.829 145.245 197.829 127.919V44.6621C197.829 39.2392 193.419 34.8513 188.018 34.8513C182.618 34.8513 178.23 39.2617 178.23 44.6621H178.252V127.784C178.95 128.211 179.625 128.684 180.278 129.156V44.6621C180.278 40.3868 183.765 36.899 188.041 36.899C192.316 36.899 195.804 40.3868 195.804 44.6621V127.919C195.804 146.37 210.813 161.379 229.264 161.379C247.716 161.379 262.724 146.37 262.724 127.919V36.7172L260.699 45.8293Z" fill="#E5E5E5"/>
                        <path d="M248.511 -44.1277V36.9199C248.511 47.5408 239.87 56.1815 229.249 56.1815H220.887V54.1563H229.249C238.745 54.1563 246.486 46.4157 246.486 36.9199V-44.1277H248.511Z" fill="#E5E5E5"/>
                        <path d="M251.213 -44.1277V36.921C251.213 49.027 241.357 58.8828 229.251 58.8828H220.889V60.9079H229.251C242.482 60.9079 253.238 50.1521 253.238 36.921V-44.1277H251.213Z" fill="#E5E5E5"/>
                        <path d="M255.939 -44.1277V36.9191C255.939 51.6352 243.968 63.6062 229.252 63.6062H220.889V65.6314H229.252C245.07 65.6314 257.964 52.7603 257.964 36.9191V-44.1277H255.939Z" fill="#E5E5E5"/>
                        <path d="M260.686 -44.1277V36.9226C260.686 54.249 246.578 68.3577 229.251 68.3577H220.889V70.3828H229.251C247.703 70.3828 262.712 55.3741 262.712 36.9226V-44.1277H260.686Z" fill="#E5E5E5"/>
                        <path d="M237.014 -44.1277V36.9206C237.014 41.1959 233.526 44.6837 229.251 44.6837H220.888V46.7088H229.251C234.651 46.7088 239.039 42.321 239.039 36.9206V-44.1277H237.014Z" fill="#E5E5E5"/>
                        <path d="M241.764 -44.1277V36.9211C241.764 43.8292 236.139 49.4321 229.253 49.4321H220.89V51.4573H229.253C237.264 51.4573 243.789 44.9318 243.789 36.9211V-44.1277H241.764Z" fill="#E5E5E5"/>
                </g>
                <defs>
                        <clipPath id="clip0_20061_136026">
                                <path d="M0 12C0 5.37258 5.37258 0 12 0H231C237.627 0 243 5.37258 243 12V301H0V12Z" fill="white"/>
                        </clipPath>
                </defs>
        </svg>
    );
};

export default AuthLineMobileSvg;