import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitlePage } from '../../components/TitlePage';
import { Button } from '../../components/UI/buttons/Button';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import { CarsList } from '../../components/CarsList';
import { PlusSvg } from '../../components/UI/svg-icon/PlusSvg';
import { CarPagePopup } from '../../components/CarPagePopup';
import { toast, ToastContainer } from 'react-toastify';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import useDebounce from '../../CustomHooks/useDebounce';

// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import styles from './CarsPage.module.scss';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { declinationWord } from '../../common/declinationWord.helper';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
const InitialCar = {
  valueTypeTC: '',
  valueBrandTC: '',
  stsNumber: '',
  stateNumber: '',
  valueOwnership: '',
  volumeBody: '',
};
interface ICar {
  valueOwnership: string | { value: string; label: string };
  valueTypeTC?: string | { value: string; label: string };
  valueBrandTC?: string | { value: string; label: string };
  volumeBody: string;
  stsNumber: string;
  stateNumber: string;
}

export const CarsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [carsList, setCarsList] = useState([]);
  const [typeList, setTypeList] = useState<{ value: string; label: string }[]>();
  const [brandList, setBrandList] = useState<{ value: string; label: string }[]>();
  const [ownershipList, setOwnershipList] = useState<{ value: string; label: string }[]>();
  const [uploadImagesOwnership, setUploadImagesOwnership] = useState<any>([]);
  const [uploadImagesStsNumber, setUploadImagesStsNumber] = useState<any>([]);
  const [popUpActive, setPopUpActive] = useState(false);
  const [error] = useState(false);
  const [car, setCar] = useState<ICar>(InitialCar);
  const [disabled, setDisabled] = useState(true);
  const [sending, setSending] = useState(false);
  const [updateListCars, setUpdateListCars] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [successfulFormSubmission] = useState(false);
  const [valueBrandTC, setValueBrandTC] = useState<any>('');
  const [valueTypeTC, setValueTypeTC] = useState<any>('');
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchFilter, setSearchFilter] = useState('');
  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const typeCars: { value: string; label: string }[] = [];
  const brandCars: { value: string; label: string }[] = [];

  const getCars = async (isPagination: Boolean) => {
    setSkeleton(true);
    try {
      const cars = await fetchGet(`/cars/filter`, {
        limit: 10,
        searchString: searchFilter,
        offset: isPagination ? offset : 0,
      });
      const types = await fetchGet(`/cars/types`, {});
      const brands = await fetchGet(`/cars/brands`, {});
      setTotalCount(cars.response.count);
      for (const { id, type } of types.response) {
        typeCars.push({
          value: id,
          label: type,
        });
      }
      for (const { id, brand } of brands.response) {
        brandCars.push({
          value: id,
          label: brand,
        });
      }

      setTypeList(typeCars);
      setBrandList(brandCars);
      setOwnershipList([
        { value: 'ownership', label: 'Собственность' },
        { value: 'leasing', label: 'Лизинг' },
      ]);
      if (isPagination && offset !== 0) {
        setCarsList(prevState => [...prevState, ...cars.response.rows]);
      } else {
        setCarsList(cars.response.rows);
      }

      setSkeleton(false);
    } catch (e) {
      setSkeleton(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      getCars(true);
    }
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    getCars(false);
  }, [updateListCars, debouncedSearchTerm]);

  useEffect(() => {
    setDisabled(
      (typeof car?.valueOwnership !== 'string' && !car.valueOwnership?.value) ||
        (typeof car?.valueBrandTC !== 'string' && !car.valueBrandTC?.value) ||
        (typeof car?.valueTypeTC !== 'string' && !car.valueTypeTC?.value) ||
        ((car?.valueOwnership === 'Лизинг' ||
          (car?.valueOwnership as { value: string; label: string }).value === 'leasing') &&
          uploadImagesOwnership?.length < 1) ||
        uploadImagesStsNumber?.length < 1 ||
        !car?.stsNumber ||
        !car?.volumeBody,
    );
  }, [car, uploadImagesStsNumber, uploadImagesOwnership]);

  const sendDataCars = async (ownershipValue: string, isCheckingAutoCodeSuccessful: Boolean) => {
    setDisabled(true);
    setSending(true);

    const cars = await fetchGet(`/cars/list`, {});

    const currentCar = cars.response.find(item => item.stateNumber === car.stateNumber);

    if (Boolean(currentCar) && !currentCar.active) {
      const patchCar = await fetchPost(`/cars/${currentCar.id}`, 'PATCH', {
        active: true,
      });

      if (patchCar.error) {
        setValueBrandTC(null);
        setValueTypeTC(null);
        patchCar.message.map(el => toast.error(el));
        setSending(false);
        return;
      }

      toast.success('Машина успешно добавлена');
      setSending(false);
      setPopUpActive(closePopUp);
      setCar(prevState => ({ ...prevState, stateNumber: '' }));
      setUpdateListCars(!updateListCars);
      return;
    }

    if (Boolean(currentCar) && currentCar.active) {
      toast.error(`Машина с номером ${car.stateNumber} уже существует`);
      setPopUpActive(closePopUp);
      setCar(prevState => ({ ...prevState, stateNumber: '' }));
      setSending(false);
      return;
    }
    const carsDataHired = {
      stateNumber: car.stateNumber,
      carTypeId: typeof car.valueTypeTC !== 'string' && car.valueTypeTC.value,
      carBrandId: typeof car.valueBrandTC !== 'string' && car.valueBrandTC.value,
      stsSerialNumber: car.stsNumber,
      volumeBody: Number(car.volumeBody),
    };

    const carsDataUnconfirmed = {
      stateNumber: car.stateNumber,
      carTypeId: typeof car.valueTypeTC !== 'string' && car.valueTypeTC.value,
      carBrandId: typeof car.valueBrandTC !== 'string' && car.valueBrandTC.value,
      stsSerialNumber: car.stsNumber,
      volumeBody: Number(car.volumeBody),
      legalOwner: typeof car.valueOwnership !== 'string' && car.valueOwnership.value,
    };

    const carsDataConfirmed = {
      stateNumber: car.stateNumber,
      stsSerialNumber: car.stsNumber,
      volumeBody: Number(car.volumeBody),
    };

    let fetchRequest;
    if (ownershipValue !== 'own') {
      fetchRequest = await fetchPost(`/cars/hired`, 'POST', carsDataHired);
    } else if (isCheckingAutoCodeSuccessful) {
      fetchRequest = await fetchPost(`/cars/confirmed`, 'POST', carsDataConfirmed);
    } else {
      fetchRequest = await fetchPost(`/cars/unconfirmed`, 'POST', carsDataUnconfirmed);
    }

    const OwnershipFiles = {
      communicationCategory: 'cars',
      category: 'Договор лизинга',
      fileBelongsId: Number(fetchRequest?.response?.id),
      objectUuid: '',
      files: [...uploadImagesOwnership?.map(file => file.value)],
    };
    const StsNumberFiles = {
      communicationCategory: 'cars',
      category: 'СТС',
      fileBelongsId: Number(fetchRequest?.response?.id),
      objectUuid: '',
      files: [...uploadImagesStsNumber?.map(file => file.value)],
    };
    const requestOption = [StsNumberFiles, OwnershipFiles]?.filter(item => item?.files?.length !== 0);
    await fetchPost(`/files/`, 'POST', requestOption);
    if (fetchRequest.error) {
      setPopUpActive(closePopUp);
      setCar(InitialCar);
      fetchRequest.message.map(el => toast.error(el));
      setSending(false);
      return;
    }
    setCar(InitialCar);
    setUploadImagesOwnership([]);
    setUploadImagesStsNumber([]);

    setPopUpActive(closePopUp);
    setCar(prevState => ({ ...prevState, stateNumber: '' }));
    setSending(false);
    setUpdateListCars(!updateListCars);
    toast.success('Машина успешно добавлена');
  };

  return (
    <>
      <PageWrapper style={{ zIndex: popUpActive ? '100000' : '100' }}>
        <ToastContainer />
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Машины'} />
            <ButtonFilled
                sizeText={'small'}
                svg={<NewPlusSvg />}
                width={isMobile ? '32px' : '140px'}
                height={isMobile ? '32px' : '40px'}
                onClick={() => setPopUpActive(openPopUp)}
                text={isMobile ? '' : 'Добавить'}
            />
          </div>
          <SubTitleMain
              subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['машина', 'машины', 'машин'])}`}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <InputSearch
            placeholder="Поиск"
            onInput={({ currentTarget: { value: search } }) => setSearchFilter(search)}
            value={searchFilter}
          />
        </div>

        {carsList.length ? (
          <div className="CarsPage__content">
            <div className="CarsPage__titles">
              {!isMobile &&
                ['Номер ТС', 'Марка ТС', 'Вид ТС'].map((item, index) => (
                  <div className="CarsPage__title" key={index}>
                    {item}
                  </div>
                ))}
            </div>

            {skeleton ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '900px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            ) : (
              <CarsList list={carsList} />
            )}
          </div>
        ) : (
          <div className="FlightSection__content">
            <div className="EmptyFlightList">
              <div className="EmptyFlightList__banner">
                <div className="EmptyFlightList__error">
                  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                В данный момент у вас нет машин. Добавьте машину, чтобы увидеть её в общем списке.
              </div>
              <div className="EmptyFlightList__wrapper">
                <div className="EmptyFlightList__content">
                  <div className="EmptyFlightList__svg">
                    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="EmptyFlightList__title">Машины отсутствуют</div>
                  <div className="EmptyFlightList__subTitle">В данный момент у вас нет машин</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CarPagePopup
          setCar={setCar}
          car={car}
          setUploadImagesOwnership={setUploadImagesOwnership}
          uploadImagesOwnership={uploadImagesOwnership}
          setUploadImagesStsNumber={setUploadImagesStsNumber}
          uploadImagesStsNumber={uploadImagesStsNumber}
          brandCars={brandList}
          typeCars={typeList}
          disabled={disabled}
          sending={sending}
          ownershipList={ownershipList}
          sendDataCars={sendDataCars}
          popUpActive={popUpActive}
          setPopUpActive={setPopUpActive}
          successfulFormSubmission={successfulFormSubmission}
          error={error}
        />
      </PageWrapper>
    </>
  );
};
