import React from 'react';

export const InputVerification = ({ errorSending,setErrorSending,refNew,focus,setFocus, ...props }:any) => {
    return (
        <input
            style={{cursor:'pointer'}}
          {...props}
          ref={refNew}
          id={'single-factor-code-text-field'}
          type={'text'}
          name={'token'}
          autoComplete={'off'}
          inputMode={'numeric'}
          pattern="\d{5}"
          className={errorSending
              ? 'InputVerification error'
              : 'InputVerification'
          }
          onFocus={()=> setErrorSending(false)}
          onChange={e => setFocus(e.target.value)}
        />
    );
};