import React from 'react';

const WorkerMobileSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="worker">
                <path id="Vector" d="M16.5 9.10526C16.5 15.2768 7.5 15.2768 7.5 9.10526H9.75C9.75 12.1968 14.25 12.1968 14.25 9.10526M21 19.5263V23H3V19.5263C3 16.4347 8.99625 14.8947 12 14.8947C15.0037 14.8947 21 16.4347 21 19.5263ZM18.8625 19.5263C18.8625 18.7853 15.3413 17.0947 12 17.0947C8.65875 17.0947 5.1375 18.7853 5.1375 19.5263V20.8H18.8625M12.5625 1C12.8775 1 13.125 1.25474 13.125 1.57895V5.05263H14.25V2.15789C15.0462 2.53713 15.7132 3.15391 16.1646 3.92818C16.616 4.70246 16.8308 5.59849 16.7812 6.5C16.7812 6.5 17.5688 6.66211 17.625 7.94737H6.375C6.375 6.66211 7.21875 6.5 7.21875 6.5C7.1692 5.59849 7.38404 4.70246 7.83541 3.92818C8.28678 3.15391 8.95383 2.53713 9.75 2.15789V5.05263H10.875V1.57895C10.875 1.25474 11.1225 1 11.4375 1" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default WorkerMobileSvg;