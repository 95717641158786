import React from 'react';

export const DriverLicenseSvg = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="card-account-details-outline">
              <path id="Vector" d="M22 3H2C0.91 3.04 0.04 3.91 0 5V19C0.04 20.09 0.91 20.96 2 21H22C23.09 20.96 23.96 20.09 24 19V5C23.96 3.91 23.09 3.04 22 3ZM22 19H2V5H22V19ZM14 17V15.75C14 14.09 10.66 13.25 9 13.25C7.34 13.25 4 14.09 4 15.75V17H14ZM9 7C8.33696 7 7.70107 7.26339 7.23223 7.73223C6.76339 8.20107 6.5 8.83696 6.5 9.5C6.5 9.8283 6.56466 10.1534 6.6903 10.4567C6.81594 10.76 7.00009 11.0356 7.23223 11.2678C7.70107 11.7366 8.33696 12 9 12C9.3283 12 9.65339 11.9353 9.95671 11.8097C10.26 11.6841 10.5356 11.4999 10.7678 11.2678C10.9999 11.0356 11.1841 10.76 11.3097 10.4567C11.4353 10.1534 11.5 9.8283 11.5 9.5C11.5 9.1717 11.4353 8.84661 11.3097 8.54329C11.1841 8.23998 10.9999 7.96438 10.7678 7.73223C10.5356 7.50009 10.26 7.31594 9.95671 7.1903C9.65339 7.06466 9.3283 7 9 7ZM14 7V8H20V7H14ZM14 9V10H20V9H14ZM14 11V12H18V11H14Z" fill="currentColor"/>
          </g>
      </svg>
  );
};
/*
<svg width="100%" height="100%" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 0H2C0.91 0.04 0.04 0.91 0 2V16C0.04 17.09 0.91 17.96 2 18H22C23.09 17.96 23.96 17.09 24 16V2C23.96 0.91 23.09 0.04 22 0ZM22 16H2V2H22V16ZM14 14V12.75C14 11.09 10.66 10.25 9 10.25C7.34 10.25 4 11.09 4 12.75V14H14ZM9 4C8.33696 4 7.70107 4.26339 7.23223 4.73223C6.76339 5.20107 6.5 5.83696 6.5 6.5C6.5 6.8283 6.56466 7.15339 6.6903 7.45671C6.81594 7.76002 7.00009 8.03562 7.23223 8.26777C7.70107 8.73661 8.33696 9 9 9C9.3283 9 9.65339 8.93534 9.95671 8.8097C10.26 8.68406 10.5356 8.49991 10.7678 8.26777C10.9999 8.03562 11.1841 7.76002 11.3097 7.45671C11.4353 7.15339 11.5 6.8283 11.5 6.5C11.5 6.1717 11.4353 5.84661 11.3097 5.54329C11.1841 5.23998 10.9999 4.96438 10.7678 4.73223C10.5356 4.50009 10.26 4.31594 9.95671 4.1903C9.65339 4.06466 9.3283 4 9 4ZM14 4V5H20V4H14ZM14 6V7H20V6H14ZM14 8V9H18V8H14Z" fill="currentColor"/>
</svg>*/
