import React from 'react';
import styles from '../../DispatchingItemPage.module.scss';
import { useMediaQuery } from 'react-responsive';

const InfoStatusOfRequest = ({ infoData }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const remainVolume = (): number => {
    return infoData?.volume - infoData?.distributedVolume;
  };
  const validationDisableByDate = (): boolean => {
    let now = new Date();
    let date = new Date(infoData?.acceptanceTime);
    const formatFullDateOfDisableOfRequest = date.setHours(date.getUTCHours() + 24);
    const formatNow = now.setHours(now.getUTCHours());
    return formatFullDateOfDisableOfRequest < formatNow;
  };

  const addHoursToDate = (date: string, quantity: number): string[] => {
    let correctDate = new Date(date);
    return new Date(correctDate.setHours(correctDate.getHours() + quantity)).toLocaleString().split(',');
  };

  const formatDateForInfoBlock = (date: string, quantity: number): string => {
    const correctDate = addHoursToDate(date, quantity);
    const formatDate = correctDate[0];
    const formatTime = correctDate[1];
    let correctHour;
    const hour = formatTime.slice(1, 6).split(':')[0].split('');
    if (hour[0] === '0') {
      correctHour = hour[1];
    } else {
      correctHour = hour.join('');
    }
    const minute = formatTime.slice(1, 6).split(':')[1];
    return `${correctHour} ч ${minute} мин ${formatDate}`;
  };
  const allVolumeDistribution = remainVolume() === 0 && infoData?.status?.title !== 'Выполненная';
  const periodOfOrderExpired = validationDisableByDate() && infoData?.status?.title === 'Активная';
  const validateTimeDistribution =
    infoData?.volume > infoData?.distributedVolume &&
    infoData?.status?.title === 'Активная' &&
    !validationDisableByDate();

  return (
    <>
      {allVolumeDistribution && (
        <div style={{ borderColor: '#45AC81', background: '#E5F5F2' }} className={styles.infoRequest}>
          <p style={{ color: '#45AC81' }} className={styles.textInfoRequest}>
            Весь объем заявки успешно распределен
          </p>
        </div>
      )}
      {periodOfOrderExpired && (
        <div style={{ borderColor: '#EF4444', background: '#FEF2F2' }} className={styles.infoRequest}>
          <p style={{ color: '#EF4444' }} className={styles.textInfoRequest}>
            Срок распределения заявки истек. Если вы откажетесь от планирования, то не сможете добавить новое.
            <br />
            {remainVolume() !== 0 && `Оставшийся объем заявки будет автоматически удалён.`}
          </p>
        </div>
      )}
      {infoData?.rejectComment && infoData?.status?.title === 'Отклонена' && (
        <div style={{ borderColor: '#EF4444', background: '#FEF2F2' }} className={styles.infoRequest}>
          <p style={{ color: '#EF4444' }} className={styles.textInfoRequest}>
            Данная заявка была отклонена со стороны
            <span>
              {infoData?.rejectComment === 'отказ от заявки со стороны перевозчика' ? ' перевозчика.' : ' клиента.'}
            </span>{' '}
            Пожалуйста, свяжитесь со своим логистом
          </p>
        </div>
      )}
      {infoData?.status?.title === 'Новая' && !infoData?.rejectComment && (
        <div className={styles.infoRequest}>
          <p className={styles.textInfoRequest}>
            Примите объем до {formatDateForInfoBlock(infoData?.createdAt, 20)}&nbsp; или заявка будет автоматически
            отменена
          </p>
        </div>
      )}
      {validateTimeDistribution && (
        <div className={styles.infoRequest}>
          <p className={styles.textInfoRequest}>
            Пожалуйста, распределите объем заявки до&nbsp;
            {isMobile && <br />}
            {formatDateForInfoBlock(infoData?.acceptanceTime, 24)}, иначе она будет снята
          </p>
        </div>
      )}
    </>
  );
};

export default InfoStatusOfRequest;
