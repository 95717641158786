import React from 'react';

const DumpTruckSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="dump-truck">
                <path id="Vector" d="M33.3337 13.332H31.667H28.3337H25.0003V23.332H3.33366V28.332H5.00033C5.00033 29.6581 5.52711 30.9299 6.46479 31.8676C7.40247 32.8052 8.67424 33.332 10.0003 33.332C11.3264 33.332 12.5982 32.8052 13.5359 31.8676C14.4735 30.9299 15.0003 29.6581 15.0003 28.332H25.0003C25.0003 29.6581 25.5271 30.9299 26.4648 31.8676C27.4025 32.8052 28.6742 33.332 30.0003 33.332C31.3264 33.332 32.5982 32.8052 33.5359 31.8676C34.4735 30.9299 35.0003 29.6581 35.0003 28.332H38.3337V19.9987L33.3337 13.332ZM10.0003 30.832C9.33728 30.832 8.7014 30.5686 8.23256 30.0998C7.76372 29.631 7.50033 28.9951 7.50033 28.332C7.50033 27.669 7.76372 27.0331 8.23256 26.5643C8.7014 26.0954 9.33728 25.832 10.0003 25.832C10.6634 25.832 11.2993 26.0954 11.7681 26.5643C12.2369 27.0331 12.5003 27.669 12.5003 28.332C12.5003 28.9951 12.2369 29.631 11.7681 30.0998C11.2993 30.5686 10.6634 30.832 10.0003 30.832ZM30.0003 30.832C29.3373 30.832 28.7014 30.5686 28.2326 30.0998C27.7637 29.631 27.5003 28.9951 27.5003 28.332C27.5003 27.669 27.7637 27.0331 28.2326 26.5643C28.7014 26.0954 29.3373 25.832 30.0003 25.832C30.6634 25.832 31.2992 26.0954 31.7681 26.5643C32.2369 27.0331 32.5003 27.669 32.5003 28.332C32.5003 28.9951 32.2369 29.631 31.7681 30.0998C31.2992 30.5686 30.6634 30.832 30.0003 30.832ZM28.3337 19.9987V15.832H32.5003L35.767 19.9987H28.3337ZM30.0003 11.6654H23.3337V21.6654H5.00033L2.61699 13.332H1.66699V9.9987H21.667L23.3337 8.33203H30.0003V11.6654Z" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default DumpTruckSvg;