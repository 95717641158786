import React, { useEffect, useState } from 'react';
import NewAuthPhone from './NewAuthPhone';

export const AuthPage = () => {
  const [timer, setTimer] = useState<string>('00');
  useEffect(() => {
    Number(timer) !== 0 &&
      setTimeout(() => setTimer((Number(timer) >= 0 && Number(timer) <= 10 ? '0' : '') + (Number(timer) - 1)), 1000);
  }, [timer]);

  return <div className="Page">{<NewAuthPhone />}</div>;
};
