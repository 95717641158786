import React from 'react';

const SubTitle = ({ children, ...props }) => {
  return (
    <p className={'SubTitle'} {...props}>
      {children}
    </p>
  );
};

export default SubTitle;