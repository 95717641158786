import React, { useState } from 'react';
import styles from './MobileShipped.module.scss';
import { InputSearch } from '../../../../components/UI/inputs/InputSearch';
import ChevronIconSvg from '../../../../components/UI/svg-icon/ChevronIconSvg';
import CustomRangePicker from '../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import { SelectCustom } from '../../../../components/UI/select/SelectCustom';
import { optionVolume } from '../../../../common/select-format.type';
import NewEmptySvg from '../../../../components/UI/svg-icon/NewEmptySvg';
import MobileShippedCard from './MobileShippedCard';
import { Filter } from '../../../../common/filter.type';
import { IDataResponseShipped } from '../../type';
import { Dayjs } from 'dayjs';
interface IProps {
    filter:Filter
    setFilter:React.Dispatch<React.SetStateAction<Filter>>;
    dataResponse:IDataResponseShipped[]
    openCalendar:boolean
    setOpenCalendar:React.Dispatch<React.SetStateAction<boolean>>
    dateRange:string[]|Dayjs
    setDateRange:React.Dispatch<React.SetStateAction<string[]|Dayjs>>
    
}
const MobileShipped = ({ filter,setFilter,dataResponse,openCalendar,setOpenCalendar,dateRange,setDateRange } :IProps) => {
    const [openMobileFilter, setOpenMobileFilter] = useState<boolean>(false);

    return (
        <div>
            <div className={styles.mobileContainerInput}>
                <InputSearch
                    placeholder={'Поиск по номеру рейса, водителю или номеру машины'}
                    onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                    value={filter.search}
                />
            </div>
            <div className={openMobileFilter ? styles.mobileSortContainerActive : styles.mobileSortContainer}>
                <div
                    className={styles.mobileSortInputsContainer}
                    onClick={e => {
                        setOpenMobileFilter(!openMobileFilter);
                    }}
                >
                    <p className={styles.mobileSortInputsContainerText}>
                        Фильтры
                    </p>
                    <div className={styles.mobileSortInputsContainerIcon}>
                        <ChevronIconSvg />
                    </div>
                </div>

                {openMobileFilter && (
                    <div className={styles.sortInputsContainer}>
                        <div className={styles.mobileBlockTextAndInput}>
                            <p className={styles.mobileTextFilter}>По дате</p>
                            <div style={{ marginBottom:'10px' }}>
                                <CustomRangePicker
                                    width={'100%'}
                                    placeholder={['Начало', 'Конец']}
                                    isPresets={true}
                                    open={openCalendar}
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    setOpen={setOpenCalendar}
                                />
                            </div>

                            <SelectCustom
                                options={optionVolume}
                                maxTagCount={1}
                                maxCount={1}
                                placeholder={'По номенклатуре'}
                                mode={null}
                                value={filter.sort.field}
                                setValue={value => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        sort: {
                                            field: value?.value,
                                            order: value?.value === 'сначала старые' ? 'ASC' : 'DESC',
                                        },
                                    }));
                                }}
                                disabled={false}
                                status={''}
                                errorText={''}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div style={{ paddingTop:openMobileFilter? '41px':'0' }}>
                {dataResponse?.length ? (
                    dataResponse?.map(item => (
                        <MobileShippedCard flight={item}/> ))
                    ) : (
                    <div>
                        <div className={'emptyContentContainer'}>
                            <div
                                style={{
                                    marginTop: '-60px',
                                    textAlign: 'center',
                                }}
                            >
                                <NewEmptySvg />
                            </div>
                            <div
                                style={{
                                    marginTop: '-40px',
                                }}
                            >
                                <h4 className={'emptyTitle'}>Документов не найдено</h4>
                                <p className={'emptyDescription'}>
                                    По выбранным фильтрам нет документов. <br />
                                    Попробуйте изменить параметры
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileShipped;