import React from 'react';

const NewPlusSvg = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus">
                <path id="Vector" d="M19.5 12.0001L12.5 12.0001M12.5 12.0001L5.5 12.0001M12.5 12.0001L12.5 5M12.5 12.0001L12.5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" fill={'currentColor'}/>
            </g>
        </svg>
    );
};

export default NewPlusSvg;