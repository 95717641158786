import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitlePage } from '../../components/TitlePage';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import { FlightList } from '../../components/FlightList';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { Flight } from '../../common/flight-type';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import { NotActiveProfile } from '../../components/NotActiveProfile';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { statuses, StatusTabs } from '../../common/status-tabs.type';
import { Filter } from '../../common/filter.type';
import { optionVolume } from '../../common/select-format.type';
import { useNavigate, useLocation } from 'react-router-dom';
import useDebounce from '../../CustomHooks/useDebounce';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { TitleFlightsDriver } from 'src/components/TitleFlightsDriver';
import styles from '../CarrierFlightPages/CarrierFlightsPage.module.scss';

export const FlightsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [offset, setOffset] = useState<number>(0);
  const { user } = useContext(Context);
  const [activeFlightsList, setActiveFlightsList] = useState<Flight[]>([]);
  const [completedFlightsList, setCompletedFlightsList] = useState<Flight[]>([]);
  const [skeleton, setSkeleton] = useState(true);
  const [filter, setFilter] = useState<Filter>({ search: '', sort: { field: 'date', order: 'DESC' } });
  delete statuses['Проблемные'];
  const [statusTabs, setStatusTabs] = useState<StatusTabs>(statuses);
  const [status, setStatus] = useState<string>(location.hash ? location.hash.replace('#', '') : 'active');
  const [more, setMore] = useState(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const loadingProfileAndList = async (status, isPagination: boolean) => {
    setSkeleton(true);
    try {
      const flights = await fetchGet(`/flights/driver/completed`, {
        status: status,
        offset: isPagination ? offset : 0,
        limit: 10,
        order: filter.sort.order,
        searchString: filter.search,
        flightCombinedStatus: 'all',
      });
      const activeFlight = await fetchGet(`/flights/driver/active`, {});

      const list: Flight[] = flights.response.rows.map(item => ({
        ...item,
        date: new Date(item.dateTn),
      }));

      const activeList: Flight[] = [{ ...activeFlight?.response, date: new Date(activeFlight?.response?.date) }];

      setTotalCount(flights.response.count);
      if (isPagination && offset !== 0) {
        setCompletedFlightsList(prevState => [...prevState, ...list]);
      } else {
        setCompletedFlightsList(list);
      }

      setActiveFlightsList(activeList);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      console.log(e);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      loadingProfileAndList(status, true);
    }
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    loadingProfileAndList(status, false).catch(console.log);
  }, [more, filter.sort.order, debouncedSearchTerm]);

  const sortFlightStatus = statusName => {
    for (const [key, tab] of Object.entries(statusTabs)) {
      if (key === statusName) {
        setStatus(tab.statusName);
        navigate(`#${tab.statusName}`);
        setOffset(0);
        loadingProfileAndList(tab.statusName, false).catch(console.log);
      }
    }

    setStatusTabs(prevState => ({ ...prevState, ...statusTabs }));
  };
  // @ts-ignore
  return (
    <>
      {user.active ? (
        <PageWrapper>
          <div className="FlightSection__header">
            <TitlePage title={'Рейсы'} quantity={''} />
          </div>
          <div>
            <div className="FlightSection__subTitle" style={{ marginBottom: '40px' }}>
              Просматривайте ваши рейсы и отслеживайте их статус в реальном времени
            </div>

            {/* Текущий рейс */}
            <div className="FlightSection__content">
              <div className="FlightSection__header">
                <TitleFlightsDriver title={'Текущий рейс'} quantity={''} />
              </div>
              <div>
                <div className={`FlightSection__requestTitle`}>
                  {['Рейс', 'Загрузка', 'Разгрузка', 'Транспорт', 'Объем и груз', 'Стоимость рейса и статус'].map(
                    (item, index) => (
                      <div key={index}>{item}</div>
                    ),
                  )}
                </div>
              </div>
              {activeFlightsList[0]?.id ? (
                <>
                  <FlightList
                    setFlightInfo={null}
                    setPopUpActive={null}
                    more={more}
                    setMore={setMore}
                    flightItems={activeFlightsList}
                    filter={filter}
                    isCompleted={false}
                  />
                </>
              ) : (
                <>
                  <div className="requestItem none-flights">
                    <div className="requestItem__title">В настоящий момент у вас нет активных рейсов</div>
                    <div className="requestItem__subTitle">
                      Тут будет отображаться информация по текущему рейсу, как только он появится
                    </div>
                  </div>
                </>
              )}

              {/* Выполненные рейсы */}
              <div className="FlightSection__header" style={isMobile ? { marginTop: '24px' } : { marginTop: '40px' }}>
                <TitleFlightsDriver title={'Выполненные рейсы'} quantity={''} />
              </div>

              {/* Поиск и фильтры */}
              <div
                className={isMobile ? styles.inputsAndSelectorsContainerMobile : styles.inputsAndSelectorsContainer}
                style={isMobile ? { flexDirection: 'column', marginBottom: '24px', gap: '8px' } : {}}
              >
                <div className={styles.inputSearchContainer}>
                  <InputSearch
                    placeholder={'Поиск'}
                    onInput={({ currentTarget: { value: search } }) =>
                      setFilter(prevState => ({ ...prevState, search }))
                    }
                    value={filter.search}
                  />
                </div>
                <div className="searchContent__select" style={isMobile ? {} : { width: '290px' }}>
                  <CustomSelect
                    error={false}
                    placeholder={'Сортировка'}
                    noOptionsMessage={'Нет вариантов'}
                    isClearable={false}
                    isSearchable={false}
                    options={optionVolume}
                    value={filter.sort.field?.label}
                    setValue={({ value, dataset }) =>
                      setFilter(prevState => ({
                        ...prevState,
                        sort: {
                          field: value || '',
                          order: dataset === 'DESC' ? 'DESC' : 'ASC',
                        },
                      }))
                    }
                  />
                </div>
              </div>

              {/* Рейсы */}
              {completedFlightsList?.length ? (
                <div>
                  <div className={`FlightSection__requestTitle completed`}>
                    {['Рейс', 'Загрузка', 'Разгрузка', 'Транспорт', 'Объем и груз', 'Стоимость рейса'].map(
                      (item, index) => (
                        <div key={index}>{item}</div>
                      ),
                    )}
                  </div>
                  {skeleton ? (
                    <div className="FlightSection__content">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '485px ',
                        }}
                      >
                        <div>
                          <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FlightList
                      setFlightInfo={null}
                      setPopUpActive={null}
                      more={more}
                      setMore={setMore}
                      flightItems={completedFlightsList}
                      filter={filter}
                      isCompleted={true}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="FlightSection__content">
                    <div className="EmptyFlightList">
                      <div className="EmptyFlightList__banner">
                        <div className="EmptyFlightList__error">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        В данный момент у вас нет рейсов
                      </div>
                      <div className="EmptyFlightList__wrapper">
                        <div className="EmptyFlightList__content">
                          <div className="EmptyFlightList__svg">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="EmptyFlightList__title">Рейсы отсутствуют</div>
                          <div className="EmptyFlightList__subTitle">В данный момент у вас нет рейсов</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <TitlePage title={'Рейсы'} quantity={''} />
          <NotActiveProfile role={'водителя '} />
        </PageWrapper>
      )}
    </>
  );
};
