import React, { useEffect, useState } from 'react';
import styles from './AddContract.module.scss';
import { Input, Modal } from 'antd';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { systemOptionsOfEDO } from './const';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import SuccessContractModal from '../SuccessContractModal/SuccessContractModal';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { TypeTitlesOfTheCreatedContract } from '../../type';
import { InitStateTitlesOfTheCreatedContract } from '../../constans';

interface IProps {
    openAddContract: boolean;
    setOpenAddContract: React.Dispatch<React.SetStateAction<boolean>>;
    getData:any
    isFirstRender:number
}
const AddContract = ({ openAddContract, setOpenAddContract,getData,isFirstRender }:IProps) => {
    const [valueSystemEDO, setValueSystemEDO] = useState(null);
    const [successContractModal,setSuccessContractModal] = useState<boolean>(false);
    const [titlesOfTheCreatedContract,setTitlesOfTheCreatedContract] = useState<TypeTitlesOfTheCreatedContract>(InitStateTitlesOfTheCreatedContract);
    const [loading, setLoading] = useState<boolean>(false);
    const closeAddContract = () => {
        setOpenAddContract(false);
    };
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const validateDisableButton = valueSystemEDO===null;
    const addNewContract = async () => {
        setLoading(true);
        try {

            const response = await fetchPost('/information/contracts', 'POST', {});
            
            if (response.error) {
                const errorMessages = response.message.map((message: string) => (
                    <p>
                        {message}
                        <br />
                        <br />
                    </p>
                ));
                toast.error(<div>{errorMessages}</div>);

                setLoading(false);
                return;
            }
            
            toast.success('Данные успешно отправлены');
            closeAddContract();
            setSuccessContractModal(true);

            setTitlesOfTheCreatedContract(response?.response);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    useEffect(()=>{
        setValueSystemEDO(null);
    },[openAddContract]);
    return (
        <>
            {isMobile ?
                <div className={openAddContract ? 'DriverPagePopup active' : 'DriverPagePopup'}>
                    <div onClick={() => closeAddContract()} className="DriverPagePopup__white"></div>
                    <div className="DriverPagePopup__line"></div>
                    <div className="DriverPagePopup__wrapper"
                         style={{ height: 'fit-content',
                             padding:'24px 16px 10px 16px',
                             top: 'auto',bottom:'0',borderRadius: '12px 12px 0px 0px',background: `#EAEEFF`,
                    }}
                    >
                    <div className={styles.drawerTriangleContainer}>
                        <div className={styles.drawerTriangle} />
                    </div>
                    <div className={styles.container}>
                        <div className={styles.topContainer}>
                            <div className={styles.titleAndCloseButton}>
                                <h1 className={styles.title}>
                                    Добавление договора
                                </h1>
                                <div className={styles.mobileCloseIconDrawer} >
                                    <div className={styles.mobileCloseIconBlockDrawer} onClick={closeAddContract}>
                                        <CloseSvg />
                                    </div>
                                </div>
                            </div>
                            {loading ?
                                <LoadingSpin/>
                                :
                                <div className={styles.contentContainer}>
                                    <div className={styles.blockContainer}>
                                        <p className={styles.blockTitleText}>Получатель</p>
                                        <Input className={styles.inputContainer} value={'Неруд центр'} disabled={true}/>
                                    </div>
                                    <div className={styles.border}/>
                                    <div className={styles.blockContainer}>
                                        <div className={styles.blockTitleContainer}>
                                            <p className={styles.blockTitleText}>Тип документа</p>
                                        </div>
                                        <Input className={styles.inputContainer} value={'Договор'} disabled={true}/>
                                    </div>
                                    <div className={styles.border}/>
                                    <div className={styles.blockContainer}>
                                        <p className={styles.blockTitleText}>Система ЭДО</p>
                                        <CustomSelect
                                            height40px={true}
                                            value={valueSystemEDO}
                                            options={systemOptionsOfEDO}
                                            setValue={setValueSystemEDO}
                                            placeholder={'Выберите систему ЭДО'}
                                            isClearable={false}
                                            error={false}
                                            isSearchable={false}
                                            noOptionsMessage={''}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.buttonContainer}>
                            <ButtonNotFilled
                                disabled={loading}
                                sizeText={'small'}
                                onClick={closeAddContract}
                                text={'Отмена'}
                                width={'100%'}
                            />
                            <ButtonFilled
                                sizeText={'small'}
                                disabled={validateDisableButton || loading}
                                onClick={addNewContract}
                                text={'Отправить'}
                                width={'100%'}
                            />
                        </div>
                    </div>
                    </div>
                </div>
                    :
                <Modal zIndex={9500} width={480} footer={null} open={openAddContract} onCancel={closeAddContract} closeIcon={false}>
                    <div className={styles.container}>
                        <div className={styles.topContainer}>
                            <h1 className={styles.title}>
                                Добавление договора
                            </h1>
                            {loading ?
                                <LoadingSpin/>
                                :
                                <div className={styles.contentContainer}>
                                    <div className={styles.blockContainer}>
                                        <p className={styles.blockTitleText}>Получатель</p>
                                        <Input className={styles.inputContainer} value={'Неруд центр'} disabled={true}/>
                                    </div>
                                    <div className={styles.border}/>
                                    <div className={styles.blockContainer}>
                                        <div className={styles.blockTitleContainer}>
                                            <p className={styles.blockTitleText}>Тип документа</p>
                                        </div>
                                        <Input className={styles.inputContainer} value={'Договор'} disabled={true}/>
                                    </div>
                                    <div className={styles.border}/>
                                    <div className={styles.blockContainer}>
                                        <p className={styles.blockTitleText}>Система ЭДО</p>
                                        <CustomSelect
                                            height40px={true}
                                            value={valueSystemEDO}
                                            options={systemOptionsOfEDO}
                                            setValue={setValueSystemEDO}
                                            placeholder={'Выберите систему ЭДО'}
                                            isClearable={false}
                                            error={false}
                                            isSearchable={false}
                                            noOptionsMessage={''}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.buttonContainer}>
                            <ButtonNotFilled
                                onClick={closeAddContract}
                                disabled={loading}
                                text={'Отмена'}
                                width={'100%'}
                            />
                            <ButtonFilled
                                disabled={validateDisableButton || loading}
                                onClick={addNewContract}
                                text={'Отправить'}
                                width={'100%'}
                            />
                        </div>
                    </div>
                <div className={styles.closeButton}>
                    <FrameModalSvg onClick={closeAddContract} />
                </div>
            </Modal>
            }
            <SuccessContractModal successContractModal={successContractModal} titlesOfTheCreatedContract={titlesOfTheCreatedContract}
                                  setSuccessContractModal={setSuccessContractModal} getData={getData} isFirstRender={isFirstRender}/>
        </>

    );
};

export default AddContract;