import React from 'react';

const UnionRefuseSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Union">
                <path d="M9.03031 7.96967C8.73742 7.67678 8.26254 7.67678 7.96965 7.96967C7.67676 8.26256 7.67676 8.73744 7.96965 9.03033L9.93933 11L7.96967 12.9697C7.67678 13.2626 7.67678 13.7374 7.96967 14.0303C8.26256 14.3232 8.73744 14.3232 9.03033 14.0303L11 12.0607L12.9696 14.0303C13.2625 14.3232 13.7374 14.3232 14.0303 14.0303C14.3232 13.7374 14.3232 13.2625 14.0303 12.9697L12.0606 11L14.0303 9.03035C14.3232 8.73746 14.3232 8.26258 14.0303 7.96969C13.7374 7.6768 13.2625 7.6768 12.9696 7.96969L11 9.93935L9.03031 7.96967Z" fill="#DC2626"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="#DC2626"/>
            </g>
        </svg>
    );
};

export default UnionRefuseSvg;