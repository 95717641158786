// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Constans_containerColumn__Mg-Bk {\n  display: flex;\n  align-items: center;\n  height: 70px;\n  width: 100%;\n}\n\n.Constans_tableContainer__tiXJ7 {\n  padding: 16px;\n  background-color: #EFF6FF;\n  border-radius: 12px;\n}\n\n.Constans_thickTextColumn__kJUOc {\n  color: #373D50;\n  font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  padding-right: 10px;\n}\n\n.Constans_mediumTextColumn__5W9Es {\n  color: #373D50;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding-right: 10px;\n}\n\n.Constans_softTextColumn__o2Dir {\n  color: #878DA0;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/DispatchingPages/Constans/Constans.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGF;;AADA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAKF","sourcesContent":[".containerColumn {\n  display: flex;\n  align-items: center;\n  height: 70px;\n  width: 100%;\n}\n.tableContainer{\n  padding: 16px;\n  background-color: #EFF6FF;\n  border-radius: 12px;\n}\n.thickTextColumn{\n  color: #373D50;\n  font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  padding-right: 10px;\n}\n.mediumTextColumn{\n  color: #373D50;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding-right: 10px;\n}\n.softTextColumn{\n  color: #878DA0;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerColumn": "Constans_containerColumn__Mg-Bk",
	"tableContainer": "Constans_tableContainer__tiXJ7",
	"thickTextColumn": "Constans_thickTextColumn__kJUOc",
	"mediumTextColumn": "Constans_mediumTextColumn__5W9Es",
	"softTextColumn": "Constans_softTextColumn__o2Dir"
};
export default ___CSS_LOADER_EXPORT___;
