import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Line } from './UI/Line';
import { useMediaQuery } from 'react-responsive';

type TextType = {
  title: string,
  description: string,
  isLink: boolean,
  hrefLink: string,
  link: string
  props: any
}

export const HelpItem = ({ title, description, isLink, hrefLink, link, ...props }: TextType) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
    const [active, setActive] = useState(false);

    return (
      <>
        <div {...props} className={active ? 'HelpItem active' : 'HelpItem'} >
          <div className="HelpItem__titleBox" onClick={() => setActive(!active)}>
            <div className="HelpItem__title">{title}</div>
            <div className="HelpItem__btn"><span></span></div>
          </div>
          <div className="HelpItem__description">
            {description}
            {isLink && <NavLink to={hrefLink} >{link}</NavLink>}
          </div>
        </div>
        {isMobile && <Line/>}
      </>
    );
};