import React, { FC } from 'react';
import { Map, Placemark, TypeSelector, YMaps } from '@pbe/react-yandex-maps';
import styles from './CustomYMap.module.scss';

interface IProps {
  latitude: string | number;
  longitude: string | number;
}

const CustomYMap: FC<IProps> = ({ latitude, longitude }) => {
  return (
    <div>
      <YMaps
        query={{
          apikey: '0e34bd35-ff2f-46ac-abbd-6a8143e395c3',
        }}
      >
        <div>
          <Map
            className={styles.yMapsObjectsPage}
            state={{
              center: [Number(latitude), Number(longitude)],
              zoom: 5,
            }}
          >
            <Placemark geometry={[Number(latitude), Number(longitude)]} />
            {/*@ts-ignore*/}
            <TypeSelector options={{ float: 'right' }} />
          </Map>
        </div>
      </YMaps>
    </div>
  );
};

export default CustomYMap;
