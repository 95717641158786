import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from '../../../context/context';
// @ts-ignore
import styles from './NewNavItem.module.css';

const NewNavItem = ({
  link,
  disabled,
  svg,
  typeAccess,
  svgActive,
  svgProfileError,
  name,
  notActiveProfile,
  errorProfile,
  ...props
}) => {
  const { user } = useContext(Context);
  const [activeLink, setActiveLink] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const changeState = state => {
    if (state) {
      setActiveLink(true);
      return styles.activeLink;
    }
    setActiveLink(false);
    return styles.noActiveLink;
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const correctionStyleActiveSvg = () => {
    if (isHovering) {
      return styles.NavItemSvgHover;
    } else if (activeLink) {
      return styles.NavItemSvgActive;
    } else {
      return styles.NavItemSvg;
    }
  };
  const successfulAccess = typeAccess?.includes(user?.type);
  return (
    successfulAccess && (
      <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <NavLink {...props} to={link} className={({ isActive }) => changeState(isActive)}>
          <div className={isHovering ? styles.NavLinkContainerDisplay : styles.NavLinkContainer}>
            <div className={correctionStyleActiveSvg()}>{activeLink ? svgActive : svg}</div>
            <div className={isHovering ? styles.NavItemTextDisplay : styles.NavItemText}>{name}</div>
          </div>
        </NavLink>
      </div>
    )
  );
};

export default NewNavItem;
