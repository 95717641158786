import React, {useRef, useState } from 'react';
import styles from './CustomInput.module.scss';
import { CloseCircleNewSvg } from '../../UI/svg-icon/CloseCircleNewSvg';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { HelpNewSvg } from '../../UI/svg-icon/HelpNewSvg';

interface IProps {
    type?: string;
    value: any;
    placeholder: string;
    setValue?: React.Dispatch<any>;
    disabled: boolean;
    onChange?:any
    name?:string
    width?:string
    clearButton?:boolean
    clearButtonFunction?:any
    helpText?:string
    widthSvg?:string
    widthHelp?:string
    error?:boolean
    index?:number
}
const CustomInput = ({
                         type,
                         value,
                         setValue,
                         disabled,
                         placeholder,
                         onChange,
                         name,
                         width,
                         clearButton,
                         helpText,
                         widthSvg,
                         widthHelp,
                         error,
                         clearButtonFunction,
                         index,
}: IProps) => {
    const [focus,setFocus] = useState<boolean>(false);
    const refInput = useRef()
    return (
      <>
          <div
              style={{
                  width:width,
                  gap:(helpText||clearButton) && '16px',
                  backgroundColor:error&&'#FEF2F2',
                  border:error&&'1px solid #DC2626'
              }}
              className={focus?styles.containerFocusByInput:styles.container} tabIndex={1}>
              <input
                  ref={refInput}
                  autoComplete={'off'}
                  onFocus={()=>setFocus(true)}
                  onBlur={()=>setFocus(false)}
                  name={name}
                  placeholder={placeholder}
                  className={styles.input}
                  type={type}
                  disabled={disabled}
                  value={value || ''}
                  onChange={onChange ? onChange : ({ target: { value } }) => setValue(String(value))}
              />
              <div className={styles.helpersButtonContainer}>
                  {clearButton && (
                      <div
                          onClick={(e) => {
                              clearButtonFunction ? clearButtonFunction(refInput,index) : setValue((value = ''));
                              e.stopPropagation();
                          }}
                          className={styles.closeButton}
                      >
                          <CloseCircleNewSvg/>
                      </div>
                  )}
                  {helpText && (
                      <CustomTooltip
                          SideTooltip={'left'}
                          MobileSideTooltip={'left'}
                          svg={<HelpNewSvg color={'#1C274C'}/>}
                          text={helpText}
                          widthSvg={'16px'}
                          widthTooltip={widthHelp ? widthHelp : '311px'}
                          positionTooltipRight={'-13px'}
                          positionTooltipBottom={'28px'}
                      />
                  )}
              </div>
          </div>
      </>

  );
};

export default CustomInput;
