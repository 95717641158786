import React from 'react';

type TCloseCircleNewSvg = {
  width?: string;
  color?: string;
};

export const CloseCircleNewSvg = ({ width = '16px', color = '#1C274C' }: TCloseCircleNewSvg) => {
  return (
    <svg width={`${width}`} height={`${width}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25576_213460)">
        <path
          d="M6.68752 5.97994C6.49226 5.78468 6.17568 5.78468 5.98042 5.97994C5.78515 6.1752 5.78515 6.49179 5.98042 6.68705L7.29354 8.00017L5.98043 9.31328C5.78517 9.50854 5.78517 9.82512 5.98043 10.0204C6.17569 10.2156 6.49228 10.2156 6.68754 10.0204L8.00064 8.70728L9.31374 10.0204C9.509 10.2156 9.82558 10.2156 10.0208 10.0204C10.2161 9.82511 10.2161 9.50852 10.0208 9.31326L8.70775 8.00017L10.0209 6.68706C10.2161 6.4918 10.2161 6.17522 10.0209 5.97996C9.82559 5.78469 9.50901 5.78469 9.31375 5.97996L8.00064 7.29306L6.68752 5.97994Z"
          fill={`${color}`}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00065 0.833496C4.04261 0.833496 0.833984 4.04212 0.833984 8.00016C0.833984 11.9582 4.04261 15.1668 8.00065 15.1668C11.9587 15.1668 15.1673 11.9582 15.1673 8.00016C15.1673 4.04212 11.9587 0.833496 8.00065 0.833496ZM1.83398 8.00016C1.83398 4.59441 4.5949 1.8335 8.00065 1.8335C11.4064 1.8335 14.1673 4.59441 14.1673 8.00016C14.1673 11.4059 11.4064 14.1668 8.00065 14.1668C4.5949 14.1668 1.83398 11.4059 1.83398 8.00016Z"
          fill={`${color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_25576_213460">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
