import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <>
      <App />
    </>
);
