import React from 'react';

type TCheckCircleSvg = {
  width?: string;
  color?: string;
};

export const CheckCircleSvg = ({ width = '16px', color = '#1C274C' }: TCheckCircleSvg) => {
  return (
    <svg width={`${width}`} height={`${width}`} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5303 10.0303C16.8232 9.73744 16.8232 9.26256 16.5303 8.96967C16.2374 8.67678 15.7626 8.67678 15.4697 8.96967L11 13.4393L9.53033 11.9697C9.23744 11.6768 8.76256 11.6768 8.46967 11.9697C8.17678 12.2626 8.17678 12.7374 8.46967 13.0303L10.4697 15.0303C10.7626 15.3232 11.2374 15.3232 11.5303 15.0303L16.5303 10.0303Z"
        fill={`${color}`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 1.25C6.56294 1.25 1.75 6.06294 1.75 12C1.75 17.9371 6.56294 22.75 12.5 22.75C18.4371 22.75 23.25 17.9371 23.25 12C23.25 6.06294 18.4371 1.25 12.5 1.25ZM3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12Z"
        fill={`${color}`}
      />
    </svg>
  );
};
