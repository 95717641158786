import React from 'react';
import { WarningSvg } from './UI/svg-icon/WarningSvg';

type Props = {
  text: string;
  color: string;
};

export const Notification = ({ text, color }: Props) => {
  return (
    <div className={`notification ${color}`}>
      <div className={'notification__text'}>{text}</div>
    </div>
  );
};
