import React from 'react';
import styles from './ShowcaseCard.module.scss';
import { dataCardResponseType } from '../../type';
import { useNavigate } from 'react-router-dom';
import headerImage from '../../images/default-header-showcases.png'
import logoImage from '../../images/default-logo.png'

interface IProps {
    dataCardResponse: dataCardResponseType[];
}
const ShowcaseCard = ({ dataCardResponse }: IProps) => {
    const navigate = useNavigate();

    const logoOrHeaderImage = {
        'Шапка витрины':headerImage,
        'Лого витрины':logoImage,
    }
    const chooseImageForLogoAndCover = (showCase, logoOrCover:'Шапка витрины'|'Лого витрины') => {
        let image = showCase?.files?.find(item => item.category === logoOrCover)
        return image ? image.location : logoOrHeaderImage[logoOrCover]
    }

    return (
        <div className={styles.cardContainer}>
            {dataCardResponse.map(showCase => (
                <div key={showCase?.id} className={styles.container} onClick={() => navigate(`/showcases/${showCase?.id}`)}>
                    <div
                        className={styles.backgroundImageContainer}
                        style={{
                            backgroundImage: `url(${
                                chooseImageForLogoAndCover(showCase,'Шапка витрины')
                            })`,
                        }}
                    />
                    <div className={styles.contentContainer}>
                        <p className={styles.titleContentText}>{showCase?.title ? showCase?.title : 'Нет данных'}</p>
                        <p className={styles.subTitleContentText}>{showCase?.description ? showCase?.description : 'Нет данных'}</p>
                        <div className={styles.border} />
                        <p className={styles.addressContentText}>
                            {showCase?.quarry?.address?.address ?
                                showCase?.quarry?.address?.address : 'Нет данных'}
                        </p>
                    </div>
                    <div
                        className={styles.logoContainer}
                        style={{
                            backgroundImage: `url(${
                                chooseImageForLogoAndCover(showCase,'Лого витрины')
                            })`,
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default ShowcaseCard;
