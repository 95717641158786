import React from 'react';
import { cardNumberRegex } from './CardItem';
import { CustomSelect } from './UI/select/CustomSelect';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { CardSvg } from './UI/svg-icon/CardSvg';
import { formPrice } from '../common/form-price.helper';

const logoType = {
  'Рос нефть': 'РОСНЕФТЬ',
  'Тат нефть': 'ТАТНЕФТЬ'
};

export const CardInformation = ({ data, driverList, pinDriver, setPickDriver, buttonOptions, buttonColor }) => {
  const changeValue = (value) => {
    if (!value) {
      buttonColor({ color: 'gray', disable: true });
      return;
    }

    buttonColor({ color: '', disable: false });
    setPickDriver(value);
  };

  return (
    <div className="cardInformation">
      <div className="cardInformation__box cardInformation__box_sub">
        <div className="cardInformation__title">Информация по топливной карте</div>
        <div className="cardInformation__content">
          <div className="cardInformation__item">
            <div className="cardInformation__name">Номер карты</div>
            <div className="cardInformation__subName">{data?.cardNumber ? cardNumberRegex(data?.cardNumber) : 'Нет данных'}</div>
          </div>
          <div className="cardInformation__item">
            <div className="cardInformation__name">Баланс</div>
            <div className="cardInformation__subName">{data?.balanceAmount ? formPrice(data?.balanceAmount, 'RUB') : 'Нет данных'}</div>
          </div>
          <div className="cardInformation__item">
            <div className="cardInformation__name">Лимит по карте</div>
            <div className="cardInformation__subName">{data?.limitAmount ? formPrice(data?.limitAmount, 'RUB') : 'Нет данных'}</div>
          </div>
          <div className="cardInformation__item">
            <div className="cardInformation__name">Сеть АЗС</div>
            <div className="cardInformation__subName">{data?.company ? logoType[data?.company] : 'Нет данных'}</div>
          </div>
          <div className="cardInformation__item">
            <div className="cardInformation__name">Назначенный водитель</div>
            <div className="cardInformation__subName">{data?.users?.fullName ? data?.users?.fullName : 'Нет данных'}</div>
          </div>
        </div>
      </div>
      <div className="cardInformation__box">
        <div className="cardInformation__title">Закрепление за водителем</div>
        <div className="cardInformation__content">
          <div className="cardInformation__select">
            <CustomSelect
                error={false}
              defaultValue={data?.users && { value: data?.users.uuid, label: data?.users.fullName }}
              setValue={(value) => changeValue(value)}
              options={driverList}
              placeholder={'Выберите водителя'}
              noOptionsMessage={'Нет вариантов'}
              isSearchable={true}
              isClearable={true}
            />
          </div>
          <div className="cardInformation__button">
            <ButtonTransparent
              modifier={''}
              onClick={pinDriver}
              children={'Закрепить за водителем'}
              isSvg={true}
              svg={<CardSvg/>}
              color={buttonOptions.color}
              disabled={buttonOptions.disable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
