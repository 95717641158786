import React from 'react';

const SuccessSvgVer2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148" fill="none">
            <path d="M75.8258 98.7996C82.7545 96.1836 109.908 83.1493 111.11 39.2038C111.161 37.3316 110.184 35.5622 108.594 34.5727C87.5537 21.4809 60.9025 21.4809 39.8622 34.5727C38.272 35.5622 37.2952 37.3316 37.3464 39.2038C38.5481 83.1493 65.7017 96.1836 72.6304 98.7996C73.6648 99.1902 74.7914 99.1902 75.8258 98.7996Z" fill="url(#paint0_linear_24505_185379)"/>
            <g filter="url(#filter0_b_24505_185379)">
                <path d="M37.5903 49.9652L34.252 51.8728C32.3347 52.9684 31.1373 55.0362 31.2101 57.2433C32.7821 104.882 65.4258 118.44 72.7697 120.893C73.7356 121.215 74.7206 121.215 75.6865 120.893C83.0304 118.44 115.674 104.882 117.246 57.2433C117.319 55.0362 116.122 52.9684 114.204 51.8728L110.866 49.9652C88.1633 36.9922 60.2929 36.9922 37.5903 49.9652Z" fill="#6AE092" fill-opacity="0.6"/>
                <path d="M34.4038 52.1384L37.7421 50.2308C60.3507 37.3116 88.1055 37.3116 110.714 50.2308L114.052 52.1384C115.875 53.1798 117.009 55.1435 116.94 57.2332C116.157 80.9763 107.634 96.2038 98.4102 105.801C89.1805 115.404 79.2333 119.386 75.5896 120.602C74.6866 120.904 73.7696 120.904 72.8666 120.602C69.2229 119.386 59.2757 115.404 50.046 105.801C40.8224 96.2038 32.2994 80.9763 31.5159 57.2332C31.447 55.1435 32.5813 53.1798 34.4038 52.1384Z" stroke="url(#paint1_linear_24505_185379)" stroke-width="0.611895"/>
            </g>
            <path d="M70.978 84.2138C71.0239 84.2597 71.0861 84.2854 71.151 84.2854C71.2159 84.2854 71.2782 84.2597 71.3241 84.2138L89.5989 65.9389C91.3057 64.2321 94.0731 64.2321 95.7799 65.9389C97.4867 67.6458 97.4867 70.4131 95.7799 72.1199L74.2415 93.6583C73.4219 94.4779 72.3102 94.9384 71.151 94.9384C69.9919 94.9384 68.8802 94.4779 68.0605 93.6583L55.7529 81.3506C54.0461 79.6438 54.0461 76.8765 55.7529 75.1697C57.4597 73.4628 60.227 73.4628 61.9339 75.1697L70.978 84.2138Z" fill="url(#paint2_linear_24505_185379)" fill-opacity="0.9" stroke="url(#paint3_linear_24505_185379)" stroke-width="0.489516" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
                <filter id="filter0_b_24505_185379" x="18.8994" y="27.9287" width="110.657" height="105.514" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.15382"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24505_185379"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24505_185379" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_24505_185379" x1="53.7287" y1="43.8032" x2="133.972" y2="104.195" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#75EDA1"/>
                    <stop offset="1" stop-color="#349256"/>
                </linearGradient>
                <linearGradient id="paint1_linear_24505_185379" x1="32.8127" y1="48.6099" x2="139.618" y2="127.373" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.734375" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_24505_185379" x1="60.9598" y1="76.1455" x2="128.268" y2="84.1002" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_24505_185379" x1="55.4527" y1="68.8028" x2="82.988" y2="77.9812" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SuccessSvgVer2;