import React from 'react';
import LoadingGif from '../../../assets/img/Loader-Solber.gif';
import { useMediaQuery } from 'react-responsive';

const LoadingSpin = () => {
    
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const Spin = () => {
        return (
            <div style={{ height:isMobile?'290px':'384px',display:'flex',alignItems:'center',justifyContent:'center' }}>
                <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
            </div>
        );
    };
    return (
        <Spin/>
    );
};

export default LoadingSpin;