import React from 'react';

export const CheckBox = ({ children, profile, setProfile }) => {
    const doAlert = ({ target: { checked } }) => {
      setProfile({ ...profile, citizenship: checked });
    };

    return (
        <div className='CheckBox'>
            <input onChange={doAlert} className='CheckBox__input' type='checkbox' name='citizenship' id='citizenship'/>
            <label className={profile.citizenship ? 'CheckBox active' : 'CheckBox'}  htmlFor='citizenship'>
                <div className='CheckBox__check'>
                    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M18.0002 8.66656L10.0002 16.6666L6.3335 12.9999L7.2735 12.0599L10.0002 14.7799L17.0602 7.72656L18.0002 8.66656Z' fill='white'/>
                    </svg>
                </div>
                {children}
            </label>
        </div>
    );
};