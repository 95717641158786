import React from 'react';
import { Link } from 'react-router-dom';
import { RosneftLogo } from './UI/svg-icon/RosneftLogo';
import { TatneftLogo } from './UI/svg-icon/TatneftLogo';
import { formPrice } from '../common/form-price.helper';

type Card = {
  uuid: string;
  cardNumber: number;
  remainder: number;
  id: number;
  logo: string;
  link: boolean;
  nameCompany: string;
}

export const cardNumberRegex = (number) => {
  const cleaned = ('' + number);
  return ([
    cleaned[0], cleaned[1], cleaned[2], cleaned[3], ' ',
    cleaned[4], cleaned[5], cleaned[6], cleaned[7], ' ',
    cleaned[8], cleaned[9], cleaned[10], cleaned[11], ' ',
    cleaned[12], cleaned[13], cleaned[14], cleaned[15]
  ].join(''));
};

const logoType = (value) => {
  switch (value) {
    case 'Рос нефть': {
      return <RosneftLogo/>;
    }

    case 'Тат нефть': {
      return <TatneftLogo/>;
    }

    default :{
      return '';
    }
  }
};

export const CardItem = ({ uuid, cardNumber, id, remainder, logo, link, nameCompany }: Card) => {

  const cardItem = (
    <>
      <div className="card__number number">
        <div className="number__text">{nameCompany ? nameCompany : 'Нет данных'}</div>
        <div className="number__number">&#8470;&#32;{cardNumberRegex(cardNumber)}</div>
      </div>
      <div className="card__description">
        <div className="card__balance balance">
          <div className="balance__number">{formPrice(remainder | 0, 'RUB')}</div>
        </div>
        <div className="card__logoOil">{logoType(logo)}</div>
      </div>
    </>
  );

  return (
    <>
      {link
        ? <Link to={`/gsm/${id}`} onClick={() => localStorage.setItem('cardNumber', `${uuid}`)} className="card">
          {cardItem}
        </Link>
        : <div className="card">
          {cardItem}
        </div>
      }
    </>
  );
};
