import React from 'react';
import { WarningEmptySvg } from '../svg-icon/WarningEmptySvg';
import CustomTooltip from '../../newUI/CustomTooltip/CustomTooltip';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { HelpNewSvg } from '../svg-icon/HelpNewSvg';
import { CloseCircleNewSvg } from '../svg-icon/CloseCircleNewSvg';

type Props = {
  isLabel: boolean; // Наличие заголовка
  margin: string;
  width?: string;
  error: boolean;
  showError?: boolean;
  type: string;
  value: any;
  textError: string;
  placeholder: string;
  setValue: React.Dispatch<any>;
  disabled: boolean;
  clearButton?: boolean;
  helpText: string | ReactJSXElement;
  widthHelp?: any;
  modificator?: string;
  additionalSvg?: ReactJSXElement;
  [key: string]: any;
};

export const InputText = ({
  isLabel,
  margin,
  error,
  showError,
  clearButton,
  type,
  value,
  textError,
  placeholder,
  setValue,
  disabled,
  helpText,
  widthHelp,
  width,
  modificator = '',
  additionalSvg = <></>,
  ...props
}: Props) => {
  return (
    <div>
      <div
        className={`InputText__wrapper ${(error && !value) || showError ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
        style={{ marginTop: margin, width: width }}
      >
        <div style={{ display: (helpText || clearButton) && 'flex' }} className={`InputText form-item ${modificator}`}>
          <input
            {...props}
            className={`InputText__input form-input`}
            type={type}
            disabled={disabled}
            value={value || ''}
            onChange={({ target: { value } }) => setValue(String(value))}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '4px 16px 0 0' }}>{additionalSvg}</div>
          <div style={{ display: 'flex' }}>
            {clearButton && (
              <div
                onClick={() => setValue((value = ''))}
                style={{ display: 'flex', alignItems: 'center', width: '16px', marginRight: '16px', cursor: 'pointer' }}
              >
                <CloseCircleNewSvg />
              </div>
            )}
            {helpText && (
              <CustomTooltip
                SideTooltip={'left'}
                MobileSideTooltip={'left'}
                style={{ marginRight: '16px' }}
                svg={<HelpNewSvg color="#1C274C" />}
                text={helpText}
                widthSvg={'24px'}
                widthTooltip={widthHelp ? widthHelp : '311px'}
              />
            )}
          </div>
          {isLabel && (
            <label className={value ? 'InputText__label form-label valid' : 'InputText__label form-label'}>
              {placeholder}
            </label>
          )}
        </div>
      </div>
      {((error && !value) || showError) && <div className={'InputText__text'}>{textError}</div>}
    </div>
  );
};
