import React from 'react';

export const SkeletonQuarry = () => {
    return (
        <div className={'skeleton-quarry'}>
            <div className={'skeleton-quarry__back'}>
                <div></div>
                <div></div>
            </div>
            <div className={'skeleton-quarry__title'}></div>
            <div className={'skeleton-quarry-box'}>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={'skeleton-quarry-box skeleton-quarry-box_map'}>
                <div>
                    <div></div>
                </div>
                <div>
                    <div className={'mini'}></div>
                    <div></div>
                    <div className={'mini'}></div>
                    <div className={'duo'}>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={'button'}>
                        <div></div>
                    </div>
                    <div className={'map'}></div>
                </div>
            </div>
            <div className={'skeleton-quarry-box'}>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={'skeleton-quarry-box'}>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={'skeleton-quarry-box'}>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};