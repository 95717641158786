import React from 'react';
import styles from './MaterialCatalog.module.scss';
import { useMediaQuery } from 'react-responsive';
import { CirclePlusSvg } from 'src/components/UI/svg-icon/CirclePlusSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMaterials } from '../../type';
import {
    convertNameFromValueMaterial,
    svgByMaterial
} from '../../constans';
interface IProps {
    materials:IMaterials[]
}
export const MaterialCatalog = ({ materials }:IProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const isShowcasePath = !location.pathname.startsWith('/store');

    const isMobile = useMediaQuery({ maxWidth: 768 });
    let width = isMobile ? '24px' : '16px';
    return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <div className={isMobile ? 'textH5' : 'textH4 textH4_medium'}>Каталог материалов</div>
        {isShowcasePath && (
          <div className={styles.addMaterialButton}
               onClick={()=>navigate(`/showcases/materials/${pathname.split('/')[pathname.split('/').length-1]}`)}>
            <CirclePlusSvg width={width} />
            {!isMobile && <p>Добавить материал</p>}
          </div>
        )}
      </div>
        <div className={styles.cardsContainer}>
            {materials.map((material)=>(
                <div
                    className={styles.cardContainer}
                    onClick={()=> {
                        navigate(`/showcases/materials/${material.breed}/${material.id}/${material.idShowCase}`);
                        localStorage.setItem('currentKindMaterialForSelect',material.properties.find(item=>item.title==='kind').value);
                    }}
                >
                    {isMobile ?
                        <>
                            <div className={styles.cardContent}>
                                <div className={styles.titleAndShowImageCard}>
                                    <p className={styles.titleCard}>{convertNameFromValueMaterial[material.breed] + ' ' +
                                        material?.properties?.find(item=> item.title === 'kind').value.toLowerCase()}</p>
                                </div>
                                <div className={styles.fractionMaterialContainer}>
                                    {material?.properties?.filter(fraction=>fraction.title === 'fraction').map(item=>(
                                        <p className={styles.fractionMaterial}>{item.value}</p>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.imageAndVolumeAndPrice}>
                                <div className={styles.svgMaterialContainer}>{svgByMaterial[material.breed]}</div>
                                <div className={styles.volumeAndPrice}>
                                    <div className={styles.infoContainer}>
                                        <p className={styles.infoTextTitleMobile}>Остаток:</p>
                                        <p className={styles.infoTextValueMobile}>{material.volume} тн</p>
                                    </div>
                                    <div className={styles.infoContainer}>
                                        <p className={styles.infoTextTitleMobile}>Цена:</p>
                                        <p className={styles.infoTextValueMobile}>от {material.minimalPrice} ₽/тн</p>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={styles.svgMaterialContainer}>{svgByMaterial[material.breed]}</div>
                            <div className={styles.cardContent}>
                                <div className={styles.titleAndShowImageCard}>
                                    <p className={styles.titleCard}>{convertNameFromValueMaterial[material.breed] + ' ' +
                                        material?.properties?.find(item=> item.title === 'kind').value.toLowerCase()}
                                    </p>
                                </div>
                                <div className={styles.fractionMaterialContainer}>
                                    {material?.properties?.filter(fraction=>fraction.title === 'fraction').map(item=>(
                                        <p className={styles.fractionMaterial}>{item.value}</p>
                                    ))}
                                </div>
                                <div className={styles.infoContainer}>
                                    <p className={styles.infoText}>Остаток:</p>
                                    <p className={styles.downBorder}/>
                                    <p className={styles.infoText}>{material.volume} тн</p>
                                </div>
                                <div className={styles.infoContainer}>
                                    <p className={styles.infoText}>Цена:</p>
                                    <p className={styles.downBorder}/>
                                    <p className={styles.infoText}>от {material.minimalPrice} ₽/тн</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            ))}
        </div>
    </div>
  );
};
