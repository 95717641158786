import React from 'react';
import styles from './MobileAwaitingShipment.module.scss';
import { InputSearch } from '../../../../components/UI/inputs/InputSearch';
import NewEmptySvg from '../../../../components/UI/svg-icon/NewEmptySvg';
import MobileAwaitingShipmentCard from './MobileAwaitingShipmentCard';
import { Filter } from '../../../../common/filter.type';
import { IDataResponseAwaitingShipped } from '../../type';
interface IProps {
    filter:Filter
    setFilter:React.Dispatch<React.SetStateAction<Filter>>
    dataResponse:IDataResponseAwaitingShipped[]
}
const MobileAwaitingShipment = ({ filter,setFilter,dataResponse }:IProps) => {

    return (
        <div>
            <div className={styles.mobileContainerInput}>
                <InputSearch
                    placeholder={'Поиск по номеру рейса, водителю или номеру машины'}
                    onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                    value={filter.search}
                />
            </div>
            <div style={{ borderBottom:'solid 1px #D4D4D4',marginBottom:'4px' }}></div>
            <div>
                {dataResponse?.length ? (
                    dataResponse?.map(item => (
                        <MobileAwaitingShipmentCard
                            flight={item}
                        />
                        ))
                ) : (
                    <div>
                        <div className={'emptyContentContainer'}>
                            <div
                                style={{
                                    marginTop: '-60px',
                                    textAlign: 'center',
                                }}
                            >
                                <NewEmptySvg />
                            </div>
                            <div
                                style={{
                                    marginTop: '-40px',
                                }}
                            >
                                <h4 className={'emptyTitle'}>Документов не найдено</h4>
                                <p className={'emptyDescription'}>
                                    По выбранным фильтрам нет документов. <br />
                                    Попробуйте изменить параметры
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileAwaitingShipment;