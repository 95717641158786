import React from 'react';

const NewDriverRoleSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="steering">
                <path id="Vector" d="M21.6663 33.1987C24.6663 32.832 27.2497 31.582 29.4163 29.4154C31.583 27.2487 32.833 24.6654 33.1997 21.6654H28.1997C27.833 23.332 27.0663 24.732 25.8997 25.8987C24.733 27.0654 23.333 27.832 21.6663 28.1987V33.1987ZM16.6663 13.332H23.333L28.333 18.332H33.1997C32.783 15.082 31.3163 12.2987 28.783 9.9987C26.2663 7.76536 23.333 6.66536 19.9997 6.66536C16.6663 6.66536 13.733 7.76536 11.2163 9.9987C8.68301 12.2987 7.21634 15.082 6.79967 18.332H11.6663L16.6663 13.332ZM18.333 33.1987V28.1987C16.6663 27.832 15.2663 27.0654 14.0997 25.8987C12.933 24.732 12.1663 23.332 11.7997 21.6654H6.79967C7.16634 24.6154 8.41634 27.1654 10.583 29.332C12.7497 31.4987 15.333 32.782 18.333 33.1987ZM19.9997 3.33203C24.583 3.33203 28.4997 4.9987 31.7497 8.2487C34.9997 11.4987 36.6663 15.4154 36.6663 19.9987C36.6663 24.582 34.9997 28.4987 31.7497 31.7487C28.4997 34.9987 24.583 36.6654 19.9997 36.6654C15.4163 36.6654 11.4997 34.9987 8.24967 31.7487C4.99967 28.4987 3.33301 24.582 3.33301 19.9987C3.33301 15.4154 4.99967 11.4987 8.24967 8.2487C11.4997 4.9987 15.4163 3.33203 19.9997 3.33203Z" fill="currentColor"/>
            </g>
        </svg>

    );
};

export default NewDriverRoleSvg;