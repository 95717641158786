import React, { useEffect, useState } from 'react';
import styles from './UpdAndRegistryPage.module.scss';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { ArrowBack } from '../../components/UI/svg-icon/ArrowBack';
import DollySvg from '../../components/UI/svg-icon/DollySvg';
import FileSign from '../../components/UI/svg-icon/FileSign';
import HandCoinOutline from '../../components/UI/svg-icon/HandCoinOutline';
import MapMarkerMultipleOutline from '../../components/UI/svg-icon/mapMarkerMultipleOutline';
import TruckFastOutline from '../../components/UI/svg-icon/TruckFastOutline';
import { Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import UpdInfoContainer from './UpdInfoContainer/UpdInfoContainer';
import { IRegistryId } from './UpdInfoContainer/types';
import UpdMobileTableContainer from './UpdMobileTableContainer/UpdMobileTableContainer';
import { IOrder, IRegistryBuyTableDataResponse, IFlightsAddress } from './type';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { toast } from 'react-toastify';
import { formPrice } from '../../common/form-price.helper';
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";

const BuyerUpdAndRegistryPageId = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataResponse, setDataResponse] = useState<IRegistryId>();
  const [tableDataResponse, setTableDataResponse] = useState<[IRegistryBuyTableDataResponse]>();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [more, setMore] = useState(isMobile ? 5 : 10);
  const [deliveryType, setDeliveryType] = useState<string[]>([]);
  const [objectName, setObjectName] = useState<string[]>([]);
    const downloadedFile = async (id: number) => {
    try {
      const response = await fetchPost(`/reports/upd`, 'POST', {
        registryId: id,
      });
      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      const link = document.createElement('a');
      link.download = response.response?.name;

      link.href = response.response.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  const getData = async () => {
    setLoading(true);

    const pathArr = location.pathname.split('/');

    try {
      const response = await fetchGet(`/registries/${pathArr[pathArr.length-1]}`, {});
      setLoading(false);
      setDataResponse(response?.response);
      const correctionTableData = response?.response?.flights?.map(item => {
        return {
          registryData: {
            registryDate: response?.response?.registryDate,
            registryNumber: response?.response?.registryNumber,
          },
          numberTnAndCArNumber: {
            stateNumber: item?.car?.stateNumber,
            numberTn: item?.numberTn,
          },
          contentAndUnit: {
            content: item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
            unit: item?.nomenclature?.unit,
            unloadingVolume: item?.unloadingVolume,
          },
          address: item?.object?.name,
          FlightAmount: {
              price:item?.buyerSpecification?.nomenclatureSpecification?.price,
              unloadingVolume: item?.unloadingVolume,
          },
          id: item.id,
        };
      });
        setTableDataResponse(correctionTableData);

        const flightsAddress = response?.response?.flights.map(item => item?.object?.name.trim())
        const deliveryType = response?.response?.orders.map(item => item?.specification?.deliveryType.trim())
        
        // удаление дубликатов для адресов объектов
          deleteDuplicates(flightsAddress, setObjectName);
        // удаление дубликатов типа перевозок
          deleteDuplicates(deliveryType, setDeliveryType);
         
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getData();

  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteDuplicates = (items: string[], setter: (uniqueItems: string[]) => void) => {
    const uniqueItems = items.filter((item, index, array) => array.indexOf(item) === index);
    setter(uniqueItems);
};

    const columns = [
    {
      title: 'Дата',
      dataIndex: 'registryData',
      width: 110,
      key: 'registryData',
      render: text => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
            marginRight: '8px',
          }}
        >
          <p
            style={{
              height: '22px',
              textAlign: 'center',
              width: '113px',
              borderRadius: '30px',
              border: '1px solid #2563EB',
              color: '#2563EB',
            }}
          >
            {text?.registryDate.slice(0, 10).split('-').reverse().join('.')}
          </p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Пункт назначения',
      dataIndex: 'address',
      width: 382,
      key: 'address',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p style={{ width: '254px', height: '60px', display: 'flex', alignItems: 'center' }}> {text}</p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Номенклатура, количество',
      dataIndex: 'contentAndUnit',
      width: 382,
      key: 'contentAndUnit',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div>
            <div
              style={{
                width: '382px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '8px',
                fontSize: '12px',
                lineHeight: '18px',
              }}
            >
              <strong style={{ fontSize: '12px', width: '110px' }}>Номенклатура:</strong>
              <p style={{ paddingLeft: '13px' }}>{text.content}</p>
            </div>
            <div
              style={{
                width: '382px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                lineHeight: '18px',
              }}
            >
              <strong style={{ fontSize: '12px', width: '110px' }}>Объем, ед. изм:</strong>
              <p style={{ paddingLeft: '13px' }}>
                {text.unloadingVolume} {text.unit}
              </p>
            </div>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: '№ТТН, №ТС',
      dataIndex: 'numberTnAndCArNumber',
      width: 204,
      key: 'numberTnAndCArNumber',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div>
            <div
              style={{
                width: '184px',
                height: '22px',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '8px',
                fontSize: '12px',
                lineHeight: '18px',
              }}
            >
              <strong style={{ fontSize: '12px', lineHeight: '18px' }}>№ТТН</strong>
              <div style={{ paddingLeft: '8px' }}>
                <p style={{}}>{text?.numberTn}</p>
              </div>
            </div>
            <div style={{ width: '184px', height: '22px', display: 'flex', alignItems: 'center' }}>
              <strong style={{ fontSize: '12px', lineHeight: '18px' }}>№ТС:</strong>
              <p style={{ paddingLeft: '14px', fontSize: '12px', lineHeight: '18px' }}>{text?.stateNumber}</p>
            </div>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'FlightAmount',
      width: 128,
      key: 'FlightAmount',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <p>{formPrice((text?.price * text?.unloadingVolume), 'RUB')}</p>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="PageWrapper">
      <div className={isMobile ? styles.mobileMainBlockBackButton : styles.mainBlockBackButton}>
        <div className={styles.blockBackButton} onClick={() => navigate('/document')}>
          <div className={styles.arrowBack}>
            <ArrowBack />
          </div>
          <div>К списку документов</div>
        </div>
      </div>

      <div className={!isMobile && styles.mainTitleBlock}>
        <div className={isMobile ? styles.mobileMainTitleString : styles.mainTitleString}>
          РЕЕСТР {dataResponse?.registryNumber} ОТ {dataResponse?.registryDate?.slice(0, 10)?.replaceAll('-', '.')}
        </div>
        {!isMobile && (
          <div>
            {/* <Button
              left={false}
              right={false}
              sending={false}
              modifier={''}
              style={{
                marginTop: isMobile ? '8px' : '0',
                position: isMobile ? 'sticky' : 'static',
                top: isMobile ? '52px' : '20px',
                width: isMobile ? '100%' : '240px',
                zIndex: 10000,
              }}
              colorSvg={''}
              children={'Скачать УПД'}
              color={''}
              onClick={() => downloadedFile(Number(window.location.href.split('/').reverse()[0]))}
              svg={''}
              disabled={!dataResponse?.flights?.length}
            />*/}
          </div>
        )}
      </div>

      <div
        style={{ position: isMobile ? 'sticky' : 'static', top: '50px' }}
        className={isMobile && styles.headerPageContainer}
      >
        <div className={isMobile ? styles.mobileSubTitleString : styles.subTitleString}>
          {dataResponse?.contract?.title} от {dataResponse?.contract?.date.slice(0, 10)?.replaceAll('-', '.')}
        </div>
        {isMobile && (
          <div>
            {/* Закоментировано в связи с хот фиксом <Button
              left={false}
              right={false}
              sending={false}
              modifier={''}
              style={{
                marginTop: isMobile ? '8px' : '0',
                position: isMobile ? 'sticky' : 'static',
                top: isMobile ? '52px' : '20px',
                width: isMobile ? '100%' : '240px',
                zIndex: 10000,
                marginBottom: isMobile && '24px',
              }}
              colorSvg={''}
              children={'Скачать УПД'}
              color={''}
              onClick={() => downloadedFile(Number(window.location.href.split('/').reverse()[0]))}
              svg={''}
              disabled={!dataResponse?.flights?.length}
            />*/}
          </div>
        )}
      </div>
      {isMobile ? (
        <div className={styles.mobileTable}>
          {/*Мобильная версия*/}
          <UpdInfoContainer data={dataResponse} objectName={objectName} deliveryType={deliveryType} />
          <div>
            <UpdMobileTableContainer data={tableDataResponse?.slice(0, more)} />
          </div>
        </div>
      ) : (
        <div className={styles.sortInputsContainer}>
          {/*Компьютерная версия*/}
          <div className={styles.allBlocks}>
            {/*Все блоки*/}
            <div className={styles.firstAndSecondBlocks}>
              {/*первый и второй блок*/}
              <div className={styles.firstBlock}>
                {/*первый блок*/}
                <div className={styles.contentFirstBlock}>
                  <div className={styles.imageFirstBlock}>
                    <DollySvg />
                  </div>
                  <div className={styles.allStringsFirstBlock}>
                    <div className={styles.titleStringFirstBlock}>Номенкатура и количество материала</div>
                    {dataResponse?.volumeDetail.map((item, index) => (
                      <div key={index} className={styles.numAndUnitFirstBlock}>
                        <div>{item?.nomenclatureName}</div>
                        <div>
                          {item?.value} {item?.unitName}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.secondBlock}>
                {/*второй блок*/}
                <div className={styles.contentSecondBlock}>
                  <div className={styles.imageSecondBLock}>
                    <FileSign />
                  </div>
                  <div className={styles.allStringsSecondBlock}>
                    <div className={styles.titleStringSecondBlock}>Комментарий</div>
                    <div className={styles.commentSecondBlock}>
                      {dataResponse?.verifications[0]?.comment?.length < 115
                        ? dataResponse?.verifications[0]?.comment || 'Комментарий отсутствует'
                        : `${dataResponse?.verifications[0]?.comment?.slice(0, 114) || 'Комментарий отсутствует'}...`}
                    </div>
                    {dataResponse?.verifications[0]?.comment?.length < 115 ? (
                      <div></div>
                    ) : (
                      <div className={styles.buttonOfModalSecondBlock}>
                        <p style={{ cursor: 'pointer' }} onClick={showModal}>
                          Показать полностью
                        </p>
                      </div>
                    )}

                    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800} footer={null}>
                      <h3 style={{ paddingBottom: '8px' }}>Комментарий</h3>
                      <p style={{ fontSize: '16px', lineHeight: '24px' }}>{dataResponse?.verifications[0]?.comment}</p>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.thirdFourthFifthBlocks}>
              {/*третий, четвертый, пятый блок*/}
              <div className={styles.thirdBlock}>
                {/*третий блок*/}
                <div className={styles.contentThirdBlock}>
                  <div className={styles.imageThirdBLock}>
                    <HandCoinOutline />
                  </div>
                  <div style={{ fontSize: '18px' }}>
                    <div className={styles.titleStringThirdBlock}>Общая сумма</div>
                    <div>{formPrice(dataResponse?.documentAmount, 'RUB')}</div>
                  </div>
                </div>
              </div>
              <div className={styles.fourthBlock}>
                {/*четвертый блок*/}
                <div className={styles.contentFourthBlock}>
                  <div className={styles.imageFourthBLock}>
                    <MapMarkerMultipleOutline />
                  </div>
                  <div style={{ fontSize: '18px' }}>
                    <div className={styles.titleStringFourthBlock}>Адрес объектов</div>
                      {objectName?.map((item)=>(
                          <div className={styles.addressFourthBlock}>
                              {item}
                          </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={styles.fifthBlock}>
                {/*пятый блок*/}
                <div className={styles.contentFifthBlock}>
                  <div className={styles.imageFifthBlock}>
                    <TruckFastOutline />
                  </div>
                  <div style={{ fontSize: '18px' }}>
                    <div className={styles.titleStringFifthBlock}>Тип перевозки</div>
                      {deliveryType?.map((item)=>(
                          <div className={styles.typeFifthBlock}>
                              {item}
                          </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomTable loading={loading} link={false} columns={columns} data={tableDataResponse?.slice(0, more)} />
        </div>
      )}
      {tableDataResponse?.length > more && (
          <ButtonNotFilled
              margin={'40px 0 0 0'}
              onClick={() => setMore(isMobile ? more + 5 : more + 10)}
              text={'Показать ещё'}
              width={'100%'}
              color={'#ff5100'}
          />
      )}
    </div>
  );
};
export default BuyerUpdAndRegistryPageId;
