import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { HelpSvg } from '../svg-icon/HelpSvg';
import { HelpBalloon } from '../balloons/HelpBalloon';
import { ErrorSvg } from '../svg-icon/ErrorSvg';
import { useMediaQuery } from 'react-responsive';
import { HelpTooltip } from 'src/components/HelpTooltip';

export const VolumeInput = ({
  type,
  isHelp,
  textHelp,
  errorText,
  value,
  setValue,
  disabled,
  isLabel,
  placeholder,
  margin,
  error,
  maskFormatVolume,
}) => {
  const [helpVisible, setHelpVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const setCursor = e => {
    const input = e.target;
    input.focus();
    input.setSelectionRange(0, 0);
  };

  return (
    <div>
      <div className={`InputText__wrapper ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
        <div className={`InputText form-item ${error ? 'error' : ''}`} style={{ marginBottom: margin }}>
          <InputMask
            type={type}
            className={`InputText__input form-input ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
            maskChar={'0'}
            disabled={disabled}
            value={value}
            mask={maskFormatVolume}
            onClick={e => setCursor(e)}
            onChange={({ target: { value } }) => setValue(value)}
          />
          {isHelp && !disabled && (
            <div
              className={`InputText__help ${error && 'error'}`}
              onMouseEnter={() => setHelpVisible(true)}
              onMouseLeave={() => setHelpVisible(false)}
            >
              {error ? (
                <ErrorSvg />
              ) : (
                <HelpTooltip
                  color="#1C274C"
                  textHelp={textHelp}
                  isLeft={isMobile}
                  style={
                    isMobile
                      ? { width: '252px', bottom: '38px', left: '-16px' }
                      : { width: '252px', bottom: '38px', right: '-16px' }
                  }
                />
              )}
            </div>
          )}
          {isLabel && (
            <label className={`InputText__label form-label ${value ? 'valid' : ''} ${disabled ? 'disabled' : ''}`}>
              {placeholder}
            </label>
          )}
        </div>
      </div>
      <div>{error && <div className={'InputText__text'}>{errorText}</div>}</div>
    </div>
  );
};
