export const getOrganization = async (url, token) => {
  const responseInn = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  return await responseInn.json();
};

export const getBank = async (url, token) => {
  const responseBic = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    credentials: 'include',
  });

  return await responseBic.json();
};
