export const fetchAuth = async (url: string, options: any) => {
  try {
    if (!options.headers) {
      options.headers = {};
    }
    const apiKey = localStorage.getItem('apiKey');
    options.headers['authorization'] = `Bearer ${apiKey}`;
    const response = await fetch(url, options);

    return await response.json();
  } catch (e) {
    console.log(e);
  }
};
