import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SkeletonStatistics = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className={'skeleton-statistics'}>
            <div className={'skeleton-statistics__title'}></div>
            <div className={'skeleton-statistics__sub-title'}></div>
            <div className={'skeleton-statistics__inputs'}>
                <div className={'skeleton-statistics__input-item'}></div>
                <div className={'skeleton-statistics__input-item'}></div>
            </div>
            {!isMobile && <div className={'skeleton-statistics__requests'}>
                <div className={'skeleton-statistics__request-item'} style={{ width: '81px' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '72px' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '173px' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '91px' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '80px', marginLeft: 'auto' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '83px', marginLeft: 'auto' }}></div>
                <div className={'skeleton-statistics__request-item'} style={{ width: '53px', marginLeft: 'auto' }}></div>
            </div>}
            <div className={'skeleton-statistics__list'}>
                {[1,2,3,4,5,6,7,8].map((item, index) =>
                    <div key={index} className={'skeleton-statistics__item'}>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '119px' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '119px' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '48px' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '48px' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '172px' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '172px' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '204px' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '204px' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '48px', marginLeft: 'auto' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '48px', marginLeft: 'auto' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '48px', marginLeft: 'auto' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '48px', marginLeft: 'auto' }}></div>}
                        </div>
                        <div className={'skeleton-statistics__item-box'}>
                            <div className={'skeleton-statistics__item-elem'} style={{ width: '64px', marginLeft: 'auto' }}></div>
                            {isMobile && <div className={'skeleton-statistics__item-elem'} style={{ width: '64px', marginLeft: 'auto' }}></div>}
                        </div>
                    </div>
                )}
            </div>
            <div className={'skeleton-statistics__button'}></div>
        </div>
    );
};