import React from 'react';

const MapMarkerMultipleOutline = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1665 9C12.1665 7.62 13.2865 6.5 14.6665 6.5C15.7665 6.5 16.6965 7.21 17.0365 8.19C17.1165 8.45 17.1665 8.72 17.1665 9C17.1665 10.38 16.0465 11.5 14.6665 11.5C13.5765 11.5 12.6665 10.81 12.3065 9.84C12.2165 9.58 12.1665 9.29 12.1665 9ZM5.6665 9C5.6665 13.5 10.7465 19.66 11.6665 20.81L10.6665 22C10.6665 22 3.6665 14.25 3.6665 9C3.6665 5.83 5.7765 3.15 8.6665 2.29C6.8265 3.94 5.6665 6.33 5.6665 9ZM14.6665 2C18.5265 2 21.6665 5.13 21.6665 9C21.6665 14.25 14.6665 22 14.6665 22C14.6665 22 7.6665 14.25 7.6665 9C7.6665 5.13 10.8065 2 14.6665 2ZM14.6665 4C11.9065 4 9.6665 6.24 9.6665 9C9.6665 10 9.6665 12 14.6665 18.71C19.6665 12 19.6665 10 19.6665 9C19.6665 6.24 17.4265 4 14.6665 4Z" fill="#FF5100"/>
        </svg>
    );
};

export default MapMarkerMultipleOutline;