import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/context';
import styles from './FiltersButtons.module.scss';
import CustomDatePicker from '../../../../components/newUI/CustomDatePicker/CustomDatePicker';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { WarningEmptySvg } from '../../../../components/UI/svg-icon/WarningEmptySvg';
import { arrayFilterButtonsPaymentProcess, mainTextOfFilterButtons } from '../../constans';
import { IButton, IFilterButtons, ISection } from '../../type';
import dayjs, { Dayjs } from 'dayjs';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  getDataTable?: (resultBy: any, date: any) => Promise<void>;
  tab: string;
}

const FiltersProcessingOfPaymentProcess = ({ getDataTable, tab }: IProps) => {
  const [filterButtons, setFilterButtons] = useState<IFilterButtons>(arrayFilterButtonsPaymentProcess);
  const [dateRange, setDateRange] = useState<string | Dayjs>(dayjs(new Date()));
  const { user } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const getGeneralCalculations = async date => {
    const requestOption = {
      date: new Date(date.set('hour', 23).set('minute', 59).set('second', 59)),
    };
    try {
      const response = await fetchGet(`/reports/general-calculations/${user.companyType}`, requestOption);
      const newDataButtons = { ...filterButtons };
      Object.keys(newDataButtons).forEach(key => {
        if (key === user.companyType) {
          newDataButtons[key as keyof IFilterButtons].forEach(section => {
            section.buttons.forEach(button => {
              button.sum = response.response[button.value];
            });
          });
        }
      });
      await setFilterButtons(newDataButtons);
    } catch (e) {
      console.log(e);
    }
  };
  const updateFilterButtonsActiveState = (buttonTitle: string) => {
    const newData = { ...filterButtons };
    Object.keys(newData).forEach(key => {
      newData[key as keyof IFilterButtons].forEach(section => {
        section.buttons.forEach(button => {
          button.active = key === user.companyType && button.title === buttonTitle && !button.disable;
        });
      });
    });
    setFilterButtons(newData);
  };
  const handleFilter = filterValue => () => {
    getDataTable(filterValue, dateRange);
  };

  useEffect(() => {
    /*делаем активность всех фильтров = false, при изменении даты*/
    const resetFilterButtons = { ...filterButtons };
    Object.keys(resetFilterButtons).forEach(key => {
      resetFilterButtons[key as keyof IFilterButtons].forEach(section => {
        section.buttons.forEach(button => {
          button.active = false;
        });
      });
    });
    setFilterButtons(resetFilterButtons);
    handleFilter('')();
    if (tab === '1') {
      getGeneralCalculations(dateRange);
    }
  }, [dateRange, tab]);

  const nowDate = new Date();

  const minDate = new Date(nowDate.setMonth(nowDate.getMonth() - 1)).toISOString().split('T')[0];
  const maxDate = new Date().toISOString().split('T')[0];

  return (
    <div className={styles.container}>
      <div className={styles.filterDateContainer}>
        {!isMobile && <p className={styles.filterDateText}>{mainTextOfFilterButtons[user.companyType]}</p>}
        <CustomDatePicker
          closingConditionOfCalendar={tab}
          maxDate={maxDate}
          minDate={minDate}
          value={dateRange}
          defaultToday={true}
          setValue={setDateRange}
          height={'40px'}
          width={isMobile ? '100%' : '232px'}
        />
      </div>
      {/*Фильтры в зависимости от выбранного личного кабинета*/}
      <div className={styles.filterButtonsAndTitleContainer}>
        {filterButtons[user.companyType].map((container: ISection) => (
          <div style={{ width: isMobile ? '100%' : container.widthContainer }} className={styles.filterButtonsContent}>
            <h2 className={styles.filterButtonsTitleText}>{container?.mainTitle}</h2>
            <div className={styles.filterButtonsContainer}>
              {container.buttons.map((button: IButton) => (
                <div
                  onClick={() => {
                    if (!(button.disable || button.active)) {
                      handleFilter(button.value)();
                      updateFilterButtonsActiveState(button.title);
                    }
                  }}
                  style={{
                    background: button.disable && '#E8EBF0',
                    borderColor: button.active ? '#FF641D' : '#9BA1B4',
                    cursor: button.disable ? 'auto' : 'pointer',
                  }}
                  className={styles.filterButtonContainer}
                >
                  <div className={styles.filterButtonContent}>
                    <div className={styles.filterButtonSubtitleAndTooltip}>
                      <p className={styles.filterSubtitleText}>{button?.title}</p>
                      <div onClick={e => e.stopPropagation()}>
                        <CustomTooltip
                          SideTooltip={'up'}
                          MobileSideTooltip={'left'}
                          svg={<WarningEmptySvg />}
                          text={button.tooltipText}
                          widthTooltip={'250px'}
                        />
                      </div>
                    </div>
                    <p style={{ color: button.active ? '#FF641D' : '#373D50' }} className={styles.valueText}>
                      {formPrice(button.sum, 'RUB')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FiltersProcessingOfPaymentProcess;
