import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitlePage } from '../../components/TitlePage';
import { TitleName } from '../../components/TitleName';
import { InputText } from '../../components/UI/inputs/InputText';
import { Button } from '../../components/UI/buttons/Button';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { ErrorText } from '../../components/ErrorText';
import { CheckBox } from '../../components/UI/inputs/CheckBox';
import 'react-datepicker/dist/react-datepicker.css';
import { DateInput } from '../../components/UI/inputs/DateInput';
import { profileData } from '../../common/profile.type';
import { SkeletonProfile } from '../../components/skeletons/SkeletonProfile';
import { Context } from '../../context/context';
import { InputNumber } from '../../components/UI/inputs/InputNumber';
import { useMediaQuery } from 'react-responsive';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast, ToastContainer } from 'react-toastify';
import { logout } from '../../common/logout.mapping';
import { validationPassport } from '../DriverLicensePages/DriversLicensePage';
import styles from './Profile.module.scss';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';

export const ProfilePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [skeleton, setSkeleton] = useState(false);
  const { user, setUser } = useContext(Context);
  const [isActive, setIsActive] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [phoneEmpty, setPhoneEmpty] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState(false);
  const [update] = useState(false);
  const [error, setError] = useState(false);

  const [profile, setProfile] = useState(profileData);

  useEffect(() => {
    (async () => {
      setSkeleton(true);

      const {
        response: { id, name, patronymic, surname, phone, profile, passport, driverLicense },
        statusCode,
      } = await fetchGet(`/users`, {});

      if (statusCode === 410) {
        return logout(user, setUser);
      }

      setProfile({
        ...profile,
        id: id || '',
        name: name || '',
        patronymic: patronymic || '',
        surname: surname || '',
        phone: phone || '',
        passportSeries: passport?.passportSeries || '',
        passportNumber: passport?.passportNumber || '',
        passportIssued: passport?.passportIssued || '',
        passportDate: new Date(passport?.passportDate).toLocaleString() || '',
        registrationAddress: profile?.registrationAddress || '',
        citizenship: profile?.citizenship || '',
        driversLicenseSeries: driverLicense?.driversLicenseSeries || '',
        driversLicenseNumber: driverLicense?.driversLicenseNumber || '',
        driversLicenseDate: new Date(driverLicense?.driversLicenseDate).toLocaleString() || '',
        driversLicenseIssued: driverLicense?.driversLicenseIssued || '',
      });

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    })().catch(console.error);
  }, [update]);

  useEffect(() => {
    if (!profile?.phone) {
      setPhoneEmpty(true);
      setIsErrorPhone(true);
      return;
    }

    setIsErrorPhone(false);
    setPhoneEmpty(false);
  }, [profile?.phone]);

  const sendPersonalData = async (event: Event) => {
    setDisabled(true);
    setSending(true);

    event.preventDefault();

    try {
      const getProfile = await fetchPost(`/drivers/${profile.id}`, 'PATCH', {
        surname: profile.surname.trim() || null,
        name: profile.name.trim() || null,
        patronymic: profile.patronymic.trim() || null,
        phone: profile.phone || null,
        profile: {
          citizenship: profile.citizenship ? 'Россия' : null,
          registrationAddress: profile.registrationAddress || null,
        },
        passport: {
          passportSeries: profile.passportSeries || null,
          passportNumber: profile.passportNumber || null,
          passportDate: profile.passportDate || null,
          passportIssued: profile.passportIssued || null,
        },
        driverLicense: {
          driversLicenseSeries: profile.driversLicenseSeries || null,
          driversLicenseNumber: profile.driversLicenseNumber || null,
          driversLicenseDate: profile.driversLicenseDate || null,
          driversLicenseIssued: profile.driversLicenseIssued || null,
        },
        active: true,
      });

      if (getProfile.statusCode === 410) {
        return logout(user, setUser);
      }

      if (getProfile.error) {
        const errorMessages = getProfile.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setDisabled(false);
        setSending(false);
        return;
      }

      toast.success('Данные профиля успешно сохранены');
    } catch (e) {
      setDisabled(false);
      setSending(false);
      console.log(e);
    }

    setDisabled(false);
    setSending(false);
  };

  return (
    <PageWrapper>
      {skeleton ? (
        <SkeletonProfile />
      ) : (
        <div>
          <ToastContainer />
          <TitleAndSubTitleMain
            title={'Профиль'}
            subTitle={'В вашем профиле хранится важная информация, необходимая для идентификации и настройки вашего аккаунта'}
          />
          <div className={styles.ProfilePage}>
            <form>
              <div>
                <div className={styles.container}>
                  <TitleName field={true} children="Личные данные" />
                  <div className={styles.inputs}>
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile?.surname || ''}
                      error={true}
                      setValue={surname => setProfile({ ...profile, surname })}
                      margin="0"
                      fill={true}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder="Фамилия"
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile?.name}
                      setValue={name => setProfile({ ...profile, name })}
                      error={true}
                      margin=""
                      fill={true}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder="Имя"
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile.patronymic}
                      setValue={patronymic => setProfile({ ...profile, patronymic })}
                      margin=""
                      fill={true}
                      error={true}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder="Отчество"
                    />
                    <MyPhoneInput
                      disabled={false}
                      placeholder="Номер телефона"
                      setIsError={setIsErrorPhone}
                      isError={isErrorPhone}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      value={profile?.phone?.replace('+', '')}
                      onChange={phone => setProfile({ ...profile, phone })}
                      isFunction={null}
                      btnHelp={false}
                    />
                    <ErrorText
                      isError={isErrorPhone}
                      empty={phoneEmpty}
                      correctText={'Вы ввели некорректный номер телефона'}
                      emptyText={'Поле обязательное для заполнения'}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '24px' }}>
                <div className={styles.container}>
                  <TitleName field={true} children="Гражданство и паспорт" />
                  <div className={styles.inputs}>
                    <CheckBox profile={profile} setProfile={setProfile} children="Гражданство РФ" />
                    <InputText
                      type={''}
                      disabled={false}
                      placeholder={'Серия'}
                      error={false}
                      secondError={false}
                      textError={''}
                      fill={false}
                      helpText={''}
                      widthHelp={0}
                      margin={!isMobile ? '16px' : '0'}
                      value={profile.passportSeries}
                      setValue={value =>
                        validationPassport(
                          value,
                          setProfile,
                          {
                            ...profile,
                            passportSeries: value,
                          },
                          4,
                        )
                      }
                      isLabel={true}
                    />
                    <InputText
                      type={''}
                      disabled={false}
                      placeholder={'Номер'}
                      error={false}
                      secondError={false}
                      textError={''}
                      fill={false}
                      helpText={''}
                      widthHelp={0}
                      margin={!isMobile ? '16px' : '0'}
                      value={profile.passportNumber}
                      setValue={value =>
                        validationPassport(
                          value,
                          setProfile,
                          {
                            ...profile,
                            passportNumber: value,
                          },
                          6,
                        )
                      }
                      isLabel={true}
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile?.passportIssued}
                      setValue={passportIssued => setProfile({ ...profile, passportIssued })}
                      margin=""
                      fill={false}
                      error={false}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder="Кем выдан паспорт"
                    />
                    <DateInput
                      setDisabled={null}
                      disabled={false}
                      formatDate={'DD.MM.YYYY'}
                      maskFormatDate={'99.99.9999'}
                      isLabel={true}
                      value={profile?.passportDate}
                      setValue={passportDate => setProfile({ ...profile, passportDate })}
                      margin={''}
                      placeholder={'Дата выдачи паспорта'}
                      errorText={'Неверный формат даты'}
                      isHelp={false}
                      textHelp={''}
                      error={error}
                      setError={setError}
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile?.registrationAddress}
                      setValue={registrationAddress =>
                        setProfile({
                          ...profile,
                          registrationAddress,
                        })
                      }
                      margin=""
                      fill={false}
                      error={false}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder="Адрес постоянной или временной регистрации"
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '24px' }}>
                <div className={styles.container}>
                  <TitleName field={true} children="Водительское удостоверение" />
                  <div className={styles.inputs}>
                    <InputNumber
                      placeholder={'Серия'}
                      margin={''}
                      bgError={true}
                      fill={false}
                      isValue={profile?.driversLicenseSeries}
                      setIsValue={driversLicenseSeries =>
                        setProfile({
                          ...profile,
                          driversLicenseSeries,
                        })
                      }
                    />
                    <InputNumber
                      placeholder={'Номер'}
                      margin={''}
                      bgError={true}
                      fill={false}
                      isValue={profile?.driversLicenseNumber}
                      setIsValue={driversLicenseNumber =>
                        setProfile({
                          ...profile,
                          driversLicenseNumber,
                        })
                      }
                    />
                    <DateInput
                      setDisabled={null}
                      disabled={false}
                      formatDate={'DD-MM-YYYY'}
                      maskFormatDate={'99.99.9999'}
                      isLabel={true}
                      value={profile?.driversLicenseDate}
                      setValue={driversLicenseDate =>
                        setProfile({
                          ...profile,
                          driversLicenseDate,
                        })
                      }
                      margin={''}
                      placeholder={'Дата выдачи ВУ'}
                      errorText={'Неверный формат даты'}
                      isHelp={false}
                      textHelp={''}
                      error={error}
                      setError={setError}
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={profile?.driversLicenseIssued}
                      setValue={driversLicenseIssued =>
                        setProfile({
                          ...profile,
                          driversLicenseIssued,
                        })
                      }
                      margin={''}
                      fill={false}
                      error={false}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Кем выдано'}
                    />
                    <ButtonFilled
                      margin={'40px 0 0 0'}
                      onClick={sendPersonalData}
                      text={'Сохранить'}
                      disabled={disabled}
                      width={'100%'}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};
