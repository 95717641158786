import React from 'react';
import { closePopUp } from '../common/open-close-popup.function';

export const AsidePopup = ({ children, open, close }) => {
  return (
    <div className={`aside-popup ${open && 'active'}`}>
      <div onClick={() => close(closePopUp)} className={'aside-popup__white'}></div>
      <div className={'aside-popup__wrapper'}>
        {children}
      </div>
    </div>
  );
};