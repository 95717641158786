import React, { createContext } from 'react';
import { User } from '../common/user/user.type';
import { localStorageRead } from '../common/user/local-storage.helper';

interface Context {
  openDescription: boolean;
  setOpenDescription: React.Dispatch<boolean>;
  startTour: boolean;
  setStartTour: React.Dispatch<boolean>;
  helpTitle: string;
  setHelpTitle: React.Dispatch<string>;
  helpDescription: string;
  setHelpDescription: React.Dispatch<string>;
  globalState: string;
  setGlobalState: React.Dispatch<string>;
  setNotices: React.Dispatch<any>;
  user: User;
  notices: any;
  setUser: React.Dispatch<User>;
}

export const Context = createContext<Context>({
  user: localStorageRead(),
  setUser: value => value,
  globalState: '',
  notices: [],
  startTour: false,
  setNotices: value => value,
  setStartTour: value => value,
  setGlobalState: value => value,
  helpTitle: '',
  setHelpTitle: value => value,
  helpDescription: '',
  setHelpDescription: value => value,
  openDescription: false,
  setOpenDescription: value => value,
});
