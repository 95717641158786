import React from 'react';

export const SkeletonDriver = () => {
  return (
    <div className="SkeletonDriver">
      <div className="SkeletonDriver__back"></div>
      <div className="SkeletonDriver__name"></div>
      <div className="SkeletonDriver__content">
        <div className="SkeletonDriver__form">
          <div className="SkeletonDriver__titles">
            <div className="SkeletonDriver__title"></div>
          </div>
          <div className="SkeletonDriver__inputs">
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input SkeletonDriver__input_last"></div>
          </div>
        </div>
        <div className="SkeletonDriver__form">
          <div className="SkeletonDriver__titles">
            <div className="SkeletonDriver__title"></div>
          </div>
          <div className="SkeletonDriver__inputs">
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
          </div>
        </div>
        <div className="SkeletonDriver__form">
          <div className="SkeletonDriver__titles">
            <div className="SkeletonDriver__title"></div>
          </div>
          <div className="SkeletonDriver__inputs">
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__input"></div>
            <div className="SkeletonDriver__button"></div>
            <div className="SkeletonDriver__button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};