import React from 'react';
export const SearchSVGCarrier = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 11.5C3.25 6.66751 7.16751 2.75 12 2.75C16.8325 2.75 20.75 6.66751 20.75 11.5C20.75 16.3325 16.8325 20.25 12 20.25C7.16751 20.25 3.25 16.3325 3.25 11.5ZM12 1.25C6.33908 1.25 1.75 5.83908 1.75 11.5C1.75 17.1609 6.33908 21.75 12 21.75C14.5605 21.75 16.9017 20.8111 18.6982 19.2589L21.9697 22.5303C22.2626 22.8232 22.7374 22.8232 23.0303 22.5303C23.3232 22.2374 23.3232 21.7626 23.0303 21.4697L19.7589 18.1982C21.3111 16.4017 22.25 14.0605 22.25 11.5C22.25 5.83908 17.6609 1.25 12 1.25Z"
        fill="#1C274C"
      />
    </svg>
  );
};
