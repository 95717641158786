import React from 'react';
import { formPrice } from '../../common/form-price.helper';

export const columnsUpd = [
    {
        title: 'Даты документа',
        dataIndex: 'date',
        width: 230,
        key: 'date',
        render: text => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%',paddingTop:'10px',paddingBottom:'10px' }}>
                <div>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <p>Дата УПД:</p>
                        <p
                            style={{
                                marginLeft:'10px',
                                height: '22px',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '113px',
                                borderRadius: '30px',
                                border: '1px solid #2563EB',
                                color: '#2563EB',
                            }}>
                            {text?.registryDate?.slice(0, 10).split('-').reverse().join('.')}
                        </p>
                    </div>
                    <div style={{ display:'flex', alignItems:'center',justifyContent:'space-between', marginTop:'8px' }}>
                        <p>Дата договора:</p>
                        <p
                            style={{
                                marginLeft:'10px',
                                height: '22px',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '113px',
                                borderRadius: '30px',
                                border: '1px solid #2563EB',
                                color: '#2563EB',
                            }}>
                            {text?.contractDate?.slice(0, 10).split('-').reverse().join('.')}
                        </p>
                    </div>
                </div>
                <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
            </div>
        ),
    },
    {
        title: 'Номенклатура',
        dataIndex: 'nomenclatureContentAll',
        width: 264,
        key: 'nomenclatureContentAll',
        render: text => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div></div>
                <p>{text}</p>
                <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
            </div>
        ),
    },
    {
        title: 'Номер УПД',
        dataIndex: 'registryNumber',
        width: 264,
        key: 'registryNumber',
        render: text => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div>
                    <div style={{ display: 'flex' }}>
                        <p> {text}</p>
                    </div>
                </div>
                <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
            </div>
        ),
    },
    {
        title: 'Номер договора',
        dataIndex: 'contractTitle',
        width: 264,
        key: 'contractTitle',
        render: text => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div>
                    <div style={{ display: 'flex' }}>
                        <p> {text}</p>
                    </div>
                </div>
                <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
            </div>
        ),
    },
    {
        title: 'Сумма в руб. с НДС',
        dataIndex: 'documentAmount',
        width: 264,
        key: 'documentAmount',
        render: text => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div>
                    <div style={{ display: 'flex' }}>
                        <p> {formPrice(text,'RUB')} </p>
                    </div>
                </div>
            </div>
        ),
    },
];

export const FiltersInitialState = {
    dateRange:['',''],
    dateRangeSecond:['',''],
    filterSearch:'',
};