import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from '../../components/UI/inputs/InputText';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { SkeletonCar } from '../../components/skeletons/SkeletonCar';
import { TruckRemove } from '../../components/UI/svg-icon/TruckRemove';
import { DeletePopUp } from '../../components/DeletePopUp';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { toast, ToastContainer } from 'react-toastify';
import { carNumber } from '../../common/regular-car-number';
import styles from './CarPage.module.scss';
import { ISelect } from '../DispatchingPages/types';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { SUBSERVER } from '../../common/sub-server.constant';
import { InputNumber } from '../../components/UI/inputs/InputNumber';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import DeleteOutlineBlueSvg from '../../components/UI/svg-icon/DeleteOutlineBlueSvg';

export const CarPage = () => {
  const navigate = useNavigate();
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [imagesOwnership, setImagesOwnership] = useState<any>([]);
  const [imagesStsNumber, setImagesStsNumber] = useState<any>([]);
  const [uploadImagesOwnership, setUploadImagesOwnership] = useState<any>([]);
  const [uploadImagesStsNumber, setUploadImagesStsNumber] = useState<any>([]);
  const [popUpActive, setPopUpActive] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [numberTC, setNumberTC] = useState<string>('');
  const [stsNumber, setStsNumber] = useState<string>('');
  const [valueBrandTC, setValueBrandTC] = useState<ISelect>();
  const [valueOwnership, setValueOwnership] = useState<string>();
  const [valueTypeTC, setValueTypeTC] = useState<ISelect>();
  const [valueVolumeBody, setValueVolumeBody] = useState<string>('');
  const [image, setImage] = useState('');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);

  const ownershipList = [
    { value: 'ownership', label: 'Собственный' },
    { value: 'leasing', label: 'Лизинг' },
    { value: 'hired', label: 'Наемный' },
  ];

  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActiveImg(openPopUp);
  };
  const carId = localStorage.getItem('UUIDCar');

  const backAndClose = async () => {
    closePopUp();

    navigate('/cars');
  };

  const getValidSTSNumber = value => {
    const regex = /^([0-9]|[А-Я])*$/;
    if (regex.test(value) && value.length < 11) {
      setStsNumber(value);
    }
  };
  const getValidVolumeBody = value => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setValueVolumeBody(Number(value) <= 70 ? value : 70);
    }
  };
  const dataCar = async () => {
    setSkeleton(true);
    try {
      const {
        response: { stateNumber, carBrand, carType, legalOwner, stsSerialNumber, volumeBody },
      } = await fetchGet(`/cars/${carId}`, {});
      const responseFile = await fetch(`${SUBSERVER}/api/files/?fileBelongsId=${carId}&communicationCategory=cars`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      });
      const dataFile = await responseFile.json();
      dataFile?.response?.map(file => {
        if (file?.category === 'СТС') {
          setImagesStsNumber(prevSate =>
            [...prevSate, file].filter(
              (value, index, array) => array.findIndex(value2 => value2?.name === value?.name) === index,
            ),
          );
          setUploadImagesStsNumber([]);
        }
        if (file?.category === 'Договор лизинга') {
          setImagesOwnership([file]);
          setUploadImagesOwnership([]);
        }
      });
      setStsNumber(stsSerialNumber);
      setValueBrandTC(carBrand?.brand || '');
      setValueOwnership(ownershipList?.find(ownership => ownership?.value === legalOwner)?.label);
      setValueTypeTC(carType?.type || '');
      setValueVolumeBody(volumeBody?.toString());
      setNumberTC(stateNumber || '');
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setSkeleton(false);
    }, 800);
  };

  useEffect(() => {
    dataCar().catch(console.log);
  }, []);

  useEffect(() => {
    setDisabled(!valueVolumeBody || !stsNumber);
  }, [valueVolumeBody, stsNumber]);

  const sendDataCars = async deleteCar => {
    setSending(true);
    setDisabled(true);
    try {
      const patchCar = await fetchPost(`/cars/${carId}`, 'PATCH', {
        stsSerialNumber: stsNumber,
        volumeBody: Number(valueVolumeBody),
        active: deleteCar,
      });
      const OwnershipFiles = {
        communicationCategory: 'cars',
        category: 'Договор лизинга',
        fileBelongsId: Number(carId),
        objectUuid: '',
        files: [...uploadImagesOwnership?.map(file => file?.value)],
      };
      const StsNumberFiles = {
        communicationCategory: 'cars',
        category: 'СТС',
        fileBelongsId: Number(carId),
        objectUuid: '',
        files: [...uploadImagesStsNumber?.map(file => file?.value)],
      };
      const requestOption = [StsNumberFiles, OwnershipFiles]?.filter(item => item?.files?.length !== 0);
      if (patchCar?.response?.id) {
        await fetchPost(`/files/`, 'POST', requestOption);
      }

      if (patchCar?.error) {
        const errorMessages = patchCar?.message?.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }

      if (patchCar?.response?.id) {
        deleteCar && toast.success('Данные успешно обновлены');
        setSending(false);
        setDisabled(false);
        return;
      }
      setSending(false);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setSending(false);
      setDisabled(false);
    }
  };
  const deleteFile = async (id, name, communicationCategory) => {
    try {
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify({
          communicationCategory: communicationCategory,
          name: name,
          objectUuid: '',
        }),
      });
      const data = await response.json();
      if (data?.error) {
        const errorMessages = data?.message?.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const sendDeleteCars = async deleteCar => {
    setSending(true);
    setDisabled(true);
    try {
      const response = await fetchPost(`/cars/${carId}`, 'DELETE', {});
      if (response?.error) {
        const errorMessages = response?.message?.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      if (response[0]?.id) {
        response.forEach(({ flightName }) =>
          toast.error(`Завершите данный рейс (${flightName}) перед удалением машины`),
        );
        setDisabled(false);
        setSending(false);
        return;
      }
      response?.response?.id && toast.success('Данные транспортного средства успешно изменены');
      setSending(false);
      setDisabled(false);
    } catch (e) {
      setSending(false);
      setDisabled(false);
      console.log(e);
    }
  };
  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonCar />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <div className={styles.CarPage}>
            <div className={styles.carPageBtnBack}>
              <Link className="button-back" to="/cars">
                <div className="button-back__svg">
                  <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                Вернуться к списку машин
              </Link>
            </div>
            <div className={styles.carPageTitle}>Редактировать данные ТС</div>
            <div className={styles.carPageContent}>
              <div className={styles.inputs}>
                <div className={styles.inputsTitle}>Данные ТС</div>
                <div className={styles.inputsContent}>
                  <div className="DriverPagePopup__inputItem">
                    <InputText
                      type={''}
                      placeholder={'Номер ТС'}
                      textError={'Поле обязательное для заполнения'}
                      helpText={''}
                      widthHelp={0}
                      value={numberTC}
                      setValue={value => carNumber(value, setNumberTC)}
                      isLabel={true}
                      error={false}
                      secondError={false}
                      margin={''}
                      disabled={true}
                    />
                  </div>
                  <div className="DriverPagePopup__inputItem">
                    <InputText
                      type={''}
                      defaultValue={''}
                      error={false}
                      placeholder={'Марка ТС'}
                      value={valueBrandTC}
                      setValue={value => setValueBrandTC(value)}
                      disabled={true}
                      isLabel={true}
                      secondError={false}
                      fill={false}
                      textError={''}
                      margin={''}
                      helpText={''}
                      widthHelp={0}
                    />
                  </div>
                  <div className="DriverPagePopup__inputItem">
                    <InputText
                      type={''}
                      defaultValue={''}
                      error={false}
                      placeholder={'Вид ТС'}
                      value={valueTypeTC}
                      setValue={value => setValueTypeTC(value)}
                      disabled={true}
                      isLabel={true}
                      secondError={false}
                      fill={false}
                      textError={''}
                      margin={''}
                      helpText={''}
                      widthHelp={0}
                    />
                  </div>

                  <div className="DriverPagePopup__inputItem">
                    <InputText
                      type={''}
                      defaultValue={''}
                      placeholder={'Владение ТС'}
                      value={valueOwnership}
                      error={false}
                      setValue={value => setValueOwnership(value)}
                      disabled={true}
                      isLabel={true}
                      secondError={false}
                      fill={false}
                      textError={''}
                      margin={''}
                      helpText={''}
                      widthHelp={0}
                    />
                  </div>
                  {valueOwnership === 'Лизинг' && (
                    <div className="DriverPagePopup__inputItem">
                      <ProfilePageUploading
                        name={'Договор лизинга'}
                        desc={'Файл договора лизинга'}
                        setImages={setUploadImagesOwnership}
                        images={uploadImagesOwnership}
                        count={1 - imagesOwnership?.length}
                      />
                      {imagesOwnership?.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? '' : location}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={mimeType}
                            openPopUp={() =>
                              (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                            }
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => {
                                deleteFile(id, name, 'cars');
                                removeImage(target, imagesOwnership, setImagesOwnership);
                              }}
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                      {uploadImagesOwnership?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) =>
                                removeImage(target, uploadImagesOwnership, setUploadImagesOwnership)
                              }
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="DriverPagePopup__inputItem">
                    <InputText
                      bgError={true}
                      fill={false}
                      placeholder={'Объем кузова, м³'}
                      margin={''}
                      textError={''}
                      error={false}
                      value={valueVolumeBody}
                      type={'text'}
                      helpText={'Максимальное значение до 70 м³'}
                      widthHelp={'260px'}
                      setValue={value => getValidVolumeBody(value)}
                      isLabel={true}
                      disabled={false}
                    />
                  </div>

                  <div className="DriverPagePopup__inputItem">
                    <InputNumber
                      maxLength={10}
                      bgError={true}
                      fill={false}
                      placeholder={'Серия и номер CТС '}
                      margin={''}
                      textError={''}
                      error={false}
                      isValue={stsNumber}
                      setIsValue={value => getValidSTSNumber(value.toUpperCase())}
                    />
                  </div>
                  <div className="DriverPagePopup__inputItem">
                    <ProfilePageUploading
                      name={'СТС транспортного средства'}
                      desc={'Лицевая и оборотная сторона'}
                      setImages={setUploadImagesStsNumber}
                      images={uploadImagesStsNumber}
                      count={2 - imagesStsNumber?.length}
                    />
                    {imagesStsNumber.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? '' : location}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={mimeType}
                          openPopUp={() =>
                            (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                          }
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) => {
                              deleteFile(id, name, 'cars');
                              removeImage(target, imagesStsNumber, setImagesStsNumber);
                            }}
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                    {uploadImagesStsNumber?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                      <div key={id}>
                        <ImageContainer
                          id={id}
                          value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={type}
                          openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) =>
                              removeImage(target, uploadImagesStsNumber, setUploadImagesStsNumber)
                            }
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputs}>
              <div className={styles.inputsTitle}></div>
              <div className={styles.ButtonContent}>
                <ButtonFilled onClick={() => sendDataCars(true)} text={'Сохранить'} disabled={disabled} width={'100%'}/>
                <ButtonNotFilled onClick={() => setPopUpActive(openPopUp)} width={'100%'} text={'Удалить ТС'} svg={<DeleteOutlineBlueSvg/>}/>
              </div>
            </div>
            <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={'Транспортная накладная'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeletePopUp
            title={['Подтвердите удаление ТС', `Машина ${numberTC} успешно удалена`]}
            svg={<TruckRemove />}
            popUpActive={popUpActive}
            setPopUpActive={setPopUpActive}
            name={valueBrandTC?.label}
            numberTC={numberTC}
            sendData={sendDeleteCars}
            closeWindow={backAndClose}
            deleted={deleted}
          />
        </PageWrapper>
      )}
    </>
  );
};
