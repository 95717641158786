// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmployeesPage_Container__rmPvu {\n  padding: 8px;\n  border-radius: 12px;\n  background: #EFF6FF;\n}\n\n.EmployeesPage_titleAndSubTitleContainer__Kjsql {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n.EmployeesPage_titleAndAddButton__EBsKs {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n@media (max-width: 768px) {\n  .EmployeesPage_titleAndSubTitleContainer__Kjsql {\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/EmployeesPage/EmployeesPage.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGF;;AADA;EACE;IACE,QAAA;IACA,mBAAA;EAIF;AACF","sourcesContent":[".Container{\n  padding: 8px;\n  border-radius: 12px;\n  background: #EFF6FF;\n}\n.titleAndSubTitleContainer{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n.titleAndAddButton{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n@media (max-width: 768px) {\n  .titleAndSubTitleContainer{\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "EmployeesPage_Container__rmPvu",
	"titleAndSubTitleContainer": "EmployeesPage_titleAndSubTitleContainer__Kjsql",
	"titleAndAddButton": "EmployeesPage_titleAndAddButton__EBsKs"
};
export default ___CSS_LOADER_EXPORT___;
