import React, { FC } from 'react';
import styles from './UpdMobileTableContainer.module.scss';
import { IRegistryBuyTableDataResponse } from '../type';
import { formPrice } from '../../../common/form-price.helper';
interface IProps{
    data:IRegistryBuyTableDataResponse[],
}
const UpdMobileTableContainer : FC<IProps> = ({ data })=>{
    return (
        <div>
            {data?.map((item) => (
                <div className={styles.container}>
                    <div className={styles.textContainer} style={{ borderBottom: '1px solid #D4D4D4' }}>
                        <p className={styles.dateValue}> {item?.registryData?.registryDate.slice(0, 10).split('-').reverse().join('.')}</p>
                        <p className={styles.textValue}>{item?.contentAndUnit?.content}</p>
                    </div>
                    <div style={{ borderBottom: '1px solid #D4D4D4' }}>
                        <div className={styles.textContainer}>
                            <p className={styles.textTitle}>Пункт назначения:</p>
                            <p className={styles.textValue}>{item?.address}</p>
                        </div>
                        <div className={styles.textContainer}>
                            <p className={styles.textTitle}>Количество:</p>
                            <p className={styles.textValue}>{item?.contentAndUnit?.unloadingVolume} {item?.contentAndUnit?.unit}</p>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #D4D4D4' }}>
                        <div className={styles.textContainer}>
                            <p className={styles.textTitle}>№ТТН:</p>
                            <p className={styles.textValue}>{item?.numberTnAndCArNumber?.numberTn}</p>
                        </div>
                        <div className={styles.textContainer}>
                            <p className={styles.textTitle}>№ТС:</p>
                            <p className={styles.textValue}>{item?.numberTnAndCArNumber?.stateNumber}</p>
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <p className={styles.textTitle}>Сумма:</p>
                        <p className={styles.textValue}>{formPrice((item?.FlightAmount?.price * item?.FlightAmount?.unloadingVolume),'RUB')}</p>
                    </div>
                </div>
            ))}

        </div>

    );
};

export default UpdMobileTableContainer;