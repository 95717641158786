// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DocumentRegistriesEditPage_mobileButtons__6ydhV {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  align-items: end;\n  margin: 40px 0 32px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/DocumentsPages/Components/Registry/Components/EditRegistryDocument/DocumentRegistriesEditPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".mobileButtons {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  align-items: end;\n  margin: 40px 0 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileButtons": "DocumentRegistriesEditPage_mobileButtons__6ydhV"
};
export default ___CSS_LOADER_EXPORT___;
