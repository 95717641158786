import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from './UI/buttons/Button';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { closePopUp } from '../common/open-close-popup.function';

export const DeletePopUp = ({
  title,
  deleted,
  svg,
  popUpActive,
  setPopUpActive,
  name,
  numberTC,
  sendData,
  closeWindow,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={popUpActive ? 'deletePopUp active' : 'deletePopUp'}>
      <div className="deletePopUp__white"></div>
      {isMobile && <div className="deletePopUp__line"></div>}
      <div className="deletePopUp__wrapper">
        <div className="deletePopUp__svg">{svg}</div>
        <div className="deletePopUp__title">{deleted ? title[1] : title[0]}</div>
        {!deleted && (
          <div className="deletePopUp__subTitle">
            Вы действительно хотите удалить &nbsp;<span>{name}</span>, номер&nbsp;
            <span>{numberTC}</span>?
          </div>
        )}
        <div className={`deletePopUp__buttons ${deleted && 'deletePopUp__buttons_delete'}`}>
          {deleted ? (
            <Button
              left={false}
              right={false}
              onClick={closeWindow}
              modifier={''}
              children={'Хорошо'}
              svg={null}
              colorSvg={''}
              disabled={false}
              sending={false}
              color={'green'}
            />
          ) : (
            <>
              <ButtonTransparent
                modifier={''}
                color={'gray'}
                disabled={false}
                svg={''}
                isSvg={false}
                onClick={() => setPopUpActive(closePopUp)}
                children={'Отменить'}
              />
              <Button
                left={false}
                right={false}
                onClick={async () => {
                  await sendData(false);
                  closeWindow();
                }}
                modifier={''}
                children={'Удалить'}
                svg={null}
                colorSvg={''}
                disabled={false}
                sending={false}
                color={'red'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
