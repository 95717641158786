import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { InputText } from '../../../../components/UI/inputs/InputText';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import { IEmployees } from '../../type';
import styles from './FormEmployees.module.scss';
import { MyPhoneInput } from '../../../../components/UI/inputs/MyPhoneInput';
import { ErrorText } from '../../../../components/ErrorText';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useMediaQuery } from 'react-responsive';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import QuestionOutlineSvg from '../../../../components/UI/svg-icon/QuestionOutlineSvg';
import { initStateEmployee, textTooltipByTypeOfUser } from '../../Constans';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { closePopUp, openPopUp } from '../../../../common/use-popup.functiopn';
import { Context } from '../../../../context/context';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import {validateEmail} from "../../../../common/validation-email.function";
interface IProps {
  employee: IEmployees;
  sendFunction?: any;
  refusalFunction?: (id: number) => void;
  title: string;
  openFormModal: boolean;
  setEmployee: React.Dispatch<React.SetStateAction<IEmployees>>;
  setOpenFormAddition: React.Dispatch<React.SetStateAction<boolean>>;
}
const FormEmployees = ({
  refusalFunction,
  openFormModal,
  setOpenFormAddition,
  setEmployee,
  employee,
  sendFunction,
  title,
}: IProps) => {
  const [isActive, setIsActive] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(true);

  const { user } = useContext(Context);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const validateSurname = !!employee?.surname?.replace(/[а-яА-Яё]/gi, '');
  const validateName = !!employee?.name?.replace(/[а-яА-Яё]/gi, '');
  const validatePatronymic = !!employee?.patronymic?.replace(/[а-яА-Яё]/gi, '');
  const noValueInputs = employee?.type?.value && employee?.name && employee?.surname;
  const wrongValueInFieldsInputs = !validateName && !validateSurname && !validatePatronymic && validateEmail(employee?.profile?.email);
  const employeeTypes = [
    { value: 'accountant', label: 'Бухгалтер' },
    { value: 'manager', label: 'Менеджер' },
    { value: 'admin', label: 'Администратор' },
  ];
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isEdit = title === 'Редактирование сотрудника';

  useEffect(() => {
    setDisabledSaveBtn(false);
  }, [employee]);

  useEffect(() => {
    const rooDiv = document.getElementById('root');
    document.body.classList.add('off');
    if (openFormModal) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      document.body.classList.remove('off');
      rooDiv.style.overflow = 'unset';
    }
  }, [openFormModal]);

  useEffect(() => {
    if (employee?.phone) {
      setIsErrorPhone(false);
    }
    if (!isEdit) {
      setEmployee({ ...initStateEmployee });
    }
    setDisabledSaveBtn(true);
  }, [openFormModal]);

  const closeModal = () => {
    closePopUp(setOpenConfirmModal);
    closePopUp(setOpenFormAddition);
  };

  const disableAddButton = () => {
    if (wrongValueInFieldsInputs && noValueInputs && !isErrorPhone && !disabledSaveBtn) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      {isMobile ? (
        <div className={openFormModal ? 'DriverPagePopup active' : 'DriverPagePopup'}>
          <div onClick={() => closeModal()} className="DriverPagePopup__white"></div>
          <div className="DriverPagePopup__line"></div>
          <div className="DriverPagePopup__wrapper" style={{ backgroundColor: '#EFF6FF', height: '100%' }}>
            <div className={styles.Container}>
              <div className={styles.mobileCloseIconDrawer} onClick={closeModal}>
                <h3 className={styles?.titleModal}>{title}</h3>
                <div className={styles.mobileCloseIconBlockDrawer}>
                  <CloseSvg />
                </div>
              </div>
              <div className={styles.formContainer}>
                <div className={styles.inputsContainer}>
                  <p className={styles.textTitleFormStep}>1. Личные данные сотрудника</p>
                  <div>
                    <MyPhoneInput
                      disabled={false}
                      setIsError={setIsErrorPhone}
                      isError={isErrorPhone}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      value={employee.phone}
                      onChange={phone => setEmployee({ ...employee, phone: phone })}
                      isFunction={null}
                      btnHelp={true}
                    />
                    <ErrorText
                      isError={isErrorPhone}
                      empty={isErrorPhone}
                      emptyText={'Поле обязательное для заполнения'}
                      correctText="Вы ввели некорректный номер телефона"
                    />
                  </div>
                  <div>
                    <InputText
                        clearButton={true}
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'E-mail'}
                        textError={'Поле обязательное для заполнения'}
                        value={employee?.profile?.email}
                        setValue={value => setEmployee({ ...employee, profile: {email:value} })}
                        isLabel={true}
                        error={true}
                        margin={''}
                        disabled={false}
                        showError={!validateEmail(employee?.profile?.email)}
                    />
                  </div>
                  <div>
                    <InputText
                      clearButton={true}
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'Фамилия'}
                      textError={
                        validateSurname ? 'Допускаются только русские буквы' : 'Поле обязательное для заполнения'
                      }
                      value={employee.surname}
                      setValue={value => setEmployee({ ...employee, surname: value })}
                      isLabel={true}
                      error={true}
                      margin={''}
                      disabled={false}
                      showError={validateSurname}
                    />
                  </div>
                  <InputText
                    clearButton={true}
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Имя'}
                    textError={validateName ? 'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                    value={employee.name}
                    setValue={value => setEmployee({ ...employee, name: value })}
                    isLabel={true}
                    error={true}
                    margin={''}
                    disabled={false}
                    showError={validateName}
                  />
                  <InputText
                    clearButton={true}
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Отчество (при наличии)'}
                    textError={validatePatronymic ? 'Допускаются только русские буквы' : ''}
                    value={employee.patronymic}
                    setValue={value => setEmployee({ ...employee, patronymic: value })}
                    isLabel={true}
                    error={false}
                    margin={''}
                    disabled={false}
                    showError={validatePatronymic}
                  />
                </div>
                <div className={styles.selectContainer}>
                  <div className={styles.titleAndTooltip}>
                    <p className={styles.textTitleFormStep}>2. Должность сотрудника</p>
                    <CustomTooltip
                      MobileSideTooltip={'left'}
                      svg={<QuestionOutlineSvg />}
                      text={textTooltipByTypeOfUser[user?.type]}
                      widthSvg={'24px'}
                      widthTooltip={'241px'}
                    />
                  </div>
                  <CustomSelect
                    value={employee?.type?.value ? employee?.type : null}
                    options={employeeTypes}
                    setValue={value => setEmployee({ ...employee, type: value })}
                    placeholder={'Выберите должность сотрудника'}
                    isClearable={false}
                    error={!employee?.type?.value}
                    isSearchable={false}
                    noOptionsMessage={''}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <ButtonNotFilled
                text={isEdit ? 'Удалить сотрудника' : 'Отмена'}
                onClick={() => {
                  isEdit ? openPopUp(setOpenConfirmModal) : closePopUp(setOpenFormAddition);
                }}
                width={isMobile ? '100%' : '480px'}
              />
              <ButtonFilled
                disabled={disableAddButton()}
                onClick={() => {
                  isEdit ? sendFunction(employee?.id) : sendFunction();
                }}
                text={isEdit ? 'Сохранить изменения' : 'Сохранить'}
                width={isMobile ? '100%' : '480px'}
              />
            </div>
          </div>
        </div>
      ) : (
        <Modal zIndex={9500} width={1054} footer={null} open={openFormModal} onCancel={closeModal} closeIcon={false}>
          <div className={styles.Container}>
            <h1>{title}</h1>
            <div className={styles.formContainer}>
              <div className={styles.inputsContainer}>
                <p className={styles.textTitleFormStep}>1. Личные данные сотрудника</p>
                <div>
                  <MyPhoneInput
                    disabled={false}
                    setIsError={setIsErrorPhone}
                    isError={isErrorPhone}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    value={employee.phone}
                    onChange={phone => setEmployee({ ...employee, phone: phone })}
                    isFunction={null}
                    btnHelp={true}
                  />
                  <ErrorText
                    isError={isErrorPhone}
                    empty={isErrorPhone}
                    emptyText={'Поле обязательное для заполнения'}
                    correctText="Вы ввели некорректный номер телефона"
                  />
                </div>
                <div>
                  <InputText
                      clearButton={true}
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'E-mail'}
                      textError={'Поле обязательное для заполнения'}
                      value={employee?.profile?.email}
                      setValue={value => setEmployee({ ...employee, profile: {email:value} })}
                      isLabel={true}
                      error={true}
                      margin={''}
                      disabled={false}
                      showError={!validateEmail(employee?.profile?.email)}
                  />
                </div>
                <div>
                  <InputText
                    clearButton={true}
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Фамилия'}
                    textError={
                      validateSurname ? 'Допускаются только русские буквы' : 'Поле обязательное для заполнения'
                    }
                    value={employee?.surname}
                    setValue={value => setEmployee({ ...employee, surname: value })}
                    isLabel={true}
                    error={true}
                    margin={''}
                    disabled={false}
                    showError={validateSurname}
                  />
                </div>
                <InputText
                  clearButton={true}
                  type={''}
                  secondError={false}
                  helpText={''}
                  widthHelp={0}
                  placeholder={'Имя'}
                  textError={validateName ? 'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                  value={employee.name}
                  setValue={value => setEmployee({ ...employee, name: value })}
                  isLabel={true}
                  error={true}
                  margin={''}
                  disabled={false}
                  showError={validateName}
                />
                <InputText
                  clearButton={true}
                  type={''}
                  secondError={false}
                  helpText={''}
                  widthHelp={0}
                  placeholder={'Отчество (при наличии)'}
                  textError={validatePatronymic ? 'Допускаются только русские буквы' : ''}
                  value={employee.patronymic}
                  setValue={value => setEmployee({ ...employee, patronymic: value })}
                  isLabel={true}
                  error={false}
                  margin={''}
                  disabled={false}
                  showError={validatePatronymic}
                />
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.titleAndTooltip}>
                  <p className={styles.textTitleFormStep}>2. Должность сотрудника</p>
                  <CustomTooltip
                    SideTooltip={'left'}
                    svg={<QuestionOutlineSvg />}
                    text={textTooltipByTypeOfUser[user?.type]}
                    widthSvg={'24px'}
                    widthTooltip={'501px'}
                  />
                </div>
                <CustomSelect
                  value={employee?.type?.value ? employee?.type : null}
                  options={employeeTypes}
                  setValue={value => setEmployee({ ...employee, type: value })}
                  placeholder={'Выберите должность сотрудника'}
                  isClearable={false}
                  error={!employee?.type?.value}
                  isSearchable={false}
                  noOptionsMessage={''}
                />
              </div>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <ButtonNotFilled
              text={isEdit ? 'Удалить сотрудника' : 'Отмена'}
              onClick={() => {
                isEdit ? openPopUp(setOpenConfirmModal) : closePopUp(setOpenFormAddition);
              }}
              width={isMobile ? '100%' : '480px'}
            />
            <ButtonFilled
              disabled={disableAddButton()}
              onClick={() => {
                isEdit ? sendFunction(employee?.id) : sendFunction();
              }}
              text={isEdit ? 'Сохранить изменения' : 'Сохранить'}
              width={isMobile ? '100%' : '480px'}
            />
          </div>
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={closeModal} />
          </div>
        </Modal>
      )}
      <ConfirmModal
        closeModal={closeModal}
        openModal={openConfirmModal}
        employee={employee}
        setOpenModal={setOpenConfirmModal}
        sendFunction={refusalFunction}
      />
    </div>
  );
};
export default FormEmployees;
