import React from 'react';

export const ButtonTransparent = ({ children, disabled, isSvg, svg, color, modifier, ...props }) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={
        disabled ? `ButtonTransparent disabled ${modifier} ${color}` : `ButtonTransparent  ${color} ${modifier}`
      }
    >
      {isSvg && <div className={`ButtonTransparent__svg ${props.svgModifier ? props.svgModifier : ''}`}>{svg}</div>}
      {children}
    </button>
  );
};
