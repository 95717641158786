// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentProgress_container__APdV\\+ {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.PaymentProgress_tableContainer__IoZZ0 {\n  border-radius: 12px;\n  background: #EFF6FF;\n  padding: 8px;\n}\n\n@media (max-width: 768px) {\n  .PaymentProgress_container__APdV\\+ {\n    gap: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/PaymentProcessPage/Components/PaymentProgress/PaymentProgress.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,mBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AAAA;EACE;IACE,SAAA;EAGF;AACF","sourcesContent":[".container{\n  display: flex;\n  flex-direction: column;\n  gap:40px;\n}\n.tableContainer{\n  border-radius: 12px;\n  background:#EFF6FF;\n  padding: 8px;\n}\n@media (max-width: 768px){\n  .container{\n    gap:24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PaymentProgress_container__APdV+",
	"tableContainer": "PaymentProgress_tableContainer__IoZZ0"
};
export default ___CSS_LOADER_EXPORT___;
