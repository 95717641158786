import React, { useEffect, useState } from 'react';
import CustomTable from '../../../components/newUI/CustomTable/CustomTable';
import styles from './Shipped.module.scss';
import { useMediaQuery } from 'react-responsive';
import { InputSearch } from '../../../components/UI/inputs/InputSearch';
import CustomRangePicker from '../../../components/newUI/CustomRangePicker/CustomRangePicker';
import { Filter } from '../../../common/filter.type';
import { optionVolume } from '../../../common/select-format.type';
import { SelectCustom } from '../../../components/UI/select/SelectCustom';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import MobileShipped from './MobileShipped/MobileShipped';
import { clearObject } from '../../../common/ClearObject.helper';
import { IDataResponseShipped } from '../type';
import { convertActualVisionUnit } from '../../../common/convertActualVisionUnit.helper';
import { Dayjs } from 'dayjs';

interface IProps {
  callBackDataCount: (count: string) => void;
  callBackDataShipment: (flights: IDataResponseShipped[]) => void;
  callBackDataFilter: (IFilter) => void;
  tab: string;
  updatePage?: boolean;
}

const Shipped = ({ callBackDataCount, callBackDataShipment, tab, callBackDataFilter, updatePage }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [dataResponse, setDataResponse] = useState<IDataResponseShipped[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<string[] | Dayjs>(['', '']);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({ search: '', sort: { field: 'сначала новые', order: 'DESC' } });
  const correctOffsetGetData = async () => {
    setOffset(0);
    if (tab === '2') {
      getData(false);
    }
  };
  useEffect(() => {
    setOffset(0);
    getData(false);
  }, [updatePage]);
  useEffect(() => {
    correctOffsetGetData();
  }, [tab, filter, dateRange]);

  useEffect(() => {
    setOpenCalendar(false);
  }, [tab]);
  const getData = async (isPagination: boolean) => {
    setLoading(true);
    try {
      const requsetOption = {
        searchString: filter.search ? filter.search : undefined,
        status: 'DONE',
        limit: 10,
        offset: isPagination ? offset : 0,
        fromDate: dateRange[0] ? new Date(dateRange[0]) : undefined,
        toDate: dateRange[1] ? new Date(dateRange[1]) : undefined,
        sortByDate: filter.sort.order ? filter.sort.order : undefined,
      };
      const clearFilter = clearObject({
        searchString: filter.search ? filter.search : undefined,
        fromDate: dateRange[0] ? new Date(dateRange[0]) : undefined,
        toDate: dateRange[1] ? new Date(dateRange[1]) : undefined,
      });
      callBackDataFilter(clearFilter);
      clearObject(requsetOption);
      const response = await fetchGet('/shipments', requsetOption);
      const responseTableData = response?.response?.rows?.map(item => {
        return {
          flight: {
            id: item?.id,
            dateUnloading: item?.dateDownload,
            flightName: item?.flightName,
          },
          loading: {
            organization: item?.supplier?.title,
            title: item?.quarry?.address?.address,
          },
          vehicle: {
            car: item?.car?.carBrand?.brand,
            number: item?.car?.stateNumber,
            driver: item?.driver?.fullName,
          },
          volumeAndLoad: {
            unit: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit,
            volume: item?.volumePlan,
            nomenclature: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
          },
          actualLoading: {
            unitDownload: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit,
            nomenclature: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
            supplierFilledVolume: item?.supplierFilledVolume,
          },
        };
      });
      setTotalCount(response?.response?.count);
      callBackDataCount(response?.response?.count);

      if (isPagination && offset !== 0) {
        setDataResponse(prevState => [...prevState, ...responseTableData]);
        callBackDataShipment([...dataResponse, ...responseTableData]);
      } else {
        setDataResponse(responseTableData);
        callBackDataShipment(responseTableData);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (tab === '2' && totalCount > dataResponse?.length) {
      getData(true);
    }
  }, [offset]);

  const columns = [
    {
      title: 'Рейс',
      dataIndex: 'flight',
      width: 136,
      key: 'flight',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '8px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
            <div className={styles.textBorderBlue}>
              <p className={styles.textBlue}>{text?.dateUnloading?.slice(0, 10).split('-').reverse().join('.')}</p>
            </div>
            <div className={styles.textBorder}>
              <p className={styles.text}>{text.flightName}</p>
            </div>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Загрузка',
      dataIndex: 'loading',
      width: 287.5,
      key: 'loading',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>{text.organization}</p>
            <p className={styles.bottomText}>{text.title}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'vehicle',
      width: 287.5,
      key: 'vehicle',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>
              {text.car} {text.number}
            </p>
            <p className={styles.bottomText}>{text.driver}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Объем и груз',
      dataIndex: 'volumeAndLoad',
      width: 287.5,
      key: 'volumeAndLoad',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>
              {text.volume} {convertActualVisionUnit(text.unit)}
            </p>
            <p className={styles.bottomText}>{text.nomenclature}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Фактическая погрузка с ед. измерения',
      dataIndex: 'actualLoading',
      width: 287.5,
      key: 'actualLoading',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>
              {text.supplierFilledVolume} {convertActualVisionUnit(text.unitDownload)}
            </p>
            <p className={styles.bottomText}>{text.nomenclature}</p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={!isMobile && styles.Container}>
      {isMobile ? (
        /*Мобильная версия*/
        <MobileShipped
          filter={filter}
          setFilter={setFilter}
          dataResponse={dataResponse}
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      ) : (
        /*Компьютерная версия*/
        <div>
          <div className={styles.containerInput}>
            <div style={{ width: '100%' }}>
              <InputSearch
                placeholder={'Поиск по номеру рейса, водителю или номеру машины'}
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter.search}
              />
            </div>
            <div>
              <CustomRangePicker
                isPresets={true}
                dateRange={dateRange}
                setDateRange={setDateRange}
                open={openCalendar}
                setOpen={setOpenCalendar}
                placeholder={['Начало периода', 'Конец периода']}
                width={'360px'}
              />
            </div>
            <div>
              <SelectCustom
                options={optionVolume}
                maxTagCount={1}
                maxCount={1}
                placeholder={'По номенклатуре'}
                mode={null}
                value={filter.sort.field}
                setValue={value => {
                  setFilter(prevState => ({
                    ...prevState,
                    sort: {
                      field: value?.value,
                      order: value?.value === 'сначала старые' ? 'ASC' : 'DESC',
                    },
                  }));
                }}
                disabled={false}
                status={''}
                errorText={''}
              />
            </div>
          </div>
          <div>
            <CustomTable loading={loading} link={false} columns={columns} data={dataResponse} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Shipped;
