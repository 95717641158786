import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import styles from './NewAuthSms.module.scss';
import AuthLineSvg from '../../components/UI/svg-icon/AuthLineSvg';
import { InputVerification } from '../../components/UI/inputs/InputVerification';
import { AUTHSUBSERVER } from '../../common/sub-server.constant';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { toast } from 'react-toastify';
import FooterAuth from './footerAuth/FooterAuth';
import LogoIconSvg from '../../components/UI/svg-icon/LogoIconSvg';
import LogoTextSvg from '../../components/UI/svg-icon/LogoTextSvg';
import AuthLineMobileSvg from '../../components/UI/svg-icon/AuthLineMobileSvg';
import { fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useNavigate } from 'react-router-dom';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import LogoMobileSvg from "../../components/UI/svg-icon/LogoMobileSvg";
import {clearObject} from "../../common/ClearObject.helper";

interface IProps{
  type:string
}
const NewAuthSms = ({type}:IProps) => {
  const { setUser, user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [codeValue, setCodeValue] = useState<string>('');
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(59);
  const [focus, setFocus] = useState<boolean>(false);
  const refCode = useRef<HTMLInputElement | null>();
  const navigate = useNavigate();

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const getMe = async () => {
    const requestOptionsGet: any = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.apiKey}` },
    };
    const responseGet = await fetch(`${AUTHSUBSERVER}/api/auth/getMe`, requestOptionsGet);
    const data = await responseGet.json();
    if (data.permissions?.AUTH?.[0].toLowerCase()) {
      setUser({ ...user, type: data.permissions?.AUTH[0].toLowerCase() });
      localStorageWrite({ ...user, type: data.permissions?.AUTH[0].toLowerCase() });
      sendRole();
    } else {
      setUser({ ...user, type: data.permissions?.LK?.[0].toLowerCase() });
      localStorageWrite({ ...user, type: data.permissions?.LK?.[0].toLowerCase() });
    }
  };
  const sendRole = async () => {
    const utmObj= JSON.parse(localStorage.getItem('Utm'))
    const type = localStorage.getItem('RoleType');
    const requestBody={ service: `LK`, role: type?.toUpperCase(),utm:utmObj?utmObj:undefined }
    const requestOptions: any = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.apiKey}` },
      body: JSON.stringify(clearObject(requestBody)),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/setRole`, requestOptions);
      const data = await response.json();
      localStorage.setItem('apiKey', data.access);
      if (data.access) {
        await fetchPost('/users/terms', 'PATCH', { accepted: user.agreement });
      }

      setUser({ ...user, isLogin: true, type });
      localStorageWrite({ ...user });
    } catch (e) {
      console.log(e);
    }
  };

  const sendCode = async () => {
    refCode.current.blur();
    const requestOptions: any = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ phone: `+${user.phone}`, code: Number(codeValue) }),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/login/v2`, requestOptions);
      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem('apiKey', response.headers.get('Authorization'));
        Object.assign(user, { apiKey: response.headers.get('Authorization') });
        setUser({ ...user });
        localStorageWrite(user);
        await getMe();

        setTimeout(() => {
          navigate('/');
        }, 600);
      } else if (response.status === 401) {
        toast.error('Некорректный код из смс');
        setErrorSending(true);
        setCodeValue('');
      } else {
        toast.error(data.message);
        setErrorSending(true);
        setCodeValue('');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };

  const sendPhone = async () => {
    setErrorSending(false);
    setTimer(59);
    setCodeValue('');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: `+${user.phone}` }),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/identificate`, requestOptions);
      if (response.status === 204) {
        setUser({ ...user, isInn: true, isPhone: true });
        localStorageWrite(user);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    refCode?.current?.click();
  }, []);

  useEffect(() => {
    if (codeValue.length === 5) {
      sendCode();
      setCodeValue('');
    }
  }, [codeValue]);
  const inputVerificationChange = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/gu, '').slice(0, 5));
  };

  const formatPhoneNumber = (phoneNumber): string => {
    let arrayOfNumberPhone = phoneNumber.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (arrayOfNumberPhone) {
      let initialCode = arrayOfNumberPhone[1] ? '+7 ' : '';
      return [
        initialCode,
        '',
        arrayOfNumberPhone[2],
        '',
        arrayOfNumberPhone[3],
        '',
        arrayOfNumberPhone[4],
        '',
        arrayOfNumberPhone[5],
      ].join(' ');
    }
    return 'некорректный номер телефона';
  };

  return (
    <div style={{ height: '100vh' }}>
      {isMobile ? (
        <div style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.headerLogo}>
              <div className={styles.headerLogoContent}>
                <LogoMobileSvg/>
                <p className={styles.textLogo}>
                  Платформа нерудных
                  <br/>
                  материалов
                </p>
              </div>
            </div>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineMobileSvg />
              </div>

              <div className={styles.inputContainer}>
                <h5 className={styles.titleInputContainer}>Введите код</h5>
                <div style={{ zIndex: '20' }}>
                  <p className={styles.descriptionInputContainer}>Мы отправили код подтверждения на номер</p>

                  <div className={styles.containerNumberAndChangeInputContainer}>
                    <div className={styles.numberInputContainer}>{formatPhoneNumber(user?.phone)}

                      <span className={styles.changeInputContainer} onClick={()=>navigate(-1)}>  Изменить</span></div>
                  </div>
                </div>
                <InputVerification
                  placeholder="00000"
                  errorSending={errorSending}
                  setErrorSending={setErrorSending}
                  onInput={inputVerificationChange}
                  value={codeValue}
                  refNew={refCode}
                  focus={focus}
                  setFocus={setFocus}
                  autoFocus={true}
                />
                {errorSending && (
                  <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>
                )}
                <div style={{ marginTop: '40px' }}>
                  {timer > 0 ? (
                    <div className={styles.newCodeCounter}>
                      Получить новый код можно <br />
                      через {convertVisualOfTimerCount()}
                    </div>
                  ) : (
                    <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex:'20', position:'relative' }}>
                      <ButtonFilled
                        text={'Отправить код повторно'}
                        width={'100%'}
                        onClick={() => sendPhone()}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*компьютерная версия*/
        <div>
          <div className={styles.container}>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineSvg />
              </div>
              <div className={styles.inputContainer}>
                <h5 className={styles.titleInputContainer}>Введите код</h5>
                <div>
                  <p className={styles.descriptionInputContainer}>Мы отправили код подтверждения на номер</p>

                  <div className={styles.containerNumberAndChangeInputContainer}>
                    <div className={styles.numberInputContainer}>{formatPhoneNumber(user?.phone)}
                      <span className={styles.changeInputContainer} onClick={()=>navigate(-1)}>  Изменить</span></div>
                  </div>
                </div>
                <InputVerification
                  placeholder="00000"
                  errorSending={errorSending}
                  setErrorSending={setErrorSending}
                  onInput={inputVerificationChange}
                  value={codeValue}
                  refNew={refCode}
                  focus={focus}
                  setFocus={setFocus}
                  autoFocus={true}
                />
                {errorSending && (
                  <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>
                )}
                <div style={{ marginTop: '20px' }}>
                  {timer > 0 ? (
                    <div className={styles.newCodeCounter}>
                      Получить новый код можно <br />
                      через {convertVisualOfTimerCount()}
                    </div>
                  ) : (
                    <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex:'20', position:'relative' }}>
                      <ButtonFilled
                        text={'Отправить код повторно'}
                        width={'100%'}
                        onClick={() => sendPhone()}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <FooterAuth />
        </div>
      )}
    </div>
  );
};

export default NewAuthSms;
