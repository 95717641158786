import React, { useEffect } from 'react';
import styles from './SuccessContractModal.module.scss';
import { Modal } from 'antd';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import SuccessSvgVer2 from '../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { TypeTitlesOfTheCreatedContract } from '../../type';

interface IProps {
    successContractModal: boolean;
    setSuccessContractModal: React.Dispatch<React.SetStateAction<boolean>>;
    getData:any
    titlesOfTheCreatedContract:TypeTitlesOfTheCreatedContract
    isFirstRender:number
}
const SuccessContractModal = ({ successContractModal,setSuccessContractModal,getData,titlesOfTheCreatedContract,isFirstRender }:IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const closeSuccessContractModal = () => {
        setSuccessContractModal(false);
    };
    useEffect(()=>{
        if (successContractModal===false && isFirstRender>0){
            getData();
        }
    },[successContractModal]);
    return (
        <>
            {isMobile ?
                <div className={successContractModal ? 'DriverPagePopup active' : 'DriverPagePopup'}>
                    <div onClick={() => closeSuccessContractModal()} className="DriverPagePopup__white"></div>
                    <div className="DriverPagePopup__line"></div>
                    <div className="DriverPagePopup__wrapper"
                         style={{ height: 'fit-content',
                             top: 'auto',bottom:'0',borderRadius: '12px 12px 0px 0px',background: `#EAEEFF` }}>
                    <div className={styles.drawerTriangleContainer}>
                        <div className={styles.drawerTriangle} />
                    </div>
                    <div className={styles.container}>
                        <div className={styles.topContainer}>
                            <div className={styles.titleAndCloseButton}>
                                <p className={styles.textTitle}>Добавление договора</p>
                                <div className={styles.mobileCloseIconDrawer} >
                                    <div className={styles.mobileCloseIconBlockDrawer} onClick={closeSuccessContractModal}>
                                        <CloseSvg />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.infoContainer}>
                                <SuccessSvgVer2/>
                                <p className={styles.infoTitleText}>
                                    Договоры {titlesOfTheCreatedContract.deliveryContract.title} на поставку и&nbsp;
                                    {titlesOfTheCreatedContract.transportationContractId.title} на перевозку  отправлены на проверку
                                </p>
                                <p className={styles.infoSubTitleText}>Они появятся в общем списке договоров</p>
                            </div>
                            <ButtonFilled sizeText={'small'} width={'100%'} text={'Готово'} onClick={()=>setSuccessContractModal(false)}/>
                        </div>
                    </div>
                </div>
                </div>
                :
                <Modal zIndex={9500} width={480} footer={null} open={successContractModal} onCancel={closeSuccessContractModal} closeIcon={false}>
                    <div className={styles.container}>
                        <div className={styles.topContainer}>
                            <div className={styles.titleContainer}>
                                <p className={styles.textTitle}>Добавление договора</p>
                            </div>
                            <SuccessSvgVer2/>
                            <p className={styles.infoTitleText}>
                                Договоры {titlesOfTheCreatedContract.deliveryContract.title} на поставку и&nbsp;
                                {titlesOfTheCreatedContract.transportationContractId.title} на перевозку  отправлены на проверку
                            </p>
                            <p className={styles.infoSubTitleText}>Они появятся в общем списке договоров</p>
                        </div>
                        <ButtonFilled width={'100%'} text={'Готово'} onClick={()=>setSuccessContractModal(false)}/>
                    </div>
                    <div className={styles.closeButton}>
                        <FrameModalSvg onClick={closeSuccessContractModal} />
                    </div>
                </Modal>
            }
        </>
    );
};

export default SuccessContractModal;