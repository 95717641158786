import React from 'react';
import type { SelectProps } from 'antd';
import { Select } from 'antd';

interface ItemProps {
  parent?: number;
  label: string;
  value: string | number;
}

interface IProps {
  allowClear?: boolean;
  options?: ItemProps[];
  disabled?: boolean;
  showSearch?: boolean;
  value?: any;
  setValue?: any;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  maxTagCount?: number | 'responsive';
  maxCount?: number;
  status?: any;
  errorText?: string;
  style?: React.CSSProperties;
}

const defaultStyle: React.CSSProperties = { width: '100%' };

export const SelectCustom = ({
  allowClear,
  showSearch,
  options,
  value,
  setValue,
  mode,
  placeholder,
  disabled,
  maxTagCount,
  maxCount,
  status,
  errorText,
  style,
}: IProps) => {
  const selectProps: SelectProps = {
    style: style || defaultStyle,
    allowClear,
    showSearch,
    disabled,
    mode,
    value,
    options,
    onChange: (newValue: string[], option) => {
      setValue(option);
    },
    placeholder,
    maxTagCount,
    maxCount,
    status: status,
  };

  return (
    <div className={`select-custom ${status ? '-status-error' : ''}`}>
      <Select {...selectProps} />
      {status && <div className={'select-custom__error-text textSmall'}>{errorText}</div>}
    </div>
  );
};
