// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomYMap_yMapsObjectsPage__pkEo2 {\n  height: 350px;\n  padding-top: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/CustomYMap/CustomYMap.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,iBAAA;AAAF","sourcesContent":["\n.yMapsObjectsPage{\n  height: 350px;\n  padding-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yMapsObjectsPage": "CustomYMap_yMapsObjectsPage__pkEo2"
};
export default ___CSS_LOADER_EXPORT___;
