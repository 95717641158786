import React, { useEffect } from 'react';
import styles from './AddArea.module.scss';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { CustomSelect } from '../../../../../components/UI/select/CustomSelect';
import { optionsAreaOfPrice } from '../../../constans';
import ButtonNotFilled from '../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import DeleteOutlineSvg from '../../../../../components/UI/svg-icon/DeleteOutlineSvg';
import { CheckBoxCustom } from '../../../../../components/UI/checkBox/CheckBoxCustom';
import { ICheckBox, ITypeInputsOfMaterial } from '../../../type';
import uuid from 'react-uuid';
import { useMediaQuery } from 'react-responsive';
interface IProps {
    valueOfInputsOfMaterial:ITypeInputsOfMaterial
    setValueOfInputsOfMaterial:React.Dispatch<React.SetStateAction<ITypeInputsOfMaterial>>
    valueMaterial:string
    edit:boolean
    fixedPriceCheckBox:ICheckBox
    setFixedPriceCheckBox:React.Dispatch<React.SetStateAction<ICheckBox>>
}
const AddArea = ({ valueOfInputsOfMaterial, setValueOfInputsOfMaterial, valueMaterial, edit, fixedPriceCheckBox, setFixedPriceCheckBox }:IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const handleAddInput = ():void => {
        setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial] :
                { ...prevState[valueMaterial],prices:[...prevState[valueMaterial].prices,{ region:'',value:0,id:uuid(),error:false }] } }));
    };
    const handleChange = (event, index:number):void => {
        let { name, value } = event.target;
        let onChangeValue = [...valueOfInputsOfMaterial[valueMaterial].prices];
        onChangeValue[index][name] = name === 'value'? Number(value) : value;
        setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial]:{ ...prevState[valueMaterial],prices:onChangeValue } }));
    };
    const handleClear = (event, index:number):void => {
        let { name } = event.current;
        let onChangeValue = [...valueOfInputsOfMaterial[valueMaterial].prices];
        onChangeValue[index][name] = 0;
        setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial]:{ ...prevState[valueMaterial],prices:onChangeValue } }));
    };
    const handleDeleteInput = (index:number):void => {
        const filteredArrayOfInputsOfMaterial = [...valueOfInputsOfMaterial[valueMaterial].prices].splice(index, 1);
        setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial]:
                { ...prevState[valueMaterial],prices:filteredArrayOfInputsOfMaterial } }));
    };
    const pickCheckFixedPrice = id => {
        setFixedPriceCheckBox(prevState => ({ id: id, active: !prevState.active }));
    };
    return (
        <>
            {isMobile ?
                <>
                    {valueOfInputsOfMaterial[valueMaterial].prices.map((item,index) => (
                        <div key={item.id} className={styles.addAreaContainer}>
                            <CustomInput
                                width={'100%'}
                                name='value'
                                clearButton={true}
                                value={item.value}
                                placeholder={'Введите сумму'}
                                onChange={(event) => {
                                    handleChange(event, index);
                                }}
                                disabled={false}
                                clearButtonFunction={handleClear}
                                index={index}
                            />
                                {!fixedPriceCheckBox.active &&
                                    <div className={styles.addButtonAndArea}>
                                    <CustomSelect
                                        value={edit && valueOfInputsOfMaterial[valueMaterial].prices[index].region ?
                                            { value:valueOfInputsOfMaterial[valueMaterial].prices[index].region,
                                                label:valueOfInputsOfMaterial[valueMaterial].prices[index].region }:undefined}
                                        isDisabled={false}
                                        error={false}
                                        options={optionsAreaOfPrice}
                                        setValue={(event) => {
                                            const eventValue={ target: { value:event.value,name:'region' } };
                                            handleChange(eventValue, index);
                                        }}
                                        placeholder={'Введите область'}
                                        isClearable={false}
                                        isSearchable={true}
                                        noOptionsMessage={'Нет вариантов'}
                                    />
                                        {valueOfInputsOfMaterial[valueMaterial].prices.length > 1 && (
                                            <ButtonNotFilled onClick={() => handleDeleteInput(index)} width={'48px'} svg={<DeleteOutlineSvg/>}/>
                                        )}
                                    </div>}
                            {valueOfInputsOfMaterial[valueMaterial].prices.length===1 &&
                                <CheckBoxCustom
                                    children={'Фиксированная стоимость'}
                                    error={false}
                                    check={fixedPriceCheckBox.active}
                                    pick={pickCheckFixedPrice}
                                    name={'fixedPrice'}
                                    id={'fixedPrice'}
                                />
                            }
                            {!fixedPriceCheckBox.active && valueOfInputsOfMaterial[valueMaterial].prices.length>=1
                                && (index === valueOfInputsOfMaterial[valueMaterial].prices.length - 1) &&
                                <ButtonNotFilled
                                    key={index}
                                    text={'Добавить область'}
                                    onClick={()=>handleAddInput()}
                                    width={'100%'}
                                    sizeText={'small'}
                                    height={'48px'}
                                />
                            }
                        </div>
                    ))}
                </>
                :
                <>
                    {valueOfInputsOfMaterial[valueMaterial].prices.map((item,index,arr) => (
                        <div key={item.id} className={fixedPriceCheckBox.active ? styles.addAreaContainerFixedPrice : styles.addAreaContainer}>
                            <CustomInput
                                width={fixedPriceCheckBox.active?'100%':'60%'}
                                name='value'
                                clearButton={true}
                                value={item.value}
                                placeholder={'Введите сумму'}
                                onChange={(event) => {
                                    handleChange(event, index);
                                }}
                                disabled={false}
                                clearButtonFunction={handleClear}
                                index={index}
                            />
                            {!fixedPriceCheckBox.active &&
                                <CustomSelect
                                    value={edit && valueOfInputsOfMaterial[valueMaterial].prices[index].region ?
                                        { value:valueOfInputsOfMaterial[valueMaterial].prices[index].region,
                                        label:valueOfInputsOfMaterial[valueMaterial].prices[index].region }:undefined}
                                    isDisabled={false}
                                    error={false}
                                    options={optionsAreaOfPrice}
                                    setValue={(event) => {
                                        const eventValue={ target: { value:event.value,name:'region' } };
                                        handleChange(eventValue, index);
                                    }}
                                    placeholder={'Введите область'}
                                    isClearable={false}
                                    isSearchable={true}
                                    noOptionsMessage={'Нет вариантов'}
                                />
                            }
                            {!fixedPriceCheckBox.active && valueOfInputsOfMaterial[valueMaterial].prices.length===1
                                && (index === valueOfInputsOfMaterial[valueMaterial].prices.length - 1) &&
                                <div style={{ width:'185px' }}>
                                    <ButtonNotFilled
                                        key={index}
                                        text={'Добавить область'}
                                        onClick={()=>handleAddInput()}
                                        width={'185px'}
                                        sizeText={'small'}
                                        height={'48px'}
                                    />
                                </div>
                            }
                            {valueOfInputsOfMaterial[valueMaterial].prices.length > 1 && (
                                <ButtonNotFilled onClick={() => handleDeleteInput(index)} width={'48px'} svg={<DeleteOutlineSvg/>}/>
                            )}
                        </div>
                    ))}
                    {valueOfInputsOfMaterial[valueMaterial].prices.length===1 &&
                        <CheckBoxCustom
                            children={'Фиксированная стоимость'}
                            error={false}
                            check={fixedPriceCheckBox.active}
                            pick={pickCheckFixedPrice}
                            name={'fixedPrice'}
                            id={'fixedPrice'}
                        />
                    }
                    {valueOfInputsOfMaterial[valueMaterial].prices.map((item, index) => {
                            if( !fixedPriceCheckBox.active && valueOfInputsOfMaterial[valueMaterial].prices.length>1
                                && (index === valueOfInputsOfMaterial[valueMaterial].prices.length - 1)){
                                return (
                                    <ButtonNotFilled key={index} text={'Добавить область'} onClick={()=>handleAddInput()} width={'231px'}/>
                                );
                            }
                        }
                    )}
                </>
            }

        </>
    );
};

export default AddArea;