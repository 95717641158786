import React from 'react';
import styles from './MobileColumnsDispatching.module.scss';
import DistributionAndSubmitRequest from '../DistributionAndSubmbitRequest/DistributionAndSubmitRequest';
import { useNavigate } from 'react-router-dom';
import AlertSvg from '../../../../components/UI/svg-icon/AlertSvg';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import NewEmptySvg from '../../../../components/UI/svg-icon/NewEmptySvg';
// @ts-ignore
import LoadingGif from '../../../../assets/img/Loader-Solber.gif';

const MobileColumnsDispatching = ({ dataResponse, getData, loading }) => {
  const navigate = useNavigate();
  const Spin = () => {
    return (
      <div>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div className={'emptyContentContainer'}>
          <div className={styles.loadingSpin}>
            <Spin />
          </div>
        </div>
      ) : (
        <>
          {dataResponse?.length ? (
            <>
              {dataResponse?.map(item => (
                <div
                  key={item?.id}
                  className={
                    item?.rejectComment && item?.status?.title === 'Отклонена' ? styles.redContainer : styles.container
                  }
                  onClick={() => {
                    navigate(`/dispatching/${item?.id}`);
                  }}
                >
                  <div className={styles.orderAndDateAndTooltipContainer}>
                    <div className={styles.orderAndTooltipContainer}>
                      {item?.rejectComment && item?.status?.title === 'Отклонена' && (
                        <div onClick={e => e.stopPropagation()}>
                          <CustomTooltip
                            MobileSideTooltip={'right'}
                            SideTooltip={'right'}
                            svg={<AlertSvg />}
                            text={item?.rejectComment}
                            widthTooltip={`250px`}
                            widthSvg={'24px'}
                          />
                        </div>
                      )}
                      <p className={styles.orderNumberText}>Заявка {item?.orderNumber}</p>
                    </div>
                    <p className={styles.thickText}>{item?.orderDate?.slice(0, 10).split('-').reverse().join('.')}</p>
                  </div>
                  <div className={styles.mainContentContainer}>
                    <p className={styles.thickText}>{item?.consignee}</p>
                    <p className={styles.softText}>Контрагент получателя</p>
                  </div>
                  <div className={styles.mainContentContainer}>
                    <p className={styles.thickText}>{item?.quarry?.address?.address}</p>
                    <p className={styles.softText}>Загрузка</p>
                  </div>
                  <div className={styles.mainContentContainer}>
                    <p className={styles.thickText}>{item?.unloadAndTime?.unload}</p>
                    {item?.unloadAndTime?.aroundTheClock ? (
                      <p className={styles.timeWorkText}>круглосуточно</p>
                    ) : (
                      <p className={styles.timeWorkText}>
                        часы работы: {item?.unloadAndTime?.timeFrom}-{item?.unloadAndTime?.timeTo}
                      </p>
                    )}
                    <p className={styles.softText}>Выгрузка</p>
                  </div>
                  <div>
                    <DistributionAndSubmitRequest getData={getData} data={item?.distribution} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={'emptyContentContainer'}>
              <div
                style={{
                  marginTop: '-60px',
                  textAlign: 'center',
                }}
              >
                <NewEmptySvg />
              </div>
              <div
                style={{
                  marginTop: '-40px',
                }}
              >
                <h4 className={'emptyTitle'}>Заявок не найдено</h4>
                <p className={'emptyDescription'}>
                  По выбранным фильтрам нет заявок. <br />
                  Попробуйте изменить параметры
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MobileColumnsDispatching;
