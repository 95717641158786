import React from 'react';

export const PriceMultiplicationSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9999 5.00006L5 19M4.99994 5L18.9999 18.9999"
        stroke="#6C779C"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
