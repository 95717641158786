import React from 'react';

interface IProps {
  fill: boolean;
  margin: string;
  bgError: boolean | string;
  isValue: string | number;
  setIsValue: any;
  placeholder: string;
  maxLength?: number;
  error?: boolean;
  textError?: string;
  type?: string;
}
export const InputNumber = ({
  fill,
  margin,
  bgError,
  isValue,
  setIsValue,
  placeholder,
  maxLength = 100,
  error,
  textError,
  type='phone'
}: IProps) => {
  return (
    <div className="InputNumber  form-item" style={{ marginBottom: margin }}>
      <input
        type={type}
        maxLength={maxLength}
        className={bgError ? 'InputNumber__input form-input' : 'InputNumber__input form-input error'}
        value={isValue == null ? '' : isValue}
        onChange={({ target: { value } }) => setIsValue(value)}
      />
      <label className={isValue ? 'InputNumber__label form-label valid' : 'InputNumber__label form-label'}>
        {placeholder}
      </label>
      {fill && <div className="InputText__text">Поле обязательное для заполнения</div>}
      {error && (
        <div className="InputText__text" style={{ color: '#EB5757' }}>
          {textError}
        </div>
      )}
    </div>
  );
};
