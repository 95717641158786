import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ProgressElement } from '../../components/ProgressElement';
import { Collapse } from 'antd';
import { SkeletonOrder } from '../../components/skeletons/SkeletonOrder';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../common/form-price.helper';
import { ImageContainer } from '../../components/ImageContainer';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';

type Order = {
  status: {
    title: string;
  };
  date: string;
  orderNumber: string;
  volume: number;
  volumeDelivered: number;
  price: number;
  needSeal: boolean;
  needAttorney: boolean;
  deliveryType: string;
  contactPerson: {
    person: string;
    phone: string;
    email: string;
  };
  organization: {
    title: string;
  };
  nomenclature: {
    price: number;
    unit?: string;
    nameByDocument:string;
  };
  flights: any;
  specification: {
    id: number;
    contract: {
      organization: {
        title: string;
      };
    };
    deliveryType: string;
    files: {
      id: number;
      name: string;
      communicationCategory: string;
    }[];
    validityPeriod: string;
    volume: number;
  };

  object: {
    name: string;
    address: {
      address: string;
    };
    workSchedulesGraph: {
      from: string;
      to: string;
      aroundTheClock: boolean;
    };
  };
};
export const OrderPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [files, setFiles] = useState<any>([]);
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);

  const getData = async () => {
    setSkeleton(true);

    try {
      const response = await fetchGet(`/orders/${window.location.href.split('/').reverse()[0]}`, {});

      if (response.response.error) {
        console.log('error');
        toast.error('Ошибка получение данных');
        return;
      }
      const responseFiles = await fetchGet(`/files`, {
        communicationCategory: 'specifications',
        fileBelongsId: response.response?.specification?.id,
      });
      await setFiles(responseFiles.response);

      await setOrder(response.response);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };

  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };

  useEffect(() => {
    getData().catch(console.error);
  }, []);
  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <ToastContainer />
          <SkeletonOrder />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <Link
            className="button-back"
            to={`/orders#${localStorage.getItem('orderTab')}`}
            onClick={() => localStorage.removeItem('orderTab')}
          >
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            Вернуться к списку заказов
          </Link>
          <div className={'order-page'}>
            <div className={'order-page-content'}>
              <div className={'order-page-content-left'}>
                <div className={'textH5 textH5_medium'}>{order?.orderNumber}</div>
                <div style={{ marginBottom: '12px' }} className={'textMedium'}>
                  {order?.date && new Date(order?.date).toLocaleString().split(',')[0]}
                </div>
                <div className={'order-page__progress'}>
                  <ProgressElement
                    startText={'Факт'}
                    endText={'План'}
                    intermediateNumber={order?.volumeDelivered | 0}
                    endNumber={order?.volume | 0}
                    unit={order?.nomenclature?.unit}
                  />
                </div>
              </div>
              <div className={'order-page-content-right'}>
                <div className={'order-page-content-right__title textH5 textH5_medium'}>Общая информация</div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    Поставщик
                  </div>
                  <div className={`order-page-content-right-item__text ${isMobile ? 'textH6' : 'textMedium'}`}>
                    {order?.specification?.contract?.organization?.title}
                  </div>
                </div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    Ответственный менеджер
                  </div>
                  <div className={`order-page-content-right-item__text ${isMobile ? 'textH6' : 'textMedium'}`}>
                    {order?.contactPerson?.person}
                  </div>
                </div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    Телефон ответственного
                  </div>
                  <div className={`order-page-content-right-item__text phone ${isMobile ? 'textH6' : 'textMedium'}`}>
                    <a
                      href={`tel:${order?.contactPerson?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}`}
                    >
                      {order?.contactPerson?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                    </a>
                  </div>
                </div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    E-mail ответственного
                  </div>
                  <div className={`order-page-content-right-item__text email ${isMobile ? 'textH6' : 'textMedium'}`}>
                    <a href={`mailto:${order?.contactPerson?.email}`}>{order?.contactPerson?.email}</a>
                  </div>
                </div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    Спецификация
                  </div>
                  {files ? (
                    <div className={`order-page-content-right-item__text ${isMobile ? 'textH6' : 'textMedium'}`}>
                      {files.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={mimeType}
                            openPopUp={() =>
                              (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                            }
                            children={undefined}
                          ></ImageContainer>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={'order-page-content-right-item'}>
                  <div className={`order-page-content-right-item__title ${isMobile ? 'textH6' : 'textMedium'}`}>
                    Срок действия спецификации
                  </div>
                  <div className={`order-page-content-right-item__text ${isMobile ? 'textH6' : 'textMedium'}`}>
                    {new Date(order?.specification?.validityPeriod).toLocaleString().split(',')[0]}
                  </div>
                </div>
              </div>
            </div>
            <div className={'order-page-collapse'}>
              <Collapse
                items={[
                  {
                    key: '1',
                    label: 'Информация по заказу',
                    children: (
                      <div className={'order-page-collapse'}>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Номенклатура
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.nomenclature?.nameByDocument}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Количество
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.volume}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Ед. измерения
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.nomenclature?.unit}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Стоимость материала за ед. измерения
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {formPrice(order && order.nomenclature?.price ? order.nomenclature?.price : 0, 'RUB')}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Сумма
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {formPrice(
                              order && order.nomenclature?.price ? order.nomenclature?.price * order?.volume : 0,
                              'RUB',
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                expandIconPosition={'end'}
              />
            </div>
            <div className={'order-page-collapse'}>
              <Collapse
                items={[
                  {
                    key: '2',
                    label: 'Информация по объекту',
                    children: (
                      <div className={'order-page-collapse'}>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Наименование
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.object?.name}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Адрес
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.object?.address?.address}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Вид приёмки
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.needSeal ? 'Печать' : 'Доверенность'}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Тип перевозки
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            {order?.specification?.deliveryType}
                          </div>
                        </div>
                        <div className={'order-page-collapse__box'}>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Вид ТС
                          </div>
                          <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                            Грузовой автомобиль
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                expandIconPosition={'end'}
              />
            </div>
            {order?.status.title !== 'Закрыт' && (
              <div className={'order-page-collapse'}>
                <Collapse
                  items={[
                    {
                      key: '3',
                      label: 'Отслеживание ТС на карте',
                      children: order?.flights.map(flight => (
                        <div className={'order-page-collapse'}>
                          <div className={'order-page-collapse__box'}>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              Рейс {flight?.flightName}
                            </div>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              {flight?.status}
                            </div>
                          </div>
                          <div className={'order-page-collapse__box'}>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              Транспортное средство
                            </div>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              {flight?.car?.carBrand?.brand || 'Нет данных'} {flight?.car?.stateNumber}
                            </div>
                          </div>
                          <div className={'order-page-collapse__box'}>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              Водитель
                            </div>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              {flight?.driver?.fullName || 'Нет данных'}
                            </div>
                          </div>
                           <div className={'order-page-collapse__box'}>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              Время в пути
                            </div>
                            <div className={`order-page-collapse__sub-title ${isMobile ? 'textH6' : 'textMedium'}`}>
                              { new Date(flight?.arrivalTime * 60 * 1000).toISOString().substring(11, 19) || 'Нет данных'}
                            </div>
                          </div>
                        </div>
                      )),
                    },
                  ]}
                  expandIconPosition={'end'}
                />
              </div>
            )}
            <div className={popUpActive ? 'Popup active' : 'Popup'}>
              <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={'Транспортная накладная'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
