export const IInitialStateButtonsPick = {
    firstStep: {
        button: true,
        input: false,
        sendOk: false,
        timer: false,
        file: {
            visible: false,
            name: '',
            data: '',
            date: '',
        },
        fileOpen: false,
        finish: false,
    },
    secondStep: {
        button: true,
        input: false,
        sendOk: false,
        timer: false,
        file: {
            visible: false,
            name: '',
            data: '',
            date: '',
        },
        fileOpen: false,
        finish: false,
    },
    thirstStep: {
        button: true,
        input: false,
        file: {
            visible: false,
            name: '',
            data: '',
            date: '',
        },
        fileOpen: false,
        finish: false,
    },
    fourthStep: {
        button: true,
        input: false,
        sendOk: false,
        timer: false,
        file: {
            visible: false,
            name: '',
            data: '',
            date: '',
        },
        fileOpen: false,
        finish: false,
    },
    status: false,
};
export const initialStateOfRegister = {
    comment:'',
    company:'',
    documentAmount:0,
    registerAddress:'',
    registerDate:'',
    registerNumber:'',
    status:'',
    volume:0,
};
export const initialStateOfDocumentList = [{
    active:false,
    id:0,
    item:{
        'Вес/Объем':{
            text:0
        },
        'Водитель':{
            text:''
        },
        'Дата документа':{
            text:''
        },
        'Ед. изм.':{
            text:''
        },
        'Заказчик':{
            text:''
        },
        'Номенклатура':{
            text:''
        },
        'Пункт назначения':{
            text:''
        },
        'Пункт отправления':{
            text:''
        },
        'Сумма':{
            text:''
        },
        'Цена перевозки':{
            text:''
        },
        '№ТС':{
            text:''
        },
        '№ТТН/ТН':{
            text:''
        }
    }
}];