import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { TitlePage } from '../../components/TitlePage';
import { Button } from '../../components/UI/buttons/Button';
import { DriverLicenseList } from '../../components/DriverLicenseList';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { InputNumber } from '../../components/UI/inputs/InputNumber';
import { useMediaQuery } from 'react-responsive';
import { DateInput } from '../../components/UI/inputs/DateInput';
import { ErrorText } from '../../components/ErrorText';
import { PlusSvg } from '../../components/UI/svg-icon/PlusSvg';
import { profileData } from '../../common/profile.type';
import { closePopUp, openPopUp } from '../../common/use-popup.functiopn';
import { toast, ToastContainer } from 'react-toastify';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { Context } from '../../context/context';
import styles from './DriversLicensePage.module.scss';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import useDebounce from '../../CustomHooks/useDebounce';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { Image } from '../../common/image.type';
import { SUBSERVER } from '../../common/sub-server.constant';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import { declinationWord } from '../../common/declinationWord.helper';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';

export const validationPassport = (value, setProfile, setValue, numberOfSymbols) => {
  const regex = /^[0-9]*[A-Z]*[0-9]*$/;
  if (value.length <= numberOfSymbols && regex.test(value)) setProfile(setValue);
};

export const validationPassportSeries = (value, setProfile, setValue, numberOfSymbols) => {
  const regex = /^[0-9]*$/;
  if (value.length <= numberOfSymbols && regex.test(value)) setProfile(setValue);
};
// (скрыт, т.к. для селекта гражданства)
// const passportCitizenships = [
//   {value: 'ABH', label: 'Абхазия'},
//   {value: 'AZE', label: 'Азербайджан'},
//   {value: 'ARM', label: 'Армения'},
//   {value: 'BLR', label: 'Белоруссия'},
//   {value: 'GEO', label: 'Грузия'},
//   {value: 'KAZ', label: 'Казахстан'},
//   {value: 'KGZ', label: 'Киргизия'},
//   {value: 'MDA', label: 'Молдавия'},
//   {value: 'RUS', label: 'Россия'},
//   {value: 'TJK', label: 'Таджикистан'},
//   {value: 'TKM', label: 'Туркменистан'},
//   {value: 'UZB', label: 'Узбекистан'},
//   {value: 'OST', label: 'Южная Осетия'},
// ]

export const DriversLicensePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user, setUser } = useContext(Context);
  const [profile, setProfile] = useState(profileData);
  const [errorDatePassport, setErrorDatePassport] = useState(false);
  const [errorDateBirthday, setErrorDateBirthday] = useState(false);
  const [errorDateOfIssue, setErrorDateOfIssue] = useState(false);
  const [image, setImage] = useState('');
  const [imagesPassport, setImagesPassport] = useState<Image[]>([]);
  const [imagesDriversLicense, setImagesDriversLicense] = useState<Image[]>([]);
  const [driversList, setDriversList] = useState<[]>([]);
  const [isErrorPhone, setIsErrorPhone] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sending, setSending] = useState(false);
  const [updateListDrivers, setUpdateListDrivers] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  // (скрыт, т.к. для селекта гражданства)
  // const [passportCitizenship, setPassportCitizenship] = useState<{ value: string; label: string } | null>(null);
  // const [driverLicenseCitizenship, setDriverLicenseCitizenship] = useState<{ value: string; label: string } | null>(null);

  const [searchFilter, setSearchFilter] = useState('');

  const debouncedSearchTerm = useDebounce(searchFilter, 500);

  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    setProfile(profileData);
  }, [popUpActive]);
  const getDrivers = async (isPagination: boolean) => {
    !user.deleteDriver && toast.success('Водитель успешно удалён');

    try {
      const drivers = await fetchGet(`/drivers/filter`, {
        limit: 10,
        offset: isPagination ? offset : 0,
        searchString: searchFilter,
      });
      setTotalCount(drivers.response.count);
      if (isPagination && offset !== 0) {
        // @ts-ignore
        await setDriversList(prevState => [...prevState, ...drivers.response.rows]);
      } else {
        await setDriversList(drivers.response.rows);
      }
      if (!user.deleteDriver) {
        Object.assign(user, { deleteDriver: true });

        setUser({ ...user });

        localStorageWrite(user);
      }

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };
  useEffect(() => {
    if (totalCount > offset) {
      getDrivers(true);
    }
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    getDrivers(false);
  }, [updateListDrivers, debouncedSearchTerm]);

  useEffect(() => {
    setDisabled(
      !profile.name ||
        !profile.surname ||
        !profile.patronymic ||
        !profile.passportNumber ||
        !profile.driversLicenseNumber ||
        errorDatePassport ||
        errorDateBirthday ||
        errorDateOfIssue ||
        profile.phone?.length != 11 ||
        imagesPassport.length < 1 ||
        imagesDriversLicense.length < 1,
    );
  }, [
    profile.name,
    profile.surname,
    profile.patronymic,
    profile.phone,
    profile.passportNumber,
    profile.driversLicenseNumber,
    imagesDriversLicense,
    imagesPassport,
    errorDatePassport,
    errorDateBirthday,
    errorDateOfIssue,
  ]);

  function add1DayToDate(date: string) {
    let correctDate = new Date(date);
    return new Date(correctDate.setDate(correctDate.getDate() + 1));
  }
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const sendDataDriver = async () => {
    if (!profile.surname || !profile.name || !profile.patronymic || !profile.phone) {
      toast.error('Заполните обязательные поля');
      setDisabled(false);
      setSending(false);
      return;
    }
    setSending(true);

    try {
      const addDriver = await fetchPost(`/drivers`, 'POST', {
        surname: profile.surname.trim() || null,
        name: profile.name.trim() || null,
        patronymic: profile.patronymic.trim() || null,
        profile: {
          birthDate: profile.birthDate
            ? add1DayToDate(profile.birthDate.split(',')[0].replaceAll('/', '.').split('.').reverse().join())
            : //.replaceAll('/','.') Экстренно добавлен для фикса бага который появляется на маке в связи с локализацией даты.
              new Date().toLocaleString(),
          birthPlace: profile.birthPlace,
        },
        passport: {
          passportSeries: profile.passportSeries || null,
          passportNumber: profile.passportNumber || null,
          passportDate: profile.passportDate
            ? add1DayToDate(profile.passportDate.split(',')[0].replaceAll('/', '.').split('.').reverse().join())
            : //.replaceAll('/','.') Экстренно добавлен для фикса бага который появляется на маке в связи с локализацией даты.
              new Date().toLocaleString(),
          passportIssued: profile.passportIssued,
        },
        driverLicense: {
          driversLicenseSeries: profile.driversLicenseSeries || null,
          driversLicenseNumber: profile.driversLicenseNumber || null,
          driversLicenseDate: profile.driversLicenseDate
            ? add1DayToDate(profile.driversLicenseDate.split(',')[0].replaceAll('/', '.').split('.').reverse().join())
            : //.replaceAll('/','.') Экстренно добавлен для фикса бага который появляется на маке в связи с локализацией даты.
              new Date().toLocaleString(),
        },
        phone: profile.phone || null,
      });
      if (addDriver.error) {
        const errorMessages = addDriver.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setSending(false);
        return;
      }
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'drivers',
          category: 'Паспорт',
          fileBelongsId: addDriver.response.id,
          files: imagesPassport?.map(file => file?.value),
          objectUuid: '',
        },
        {
          communicationCategory: 'drivers',
          category: 'Водительское удостоверение',
          fileBelongsId: addDriver.response.id,
          files: imagesDriversLicense?.map(file => file?.value),
          objectUuid: '',
        },
      ];
      const responseFile = await fetch(`${SUBSERVER}/api/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify(correctionArrayFilesBody),
      });
      setImagesDriversLicense([]);
      setImagesPassport([]);
      toast.success('Водитель успешно добавлен');
      closePopUp(setPopUpActive);
      setSending(false);
    } catch (e) {
      setSending(false);
      setDisabled(false);
      console.log(e);
    }
    setProfile({
      ...profile,
      surname: '',
      name: '',
      patronymic: '',
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      passportIssued: '',
      birthDate: '',
      birthPlace: '',
      driversLicenseSeries: '',
      driversLicenseNumber: '',
      driversLicenseDate: '',
      phone: '',
    });

    setDisabled(false);
    setSending(false);
    setPopUpActive(false);
    setUpdateListDrivers(!updateListDrivers);
    document.body.classList.remove('off');
  };
  const openPopupWithImg = src => {
    setImage(src);
    openPopUp(setPopUpActiveImg);
  };
  // (скрыт, т.к. для селекта гражданства)
  // let isPassportSeries = passportCitizenship && (passportCitizenship.value === 'ABH' || passportCitizenship.value === 'RUS' || passportCitizenship.value === 'OST');
  // let isDriverLicenseSeries = driverLicenseCitizenship && (driverLicenseCitizenship.value === 'ABH' || driverLicenseCitizenship.value === 'BLR' || driverLicenseCitizenship.value === 'GEO' || driverLicenseCitizenship.value === 'KAZ' || driverLicenseCitizenship.value === 'RUS' || driverLicenseCitizenship.value === 'OST');

  return (
    <>
      <PageWrapper style={{ zIndex: popUpActive ? '100000' : '100' }}>
        <ToastContainer />
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Водители'} />
            <ButtonFilled
                sizeText={'small'}
                svg={<NewPlusSvg />}
                width={isMobile ? '32px' : '140px'}
                height={isMobile ? '32px' : '40px'}
                onClick={() => openPopUp(setPopUpActive)}
                text={isMobile ? '' : 'Добавить'}
            />
          </div>
          <SubTitleMain
              subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['водитель', 'водителя', 'водителей'])}`}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <InputSearch
            placeholder="Поиск"
            onInput={({ currentTarget: { value: search } }) => setSearchFilter(search)}
            value={searchFilter}
          />
        </div>
        {driversList.length ? (
          <div className="DriversLicensePage__content">
            <div className="DriversLicensePage__titles">
              {['ФИО', 'Номер телефона'].map((item, index) => (
                <div className="DriversLicensePage__title" key={index}>
                  {item}
                </div>
              ))}
            </div>

            {skeleton ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '900px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            ) : (
              <DriverLicenseList list={driversList} />
            )}
          </div>
        ) : (
          <div className="FlightSection__content">
            <div className="EmptyFlightList">
              <div className="EmptyFlightList__banner">
                <div className="EmptyFlightList__error">
                  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                В данный момент у вас нет водителей. Добавьте водителя, чтобы увидеть его в общем списке.
              </div>
              <div className="EmptyFlightList__wrapper">
                <div className="EmptyFlightList__content">
                  <div className="EmptyFlightList__svg">
                    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="EmptyFlightList__title">Водители отсутствуют</div>
                  <div className="EmptyFlightList__subTitle">В данный момент у вас нет водителей</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={popUpActive ? 'DriverPagePopup active' : 'DriverPagePopup'}>
          <div onClick={() => closePopUp(setPopUpActive)} className="DriverPagePopup__white"></div>
          {isMobile && <div className="DriverPagePopup__line"></div>}
          <div className="DriverPagePopup__wrapper">
            <div>
              <div className="DriverPagePopup__title">
                Добавление водителя
                <div onClick={() => closePopUp(setPopUpActive)} className="DriverPagePopup__close"></div>
              </div>
              <div className="DriverPagePopup__content">
                <div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">1. Личные данные водителя</div>
                    <div className="DriverPagePopup__inputBox">
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Фамилия'}
                        textError={'Поле обязательное для заполнения'}
                        value={profile.surname}
                        setValue={value => setProfile({ ...profile, surname: value })}
                        isLabel={true}
                        error={true}
                        fill={true}
                        margin={''}
                        disabled={false}
                      />
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Имя'}
                        textError={'Поле обязательное для заполнения'}
                        value={profile.name}
                        setValue={value => setProfile({ ...profile, name: value })}
                        isLabel={true}
                        error={true}
                        fill={true}
                        margin={''}
                        disabled={false}
                      />
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Отчество'}
                        textError={'Поле обязательное для заполнения'}
                        value={profile.patronymic}
                        setValue={value => setProfile({ ...profile, patronymic: value })}
                        isLabel={true}
                        error={true}
                        fill={true}
                        margin={''}
                        disabled={false}
                      />
                      <div>
                        <MyPhoneInput
                          disabled={false}
                          setIsError={setIsErrorPhone}
                          isError={isErrorPhone}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          value={profile.phone}
                          onChange={value => setProfile({ ...profile, phone: value })}
                          isFunction={null}
                          btnHelp={false}
                        />
                        <ErrorText
                          isError={isErrorPhone}
                          empty={isErrorPhone}
                          emptyText={'Поле обязательное для заполнения'}
                          correctText="Вы ввели некорректный номер телефона"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">2. Паспортные данные</div>
                    {/* Выбор страны гражданства (скрыт, т.к. отказались от валидации паспортов в зависимости от гражданства) */}
                    {/* <CustomSelect
                        error={false}
                        options={passportCitizenships}
                        placeholder={'Страна гражданства'}
                        noOptionsMessage={'Нет вариантов'}
                        value={passportCitizenship}
                        setValue={value => setPassportCitizenship(value)}
                        isSearchable={false}
                        isClearable={true}
                      /> */}
                    <div className="DriverPagePopup__inputBox DriverPagePopup__inputBox_sub">
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Серия'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.passportSeries}
                        setValue={value =>
                          validationPassportSeries(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, passportSeries: value },
                            4,
                          )
                        }
                        isLabel={true}
                      />

                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Номер'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.passportNumber}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, passportNumber: value.toUpperCase() },
                            9,
                          )
                        }
                        isLabel={true}
                      />
                      <DateInput
                        error={errorDatePassport}
                        setError={setErrorDatePassport}
                        setDisabled={setDisabled}
                        disabled={false}
                        formatDate="DD.MM.YYYY"
                        maskFormatDate={'99.99.9999'}
                        isLabel={true}
                        value={profile.passportDate}
                        setValue={value => setProfile({ ...profile, passportDate: value })}
                        margin={isMobile ? '12px' : '0'}
                        placeholder={'Дата выдачи'}
                        errorText={'Неверный формат даты'}
                        isHelp={false}
                        textHelp={''}
                      />
                      <DateInput
                        error={errorDateBirthday}
                        setError={setErrorDateBirthday}
                        setDisabled={null}
                        disabled={false}
                        formatDate={'DD.MM.YYYY'}
                        maskFormatDate={'99.99.9999'}
                        isLabel={true}
                        value={profile.birthDate}
                        setValue={birthDate => setProfile({ ...profile, birthDate })}
                        margin={isMobile ? '12px' : '0'}
                        placeholder={'Дата рождения'}
                        errorText={'Неверный формат даты'}
                        isHelp={false}
                        textHelp={''}
                      />
                    </div>
                    <InputText
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'Кем выдан'}
                      textError={''}
                      value={profile.passportIssued}
                      setValue={value => setProfile({ ...profile, passportIssued: value })}
                      isLabel={true}
                      error={false}
                      fill={false}
                      margin={'16px'}
                      disabled={false}
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'Место рождения'}
                      textError={''}
                      value={profile.birthPlace}
                      setValue={value => setProfile({ ...profile, birthPlace: value })}
                      isLabel={true}
                      error={false}
                      fill={false}
                      margin={'16px'}
                      disabled={false}
                    />
                    <div style={{ marginTop: '24px' }}>
                      <ProfilePageUploading
                        name={'Паспорт'}
                        desc={'Разворот с фото, разворот с пропиской'}
                        setImages={setImagesPassport}
                        images={imagesPassport}
                        count={2}
                      />
                      {imagesPassport.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => removeImage(target, imagesPassport, setImagesPassport)}
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">3. Водительское удостоверение</div>
                    {/* Выбор страны выдачи (скрыт, т.к. отказались от валидации паспортов в зависимости от гражданства) */}
                    {/* <CustomSelect
                        error={false}
                        options={passportCitizenships}
                        placeholder={'Страна выдачи'}
                        noOptionsMessage={'Нет вариантов'}
                        value={driverLicenseCitizenship}
                        setValue={value => setDriverLicenseCitizenship(value)}
                        isSearchable={false}
                        isClearable={true}
                      /> */}
                    <div className="DriverPagePopup__inputBox DriverPagePopup__inputBox_sub">
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Серия'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.driversLicenseSeries}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, driversLicenseSeries: value.toUpperCase() },
                            4,
                          )
                        }
                        isLabel={true}
                      />
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        placeholder={'Номер'}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.driversLicenseNumber}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, driversLicenseNumber: value.toUpperCase() },
                            9,
                          )
                        }
                        isLabel={true}
                      />

                      {/*
                        <InputNumber
                          bgError={true}
                          fill={false}
                          placeholder={'Номер'}
                          margin={isMobile ? '12px' : '0'}
                          isValue={profile.driversLicenseNumber}
                          setIsValue={value =>
                            validationDriversLicenseNumber(
                              value,
                              setProfile,
                              { ...profile, driversLicenseNumber: value },
                              4,
                            )
                          }
                        />*/}
                    </div>
                    <div style={{ margin: '16px 0 0' }}>
                      <DateInput
                        error={errorDateOfIssue}
                        setError={setErrorDateOfIssue}
                        setDisabled={null}
                        disabled={false}
                        formatDate="DD.MM.YYYY"
                        maskFormatDate={'99.99.9999'}
                        isLabel={true}
                        value={profile.driversLicenseDate}
                        setValue={value => setProfile({ ...profile, driversLicenseDate: value })}
                        margin={''}
                        placeholder={'Дата выдачи'}
                        errorText={'Неверный формат даты'}
                        isHelp={false}
                        textHelp={''}
                      />
                    </div>
                    <div style={{ marginTop: '24px', marginBottom: '48px' }}>
                      <ProfilePageUploading
                        name={'Водительское удостоверение'}
                        desc={'Лицевая и оборотная сторона'}
                        setImages={setImagesDriversLicense}
                        images={imagesDriversLicense}
                        count={2}
                      />
                      {imagesDriversLicense.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) =>
                                removeImage(target, imagesDriversLicense, setImagesDriversLicense)
                              }
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <ButtonFilled width={'100%'} onClick={sendDataDriver} text={'Сохранить'} disabled={disabled} />
              </div>
            </div>
            <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
              <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={'Транспортная накладная'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
