import React from 'react';
import styles from './HelpBalloon.module.scss';

type THelpBallon = {
  children: string;
  isLeft?: boolean;
  style?: React.CSSProperties;
};

export const HelpBalloon = ({ children, isLeft = false, style = {}, ...props }: THelpBallon) => {
  return (
    <div className={isLeft ? styles.HelpBalloon__left : styles.HelpBalloon} style={style} {...props}>
      {children}
    </div>
  );
};
