export type Profile = {
  id: number | null | undefined;
  companyId: number | null | undefined;
  uuid: string | null | undefined;
  phone: string | null | undefined;
  type: string | null | undefined;
  patronymic: string | null | undefined;
  name: string | null | undefined;
  surname: string | null | undefined;
  email: string | null | undefined;
  inn: string | null | undefined;
  passportSeries: string | null | undefined;
  passportNumber: string | null | undefined;
  passportDate: string | null | undefined;
  passportIssued: string | null | undefined;
  birthDate: string | null | undefined;
  birthPlace: string | null | undefined;
  registrationAddress: string | null | undefined;
  citizenship: boolean | null | undefined;
  verified: boolean | null | undefined;
  passportSeriesError: boolean;
  driversLicenseNumberError: boolean;
  driversLicenseSeriesError: boolean;
  passportNumberError: boolean;
  driversLicenseSeries: string | null | undefined;
  driversLicenseNumber: string | null | undefined;
  driversLicenseDate: string | null | undefined;
  driversLicenseIssued: string | null | undefined;
  archive: string | null | undefined;
  createdAt: string | null | undefined;
  updatedAt: string | null | undefined;
  company: {
    id: number | null | undefined;
    uuid: string | null | undefined;
    inn: string | null | undefined;
    kpp: string | null | undefined;
    buyer: string | null | undefined;
    supplier: string | null | undefined;
    carrier: string | null | undefined;
    accBik: string | null | undefined;
    bankName: string | null | undefined;
    accKorschet: string | null | undefined;
    accRaschet: string | null | undefined;
    title: string | null | undefined;
    address: string | null | undefined;
    actualAddress: string | null | undefined;
    mailingAddress: string | null | undefined;
    createdAt: string | null | undefined;
    updatedAt: string | null | undefined;
    verifications: string | null | undefined;
    companyFiles: any[] | null;
  };
  files: [];
};

export const profileData: Profile = {
  id: null,
  companyId: null,
  uuid: null,
  phone: null,
  type: null,
  surname: null,
  name: null,
  patronymic: null,
  email: null,
  inn: null,
  passportSeries: '',
  passportSeriesError: false,
  passportNumber: '',
  passportNumberError: false,
  passportDate: '',
  passportIssued: null,
  birthDate: '',
  birthPlace: null,
  registrationAddress: null,
  citizenship: null,
  verified: null,
  driversLicenseSeries: '',
  driversLicenseSeriesError: false,
  driversLicenseNumber: '',
  driversLicenseNumberError: false,
  driversLicenseDate: '',
  driversLicenseIssued: null,
  archive: null,
  createdAt: null,
  updatedAt: null,
  company: {
    id: null,
    uuid: null,
    inn: null,
    kpp: null,
    buyer: null,
    supplier: null,
    carrier: null,
    accBik: null,
    bankName: null,
    accKorschet: null,
    accRaschet: null,
    title: null,
    address: null,
    actualAddress: null,
    mailingAddress: null,
    createdAt: null,
    updatedAt: null,
    companyFiles: null,
    verifications: null,
  },
  files: [],
};
