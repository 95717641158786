import React from 'react';
import './RadioGroup.css';
import { Radio, RadioChangeEvent, ConfigProvider } from 'antd';
interface IProps {
  value: any;
  setValue: any;
  firstTitle: string;
  secondTitle: string;
  width: string;
  disabled: boolean;
}

const RadioGroup: React.FC<IProps> = ({ value, disabled, setValue, firstTitle, secondTitle, width }) => {
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              buttonColor: '#FF5100',
              buttonBg: '#FF5100',
            },
          },
        }}
      >
        <Radio.Group onChange={onChange} value={value}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: width }}>
            <Radio value={firstTitle} disabled={disabled}>
              {firstTitle}
            </Radio>
            <Radio value={secondTitle} disabled={disabled}>
              {secondTitle}
            </Radio>
          </div>
        </Radio.Group>
      </ConfigProvider>
    </div>
  );
};

export default RadioGroup;
