import React from 'react';
import styles from './FilterBox.module.scss';
import ChevronDownSvg from '../../UI/svg-icon/ChevronDownSvg';

interface IProps {
  children: any;
  clearFilters: any;
  widthButton?: string;
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}
const FilterBox = ({ children, clearFilters, widthButton = '290px', openFilter, setOpenFilter, disabled }: IProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ width: widthButton }}>
        <div
          className={`${styles.buttonOpenFilter} ${openFilter ? styles.openedFilterButton : ''} ${disabled ? '' : styles.filteredFilterButton}`}
          style={{ width: widthButton }}
          onClick={() => setOpenFilter(!openFilter)}
        >
          {disabled ? 'Фильтры' : 'Фильтры применены'}
          <ChevronDownSvg />
        </div>
      </div>
      {openFilter && (
        <div className={styles.hiddenFiltersContainer} style={{ width: widthButton }}>
          <div className={styles.hiddenFiltersContent}>{children}</div>
          <button disabled={disabled} onClick={() => clearFilters()} className={styles.buttonClearContainer}>
            Сбросить фильтры
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterBox;
