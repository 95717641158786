// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleAndSubtitle_titleAndSubTitleContainer__EkZWC {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n.TitleAndSubtitle_title__PXxc4 {\n  color: #1B1B1B;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 40px;\n}\n\n.TitleAndSubtitle_subTitle__MDx3i {\n  color: #6C779C;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n@media (max-width: 768px) {\n  .TitleAndSubtitle_title__PXxc4 {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 32px;\n  }\n  .TitleAndSubtitle_subTitle__MDx3i {\n    font-size: 14px;\n    line-height: 20px;\n  }\n  .TitleAndSubtitle_titleAndSubTitleContainer__EkZWC {\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/UI/TitleAndSubtitle/TitleAndSubtitle.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGF;;AADA;EACE;IACE,eAAA;IACA,gBAAA;IACA,iBAAA;EAIF;EAFA;IACE,eAAA;IACA,iBAAA;EAIF;EAFA;IACE,QAAA;IACA,mBAAA;EAIF;AACF","sourcesContent":[".titleAndSubTitleContainer{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n.title{\n  color: #1B1B1B;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 40px;\n}\n.subTitle {\n  color: #6C779C;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n@media (max-width: 768px){\n  .title{\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 32px;\n  }\n  .subTitle{\n    font-size: 14px;\n    line-height: 20px;\n  }\n  .titleAndSubTitleContainer{\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAndSubTitleContainer": "TitleAndSubtitle_titleAndSubTitleContainer__EkZWC",
	"title": "TitleAndSubtitle_title__PXxc4",
	"subTitle": "TitleAndSubtitle_subTitle__MDx3i"
};
export default ___CSS_LOADER_EXPORT___;
