import React from 'react';
import { Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import styles from '../PlanModals/AddPlanModal.module.scss';
import { IItemData, ISelect } from '../../types';

interface IProps {
  infoData: IItemData;
  name: any;
  data: any;
  carsList: ISelect[];
}
const DisplaySumVolume = ({ infoData, name, data, carsList }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const volumePerFlight = carsList.find(item => item.value === data?.[name]?.carId);
  const unit = infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit;
  const inputValueTonn = isNaN(
    Number(data?.[name]?.flightsNumber) * Number(volumePerFlight?.volumeBody ?? volumePerFlight?.volumeBody),
  )
    ? 0
    : Math.ceil(Number(data?.[name]?.flightsNumber) * Math.ceil(Number(volumePerFlight?.volumeBody * 1.45)));

  const inputValueMeter = isNaN(Number(data?.[name]?.flightsNumber) * Number(volumePerFlight?.volumeBody))
    ? 0
    : Number(data?.[name]?.flightsNumber) * Number(volumePerFlight?.volumeBody);

  const correctVisionOfUnit = (unit: string): string => {
    if (unit === 'тонн') {
      return 'тн';
    } else {
      return 'м³';
    }
  };
  return (
    <div>
      <Input
        value={unit === 'тонн' ? inputValueTonn : inputValueMeter}
        disabled={true}
        suffix={correctVisionOfUnit(infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit)}
        placeholder="0"
        style={{ width: isMobile ? `100%` : `121px`, fontSize: '16px', fontWeight: '300', color: 'black' }}
        className={styles.allVolumeOfFlight}
      />
    </div>
  );
};

export default DisplaySumVolume;
