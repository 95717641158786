import React from 'react';

const UnionSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Union">
                <path d="M15.0303 9.03033C15.3232 8.73744 15.3232 8.26256 15.0303 7.96967C14.7374 7.67678 14.2626 7.67678 13.9697 7.96967L9.5 12.4393L8.03033 10.9697C7.73744 10.6768 7.26256 10.6768 6.96967 10.9697C6.67678 11.2626 6.67678 11.7374 6.96967 12.0303L8.96967 14.0303C9.26256 14.3232 9.73744 14.3232 10.0303 14.0303L15.0303 9.03033Z" fill="#45AC81"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="#45AC81"/>
            </g>
        </svg>
    );
};

export default UnionSvg;