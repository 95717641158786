import React from 'react';
import { Modal } from 'antd';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { IEmployees } from '../../type';
import styles from './ConfirmModal.module.scss';
import EmployeeDelete from '../../../../components/UI/svg-icon/EmployeeDelete';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import { useMediaQuery } from 'react-responsive';
import {closePopUp} from "../../../../common/use-popup.functiopn";
interface IProps {
  employee: IEmployees;
  sendFunction: any;
  closeModal: any;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ConfirmModal = ({ employee, setOpenModal, openModal, sendFunction, closeModal }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const closeCurrentModal = () => {
    closePopUp(setOpenModal)
  };
  return (
    <Modal zIndex={9500} footer={null} closeIcon={isMobile} open={openModal} onCancel={closeCurrentModal}>
      <div className={styles.Container}>
        <div>
          <EmployeeDelete />
        </div>
        <div>
          <span className={styles.textTitle}>Подтвердите удаление </span>
          <p className={styles.textContent}>
            Вы действительно хотите удалить <span className={styles.textName}>
            {employee?.fullName?.surname} {employee?.fullName?.name} {employee?.fullName?.patronymic}
          </span> ?
          </p>
        </div>
        <div className={styles?.btnContainer}>
          <ButtonNotFilled
            text={'Отменить'}
            onClick={() => {
              closeCurrentModal();
            }}
            width={isMobile ? '140px' : '186px'}
          />
          <ButtonFilled
              onClick={() => {
              sendFunction(employee?.id);
              closeModal();
            }}
            text={'Удалить'}
            width={isMobile ? '140px' : '186px'}
          />
        </div>
      </div>
      <div className={styles.closeButton}>
        <FrameModalSvg onClick={closeCurrentModal} />
      </div>
    </Modal>
  );
};

export default ConfirmModal;
