import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { DevelopmentSvg } from '../../components/UI/svg-icon/DevelopmentSvg';
import { TitlePage } from '../../components/TitlePage';
import { useMediaQuery } from 'react-responsive';
import SubTitle from '../../components/UI/titles/SubTitle';
import { SkeletonStatistics } from '../../components/skeletons/SkeletonStatistics';
import { CalendarInput } from '../../components/UI/inputs/CalendarInput';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { MenuVerticalSvg } from '../../components/UI/svg-icon/MenuVerticalSvg';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { closePopUp, openPopUp } from '../../common/use-popup.functiopn';
import { InputText } from '../../components/UI/inputs/InputText';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { loadingImages } from '../../common/loading-image.function';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { Image } from '../../common/image.type';
import { removeImage } from '../../common/removeImage';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { SearchSvg } from '../../components/UI/svg-icon/SearchSvg';
import CustomRangePicker from '../../components/newUI/CustomRangePicker/CustomRangePicker';
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";

export const StatisticsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [popUpActive, setPopUpActive] = useState(false);
  const [isDevelopment] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [more, setMore] = useState<number>(4);
  const [inDevelopment, setInDevelopment] = useState(false);
  const [, setTitle] = useState('');
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [openCalendar2, setOpenCalendar2] = useState<boolean>(false);
  const [calendar2, setCalendar2] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>();
  const [dateRange2, setDateRange2] = useState<any>('Диапазон даты');
  const [nameQuarry, setNameQuarry] = useState<any>('Диапазон даты');
  const [images, setImages] = useState<Image[]>([]);

  const [nameQuarryOptions, setNameQuarryOptions] = useState<any>([]);
  const [data, setData] = useState({
    numberTN: '',
    date: '',
    file: [],
    comment: '',
  });
  const [statisticsData, setStatisticsData] = useState<
    {
      id: number;
      content: {
        [key: string]: any;
      };
      active: boolean;
    }[]
  >([]);
  const [teer, setTeer] = useState<
    {
      id: number;
      content: {
        [key: string]: any;
      };
      active: boolean;
    }[]
  >([]);

  const statisticsPageListTitles = [
    { title: '' },
    { title: 'Дата погрузки' },
    { title: 'Номер ТС' },
    { title: 'Наименование карьера' },
    { title: 'Адрес карьера' },
    { title: 'Наименование номенклатуры' },
    { title: 'Ед. изм.' },
    { title: 'Объем' },
  ];

  const popUp = (title: string) => {
    setTitle(title);
    openPopUp(setPopUpActive);
  };

  const getStatistic = async (startDate, finishDate) => {
    setSkeleton(true);
    try {
      const responseDetails = await fetchGet(`/reports/statistic`, {
        startDate: startDate,
        finishDate: finishDate,
      });

      const data = [];
      const dataOptions = [];
      responseDetails.response.forEach((item, index) => {
        data.push({
          id: index + 1,
          content: {
            '': <MenuVerticalSvg />,
            'Дата погрузки': new Date(item?.datePN).toLocaleString().split(',')[0],
            'Номер ТС': item?.stateNumber,
            'Наименование карьера': item?.supplierName,
            'Адрес карьера': item?.supplierAddress,
            'Наименование номенклатуры': item?.nomenclatureName,
            'Ед. изм.': item?.unit,
            Объем: item?.downloadVolume,
          },
          active: false,
        });

        if (dataOptions.find(({ label }) => label === item?.supplierName)) {
          return;
        }

        dataOptions.push({ value: index + 1, label: item?.supplierName });
      });

      setNameQuarryOptions(dataOptions);
      setStatisticsData(data);
      setTeer(data);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      console.log(e);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    }
  };

  useEffect(() => {
    const data = teer;
    let y = data;

    if (nameQuarry.length) {
      for (const name of nameQuarry) {
        y = data.filter(item => item.content['Наименование карьера'] === name.label);
      }
    }

    setStatisticsData(y);
  }, [nameQuarry]);

  return (
    <>
      {inDevelopment ? (
        <PageWrapper>
          <h1>Статистика</h1>
          <div className="development">
            <div className="development__banner banner">
              <div className="banner__svg">
                <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="banner__text">
                Раздел находится в разработке и будет доступен чере
                <span onClick={() => setInDevelopment(false)}>з</span> некоторое время
              </div>
            </div>
            <div className="development__content item">
              <div className="item__svg">
                <DevelopmentSvg />
              </div>
              <div className="item__text">
                <div className="item__title">В разработке</div>
                <div className="item__subTitle">Этот раздел разрабатывается</div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : skeleton ? (
        <PageWrapper>
          <SkeletonStatistics />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className={`development-wrapper ${isDevelopment ? 'blur' : ''}`}>
            <TitlePage title={'Статистика'} quantity={''} />
            <SubTitle children={'Список погрузочных накладных с карьеров, по которым нет транспортных накладных'} />
            <div className={'statistics-page'}>
              <div className={'statistics-page__inputs'}>
                <div className={'statistics-page__input'}>
                  <CustomRangePicker
                    sendRequest={getStatistic}
                    isPresets={true}
                    open={openCalendar}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setOpen={setOpenCalendar}
                    placeholder={['Диапозоны', 'даты']}
                  />
                </div>
                <div className={'statistics-page__input statistics-page__input_full'}>
                  <CustomSelect
                    isDisabled={false}
                    error={false}
                    options={nameQuarryOptions}
                    setValue={value => setNameQuarry(value)}
                    placeholder={'По наименованию карьера'}
                    isClearable={true}
                    isSearchable={true}
                    noOptionsMessage={'Нет вариантов'}
                    isMulti
                  />
                </div>
              </div>
            </div>
            {statisticsData.length ? (
              <>
                {isMobile ? (
                  <div className={'statistics-page-list-mobile'}>
                    {statisticsData.slice(0, more).map(item => (
                      <div key={item.id} className={'statistics-page-list-mobile__item'}>
                        {Object.entries(item.content).map(([key, value]) => (
                          <div className={`statistics-page-list-mobile__item-wrapper ${key.length ? '' : 'disabled'}`}>
                            <div className={'statistics-page-list-mobile__title textMedium textMedium_medium'}>
                              {key}
                            </div>
                            <div className={'statistics-page-list-mobile__text textMedium'}>{value}</div>
                          </div>
                        ))}
                        <div className={'statistics-page-list-mobile__button'}>
                          <ButtonTransparent isSvg={false} svg={null} disabled={true} color={''} modifier={''}>
                            Оставить комментарий
                          </ButtonTransparent>
                        </div>
                        <div className={'statistics-page-list-mobile__button'}>
                          <ButtonTransparent isSvg={false} svg={null} disabled={true} color={''} modifier={''}>
                            Отправить ТН
                          </ButtonTransparent>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={'statistics-page-list'}>
                    <div className={'statistics-page-list-left'}>
                      {!isMobile && (
                        <div className={'titles'}>
                          {statisticsPageListTitles.slice(0, 3).map(({ title }, index) => (
                            <div key={index} className={`statistics-page-data-titles__title`}>
                              {title}
                            </div>
                          ))}
                        </div>
                      )}
                      <div className={'wrapper'}>
                        {statisticsData.length ? (
                          statisticsData.slice(0, more).map(({ id, content, active }, index) => (
                            <div key={id} id={String(id)} className={`item ${active ? 'active' : ''}`}>
                              {Object.entries(content)
                                .slice(0, 3)
                                .map(([key, value], index) => (
                                  <div key={index} className={'item__box'}>
                                    {isMobile && <div className={'item__title'}>{key}</div>}
                                    <div
                                      // onClick={({currentTarget}) => menuButton(currentTarget, Boolean(key === ''), id)}
                                      className={`item__text ${key === '' ? 'item__text_button' : ''}`}
                                    >
                                      {value}
                                      {key === '' && !isMobile ? (
                                        <div className={`balloon ${active ? 'active' : ''}`}>
                                          <div
                                            className={'balloon__button'}
                                            onClick={() => popUp('Оставить комментарий')}
                                          >
                                            Оставить комментарий
                                          </div>
                                          <div
                                            className={'balloon__button'}
                                            onClick={() => popUp('Отправка транспортной накладной')}
                                          >
                                            Отправить ТН
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className={'statistics-page-list-right'}>
                      {!isMobile && (
                        <div className={'titles'}>
                          {statisticsPageListTitles.slice(3).map(({ title }, index) => (
                            <div key={index} className={`statistics-page-data-titles__title`}>
                              {title}
                            </div>
                          ))}
                        </div>
                      )}
                      <div className={'wrapper'}>
                        {statisticsData.length ? (
                          statisticsData.slice(0, more).map(({ id, content }, index) => (
                            <div key={id} className={'item'}>
                              {Object.entries(content)
                                .slice(3)
                                .map(([key, value], index) => (
                                  <div key={index} className={'item__box'}>
                                    {isMobile && <div className={'item__title'}>{key}</div>}
                                    <div className={`item__text ${key === '' ? 'item__text_button' : ''}`}>{value}</div>
                                  </div>
                                ))}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {statisticsData?.length >= more ? (
                    <ButtonNotFilled
                        margin={'28px 0 0 0'}
                        onClick={() => setMore(more + 10)}
                        text={'Показать ещё'}
                        width={'100%'}
                        color={'#ff5100'}
                    />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className={'empty-content'}>
                <div className={'empty-content__icon'}>
                  <SearchSvg />
                </div>
                <div className={'empty-content__title'}>Результатов не найдено</div>
                <div className={'empty-content__sub-title'}>
                  Нет статистики по выбранному периоду времени. Пожалуйста, попробуйте другой период.
                </div>
              </div>
            )}
            <div className={popUpActive ? 'statistics-page-popup active' : 'statistics-page-popup'}>
              <div onClick={() => closePopUp(setPopUpActive)} className="statistics-page-popup__white"></div>
              {isMobile && <div className="statistics-page-popup__line"></div>}
              <div className="statistics-page-popup__wrapper">
                <div>
                  <div className="statistics-page-popup__title">
                    Отправка транспортной накладной
                    <div onClick={() => closePopUp(setPopUpActive)} className="statistics-page-popup__close"></div>
                  </div>
                  <div className={'statistics-page-popup__description'}>
                    Обратите внимание, что все поля обязательны для заполнения
                  </div>
                  <div className="statistics-page-popup__content">
                    <div>
                      <div className="statistics-page-popup__sub-title">
                        1. Номер и Дата ТН <sup>*</sup>
                      </div>
                      <div className={'statistics-page-popup__inputs'}>
                        <div className={'statistics-page-popup__input'}>
                          <InputText
                            type={''}
                            disabled={false}
                            placeholder={'Номер ТН'}
                            error={false}
                            secondError={false}
                            textError={''}
                            fill={false}
                            helpText={''}
                            widthHelp={0}
                            margin={isMobile ? '12px' : '0'}
                            value={data.numberTN}
                            setValue={value =>
                              setData(prevState => ({
                                ...prevState,
                                numberTN: value,
                              }))
                            }
                            isLabel={true}
                          />
                        </div>
                        <div className={'statistics-page__input'}>
                          <CalendarInput
                            selectRange={false}
                            range={false}
                            openCalendar={openCalendar2}
                            setOpenCalendar={setOpenCalendar2}
                            calendar={calendar2}
                            setCalendar={setCalendar2}
                            dateRange={dateRange2}
                            setDateRange={setDateRange2}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="statistics-page-popup__sub-title">
                        1. Прикрепление ТН <sup>*</sup>
                      </div>
                      <div className={'statistics-page-popup__image'}>
                        <InputUploading
                          onChangeFunction={({ target }) => loadingImages(setImages, images, target, 2)}
                          text={'загрузить накладную'}
                          subText={'Не более 2 файлов в формате JPG, PNG, GIF или HEIC, до 100 мегабайт'}
                        />
                        {images.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                          <div className="image-container" key={id}>
                            <ImageContainer
                              id={id}
                              value={value}
                              name={name}
                              size={size}
                              isSize={isSize}
                              date={date}
                              isDate={isDate}
                              type={type}
                              openPopUp={() => console.log('')}
                            >
                              <ButtonClose
                                id={id}
                                onClick={({ target }) => removeImage(target, images, setImages)}
                                hidden=""
                              />
                            </ImageContainer>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDevelopment && (
            <div className={'development balloon'}>
              <div className="development__content item">
                <div className="item__svg">
                  <DevelopmentSvg />
                </div>
                <div className="item__text">
                  <div className="item__title">В разработке</div>
                  <div className="item__subTitle">Этот раздел разрабатывается</div>
                </div>
              </div>
            </div>
          )}
        </PageWrapper>
      )}
    </>
  );
};
