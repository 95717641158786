import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import styles from './ShowcasesPage.module.scss';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { useMediaQuery } from 'react-responsive';
import ShowcaseCard from './components/ShowcaseCard/ShowcaseCard';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import LoadingSpin from '../../components/newUI/LoadingSpin/LoadingSpin';
import { dataCardResponseType } from './type';
import { EmptyList } from '../../components/EmptyList';
import { useNavigate } from 'react-router-dom';
import { initStateDataCardResponse } from './constans';

const ShowcasesPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCardResponse, setDataCardResponse] = useState<dataCardResponseType[]>(initStateDataCardResponse);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetchGet(`/supplier/showcases`, {});

      if (response.error) {
        const errorMessages = response.message.map((message: string) => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setLoading(false);
        return;
      }
      setDataCardResponse(response.response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const createShowcase = async () => {
    navigate(`/showcases/create`);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <PageWrapper>
      <div className={styles.titleAndSubtitleAndButtonContainer}>
        <div className={styles.titleAndButtonContainer}>
          <TitleMain title={'Витрины'} />
          {!isMobile && (
            <ButtonFilled
              width={'186px'}
              sizeText={'small'}
              svg={<NewPlusSvg />}
              text={'Добавить витрину'}
              onClick={() => createShowcase()}
            />
          )}
        </div>
        <div className={styles.subTitleContainer}>
          <SubTitleMain subTitle={'Просмотр и добавление витрин для карьеров'} />
        </div>
        {isMobile && (
          <ButtonFilled
            width={'100%'}
            sizeText={'small'}
            svg={<NewPlusSvg />}
            text={'Добавить витрину'}
            onClick={() => createShowcase()}
          />
        )}
      </div>
      {loading ? (
        <LoadingSpin />
      ) : (
        <>
          {dataCardResponse.length ? (
            <ShowcaseCard dataCardResponse={dataCardResponse} />
          ) : (
            <EmptyList
              title={'Витрины отсутствуют'}
              subTitle={'В данный момент у вас нет ни одной витрины. Добавьте данные, чтобы начать работу.'}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default ShowcasesPage;
