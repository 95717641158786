import React from 'react';
import { flightStates } from '../../common/flight-states';

export const CheckPointItem = ({ state, isDriver }) => {

  return (<div className="CheckPoint__list">
    {Object.entries(flightStates).map(([key, value], index) => {
      if(!value.stepTitle) {
        return null;
      }

      let isDone = index <= state.index;

      return (
        <div key={`${key}-item`}
             className={ isDone
              ? 'CheckPointItem done'
              : key === state.nextState
                  ? 'CheckPointItem active'
                  : 'CheckPointItem'
        }>
          <div className={isDone ? 'CheckPointItem__wrapper done' : 'CheckPointItem__wrapper'}>
            <div className="CheckPointItem__circle">{value.stepTitle}</div>
            <div className="CheckPointItem__text">{key}</div>
          </div>
          { value.nextState && <div className={isDone ? 'CheckPointItem__dashed green' : 'CheckPointItem__dashed'}>
            <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="currentColor"/>
              <circle cx="2" cy="12" r="2" fill="currentColor"/>
              <circle cx="2" cy="22" r="2" fill="currentColor"/>
            </svg>
          </div>}
        </div>
      );
    })}
  </div>);
};