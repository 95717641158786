import React from 'react';

export const TatneftLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 146 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4399 8.19954C19.0193 14.7518 14.8137 18.9576 10.6245 23.1702C6.72568 27.4743 3.29139 31.9483 10.1987 40C7.61851 33.4486 11.825 29.242 16.0108 25.0336C19.913 20.7201 23.3473 16.2521 16.4399 8.19954Z" fill="#EF3D42"/>
      <path d="M10.1992 -0.179728C12.7829 6.37516 8.57898 10.5783 4.3863 14.7884C0.48836 19.0959 -2.94507 23.5699 3.96488 31.6216C1.38291 25.065 5.58852 20.8627 9.77689 16.6527C13.6774 12.3443 17.1074 7.87197 10.1992 -0.179728Z" fill="#00B274"/>
      <path d="M116.201 25.7699V39.1626H122.061V34.2598H128.499V31.3283H122.061V28.7013H134.833V39.1626H140.693V28.7013H146.002V25.7699H116.201Z" fill="#00B274"/>
      <path d="M103.629 36.2561V33.9346H111.27V31.0005H103.629V28.7013H113.443V25.7699H97.7695V39.1626H113.564V36.2561H103.629Z" fill="#00B274"/>
      <path d="M41.8271 25.7699H25.5898V28.7013H30.7788V39.1626H36.6382V28.7013H41.8271V25.7699Z" fill="#EF3D42"/>
      <path d="M46.2645 34.0881L48.9758 28.9041L51.5707 34.0881H46.2645ZM53.3968 25.7699H47.116L39.8027 39.1626H43.611L44.8133 36.8634H53.0254L54.2242 39.1626H60.7136L53.3968 25.7699Z" fill="#EF3D42"/>
      <path d="M90.5792 25.7699V32.4654L81.2079 25.7699H76.6602V39.1626H80.671V31.7942L90.2939 39.1626H94.5891V25.7699H90.5792Z" fill="#00B274"/>
      <path d="M74.6703 25.7699H58.7734V28.7013H63.9615V39.1626H69.8218V28.7013H74.6703V25.7699Z" fill="#EF3D42"/>
    </svg>
  );
};