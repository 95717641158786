import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

export const CarsList = ({ list, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div {...props} className="CarsList">
      {list.map(({ stateNumber, carBrand, carType, id }, index) => <Link
          onClick={() => localStorage.setItem('UUIDCar', id)}
          to="/cars/editCar"
          key={index}
          className="CarsList__item">
        <div className="CarsList__text number line">
          {stateNumber ? stateNumber : 'Нет данных'}
        </div>
        {isMobile && <div className="CarsList__line"><span></span></div>}
        <div className="CarsList__text brand line">
          {carBrand?.brand || 'Нет данных'}
        </div>
        <div className="CarsList__text type">
          {carType?.type || 'Нет данных'}
        </div>
      </Link>)}
    </div>
  );
};