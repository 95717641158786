import React from 'react';
import styles from "./TitleAndSubtitle.module.scss";
import TitleMain from "./TitleMain";
import SubTitleMain from "./SubTitleMain";
interface IProps {
    title:string
    subTitle:string
}
const TitleAndSubTitleMain = ({title,subTitle}:IProps) => {
    return (
        <div className={styles.titleAndSubTitleContainer}>
            {title && <TitleMain title={title} />}
            {subTitle && <SubTitleMain subTitle={subTitle} />}
        </div>
    );
};

export default TitleAndSubTitleMain;