import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header/Header';
import { Container } from '../components/Container';
import { useMediaQuery } from 'react-responsive';
import { ButtonClose } from '../components/UI/buttons/buttonClose';
import { Context } from '../context/context';
import { fetchGet } from '../common/proxy-1C-fetch-auth';
import NewAsideNavDriverMobile from '../components/asideNav/NewAsideNavDriverMobile';
import NewAsideNavDriver from '../components/asideNav/NewAsideNavDriver';
import FooterLk from '../components/newUI/FooterLk/FooterLk';

export const LayoutDriver = () => {
  const { openDescription, setOpenDescription, helpTitle, helpDescription } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [cardInfo, setCardInfo] = useState<{
    title: string | null;
    number: number | null;
    limit: number | null;
  }>({
    title: null,
    number: null,
    limit: null,
  });
  const testGetInfo = async () => {
    try {
      const fuelCard = await fetchGet('/fuel_cards', {});

      setCardInfo({
        ...cardInfo,
        title: fuelCard?.response[0]?.company?.title,
        number: fuelCard?.response[0]?.cardNumber,
        limit: fuelCard?.response[0]?.balanceAmount,
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    testGetInfo();
  }, []);

  return (
    <>
      {!isMobile && <Header />}
      {isMobile ? (
        <div id="Page" className="Page">
          <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
            <Outlet />
          </div>
          <NewAsideNavDriverMobile />
          <div
            onClick={() => setOpenDescription(false)}
            className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
          ></div>
          <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
            <ButtonClose
              onClick={() => setOpenDescription(false)}
              hidden={openDescription ? 'active' : 'hidden'}
              style={{ zIndex: '1200', position: 'absolute', top: '6px', right: '20px' }}
            />
            <div className="PopUpHelp__title">{helpTitle}</div>
            <div className="PopUpHelp__description">{helpDescription}</div>
          </div>

          <FooterLk />
        </div>
      ) : (
        <div className="Page">
          <Container>
            <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
              <div className="Aside">
                <NewAsideNavDriver />
                {/* Закомментированно в связи с задачей DVR-24
                <div>
                  <ASideBox padding="">
                    <GsmBanner data={cardInfo} />
                  </ASideBox>
                </div>*/}
              </div>
              {/*<a href='https://project9474365.tilda.ws/driver'>
                <div className="btnHelpSupport">
                  <SupportSvg/>
                </div>
              </a>*/}
              <div className="content">
                <Outlet />
              </div>
            </div>
          </Container>
          <div
            onClick={() => setOpenDescription(false)}
            className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
          ></div>
          <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
            <ButtonClose
              onClick={() => setOpenDescription(false)}
              hidden={openDescription ? 'active' : 'hidden'}
              style={{ zIndex: '1200', position: 'absolute', top: '64px', left: '-112px' }}
            />
            <div className="PopUpHelp__title">{helpTitle}</div>
            <div className="PopUpHelp__description">{helpDescription}</div>
          </div>

          <FooterLk />
        </div>
      )}
    </>
  );
};
