export type StatusTabs = {
  [key: string]: {
    statusName: string;
    active: boolean;
  }
}

export const statuses: StatusTabs = {
  'Активные': {
    statusName: 'active',
    active: true,
  },
  'Выполненные': {
    statusName: 'completed',
    active: false,
  },
  'Проблемные': {
    statusName: 'problematic',
    active: false,
  }
};