import React from 'react';
import { FileSvg } from './UI/svg-icon/FileSvg';

export const ImageContainer = ({
  children,
  PopUp = true,
  id,
  value,
  name,
  size,
  isSize,
  date,
  isDate,
  type,
  openPopUp,
}) => {
  const openFile = type => {
    const pdfWindow = window.open('PDF');
    const title = 'My PDF';
    const URI = 'PDF/PDF';
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${value} type=${type}>
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };

  return (
    <div className="image-container">
      <div className="image-container__wrapper" onClick={() => openPopUp(value)}>
        <div className="image-container__img">
          {type === 'application/pdf' || PopUp === false ? (
            <a target={'_blank'} onClick={() => openFile(type)} rel="noreferrer">
              <FileSvg />
            </a>
          ) : value ? (
            <a target={'_blank'} href={value} rel="noreferrer">
              <FileSvg />
            </a>
          ) : (
            <a>
              <FileSvg />
            </a>
          )}
        </div>
        <div className="image-container__description">
          {type === 'application/pdf' || PopUp === false ? (
            <a target={'_blank'} onClick={() => openFile(type)} rel="noreferrer">
              <div className="image-container__name">{name}</div>
            </a>
          ) : value ? (
            <a target={'_blank'} href={value} rel="noreferrer">
              <div className="image-container__name">{name}</div>
            </a>
          ) : (
            <a>
              <div className="image-container__name">{name}</div>
            </a>
          )}

          <div className="image-container__date">{isSize && <div>{', ' + size + ' Кб'}</div>}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
