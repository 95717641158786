import React from 'react';
import { Progress, Space } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { nomenclatureUnitMapping } from '../common/nomenclature-unit.mapping';

type Props = {
  endNumber: number;
  intermediateNumber: number;
  unit: string;
  startText: string;
  endText: string;
};
export const ProgressElement = ({ startText, endText, endNumber, intermediateNumber, unit }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={'progress-element'}>
      <div className={'progress-element__wrapper'}>
        <div>
          <div className={`progress-element__title done ${isMobile ? 'textSmall' : 'textMedium'}`}>{startText}</div>
          <div className={`progress-element__text done ${isMobile ? 'textH4' : 'textH3'}`}>
            {intermediateNumber}&nbsp;{unit ? nomenclatureUnitMapping[unit] : 'тн'}
          </div>
        </div>
        /
        <div>
          <div
            style={{ textAlign: 'end' }}
            className={`progress-element__title ${intermediateNumber === endNumber ? 'done' : ''} 
                         ${isMobile ? 'textSmall' : 'textMedium'}`}
          >
            {endText}
          </div>
          <div
            style={{ textAlign: 'end' }}
            className={`progress-element__text ${intermediateNumber === endNumber ? 'done' : ''} 
                        ${isMobile ? 'textH4' : 'textH3'}`}
          >
            {endNumber}&nbsp;{unit ? nomenclatureUnitMapping[unit] : 'тн'}
          </div>
        </div>
      </div>
      <Space style={{ width: '100%' }} direction="vertical">
        <Progress percent={Math.round((intermediateNumber / endNumber) * 100)} />
      </Space>
    </div>
  );
};
