export type FlightState = {
  index: number;
  stepTitle: string | null;
  prevState: string | null;
  nextState: string | null;
  requiredInputs?: {
    [key: string]: boolean;
  }
  requiredButton?: boolean;
  transitionConditions?: {
    [key: string]: boolean;
  }
}

export type FlightStates = {
  [status: string]: FlightState
}

export const flightStates: FlightStates = {
  'Запланирован': {
    index: 0,
    stepTitle: null,
    prevState: null,
    nextState: 'Выехал на загрузку',
    requiredButton: false,
    transitionConditions: {
      flightStartDate: true
    }
  },
  'Выехал на загрузку': {
    index: 1,
    stepTitle: '1',
    prevState: 'Запланирован',
    nextState: 'Начал загрузку',
    requiredButton: false,
    transitionConditions: {
      downloadStartDate: true,
    }
  },
  'Начал загрузку': {
    index: 2,
    stepTitle: '2',
    prevState: 'Выехал на загрузку',
    nextState: 'Загрузился',
    requiredButton: false,
    requiredInputs: {
      correctionVolume: true
    },
    transitionConditions: {
      downloadEndDate: true,
      loadingUnit: true,
      loadingVolume: true
    }
  },
  'Загрузился': {
    index: 3,
    stepTitle: '3',
    prevState: 'Начал загрузку',
    nextState: 'Начал выгрузку',
    requiredButton: false,
    transitionConditions: {
      unloadingStartDate: true,
    }
  },
  'Начал выгрузку': {
    index: 4,
    stepTitle: '4',
    prevState: 'Загрузился',
    nextState: 'Выгрузился',
    requiredButton: false,
    requiredInputs: {
      correctionVolume: true
    },
    transitionConditions: {
      unloadingEndDate: true,
      unloadingUnit: true,
      unloadingVolume: true,
    }
  },
  'Выгрузился': {
    index: 5,
    stepTitle: '5',
    prevState: 'Начал выгрузку',
    nextState: 'Выполнен',
    requiredButton: false,
    requiredInputs: {
      unloadingData: true
    },
    transitionConditions: {
      billOfLoadingNumber: true,
      unloadingPhoto: true,
      unloadingDate: true
    }
  },
  'Выполнен': {
    index: 6,
    stepTitle: '6',
    prevState: 'Выгрузился',
    nextState: null,
    requiredButton: true,
    requiredInputs: {
      unloadingData: true
    }
  }
};