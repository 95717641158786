import React from 'react';
import styles from './TitleAndSubtitle.module.scss';
interface IProps {
    subTitle:string
}
const SubTitleMain = ({ subTitle }:IProps) => {
    return (
        <div className={styles.subTitle}>
            {subTitle}
        </div>
    );
};

export default SubTitleMain;