import React from 'react';
import { checkValidation } from '../common/check-validation.function';
import { ImageContainer } from './ImageContainer';
import QuestionOutlineSvgLowerWidth from './UI/svg-icon/QuestionOutlineSvgLowerWidth';
import CustomTooltip from './newUI/CustomTooltip/CustomTooltip';
import { statusColor, statusTextTooltip } from '../Pages/SpecificationPage/utils';
import {useMediaQuery} from "react-responsive";

type Props = {
  item: {
    title: string;
    content: {
      left: {
        [key: string]: string | JSX.Element;
      };
      right: {
        [key: string]: string | JSX.Element;
      };
    };
    nameStatus: string;
    status?: string;
  };
  contracts?: any;
  specifications?: any;
  filesContracts?: any;
  openPopupWithImg?: any;
};
export const InfoItem = ({ item, contracts, specifications, filesContracts, openPopupWithImg }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const leftInfo = {
    'Дата договора': checkValidation(
      contracts?.response?.date ? new Date(contracts?.response?.date).toLocaleString().split(',')[0] : null,
    ),
    'Дата окончания договора': checkValidation(
      contracts?.response?.expiredDate ? new Date(contracts?.response?.expiredDate).toLocaleString().split(',')[0] : null,
    ),
    Организация: checkValidation(contracts?.response?.organization?.title),
    'Количество открытых спецификаций': String(specifications?.length),
    Договор: (
      <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}>
        {filesContracts.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
          <div className="image-container" key={id}>
            <ImageContainer
                id={id}
                value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                name={name}
                size={size}
                isSize={isSize}
                date={date}
                isDate={isDate}
                type={mimeType}
                openPopUp={() =>
                    (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                }
                children={undefined}
            />
          </div>
        ))}
      </div>
    ),
  };
  return (
    <div className={'info-item'}>
      <div style={{ display:'flex',gap:'16px',alignItems:'center',justifyContent:isMobile?'space-between':'normal' }}>
        <div className={'info-item__title'}>
          {item.title}
        </div>
        <p className={statusColor[item?.status]}>
          {item?.status}
          <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'up'}
              svg={<QuestionOutlineSvgLowerWidth />}
              positionTooltipBottom={'22px'}
              text={statusTextTooltip[item?.status]}
              widthTooltip={'fitContent'}
              widthSvg={'16px'}
              styleWindowTooltip={{ whiteSpace:'nowrap' }}
              positionTooltipRight={isMobile && '-13px'}
          />
        </p>
      </div>
      <div className={'info-item__content'}>
        <div className={'info-item__box'}>
          {Object.entries(leftInfo).map(([key, value], index) => (
            <div key={index} className={'info-item__item'}>
              <div>{key}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
        <div className={'info-item__box'}>
          {Object.entries(item.content.right).map(([key, value], index) => (
            <div key={index} className={`info-item__item ${key.trim() ? 'no-key' : ''}`}>
              {key.trim() && <div>{key.trim()}</div>}
              <div className={`${key.trim() ? '' : 'single'}`}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
