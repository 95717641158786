import React from 'react';

type Props = {
    children: any;
}

export const Container = ({ children }: Props) => {
    return (
        <div className="Container">
            {children}
        </div>
    );
};