import React from 'react';

export const DocumentSvgActive = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="text-box-multiple">
              <path id="Vector" d="M16 15H9V13H16M19 11H9V9H19M19 7H9V5H19M21 1H7C5.89 1 5 1.89 5 3V17C5 18.11 5.9 19 7 19H21C22.11 19 23 18.11 23 17V3C23 1.89 22.1 1 21 1ZM3 5V21H19V23H3C2.46957 23 1.96086 22.7893 1.58579 22.4142C1.21071 22.0391 1 21.5304 1 21V5H3Z" fill="currentColor"/>
          </g>
      </svg>
  );
};


/*
<svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 14H8V12H15M18 10H8V8H18M18 6H8V4H18M20 0H6C4.89 0 4 0.89 4 2V16C4 17.11 4.9 18 6 18H20C21.11 18 22 17.11 22 16V2C22 0.89 21.1 0 20 0ZM2 4V20H18V22H2C1.46957 22 0.960859 21.7893 0.585786 21.4142C0.210714 21.0391 0 20.5304 0 20V4H2Z" fill="currentColor"/>
</svg>*/
