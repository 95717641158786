import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageWrapper } from '../../../../../../components/PageWrapper';
import { statusColor } from '../../Registry';
import { ButtonTransparent } from '../../../../../../components/UI/buttons/ButtonTransparent';
import { Button } from '../../../../../../components/UI/buttons/Button';
import { SuccessSvg } from '../../../../../../components/UI/svg-icon/SuccessSvg';
import { CloseSvg } from '../../../../../../components/UI/svg-icon/CloseSvg';
import { AsidePopup } from '../../../../../../components/AsidePopup';
import { ButtonClose } from '../../../../../../components/UI/buttons/buttonClose';
import { fetchGet, fetchPost } from '../../../../../../common/proxy-1C-fetch-auth';
import { formNumber, formPrice } from '../../../../../../common/form-price.helper';
import { closePopUp } from '../../../../../../common/open-close-popup.function';
import { SendCheckSvg } from '../../../../../../components/UI/svg-icon/sendCheckSvg';
import { Notification } from '../../../../../../components/Notification';
import { Line } from '../../../../../../components/UI/Line';
import { IRegister, IStepsMechanism } from '../type';
import { IInitialStateButtonsPick, initialStateOfRegister } from '../constans';
import ButtonFilled from '../../../../../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
export const RegistryDocumentId = () => {
  const [skeleton] = useState<boolean>(false);
  const [rejectedRegistry, setRejectedRegistry] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [register, setRegister] = useState<IRegister>(initialStateOfRegister);
  const [comment, setComment] = useState<string>('');
  const [buttonsPick, setButtonsPick] = useState<IStepsMechanism>(IInitialStateButtonsPick);

  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleChange = event => {
    setComment(event.target.value);
  };
  const sendRegistry = async status => {
    try {
      const registry = await fetchPost(`/files/${params.id}`, 'PATCH', {
        status,
        category: 'registry',
        comment,
      });

      if (registry.error) {
        console.log('error');
      }

      setRejectedRegistry(true);
    } catch (e) {
      console.log(e);
    }
  };

  const getDocuments = async () => {
    try {
      const { response } = await fetchGet(`/registries/${window.location.href.split('/').reverse()[0]}`, {
        category: 'registry',
      });

      setButtonsPick({
        ...buttonsPick,
        status: response.status === 'Подписан',
      });

      setRegister({
        registerAddress: 'Address',
        registerDate: new Date(response.registryDate).toLocaleDateString().split(',')[0],
        registerNumber: response.registryNumber,
        company: response.company,
        volume: response.volume,
        documentAmount: response.documentAmount,
        status: !!response.verifications[0].status.title ? response.verifications[0].status.title : 'На проверке',
        comment: !!response.verifications[0].comment ? response.verifications[0].comment : '',
      });
    } catch (e) {
      console.log(e);
    }
  };

  const notifications = {
    'На проверке': <Notification text={`Реестр ${register.registerNumber} отправлен на проверку.`} color={'grey'} />,
    Отклонен: (
      <Notification
        text={`Реестр ${register.registerNumber} отклонен. Причина: ${!!register.comment ? register.comment : 'не указана'}`}
        color={'red'}
      />
    ),
  };

  useEffect(() => {
    getDocuments().catch(console.log);
  }, []);

  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <div></div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className={'DocumentPage'}>
            <Link className="button-back" to="/document">
              <div className="button-back__svg">
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              К списку документов
            </Link>
            <div className={'DocumentPage__info info'}>
              <div className={'info__title'}>{register.registerNumber}</div>
              <div className={'info__content'}>
                <div className={'info__box'}>
                  <div className={`info__item ${statusColor[register.status]}`}>
                    <span>Статус реестра</span>
                    <div>
                      <p></p>
                      {register.status}
                    </div>
                  </div>
                  <div className={'info__item'}>
                    <div>Номер реестра</div>
                    <div>{register.registerNumber}</div>
                  </div>
                  <div className={'info__item'}>
                    <div>Дата реестра</div>
                    <div>{register.registerDate}</div>
                  </div>
                </div>
                <div className={'info__box'}>
                  <div className={'info__item'}>
                    <div>Контрагент</div>
                    <div>{register.company}</div>
                  </div>
                  <div className={'info__item'}>
                    <div>Объем</div>
                    <div>{formNumber(Number(register.volume))}</div>
                  </div>
                  <div className={'info__item'}>
                    <div>Сумма</div>
                    <div>{formPrice(Number(register.documentAmount), 'RUB')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'DocumentPage__formationOfDocuments formationOfDocuments'}>
              <div className={'formationOfDocuments__title'}>Формирование документов</div>
              <div className={'formationOfDocuments__subTitle'}>
                Сформируйте и скачайте документы для последующей отправки через оператора ЭДО
              </div>
              <div className={`formationOfDocuments__item`}>
                <div
                  className={`formationOfDocumentsBox formationOfDocumentsBox_margin ${buttonsPick.firstStep.fileOpen ? 'active' : ''}`}
                >
                  <div className={'formationOfDocumentsBox__wrapper'}>
                    <div
                      className={`formationOfDocumentsBox__step ${register.status === 'Проверен' && 'formationOfDocumentsBox__step_green'}`}
                    >
                      {register.status === 'Проверен' ? (
                        <span>
                          <SuccessSvg />
                        </span>
                      ) : (
                        1
                      )}
                    </div>
                    <div className={'formationOfDocumentsBox__content'}>
                      <div className={'formationOfDocumentsBox__title'}>Формирование и просмотр реестра</div>
                      <div className={'formationOfDocumentsBox__subTitle'}>
                        Реестр сформирован, ожидайте подтверждения
                      </div>
                    </div>
                  </div>
                  <div className={'formationOfDocumentsBox__wrapper'}>
                    {(register.status === 'На проверке' || register.status === 'Повторная проверка') && (
                        <div style={{ marginRight:isMobile?'0':'16px', marginBottom:isMobile?'16px':'0' }}>
                          <Link to={`/document/registry/registryDocument/edit/${Number(window.location.href.split('/').reverse()[0])}`}>
                            <ButtonNotFilled text={'Редактировать реестр'} width={isMobile?'100%':'180px'} sizeText={'small'}/>
                          </Link>
                        </div>
                    )}
                    <Link to={`/document/registry/registryDocument/${Number(window.location.href.split('/').reverse()[0])}`}>
                      <ButtonFilled text={'Показать реестр'} width={isMobile?'100%':'139px'} sizeText={'small'}/>
                    </Link>
                  </div>
                </div>
                <Line />
                <div className={'formationOfDocumentsBox'}>{notifications[register.status]}</div>
              </div>
            </div>
          </div>
          <AsidePopup open={popup} close={setPopup}>
            {rejectedRegistry ? (
              <div className={'DocumentPage__popup documentPopup documentPopup_success'}>
                <div className={'documentPopup__title'}>
                  <div></div>
                  <ButtonClose onClick={() => setPopup(closePopUp)} hidden={''} />
                </div>
                <div className={'documentPopup__content'}>
                  <div>
                    <div className={'documentPopup__svg'}>
                      <SendCheckSvg />
                    </div>
                    <div className={'documentPopup__title documentPopup__title_small'}>
                      Комментарий успешно отправлен
                    </div>
                    <div className={'documentPopup__subTitle documentPopup__subTitle_small'}>
                      Данному документу будет присвоен статус «Отклонен»
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={'DocumentPage__popup documentPopup'}>
                <div className={'documentPopup__title'}>
                  Комментарий
                  <ButtonClose onClick={() => setPopup(closePopUp)} hidden={''} />
                </div>
                <div className={'documentPopup__subTitle'}>Напишите нам с чем вы не согласны</div>
                <textarea onChange={event => handleChange(event)} placeholder={'Ваш комментарий...'}></textarea>
              </div>
            )}
            <Button
              left={true}
              right={false}
              onClick={() => (rejectedRegistry ? setPopup(closePopUp) : sendRegistry('Отклонен'))}
              modifier={'medium'}
              children={rejectedRegistry ? 'Подтвердить и закрыть' : 'Отклонить реестр'}
              svg={rejectedRegistry ? null : <CloseSvg />}
              colorSvg={''}
              disabled={!comment}
              sending={false}
              color={rejectedRegistry ? '' : 'red'}
            />
          </AsidePopup>
        </PageWrapper>
      )}
    </>
  );
};
