import React, { useContext } from 'react';
import styles from './MobileCard.module.scss';
import { formPrice } from '../../../../common/form-price.helper';
import { Context } from '../../../../context/context';
import { IDataResponsePaymentPaid } from '../../type';
interface IProps {
  data: IDataResponsePaymentPaid[];
}
const MobileCardPaid = ({ data }: IProps) => {
  const { user } = useContext(Context);
  return (
    <>
      {data.map(item => (
        <div className={styles.container}>
          <div className={styles.inlineContainer}>
            <p className={styles.thickText}>№ Рейса: {item?.flightName}</p>
            <p className={styles.normalText}>
              {item?.dateTn ? item?.dateTn?.slice(0, 10).split('-').reverse().join('.') : '—'}
            </p>
          </div>
          <div className={styles.border} />
          {user.companyType !== 'supplier' && (
            <>
              <div className={styles.soloElemContainer}>
                <p className={styles.thickText}>№ТН: {item?.numberTn ? item?.numberTn : '—'}</p>
              </div>
              <div className={styles.border} />
            </>
          )}
          <div className={styles.columnToLeftSideContainer}>
            <div className={styles.inlineContainer} style={{ justifyContent: 'normal' }}>
              {item?.car?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{item?.car?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{item?.car?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>{item?.driver ? item?.driver : '—'}</p>
          </div>
          <div className={styles.border} />
          {user.companyType === 'supplier' && (
            <>
              <div className={styles.columnToLeftSideContainer}>
                <p className={styles.thickText}>{item?.supplier?.title ? item?.supplier?.title : '—'}</p>
                <p className={styles.softText}>
                  {item?.quarry?.address?.address ? item?.quarry?.address?.address : '—'}
                </p>
              </div>
              <div className={styles.border} />
            </>
          )}
          <div className={styles.columnToLeftSideContainer}>
            <div className={styles.inlineContainer} style={{ justifyContent: 'normal' }}>
              {item?.volumeAndUnit?.volume ? (
                <>
                  <p className={styles.thickText}>{item?.volumeAndUnit?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{item?.volumeAndUnit?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>
              {item?.specification?.nomenclatureSpecification?.nomenclature?.nameByDocument
                ? item?.specification?.nomenclatureSpecification?.nomenclature?.nameByDocument
                : '—'}
            </p>
          </div>
          <div className={styles.border} />
          <div className={styles.inlineContainer}>
            <p className={styles.thickText}>Сумма:</p>
            <p className={styles.thickText}>
              {formPrice(
                item?.specification?.nomenclatureSpecification?.total
                  ? item?.specification?.nomenclatureSpecification?.total
                  : '0',
                'RUB',
              )}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default MobileCardPaid;
