import React, { useEffect, useState } from 'react';
import styles from './ShowcaseCreatePage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { PageWrapper } from 'src/components/PageWrapper';
import { toast, ToastContainer } from 'react-toastify';
import { CreateEditButton } from '../components/CreateEditButton';
import { ShowcaseCover } from '../components/ShowcaseCover/ShowcaseCover';
import { ShowcaseDescription } from '../components/ShowcaseDescription/ShowcaseDescription';
import { ShowcaseMainInfo } from '../components/ShowcaseMainInfo/ShowcaseMainInfo';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { fetchGet, fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { clearObject } from 'src/common/ClearObject.helper';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';

export const ShowcaseCreatePage = () => {
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState<string>('');
  const [logoImage, setLogoImage] = useState<string>('');
  const [carrierName, setCarrierName] = useState<string>('');
  const [carrierDescription, setCarrierDescription] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [address, setAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>(undefined);
  const [quarryType, setQuarryType] = useState<string>('');
  const [workRange, setWorkRange] = useState<string>('');
  const [quarry, setQuarry] = useState<string | { value: string; label: string }>('');
  const [quarryOptions, setQuarryOptions] = useState<{ value: string; label: string }[]>([]);
  const [errors, setErrors] = useState<{ carrierName: boolean; quarry: boolean }>({
    carrierName: false,
    quarry: false,
  });
  useEffect(() => {
    getQuarriesNames();
  }, []);

  useEffect(() => {
    if (errors.carrierName && carrierName) setErrors({ ...errors, carrierName: false });
    if (errors.quarry && typeof quarry !== 'string') setErrors({ ...errors, quarry: false });
  }, [carrierName, quarry]);

  useEffect(() => {
    if (typeof quarry !== 'string') getInfoQuarry(quarry?.value);
  }, [quarry]);

  const getQuarriesNames = async () => {
    try {
      const response = await fetchGet('/quarries/list', {});
      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      setQuarryOptions(
        response?.response.map(quarry => {
          return { value: quarry.id, label: quarry.name };
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getInfoQuarry = async (id: string) => {
    try {
      const response = await fetchGet(`/quarries/${id}`, {});
      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      const quarryInfo = response.response;
      setPhoneNumber(quarryInfo?.dispatcher?.phone || 'Нет данных');
      setAddress({
        ...address,
        value: quarryInfo?.address.address,
        data: {
          ...address?.data,
          geo_lat: quarryInfo?.address.latitude.toString(),
          geo_lon: quarryInfo?.address.longitude.toString(),
        },
      });
      setQuarryType(quarryInfo?.quarryType || 'Нет данных');
      setWorkRange(
        quarryInfo?.workSchedulesGraph?.aroundTheClock === true
          ? 'Круглосуточно'
          : quarryInfo?.workSchedulesGraph?.from + '–' + quarryInfo?.workSchedulesGraph?.to,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const checkErrors = () => {
    if (typeof quarry === 'string' || !carrierName) {
      setErrors({ quarry: typeof quarry === 'string', carrierName: !carrierName });
      return;
    }
    createShowcase();
  };

  const createShowcase = async () => {
    try {
      const response = await fetchPost(
        `/supplier/showcases`,
        'POST',
        clearObject({
          title: carrierName,
          description: carrierDescription || null,
          header: coverImage || undefined,
          logo: logoImage || undefined,
          quarryId: typeof quarry !== 'string' ? quarry?.value : undefined,
        }),
      );

      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      toast.success('Витрина создана');
      navigate(`/showcases/create/materials/${response?.response?.id}`);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <PageWrapper>
        <Link className="button-back" to="/showcases">
          <div className="button-back__svg">
            <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>
          Вернуться к списку витрин
        </Link>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title + ' textH4 textH4_medium'}>Создание новой витрины</div>
            <CreateEditButton isCreatePage={true} disabled={false} createShowcase={checkErrors} />
          </div>
          <ShowcaseCover isCreatePage={true} isEdit={true} coverImage={coverImage} setCoverImage={setCoverImage} />
          <ShowcaseDescription
            isCreatePage={true}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            carrierName={carrierName}
            setCarrierName={setCarrierName}
            carrierDescription={carrierDescription}
            setCarrierDescription={setCarrierDescription}
            error={errors.carrierName}
            setError={setErrors}
          />

          <div className={styles.mainInfoContainer}>
            <CustomSelect
              options={quarryOptions}
              setValue={setQuarry}
              placeholder={'Выберите карьер из списка'}
              isClearable={false}
              error={errors.quarry}
              isSearchable={false}
              noOptionsMessage={''}
              disabled={true}
              onBlur={() => {
                setErrors({ ...errors, quarry: false });
              }}
            />
            {quarry !== '' && (
              <div className={styles.mainInfoSubContainer}>
                <div className={'textH5 textH5_medium'}>Основная информация</div>
                <ShowcaseMainInfo
                  phoneNumber={phoneNumber}
                  address={address}
                  quarryType={quarryType}
                  workRange={workRange}
                />
              </div>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
