// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlightPage_titleAndQrContainer__eoncs {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n@media (max-width: 768px) {\n  .FlightPage_titleAndQrContainer__eoncs {\n    flex-direction: column;\n    justify-content: normal;\n    align-items: start;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/FlightPages/FlightPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE;IACE,sBAAA;IACA,uBAAA;IACA,kBAAA;EAEF;AACF","sourcesContent":[".titleAndQrContainer{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n@media (max-width: 768px){\n  .titleAndQrContainer{\n    flex-direction: column;\n    justify-content: normal;\n    align-items: start;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAndQrContainer": "FlightPage_titleAndQrContainer__eoncs"
};
export default ___CSS_LOADER_EXPORT___;
