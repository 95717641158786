import React from 'react';

const QrCodeScannerSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_25712_84859)">
                <path d="M3.66663 10.3333C3.66663 9.70479 3.66663 9.39052 3.86189 9.19526C4.05715 9 4.37142 9 4.99996 9H5.66663C6.29517 9 6.60943 9 6.8047 9.19526C6.99996 9.39052 6.99996 9.70479 6.99996 10.3333V11C6.99996 11.6285 6.99996 11.9428 6.8047 12.1381C6.60943 12.3333 6.29517 12.3333 5.66663 12.3333C4.72382 12.3333 4.25241 12.3333 3.95952 12.0404C3.66663 11.7475 3.66663 11.2761 3.66663 10.3333Z" stroke="#3B519D" stroke-width="1.5"/>
                <path d="M3.66663 5.66675C3.66663 4.72394 3.66663 4.25253 3.95952 3.95964C4.25241 3.66675 4.72382 3.66675 5.66663 3.66675C6.29517 3.66675 6.60943 3.66675 6.8047 3.86201C6.99996 4.05727 6.99996 4.37154 6.99996 5.00008V5.66675C6.99996 6.29529 6.99996 6.60956 6.8047 6.80482C6.60943 7.00008 6.29517 7.00008 5.66663 7.00008H4.99996C4.37142 7.00008 4.05715 7.00008 3.86189 6.80482C3.66663 6.60956 3.66663 6.29529 3.66663 5.66675Z" stroke="#3B519D" stroke-width="1.5"/>
                <path d="M10 10.6667C10 10.3991 10 10.2654 10.0642 10.1693C10.092 10.1277 10.1277 10.092 10.1693 10.0642C10.2654 10 10.3991 10 10.6667 10C10.9342 10 11.0679 10 11.164 10.0642C11.2056 10.092 11.2413 10.1277 11.2691 10.1693C11.3333 10.2654 11.3333 10.3991 11.3333 10.6667C11.3333 10.9342 11.3333 11.0679 11.2691 11.164C11.2413 11.2056 11.2056 11.2413 11.164 11.2691C11.0679 11.3333 10.9342 11.3333 10.6667 11.3333C10.3991 11.3333 10.2654 11.3333 10.1693 11.2691C10.1277 11.2413 10.092 11.2056 10.0642 11.164C10 11.0679 10 10.9342 10 10.6667Z" fill="currentColor"/>
                <path d="M11.3334 9.33341C11.3334 9.0659 11.3334 8.93214 11.3976 8.83605C11.4254 8.79446 11.4611 8.75874 11.5027 8.73095C11.5988 8.66675 11.7325 8.66675 12 8.66675C12.2676 8.66675 12.4013 8.66675 12.4974 8.73095C12.539 8.75874 12.5747 8.79446 12.6025 8.83605C12.6667 8.93214 12.6667 9.0659 12.6667 9.33341C12.6667 9.60093 12.6667 9.73469 12.6025 9.83077C12.5747 9.87237 12.539 9.90809 12.4974 9.93588C12.4013 10.0001 12.2676 10.0001 12 10.0001C11.7325 10.0001 11.5988 10.0001 11.5027 9.93588C11.4611 9.90809 11.4254 9.87237 11.3976 9.83077C11.3334 9.73469 11.3334 9.60093 11.3334 9.33341Z" fill="currentColor"/>
                <path d="M8.66663 11.9999C8.66663 11.7324 8.66663 11.5986 8.73083 11.5026C8.75862 11.461 8.79434 11.4252 8.83593 11.3975C8.93202 11.3333 9.06578 11.3333 9.33329 11.3333C9.60081 11.3333 9.73457 11.3333 9.83065 11.3975C9.87225 11.4252 9.90796 11.461 9.93576 11.5026C9.99996 11.5986 9.99996 11.7324 9.99996 11.9999C9.99996 12.2674 9.99996 12.4012 9.93576 12.4973C9.90796 12.5389 9.87225 12.5746 9.83065 12.6024C9.73457 12.6666 9.60081 12.6666 9.33329 12.6666C9.06578 12.6666 8.93202 12.6666 8.83593 12.6024C8.79434 12.5746 8.75862 12.5389 8.73083 12.4973C8.66663 12.4012 8.66663 12.2674 8.66663 11.9999Z" fill="currentColor"/>
                <path d="M8.66663 9.33341C8.66663 9.0659 8.66663 8.93214 8.73083 8.83605C8.75862 8.79446 8.79434 8.75874 8.83593 8.73095C8.93202 8.66675 9.06578 8.66675 9.33329 8.66675C9.60081 8.66675 9.73457 8.66675 9.83065 8.73095C9.87225 8.75874 9.90796 8.79446 9.93576 8.83605C9.99996 8.93214 9.99996 9.0659 9.99996 9.33341C9.99996 9.60093 9.99996 9.73469 9.93576 9.83077C9.90796 9.87237 9.87225 9.90809 9.83065 9.93588C9.73457 10.0001 9.60081 10.0001 9.33329 10.0001C9.06578 10.0001 8.93202 10.0001 8.83593 9.93588C8.79434 9.90809 8.75862 9.87237 8.73083 9.83077C8.66663 9.73469 8.66663 9.60093 8.66663 9.33341Z" fill="currentColor"/>
                <path d="M9 5.00008C9 4.37154 9 4.05727 9.19526 3.86201C9.39052 3.66675 9.70479 3.66675 10.3333 3.66675C11.2761 3.66675 11.7475 3.66675 12.0404 3.95964C12.3333 4.25253 12.3333 4.72394 12.3333 5.66675C12.3333 6.29529 12.3333 6.60956 12.1381 6.80482C11.9428 7.00008 11.6285 7.00008 11 7.00008H10.3333C9.70479 7.00008 9.39052 7.00008 9.19526 6.80482C9 6.60956 9 6.29529 9 5.66675V5.00008Z" stroke="#3B519D" stroke-width="1.5"/>
                <path d="M14.6667 9.33325C14.6667 11.8474 14.6667 13.1045 13.8857 13.8855C13.1046 14.6666 11.8475 14.6666 9.33337 14.6666" stroke="#3B519D" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M6.66671 14.6666C4.15255 14.6666 2.89547 14.6666 2.11442 13.8855C1.33337 13.1045 1.33337 11.8474 1.33337 9.33325" stroke="#3B519D" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M6.66671 1.33325C4.15255 1.33325 2.89547 1.33325 2.11442 2.1143C1.33337 2.89535 1.33337 4.15243 1.33337 6.66659" stroke="#3B519D" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M9.33337 1.33325C11.8475 1.33325 13.1046 1.33325 13.8857 2.1143C14.6667 2.89535 14.6667 4.15243 14.6667 6.66658" stroke="#3B519D" stroke-width="1.5" stroke-linecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0_25712_84859">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default QrCodeScannerSvg;