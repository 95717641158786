import React, { useContext, useRef, useState } from 'react';
import { ShipmentsIconSvg } from '../UI/svg-icon/ShipmentsIconSvg';
import { ShipmentsIconSvgActive } from '../UI/svg-icon/ShipmentsIconSvgActive';
import { ShipmentsIconSvgError } from '../UI/svg-icon/ShipmentsIconSvgError';
import { WalletSvg } from '../UI/svg-icon/WalletSvg';
import { WalletSvgActive } from '../UI/svg-icon/WalletSvgActive';
import { FileSvg } from '../UI/svg-icon/FileSvg';
import { FileSvgActive } from '../UI/svg-icon/FileSvgActive';
import { DocumentSvgActive } from '../UI/svg-icon/DocumentSvgActive';
import { MapMarkerMultipleSvg } from '../UI/svg-icon/MapMarkerMultipleSvg';
import { MapMarkerMultipleSvgActive } from '../UI/svg-icon/MapMarkerMultipleSvgActive';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import BurgerMenuSvg from '../UI/svg-icon/BurgerMenuSvg';
import { NavLink } from 'react-router-dom';
import { logout } from '../../common/logout.mapping';
import { Context } from '../../context/context';
import NewNavItemMobile from '../newUI/NewNavItem/NewNavItemMobile';
import Notices from '../../layouts/Notices/Notices';
import useClickOutside from '../../CustomHooks/useClickOutside';
import EmployeesSvg from '../UI/svg-icon/EmployeesSvg';
import EmployeesActiveSvg from '../UI/svg-icon/EmployeesActiveSvg';

const fullAccess = ['admin', 'manager', 'supplier', 'accountant'];
export const supplierNavLink = [
  {
    name: 'Отгрузки',
    link: '/shipments',
    type: ['manager', 'supplier', 'admin'],
    notActiveProfile: false,
    svg: <ShipmentsIconSvg />,
    svgActive: <ShipmentsIconSvgActive />,
    svgProfileError: <ShipmentsIconSvgError />,
    errorProfile: true,
  },
  {
    name: 'Взаиморасчеты',
    link: '/supplierPayment',
    type: ['accountant', 'supplier', 'admin'],
    notActiveProfile: false,
    disable: true,
    svg: <WalletSvg />,
    svgActive: <WalletSvgActive />,
    svgProfileError: null,
    errorProfile: false,
  },
  {
    name: 'Документы',
    link: '/document',
    type: fullAccess,
    notActiveProfile: false,
    svg: <FileSvg />,
    svgActive: <FileSvgActive />,
    svgProfileError: <DocumentSvgActive />,
    errorProfile: false,
  },
  {
    name: 'Карьеры',
    link: '/quarries',
    notActiveProfile: false,
    type: ['manager', 'supplier', 'admin'],
    svg: <MapMarkerMultipleSvg />,
    svgActive: <MapMarkerMultipleSvgActive />,
    svgProfileError: <ProfileErrorSvg />,
    errorProfile: true,
  },
  {
    name: 'Сотрудники',
    link: '/employees',
    type: ['supplier'],
    notActiveProfile: false,
    disable: true,
    svg: <EmployeesSvg />,
    svgActive: <EmployeesActiveSvg />,
    svgProfileError: null,
    errorProfile: false,
  },
  {
    name: 'Профиль',
    link: '/profile',
    type: fullAccess,
    notActiveProfile: false,
    svg: <ProfileSvg />,
    svgActive: <ProfileSvgActive />,
    svgProfileError: <ProfileErrorSvg />,
    errorProfile: true,
  },
  {
    name: 'Помощь',
    link: '/help',
    type: fullAccess,
    notActiveProfile: false,
    svg: <HelpSvg />,
    svgActive: <HelpSvgActive />,
    svgProfileError: null,
  },
];
const NewAsideNavSupplierMobile = () => {
  const { user, setUser } = useContext(Context);
  const [open, setOpen] = useState(false);
  const clickRef = useRef();
  useClickOutside(clickRef, setOpen);

  return (
    <div>
      <div className={styles.burgerMobile}>
        <div className={styles.burgerMobileContainer}>
          <div onClick={() => setOpen(!open)} ref={clickRef}>
            <BurgerMenuSvg />
          </div>
          <div style={{ display: 'flex' }}>
            {user.type === 'supplier' && (
              <div style={{ marginRight: '32px' }}>
                <Notices />
              </div>
            )}

            <NavLink to="/">
              <div className={styles.exitContainer} onClick={() => logout(user, setUser)}>
                <p
                  style={{
                    marginRight: '8px',
                  }}
                >
                  Выйти
                </p>
                <svg
                  className="ButtonExit__svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0H2C0.89 0 0 0.89 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM7.08 12.58L8.5 14L13.5 9L8.5 4L7.08 5.41L9.67 8H0V10H9.67L7.08 12.58Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      {open && (
        <div className={styles.AsideBoxMobile}>
          <div className={styles.AsideBoxMobileContainer}>
            {supplierNavLink.map(
              ({ name, link, type, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => (
                <NewNavItemMobile
                  typeAccess={type}
                  onClick={() => setOpen(false)}
                  disabled={null}
                  key={name}
                  name={name}
                  link={link}
                  notActiveProfile={notActiveProfile}
                  svg={svg}
                  svgActive={svgActive}
                  svgProfileError={svgProfileError}
                  errorProfile={errorProfile}
                />
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAsideNavSupplierMobile;
