import React from 'react';

export const FlightSvgActive = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="road">
              <path id="Vector" d="M11 16H13V20H11M11 10H13V14H11M11 4H13V8H11M4 22H20V2H4V22Z" fill="currentColor"/>
          </g>
      </svg>
  );
};


/*
<svg width="100%" height="100%" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14H9V18H7M7 8H9V12H7M7 2H9V6H7M0 20H16V0H0V20Z" fill="currentColor"/>
</svg>*/
