import React from 'react';
import FooterLogoSvg from '../../../components/UI/svg-icon/FooterLogoSvg';
import styles from './FooterAuth.module.scss';
import VkLogoSvg from '../../../components/UI/svg-icon/VkLogoSvg';
import OdnoklassSvg from '../../../components/UI/svg-icon/OdnoklassSvg';
import YouTubeFullColorIconSvg from '../../../components/UI/svg-icon/YouTubeFullColorIconSvg';
import FrameSvg from '../../../components/UI/svg-icon/FrameSvg';
import { useNavigate } from 'react-router-dom';
import FooterLogoTextSvg from '../../../components/UI/svg-icon/FooterLogoTextSvg';

const FooterAuth = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContentContainer}>
        <div className={styles.firstContainerFooter}>
          <div className={styles.logoContainer} >
            <FooterLogoSvg />
            <FooterLogoTextSvg />
          </div>
        </div>
        <div className={styles.secondContainerFooter}>
          <div className={styles.secondContainerContentFooter}>
            <div className={styles.stringSecondContainer}>
              <div>
                <a
                  style={{ color: '#9BA1B4', textDecoration: 'underline' }}
                  target={'_blank'}
                  href="https://solber.ru/"
                >
                  solber.ru
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.firstContainerFooter} style={{ width: '80%', marginTop: '16px' }}>
        <div className={styles.bottomContainer}>
          <p
            onClick={() => {
              navigate('/privacyPolicy');
            }}
            style={{ cursor: 'pointer' }}
            className={styles.secondContainerFooter}
          >
            Политика конфиденциальности
          </p>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/userAgreement');
            }}
            className={styles.secondContainerFooter}
          >
            Пользовательское соглашение
          </p>
        </div>
        <div className={styles.thirdStringFirstContainer}>© 2024 ООО НТК «СОЛБЕР».Все права защищены.</div>
      </div>
    </div>
  );
};

export default FooterAuth;
