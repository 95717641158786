import React from 'react';

export const ErrorText = ({ empty, isError, correctText, emptyText, ...props }) => {
    return (
        <div {...props} className={isError ? 'ErrorText active' : 'ErrorText'}>
            {empty
                ? emptyText
                : correctText
            }
        </div>
    );
};