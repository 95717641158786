import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SkeletonFlight = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className="SkeletonFlight">
            <div className="SkeletonFlight__sides">
                <div className="SkeletonFlight__box">
                    <div className="SkeletonFlight__back">
                        <div></div>
                        <span></span>
                    </div>
                    <div className="SkeletonFlight__flightNumber"></div>
                    <div className="SkeletonFlight__list">
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>
                            <div className="SkeletonFlight-checkPoint__points">
                                <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="12" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="22" r="2" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>
                            <div className="SkeletonFlight-checkPoint__points">
                                <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="12" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="22" r="2" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>
                            <div className="SkeletonFlight-checkPoint__points">
                                <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="12" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="22" r="2" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>
                            <div className="SkeletonFlight-checkPoint__points">
                                <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="12" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="22" r="2" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>
                            <div className="SkeletonFlight-checkPoint__points">
                                <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="12" r="2" fill="currentColor"/>
                                    <circle cx="2" cy="22" r="2" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className="SkeletonFlight__item">
                            <div className="SkeletonFlight-checkPoint">
                                <div className="SkeletonFlight-checkPoint__circle"></div>
                                <div className="SkeletonFlight-checkPoint__title"></div>
                            </div>

                        </div>
                    </div>
                    <div className="SkeletonFlight__button"></div>
                    <div className="SkeletonFlight__button"></div>
                </div>
            </div>
            {isMobile && <div className="SkeletonFlight__line"></div>}
            <div className="SkeletonFlight__sides">
                <div className="SkeletonFlight__box">
                    <div className="SkeletonFlight__subTitle"></div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__button"></div>
                    <div className="SkeletonFlight__line"></div>
                    <div className="SkeletonFlight__subTitle"></div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__line"></div>
                    <div className="SkeletonFlight__subTitle"></div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                    <div className="SkeletonFlight__itemBox">
                        <div className="SkeletonFlight__itemName"></div>
                        <div className="SkeletonFlight__itemDescription"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};