import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import useClickOutside from '../../../CustomHooks/useClickOutside';
import { WarningEmptySvg } from '../svg-icon/WarningEmptySvg';
import CustomTooltip from '../../newUI/CustomTooltip/CustomTooltip';
import { CloseCircleSvg } from '../svg-icon/CloseCircleSvg';
import {CloseCircleNewSvg} from "../svg-icon/CloseCircleNewSvg";

export const MyPhoneInput = ({
  value,
  disabled,
  onChange,
  isActive,
  btnHelp,
  setIsActive,
  isError,
  setIsError,
  isFunction,
  textError = '',
  onEnterDisabled = false,
  builtInErrorState = false,
  ...props
}) => {
  const [error, setError] = useState<boolean>(value?.length < 11);
  const onChangeErrorByBuiltInErrorState = builtInErrorState ? error : isError;
  const onChangeSetErrorByBuiltInErrorState = builtInErrorState ? setError : setIsError;
  useEffect(() => {
    if (value === null) {
      return;
    }

    if (value?.length === 1) {
      onChange('79');
    }
  }, [value]);

  const clickRef = useRef();
  useClickOutside(clickRef, setIsActive);

  const onEnterKeySendFunction = e => {
    if (!onEnterDisabled) {
      isFunction(e);
      return;
    }
    return;
  };
  return (
    <div {...props} className="MyPhoneInput form-item">
      <PhoneInput
        onBlur={() => onChangeSetErrorByBuiltInErrorState(value?.length >= 0 && value?.length <= 10)}
        onFocus={() => onChangeSetErrorByBuiltInErrorState(false)}
        disabled={disabled}
        specialLabel=""
        placeholder=""
        value={value}
        onChange={onChange}
        inputClass={`MyPhoneInput__input form-input ${onChangeErrorByBuiltInErrorState && 'error'} ${disabled && 'disabled'}`}
        onEnterKeyPress={e => onEnterKeySendFunction(e)}
      />
      {builtInErrorState && error && (
        <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>
      )}
      {
        <label
          className={
            onChangeErrorByBuiltInErrorState
              ? value
                ? 'InputText__label form-label valid error'
                : 'InputText__label form-label error'
              : value
                ? 'InputText__label form-label valid'
                : 'InputText__label form-label'
          }
        >
          Номер телефона
        </label>
      }
      {btnHelp ? (
        value?.length === 11 ? (
          <div onClick={() => onChange('')} className="MyPhoneInput__help">
            <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'left'}
              svg={<CloseCircleNewSvg />}
              text={''}
              widthSvg={'100%'}
              widthTooltip={'311px'}
            />
          </div>
        ) : (
          <div
            ref={clickRef}
            onClick={() => onChange('')}
            className={onChangeErrorByBuiltInErrorState ? 'MyPhoneInput__help error' : 'MyPhoneInput__help'}
          >
            <CustomTooltip
                MobileSideTooltip={'left'}
                SideTooltip={'left'}
                svg={<CloseCircleNewSvg />}
                text={''}
                widthSvg={'100%'}
                widthTooltip={'311px'}
            />
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};
