import React, { useEffect, useState } from 'react';
import styles from './AddPlanModal.module.scss';
import { Drawer, Form, Input, Modal, Select, Space } from 'antd';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import { formVolume } from '../../Constans/Constans';
import SuccessVer2Svg from 'src/components/UI/svg-icon/SuccessVer2Svg';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { declinationWord } from '../../../../common/declinationWord.helper';
import { closePopUp } from '../../../../common/use-popup.functiopn';
import { useMediaQuery } from 'react-responsive';

const EditPlanModal = ({ setOpenEditPlan, openEditPlan,infoData, planData, getData, carsList, driversList }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [form] = Form.useForm();
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const openRefuseModal = () => {
    setIsRefuseModalOpen(true);
  };

  const unit = infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit;
  const closeRefuseModal = () => {
    setIsRefuseModalOpen(false);
  };
  const closeEditModal = () => {
    setOpenEditPlan(false);
    closePopUp(setOpenEditPlan);
  };
  const closeAllModalAndDrawer = () => {
    setIsRefuseModalOpen(false);
    setOpenEditPlan(false);
  };
  useEffect(() => {
    const rooDiv = document.getElementById('root');
    document.body.classList.add('off');
    if (isRefuseModalOpen || openEditPlan) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      document.body.classList.remove('off');
      rooDiv.style.overflow = 'unset';
    }
  }, [openEditPlan, isRefuseModalOpen]);
  useEffect(() => {
    form.setFieldsValue({
      driverId: planData?.driverId,
      carId: planData?.carId,
      flightsNumber: planData?.flightsNumber,
      flightPlanVolume: planData?.flightPlanVolume,
      flightsSumVolume: planData?.flightsSumVolume,
    });
    setDisabledSaveBtn(true);
  }, [openEditPlan]);

  const location = useLocation();
  const deletePlanningFlights = async () => {
    try {
      const planningFlights = await fetchPost(
        `/dispatching/remove-flights-from-plan/${location.pathname.split('/')[2]}`,
        'DELETE',
        {
          driverId: planData?.driverId,
          carId: planData?.carId,
          flightsNumber: planData?.flightsNumber,
          flightPlanVolume: planData?.flightPlanVolume,
        },
      );
      if (planningFlights.error) {
        const errorMessages = planningFlights.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        closeAllModalAndDrawer();
        return;
      }
      closeAllModalAndDrawer();
      getData();
      toast.success(`Вы успешно удалили ${planData?.flightsNumber === 1 ? 'рейс' : 'рейсы'}`);
    } catch (e) {
      closeAllModalAndDrawer();
      console.log(e);
    }
  };
  const onFinish = async (values: any) => {
    try {
      const postEditPlanning = await fetchPost(
        `/dispatching/update-flight-details/${location.pathname.split('/')[2]}`,
        'PATCH',
        {
          oldFlightsDetails: {
            driverId: planData?.driverId,
            carId: planData?.carId,
            flightsNumber: planData?.flightsNumber,
            flightPlanVolume: planData?.flightPlanVolume,
          },
          newFlightsDetails: {
            driverId: values?.driverId,
            carId: values?.carId,
            flightsNumber: Number(values?.flightsNumber),
            flightPlanVolume: Number(values?.flightPlanVolume),
          },
        },
      );
      if (postEditPlanning.error) {
        const errorMessages = postEditPlanning.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        closeAllModalAndDrawer();
        return;
      }
      getData();
      closeAllModalAndDrawer();
      toast.success(`Вы успешно отредактировали планирование`);
    } catch (e) {
      closeAllModalAndDrawer();
      console.log(e);
    }
  };
  const correctVisionOfUnit = (unit: string): string => {
    if (unit === 'тонн') {
      return 'тн';
    } else {
      return 'м³';
    }
  };

  return (
    <>
      {isMobile ? (
        /*-------------Мобильный версия-----------------------------*/
        <>
          <div className={openEditPlan ? 'DriverPagePopup active' : 'DriverPagePopup'}>
            <div onClick={() => closeEditModal()} className="DriverPagePopup__white"></div>
            <div className="DriverPagePopup__line"></div>
            <div className="DriverPagePopup__wrapper" style={{ height: '100%' }}>
              <div className={styles.mobileContainerDrawer}>
                <div className={styles.mobileTitleAndSubtitleContainer}>
                  <div className={styles.drawerTriangleContainer}>
                    <div className={styles.drawerTriangle} />
                  </div>
                  <div className={styles.mobileIconAndTitleContainer}>
                    <div className={styles.mobileTitle}>Редактировать планирование</div>
                    <div className={styles.mobileSubtitle}>
                      Распределение объема по дате, ТС, количеству рейсов и назначение водителя на заявку
                    </div>
                  </div>
                  <div className={styles.mobileCloseIconDrawer} onClick={closeEditModal}>
                    <div className={styles.mobileCloseIconBlockDrawer}>
                      <CloseSvg />
                    </div>
                  </div>
                </div>
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  onFieldsChange={(changedFields, allFields) => {
                    setDisabledSaveBtn(false);
                    form.setFieldValue('flightsSumVolume', allFields[2].value * Number(allFields[3].value));
                    form.setFieldValue('flightPlanVolume', unit === 'тонн' ? Math.ceil(carsList.find(item => item.value === allFields[1].value).volumeBody*1.45)
                        :carsList.find(item => item.value === allFields[1].value).volumeBody);
                  }}
                  style={{ width: `100%` }}
                  autoComplete="off"
                  form={form}
                >
                  <div className={styles.mobileTableContainer}>
                    <div className={styles.mobileTableContent}>
                      <div style={{ marginBottom: '8px' }}>
                        <p className={styles.mobileTextTitleOfInput}>Водитель:</p>
                      </div>
                      <Form.Item
                        style={{ marginBottom: '12px' }}
                        name={'driverId'}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Select placeholder={'Водитель'} style={{ width: '100%' }} options={driversList} />
                      </Form.Item>
                      <div style={{ marginBottom: '8px' }}>
                        <p className={styles.mobileTextTitleOfInput}>Тс:</p>
                      </div>
                      <Form.Item
                        style={{ marginBottom: '12px' }}
                        name={'carId'}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Select placeholder={'ТС'} style={{ width: '100%' }} options={carsList} />
                      </Form.Item>
                      <div style={{ marginBottom: '8px' }}>
                        <p className={styles.mobileTextTitleOfInput}>Объем материала:</p>
                      </div>
                      <Form.Item
                        style={{ marginBottom: '12px' }}
                        name={'flightPlanVolume'}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Input disabled={true} placeholder="0" className={styles.mobileInput} />
                      </Form.Item>
                      <div style={{ marginBottom: '8px' }}>
                        <p className={styles.mobileTextTitleOfInput}>Кол-во рейсов:</p>
                      </div>
                      <Form.Item
                        style={{ marginBottom: '12px' }}
                        name={'flightsNumber'}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Input
                          placeholder="Кол-во рейсов"
                          className={styles.mobileInput}
                        />
                      </Form.Item>
                      <div style={{ marginBottom: '8px' }}>
                        <p className={styles.mobileTextTitleOfInput}>Общий объем</p>
                      </div>
                      <Form.Item
                        style={{ marginBottom: '0px' }}
                        name={'flightsSumVolume'}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Input
                          suffix={correctVisionOfUnit(unit)}
                          disabled={true}
                          placeholder="0"
                          style={{ color: 'black' }}
                          className={[styles.allVolumeOfFlight, styles.mobileInput].join(' ')}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item>
                    <div className={styles.mobileBottomButtons}>
                      <ButtonFilled
                        width={`100%`}
                        disabled={disabledSaveBtn}
                        text={'Сохранить'}
                        htmlType={'submit'}
                      />
                      <ButtonNotFilled htmlType={'button'} width={`100%`} onClick={openRefuseModal} text={'Отказаться'} />
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          <Drawer
            onClose={closeRefuseModal}
            open={isRefuseModalOpen}
            placement={`bottom`}
            closeIcon={false}
            zIndex={10001}
            height={'90%'}
            style={{ borderRadius: '12px 12px 0px 0px' }}
          >
            <div style={{ justifyContent: 'space-between' }} className={styles.mobileContainerDrawer}>
              <div style={{ marginBottom: '24px' }} className={styles.mobileIconContainer}>
                <div className={styles.drawerTriangleContainerSecond}>
                  <div className={styles.drawerTriangleSecond} />
                </div>
                <div className={styles.mobileCloseIconDrawer} onClick={closeRefuseModal}>
                  <CloseSvg />
                </div>
              </div>
              <div style={{ marginBottom: '24px' }} className={styles.containerDrawerOfRefuseConfirm}>
                <div className={styles.drawerSvgIconContainer} style={{ marginBottom: '24px' }}>
                  <SuccessVer2Svg />
                </div>
                <div style={{ marginBottom: '8px' }}>
                  <p className={styles.firstDrawerMainTextOfRefuseConfirm}>
                    Вы уверены, что хотите отказаться от этого
                    <br />
                    объема?
                  </p>
                </div>
                <div>
                  <p className={styles.secondDrawerMainTextOfRefuseConfirm}>
                    {declinationWord(planData?.flightsNumber, [
                      'C вас будет снят',
                      'C вас будут сняты',
                      'C вас будут сняты',
                    ])}
                    &nbsp;
                    <span className={styles.secondDrawerStrongMainTextOfRefuseConfirm}>
                      {planData?.flightsNumber}&nbsp;
                      {declinationWord(planData?.flightsNumber, ['рейс', 'рейса', 'рейсов'])}
                    </span>{' '}
                    общим объемом перевозки&nbsp;
                    <span className={styles.secondDrawerStrongMainTextOfRefuseConfirm}>
                      {formVolume(planData?.flightsSumVolume)}&nbsp;
                      {planData?.volumeOfMaterial?.unit}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.bottomDrawerButtonsOfRefuseConfirm}>
                <ButtonFilled width={`100%`} text={'Да, хочу отказаться'} onClick={deletePlanningFlights} />
                <ButtonNotFilled width={`100%`} onClick={closeRefuseModal} text={'Нет, хочу оставить'} />
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        /*-------------ПК версия-----------------------------*/
        <>
          <Modal
            zIndex={9500}
            style={{ minWidth: '1102px' }}
            width={1102}
            footer={null}
            closeIcon={false}
            open={openEditPlan}
            onCancel={closeEditModal}
          >
            <div className={styles.titleAndSubtitleContainer}>
              <div className={styles.title}>Редактировать планирование</div>
              <div className={styles.subtitle}>
                Распределение объема по дате, ТС, количеству рейсов и назначение водителя на заявку
              </div>
            </div>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              onFieldsChange={(changedFields, allFields) => {
                setDisabledSaveBtn(false);
                form.setFieldValue('flightsSumVolume', allFields[2].value * Number(allFields[3].value));
                form.setFieldValue('flightPlanVolume', unit === 'тонн' ? Math.ceil(carsList.find(item => item.value === allFields[1].value).volumeBody*1.45)
                    :carsList.find(item => item.value === allFields[1].value).volumeBody);
              }}
              style={{ width: 1054 }}
              autoComplete="off"
              form={form}
            >
              <div className={styles.tableContainer}>
                <div className={styles.mainNamesOfTableContainer}>
                  <p style={{ marginRight: '235px' }}>Водитель</p>
                  <p style={{ marginRight: '192px' }}>Назначенное ТС</p>
                  <p style={{ marginRight: '70px' }}>Объем на рейс</p>
                  <p style={{ marginRight: '73px' }}>Кол-во рейсов</p>
                  <p>Общий объем</p>
                </div>
                <Space className={styles.tableContent}>
                  <Form.Item
                    style={{ marginBottom: '0px' }}
                    name={'driverId'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Select placeholder={'Водитель'} style={{ width: 261 }} options={driversList} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: '0px' }} name={'carId'} rules={[{ required: true, message: '' }]}>
                    <Select placeholder={'ТС'} style={{ width: 261 }} options={carsList} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: '0px' }}
                    name={'flightPlanVolume'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input disabled={true} placeholder="0" className={styles.modalInputText} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: '0px' }}
                    name={'flightsNumber'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input placeholder="Кол-во рейсов" className={styles.modalInputText} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: '0px' }}
                    name={'flightsSumVolume'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input
                      suffix={correctVisionOfUnit(unit)}
                      disabled={true}
                      placeholder="0"
                      className={[styles.allVolumeOfFlight, styles.modalInputText, styles.volumeText].join(' ')}
                    />
                  </Form.Item>
                </Space>
              </div>
              <Form.Item>
                <div className={styles.bottomButtons}>
                  <ButtonNotFilled htmlType={'button'} onClick={openRefuseModal} text={'Отказаться'} />
                  <ButtonFilled text={'Сохранить'} disabled={disabledSaveBtn} htmlType={'submit'} />
                </div>
              </Form.Item>
            </Form>
            <div className={styles.closeButton}>
              <FrameModalSvg onClick={closeEditModal} />
            </div>
          </Modal>
          <Modal
            zIndex={10001}
            style={{ minWidth: '1102px' }}
            width={1102}
            footer={null}
            closeIcon={false}
            open={isRefuseModalOpen}
            onCancel={closeRefuseModal}
          >
            <div className={styles.containerOfRefuseConfirm}>
              <div style={{ marginBottom: '12px' }}>
                <SuccessVer2Svg />
              </div>
              <div style={{ marginBottom: '16px' }}>
                <p className={styles.firstMainTextOfRefuseConfirm}>
                  Вы уверены, что хотите отказаться от этого
                  <br />
                  объема?
                </p>
              </div>
              <div>
                <p className={styles.secondMainTextOfRefuseConfirm}>
                  {declinationWord(planData?.flightsNumber, [
                    'C вас будет снят',
                    'C вас будут сняты',
                    'C вас будут сняты',
                  ])}
                  &nbsp;
                  {planData?.flightsNumber} {declinationWord(planData?.flightsNumber, ['рейс', 'рейса', 'рейсов'])}{' '}
                  общим объемом перевозки&nbsp;
                  {formVolume(planData?.flightsSumVolume)} {planData?.volumeOfMaterial?.unit}
                </p>
              </div>
            </div>
            <div className={styles.bottomButtonsOfRefuseConfirm}>
              <ButtonNotFilled onClick={closeRefuseModal} text={'Нет, хочу оставить'} />
              <ButtonFilled text={'Да, хочу отказаться'} onClick={deletePlanningFlights} />
            </div>
            <div className={styles.closeButton}>
              <FrameModalSvg onClick={closeRefuseModal} />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditPlanModal;
