import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { QuestionSvg } from './UI/svg-icon/QuestionSvg';
import useClickOutside from '../CustomHooks/useClickOutside';

type Props = {
  items: any[];
  gridTemplateColumns: string;
  gridTemplateColumnsLinks?: string;
  link: string;
  orderTab?: string;
  help?: boolean;
  arrow?: any;
  contentHelp?: any;
  popup?: boolean;
  setPopup?: any;
  isFunction?: boolean;
  func?: Function;
};
export const Frame = ({
  items,
  gridTemplateColumns,
  gridTemplateColumnsLinks,
  link,
  orderTab,
  help,
  arrow,
  contentHelp,
  popup,
  setPopup,
  isFunction,
  func,
}: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [balloon, setBalloon] = useState(false);

  const clickRef = useRef();
  useClickOutside(clickRef, setBalloon);

  return (
    <>
      {items?.length ? (
        <div className={'frame'}>
          {!isMobile && (
            <div className={'frame__titles'} style={{ gridTemplateColumns: isMobile ? '100%' : gridTemplateColumns }}>
              {Object.entries(items[0]?.item).map(([title, content]: any, index) => (
                <div key={index + 'g'} className={'frame__title textMedium'}>
                  {title}
                  {content?.help && (
                    <div>
                      {arrow ? (
                        arrow[content?.helpName]
                      ) : (
                        <div ref={clickRef} onClick={() => setBalloon(true)}>
                          <QuestionSvg style={{ cursor: 'pointer' }} />
                          {content.contentHelp && balloon && <span>{content.contentHelp}</span>}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={'frame-list'}>
            {items.map((elem: any) =>
              link ? (
                <div
                  key={elem?.id + '-title'}
                  className={'frame-box'}
                  style={{ gridTemplateColumns: gridTemplateColumnsLinks }}
                >
                  <NavLink
                    to={`/${link}/${elem?.id}`}
                    onClick={() => localStorage.setItem('orderTab', orderTab)}
                    className={'frame-item'}
                    style={{ gridTemplateColumns: isMobile ? '100%' : gridTemplateColumns }}
                  >
                    {Object.entries(elem?.item).map(
                      ([key, value]: any, index: number) =>
                        (value?.title?.down || value?.title?.up) && (
                          <div key={index}>
                            {isMobile && key !== 'Номер реестра' && <div className={'textSmall'}>{key.trim()}</div>}
                            <div>
                              <div className={'textMedium'}>{value?.title?.up}</div>
                              <div className={'textMedium'}>{value?.title?.down}</div>
                            </div>
                          </div>
                        ),
                    )}
                  </NavLink>
                  <div className={'frame-links'}>
                    {elem?.downloaded &&
                      Object.entries(elem?.downloaded).map(
                        ([key, value]: any, index: number) =>
                          (value?.title?.down || value?.title?.up) && (
                            <div key={`${elem?.id}-${index}`}>
                              {isMobile && <div className={'textSmall'}>{key.trim()}</div>}
                              <div>
                                <div className={'textMedium'}>{value?.title?.up}</div>
                                <div className={'textMedium'}>{value?.title?.down}</div>
                              </div>
                            </div>
                          ),
                      )}
                  </div>
                </div>
              ) : (
                <div
                  key={elem?.id}
                  className={'frame-box frame-box_no-link'}
                  style={{ gridTemplateColumns: gridTemplateColumnsLinks }}
                >
                  <div
                    onClick={() => (isFunction ? func(elem?.id) : console.log(''))}
                    className={'frame-item'}
                    style={{ gridTemplateColumns: isMobile ? '100%' : gridTemplateColumns }}
                  >
                    {Object.entries(elem?.item).map(([key, value]: any, index: number) => (
                      <div key={index}>
                        {isMobile && <div className={'textSmall'}>{key}</div>}
                        <div>
                          <div className={'textMedium'}>{value?.title?.up}</div>
                          <div className={'textMedium'}>{value?.title?.down}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
