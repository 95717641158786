import React, { useContext, useEffect, useState } from 'react';
import styles from './NewAuthPhone.module.scss';
import LogoIconSvg from '../../components/UI/svg-icon/LogoIconSvg';
import LogoTextSvg from '../../components/UI/svg-icon/LogoTextSvg';
import AuthLineMobileSvg from '../../components/UI/svg-icon/AuthLineMobileSvg';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { CheckBoxCustom } from '../../components/UI/checkBox/CheckBoxCustom';
import { Button } from '../../components/UI/buttons/Button';
import AuthLineSvg from '../../components/UI/svg-icon/AuthLineSvg';
import FooterAuth from './footerAuth/FooterAuth';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { AUTHSUBSERVER } from '../../common/sub-server.constant';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { typeTranslations } from '../EmployeesPage/Constans';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import {toast} from "react-toastify";
import LogoMobileSvg from "../../components/UI/svg-icon/LogoMobileSvg";
interface IProps {
  typeRole: string;
}
const roleTypeTranslations = {
  carrier: 'перевозчик',
  supplier: 'поставщик',
  buyer: 'покупатель',
  driver: 'водитель',
};
const NewRegisterPhone = ({ typeRole }: IProps) => {
  const [active, setActive] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<{ id: string; active: boolean }>({ id: 'agreement', active: false });
  const { setUser, user } = useContext(Context);
  const [, setTooltip] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [timer, setTimer] = useState<number>(0);
  const navigate = useNavigate();
  const pickCheck = id => {
    setAgreement(prevState => ({ id: id, active: !prevState.active }));
    setUser({ ...user, agreement: !agreement.active });
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const sendPhone = async () => {

    localStorage.setItem('RoleType', typeRole);
    const requestOptions: any = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: `+${user.phone}` }),
    };
    const requestOptionsExist: any = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    try {
      const responseExist = await fetch(`${AUTHSUBSERVER}/api/auth/exist?phone=%2B${user.phone}`, requestOptionsExist);
      if (responseExist.status === 204) {
        toast.error('Данный номер уже зарегистрирован');
        return;
      }
      await fetch(`${AUTHSUBSERVER}/api/auth/register`, requestOptions);
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/identificate`, requestOptions);
      if (response.status === 204) {
        setUser({ ...user, isInn: true, isPhone: true, agreement: agreement.active, });
        localStorageWrite(user);
        const userLs = JSON.parse(localStorage.getItem('user'));
        setUser(userLs);
        navigate('/authSms');
      }
      if (response?.status === 400) {
        const data = await response?.json();
        let convertDate = Number(data?.message.slice(9).split('.')[0]) + 1;
        setTimer(convertDate);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };
  return (
    <div style={{ height: '100vh' }}>
      {isMobile ? (
        <div style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.headerLogo}>
              <div className={styles.headerLogoContent}>
                <LogoMobileSvg/>
                <p className={styles.textLogo}>
                  Платформа нерудных
                  <br/>
                  материалов
                </p>
              </div>
            </div>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineMobileSvg />
              </div>
              <div className={styles.headerTextContainer}>
                <h1 className={styles.titleTextContainer}>Регистрация</h1>
                <p className={styles.descriptionInputContainer}>
                  Чтобы зарегистрироваться как{' '}
                  <span style={{ color: '#1B1B1B', fontWeight: 500 }}> {roleTypeTranslations[typeRole]}</span>, введите
                  свой номер телефона. На указанный номер поступит sms с кодом.
                </p>
              </div>
              <div className={styles.inputContainer}>
                <MyPhoneInput
                  onEnterDisabled={timer !== 0}
                  disabled={false}
                  setIsError={setError}
                  isError={error}
                  isActive={active}
                  setIsActive={setActive}
                  style={{ marginTop: isMobile ? '24px' : '40px', backgroundColor: '#FFF' }}
                  value={user.phone}
                  onChange={value => {
                    setTooltip(false);
                    setUser({ ...user, phone: value });
                  }}
                  isFunction={sendPhone}
                  btnHelp={true}
                />
                {timer > 0 && (
                  <div className={styles.codeCounter}>
                    Получить новый код <br />
                    можно через {convertVisualOfTimerCount()}
                  </div>
                )}
                <div className={styles.checkBoxContainer}>
                  <CheckBoxCustom
                    children={''}
                    error={false}
                    check={agreement.active}
                    pick={pickCheck}
                    name={'sameAsActual'}
                    id={'agreement'}
                  />
                  <div className={styles.checkBoxText}>
                    Заполняя форму на сайте, вы подтверждаете, что прочитали и согласны с условиями{' '}
                    <span
                      onClick={() => {
                        navigate('/privacyPolicy');
                      }}
                      className={styles.checkBoxTextClick}
                    >
                      политики конфиденциальности
                    </span>
                    <span> и </span>
                    <span
                      onClick={() => {
                        navigate('/userAgreement');
                      }}
                      className={styles.checkBoxTextClick}
                    >
                      пользовательского соглашения
                    </span>
                  </div>
                </div>

                <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex:'20' }}>
                  <ButtonFilled
                    text={'Зарегистрироваться'}
                    width={'100%'}
                    onClick={() => sendPhone()}
                    disabled={timer > 0 || !user.phone || !agreement.active}
                  />
                </div>
                <div className={styles.registerTextContainer}>
                  Уже зарегистрированы?
                  <p className={styles.registerText} onClick={() => navigate('/')}>
                    &nbsp;Авторизоваться
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*Компьютерная версия*/
        <div>
          <div className={styles.container}>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineSvg />
              </div>
              <div className={styles.headerTextContainer}>
                <h1 className={styles.titleTextContainer}>Регистрация</h1>
                <p className={styles.descriptionInputContainer}>
                  Чтобы зарегистрироваться как
                  <span style={{ color: '#1B1B1B', fontWeight: 500 }}> {roleTypeTranslations[typeRole]}</span> , введите
                  свой номер телефона. На указанный номер поступит sms с кодом.
                </p>
              </div>
              <div className={styles.inputContainer}>
                <MyPhoneInput
                  onEnterDisabled={timer !== 0}
                  disabled={false}
                  setIsError={setError}
                  isError={error}
                  isActive={active}
                  setIsActive={setActive}
                  style={{ marginTop: isMobile ? '24px' : '40px', backgroundColor: '#FFF' }}
                  value={user.phone}
                  onChange={value => {
                    setTooltip(false);
                    setUser({ ...user, phone: value });
                  }}
                  isFunction={sendPhone}
                  btnHelp={true}
                />
                {timer > 0 && (
                  <div className={styles.codeCounter}>
                    Получить новый код <br />
                    можно через {convertVisualOfTimerCount()}
                  </div>
                )}
                <div className={styles.checkBoxContainer}>
                  <CheckBoxCustom
                    children={''}
                    error={false}
                    check={agreement.active}
                    pick={pickCheck}
                    name={'sameAsActual'}
                    id={'agreement'}
                  />
                  <div className={styles.checkBoxText}>
                    Заполняя форму на сайте, вы подтверждаете, что прочитали и согласны с условиями{' '}
                    <span
                      onClick={() => {
                        navigate('/privacyPolicy');
                      }}
                      className={styles.checkBoxTextClick}
                    >
                      политики конфиденциальности
                    </span>
                    <span> и </span>
                    <span
                      onClick={() => {
                        navigate('/userAgreement');
                      }}
                      className={styles.checkBoxTextClick}
                    >
                      пользовательского соглашения
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex:'20' }}>
                  <ButtonFilled
                    text={'Зарегистрироваться'}
                    width={'100%'}
                    onClick={() => sendPhone()}
                    disabled={timer > 0 || !user.phone || !agreement.active}
                  />
                </div>
                <div className={styles.registerTextContainer}>
                  Уже зарегистрированы?
                  <p className={styles.registerText} onClick={() => navigate('/')}>
                    &nbsp;Авторизоваться
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterAuth />
        </div>
      )}
    </div>
  );
};

export default NewRegisterPhone;
