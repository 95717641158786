import React, { useContext } from 'react';
import styles from './MobileCardTableSpecifications.module.scss';
import { DataResponseDocuments } from '../../../DocumentsPages/type';
// @ts-ignore
import LoadingGif from '../../../../assets/img/Loader-Solber.gif';
import { EmptyList } from '../../../../components/EmptyList';
import { Context } from '../../../../context/context';
import { useNavigate } from 'react-router-dom';
import QuestionOutlineSvgLowerWidth from '../../../../components/UI/svg-icon/QuestionOutlineSvgLowerWidth';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { statusColor, statusTextTooltip } from '../../utils';
interface IProps {
  dataResponse: DataResponseDocuments[];
  activityOfSpecifications: { value: string; label: string } | undefined | null;
  loading: boolean;
}
const MobileCardTableSpecifications = ({ dataResponse, activityOfSpecifications, loading }: IProps) => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const Spin = () => {
    return (
      <div style={{ marginTop: '40px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <>
          {dataResponse?.length ? (
            <div className={styles.containerMain}>
              {dataResponse.map(item => (
                <div className={styles.containerCard} onClick={() => navigate(`/document/specifications/${item?.id}`)}>
                  {activityOfSpecifications.value === 'open' ? (
                          <div style={{ width:'160px' }} onClick={e => e.stopPropagation()}>
                            <p className={statusColor[item?.status]}>
                              {item?.status}
                              <div>
                                <CustomTooltip SideTooltip={'up'}
                                               svg={<QuestionOutlineSvgLowerWidth />}
                                               positionTooltipBottom={'23px'}
                                               text={statusTextTooltip[item?.status]}
                                               widthTooltip={'fitContent'}
                                               widthSvg={'16px'}
                                               styleWindowTooltip={{ whiteSpace:'nowrap' }}
                                />
                              </div>
                            </p>
                          </div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.stringContainer}>
                    <p className={styles.titleText}>Номер договора:</p>
                    <p className={styles.infoText}>{item?.title}</p>
                  </div>
                  {user.companyType === 'carrier' ? (
                    <div className={styles.stringContainer}>
                      <p className={styles.titleText}>Вид договора:</p>
                      <p className={styles.infoText}>{item?.contractType}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.stringContainer}>
                    <p className={styles.titleText}>Дата начала:</p>
                    <p className={styles.infoText}>
                      {item?.date ? item?.date?.slice(0, 10).split('-').reverse().join('.') : '—'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ marginTop: '-50px' }}>
              <EmptyList title={'Документов не найдено'} subTitle={'В данном разделе пока нет документов'} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MobileCardTableSpecifications;
