import React, { useContext, useRef } from 'react';
import { WalletSvg } from '../UI/svg-icon/WalletSvg';
import { WalletSvgActive } from '../UI/svg-icon/WalletSvgActive';
import { FileSvg } from '../UI/svg-icon/FileSvg';
import { FileSvgActive } from '../UI/svg-icon/FileSvgActive';
import { DocumentSvgActive } from '../UI/svg-icon/DocumentSvgActive';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import stylesTour from './Tour.module.scss';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import { MapMarkerSvg } from '../UI/svg-icon/MapMarkerSvg';
import { MapMarkerSvgActive } from '../UI/svg-icon/MapMarkerSvgActive';
import { CartSvg } from '../UI/svg-icon/CartSvg';
import { CartSvgActive } from '../UI/svg-icon/CartSvgActive';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
import EmployeesSvg from '../UI/svg-icon/EmployeesSvg';
import EmployeesActiveSvg from '../UI/svg-icon/EmployeesActiveSvg';
import { Tour } from 'antd';
import { Context } from '../../context/context';

import OrderTourImg from '../../assets/img/TourImages/OrderTouriImg.png';
import BuyerPaymentProcessTourImg from '../../assets/img/TourImages/BuyerPaymentProcessTourImg.png';
import ObjectTourImg from '../../assets/img/TourImages/ObjectTourImg.png';
import BuyerDocumentTourImg from '../../assets/img/TourImages/BuyerDocumentTourImg.png';
import EmployeesTourImg from '../../assets/img/TourImages/EmployeesTourImg.png';
import ProfileTourImg from '../../assets/img/TourImages/ProfileTourImg.png';

export const typeTranslations = {
  accountant: 'Бухгалтер',
  manager: 'Менеджер',
  admin: 'Админ',
};
const fullAccess = ['admin', 'manager', 'buyer', 'accountant'];

const NewAsideNavBuyer = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const { setStartTour, startTour, user } = useContext(Context);

  const steps: any = [
    {
      title: <p className={stylesTour.title}>Заказы</p>,

      cover: <img alt="tour.png" src={OrderTourImg} />,
      description:
        'Создавайте заказы на доставку материалов, указав прилежащие к ним документы, номенклатуру и объект. ' +
        'Просматривайте информацию по уже созданным заказам. ',
      placement: 'right',
      typeRole: ['admin', 'manager', 'buyer'],
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnFirstNext },
      target: () => ref1.current,
    },
    {
      title: <p className={stylesTour.title}>Взаиморасчеты</p>,
      placement: 'right',
      cover: <img alt="tour.png" src={BuyerPaymentProcessTourImg} />,
      typeRole: ['admin', 'accountant', 'buyer'],
      description:
        'Просматривайте сумму внесенной предоплаты за заказы либо сумму, которую необходимо оплатить. ' +
        'Изучайте итоговую сумму к оплате на сегодня. Просматривайте размер просроченной оплаты. ' +
        'Формируйте дебиторскую задолженность по нужному параметру или дате.',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      target: () => ref2.current,
    },
    {
      title: <p className={stylesTour.title}>Объекты</p>,
      cover: <img alt="tour.png" src={ObjectTourImg} />,
      typeRole: ['admin', 'manager', 'buyer'],
      placement: 'right',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте объекты, на которые будет осуществляться доставка материалов. ' +
        'Укажите наименование объекта, адрес, контакты ответственных лиц, вид ТС и график приемки.' +
        'Объекты можно добавлять в неограниченном количестве, а также удалять неактуальные.',
      target: () => ref3.current,
    },
    {
      title: <p className={stylesTour.title}>Документы</p>,
      placement: 'right',
      cover: <img alt="tour.png" src={BuyerDocumentTourImg} />,
      typeRole: fullAccess,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Формируйте договоры и заверяйте их электронной подписью. ' +
        'Просматривайте уже сформированные договоры, а также спецификации к ним. ' +
        'В подразделе «УПД» находятся соответствующие документы и реестр транспортных накладных.',
      target: () => ref4.current,
    },
    {
      title:<p className={stylesTour.title}>Сотрудники</p>,
      placement: 'right',
      typeRole: ['buyer'],
      cover: <img alt="tour.png" src={EmployeesTourImg} />,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и удаляйте сотрудников и назначайте им роли менеджера, бухгалтера и администратора.' +
        ' У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет.' +
        ' При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.\n',
      target: () => ref5.current,
    },
    {
      title: <p className={stylesTour.title}>Профиль</p>,
      placement: 'right',
      cover: <img alt="tour.png" src={ProfileTourImg} />,
      typeRole: fullAccess,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и просматривайте данные, редактируйте при необходимости.\n' +
        'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. ' +
        'Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете.\n',
      target: () => ref6.current,
    },
    {
      title: <p className={stylesTour.title}>Помощь</p>,
      placement: 'right',
      typeRole: fullAccess,
      type: 'default',
      nextButtonProps: { children: 'Завершить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description: 'Изучайте ответы на наиболее часто задаваемые вопросы.',
      target: () => ref7.current,
    },
  ].filter(item => item?.typeRole?.includes(user?.type));
  const buyerNavLink = [
    {
      name: 'Заказы',
      ref: ref1,
      type: ['admin', 'manager', 'buyer'],
      link: '/orders#Открытые',
      notActiveProfile: false,
      svg: <CartSvg />,
      svgActive: <CartSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Взаиморасчеты',
      ref: ref2,
      type: ['admin', 'accountant', 'buyer'],
      link: '/paymentProcess',
      notActiveProfile: false,
      svg: <WalletSvg />,
      svgActive: <WalletSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Объекты',
      ref: ref3,
      link: '/objects',
      type: ['admin', 'manager', 'buyer'],
      notActiveProfile: false,
      svg: <MapMarkerSvg />,
      svgActive: <MapMarkerSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Документы',
      type: fullAccess,
      ref: ref4,
      link: '/document',
      notActiveProfile: false,
      svg: <FileSvg />,
      svgActive: <FileSvgActive />,
      svgProfileError: <DocumentSvgActive />,
      errorProfile: false,
    },
    {
      name: 'Сотрудники',
      link: '/employees',
      ref: ref5,
      type: ['buyer'],
      notActiveProfile: false,
      disable: true,
      svg: <EmployeesSvg />,
      svgActive: <EmployeesActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Профиль',
      link: '/profile',
      ref: ref6,
      type: fullAccess,
      notActiveProfile: false,
      svg: <ProfileSvg />,
      svgActive: <ProfileSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    {
      name: 'Помощь',
      link: '/help',
      ref: ref7,
      type: fullAccess,
      notActiveProfile: false,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ].filter(item => item?.type?.includes(user?.type));
  return (
    <div className={styles.AsideBox} style={{ zIndex: startTour ? '1001' : '8000' }}>
      {buyerNavLink.map(
        ({ name, type, ref, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => (
          <div ref={ref}>
            <NewNavItem
              ref={ref}
              disabled={null}
              typeAccess={type}
              key={name}
              name={name}
              link={link}
              notActiveProfile={notActiveProfile}
              svg={svg}
              svgActive={svgActive}
              svgProfileError={svgProfileError}
              errorProfile={errorProfile}
            />
          </div>
        ),
      )}
      <Tour
        closeIcon={false}
        open={startTour}
        gap={{ offset: [-6, -2], radius: 12 }}
        onClose={() => setStartTour(false)}
        steps={steps}
        indicatorsRender={(current, total) => <span></span>}
      />
    </div>
  );
};

export default NewAsideNavBuyer;
