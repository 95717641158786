import React from 'react';

const WorkerSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="worker">
                <path id="Vector" d="M27.5 15.1767C27.5 25.4627 12.5 25.4627 12.5 15.1767H16.25C16.25 20.3294 23.75 20.3294 23.75 15.1767M35 32.5452V38.3346H5V32.5452C5 27.3925 14.9937 24.8259 20 24.8259C25.0063 24.8259 35 27.3925 35 32.5452ZM31.4375 32.5452C31.4375 31.3101 25.5688 28.4925 20 28.4925C14.4312 28.4925 8.5625 31.3101 8.5625 32.5452V34.668H31.4375M20.9375 1.66797C21.4625 1.66797 21.875 2.09253 21.875 2.63288V8.42235H23.75V3.59779C25.077 4.22986 26.1887 5.25781 26.941 6.54827C27.6933 7.83873 28.0513 9.33212 27.9688 10.8346C27.9688 10.8346 29.2812 11.1048 29.375 13.2469H10.625C10.625 11.1048 12.0312 10.8346 12.0312 10.8346C11.9487 9.33212 12.3067 7.83873 13.059 6.54827C13.8113 5.25781 14.923 4.22986 16.25 3.59779V8.42235H18.125V2.63288C18.125 2.09253 18.5375 1.66797 19.0625 1.66797" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default WorkerSvg;