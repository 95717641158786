import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import styles from './SpecificationPage.module.scss';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { columnsForTableOfSpecifications, optionsOfActivityOfSpecifications } from './utils';
import { Context } from '../../context/context';
import MobileCardTableSpecifications from './Components/MobileCardTableSpecifications/MobileCardTableSpecifications';
import { DataResponseDocuments } from '../DocumentsPages/type';

interface IProps{
  loading:boolean
  dataResponse:DataResponseDocuments[]
  getData:any
  tab:string
  isFirstRender:number
  activityOfSpecifications: { value: string; label: string } | undefined | null
  setActivityOfSpecifications:React.Dispatch<React.SetStateAction<{ value: string; label: string } | undefined | null>>;
}
export const SpecificationsPage = ({ getData,dataResponse,loading,activityOfSpecifications,
                                     setActivityOfSpecifications,tab,isFirstRender }:IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(Context);

  /*Удаление колонки "статус" если мы ищем закрытые договоры*/
  const columnsForTableOfSpecificationsByStatus=activityOfSpecifications.value==='closed'
      ?columnsForTableOfSpecifications[user.companyType].filter((item)=>item.key!=='status')
      :columnsForTableOfSpecifications[user.companyType];

  useEffect(()=>{
      if (tab==='contractsAndSpecifications' && isFirstRender>0){
          getData();
      }
  },[tab]);
  return (
    <>
      <div style={{ width:isMobile?'100%':'298px' }} className={styles.customSelectContainer}>
        <CustomSelect
            defaultValue={optionsOfActivityOfSpecifications[0]}
            error={false}
            placeholder={''}
            noOptionsMessage={''}
            isSearchable={false}
            isClearable={false}
            options={optionsOfActivityOfSpecifications}
            setValue={value => setActivityOfSpecifications(value)}
        />
      </div>
      {isMobile ?
          <MobileCardTableSpecifications activityOfSpecifications={activityOfSpecifications}
                                         dataResponse={dataResponse} loading={loading}/>
          :
          <div className={styles.customTableContainer}>
            <CustomTable loading={loading} link={true} linkTextBeforeId={'specifications'}
                         columns={columnsForTableOfSpecificationsByStatus} data={dataResponse} />
          </div>
      }
    </>
  );
};
