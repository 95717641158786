import React from 'react';

export const SkeletonDocuments = () => {

  return (
    <div className={'SkeletonDocuments'}>
      <div className="SkeletonDocuments__header">
        <div className="SkeletonDocuments__title"></div>
        <div className="SkeletonDocuments__subTitle"></div>
      </div>
      <div className="SkeletonDocuments__selects">
        <div className="SkeletonDocuments__select"></div>
        <div className="SkeletonDocuments__select"></div>
      </div>
      <div className="SkeletonDocuments__requests">
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
        <div>
          <div className="SkeletonDocuments__request"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
      <div className="SkeletonDocuments__list">
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
        <div className="SkeletonDocuments__box">
          <div className="SkeletonDocuments__item"></div>
        </div>
      </div>
    </div>
  );
};