import React from 'react';

export const ArrowBack = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
        fill="currentColor"/>
    </svg>
  );
};