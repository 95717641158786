import React from 'react';
import { TransactionsItem } from './TransactionsItem';
import { useMediaQuery } from 'react-responsive';
import { StatusTabsGSM } from '../Pages/GsmPages/GSMCardPage';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { EmptyList } from './EmptyList';

type TransactionsProps = {
  data: {
    date: Date;
    fuelType: string;
    address: string;
    volume: string;
    price: string;
    expenseAmount: string;
    operation: string;
  }[];
  statusTabs: StatusTabsGSM;
  period: { value: number; label: string; empty: boolean };
  oil: { value: string; label: string } | undefined | null;
  more: number;
  setMore: any;
};

export const TransactionsList = ({ data, statusTabs, period, oil, more, setMore }: TransactionsProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { statusDeterminant } = Object.values(statusTabs).find(tab => tab.active) || { statusDeterminant: () => false };

  const newData = data.map(item => ({
    ...item,
    date: new Date(item['date']),
  }));

  const sortedList = newData
    .filter(item => statusDeterminant(item.operation))
    .filter(({ date }) =>
      period.empty
        ? date <= new Date(new Date().setDate(new Date().getDate()))
        : date >= new Date(new Date().setDate(new Date().getDate() - period.value)),
    )
    .filter(({ fuelType }) => (oil?.value ? fuelType === oil?.value : true))
    .sort((a, b) => Number(b['date']) - Number(a['date']));

  return (
    <div className="transactionsList">
      {sortedList.length ? (
        sortedList
          .slice(0, more)
          .map(({ date, fuelType, address, volume, price, expenseAmount, operation }, index) => (
            <TransactionsItem
              key={index}
              firstItem={isMobile ? true : index === 0}
              dataOil={date}
              typeOil={fuelType}
              typeOperation={operation}
              addressGas={address}
              volume={volume}
              priceForVolume={price}
              sum={expenseAmount}
            />
          ))
      ) : (
        <EmptyList title={'Нет транзакций'} subTitle={'По заданным параметрам результатов не найдено'} />
      )}
      {sortedList?.length >= more ? (
        <ButtonTransparent
          modifier={''}
          color={''}
          disabled={false}
          svg={''}
          isSvg={false}
          onClick={() => setMore(more + 10)}
          children={'Показать больше операций'}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};
