type OptionVolume = {
  value: string;
  label: string;
  dataset: string;
  selected: boolean;
}

export const optionVolume: OptionVolume[] = [
  { value: 'сначала новые', label: 'Сначала новые', dataset: 'DESC', selected: true },
  { value: 'сначала старые', label: 'Сначала старые', dataset: 'ASC', selected: false }
];
export const optionSumValue: any = [
  { value: 'по возрастанию', label: 'По возрастанию' },
  { value: 'по убыванию', label: 'По убыванию' },
];