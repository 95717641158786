import React from 'react';

export const Button = ({ svg, left, right, children, disabled, sending, modifier, color, colorSvg, ...props }) => {
    return (
        <button {...props} disabled={disabled} className={`Button ${color} ${modifier}`}>
            {sending
                ? <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                  </div>
                : <div className="Button__content">
                  {svg && left && <div className={`Button__svg Button__svg_left ${colorSvg}`}>{svg}</div>}
                  {children}
                  {svg && right && <div className={`Button__svg Button__svg_right ${colorSvg}`}>{svg}</div>}
                </div>
            }
        </button>
    );
};
