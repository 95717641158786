// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddArea_addAreaContainer__3Enhh {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.AddArea_addAreaContainerFixedPrice__K977i {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 40px;\n}\n\n@media (max-width: 768px) {\n  .AddArea_addAreaContainer__3Enhh {\n    flex-direction: column;\n  }\n  .AddArea_addButtonAndArea__k1WPC {\n    display: flex;\n    gap: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/EditAndAddMaterialPage/components/AddArea/AddArea.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;EACA,gDAAA;EACA,SAAA;AAEF;;AAAA;EACE;IACE,sBAAA;EAGF;EADA;IACE,aAAA;IACA,SAAA;EAGF;AACF","sourcesContent":[".addAreaContainer{\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n.addAreaContainerFixedPrice{\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap:40px\n}\n@media (max-width: 768px){\n  .addAreaContainer{\n    flex-direction: column;\n  }\n  .addButtonAndArea{\n    display: flex;\n    gap: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addAreaContainer": "AddArea_addAreaContainer__3Enhh",
	"addAreaContainerFixedPrice": "AddArea_addAreaContainerFixedPrice__K977i",
	"addButtonAndArea": "AddArea_addButtonAndArea__k1WPC"
};
export default ___CSS_LOADER_EXPORT___;
