import React from 'react';
import { Link } from 'react-router-dom';
import { regularPhone } from '../common/regular-phone';

export const DriverLicenseList = ({ list, ...props }) => {
  return (
    <div {...props} className="DriverLicenseList">
      {list.map(({ surname, name, patronymic, phone, id }) =>
          <Link
              onClick={() => localStorage.setItem('idDriver', id)}
              key={id}
              to="/driverLicense/editDriver"
          >
        <div className="DriverLicenseList__item">
          <div className="DriverLicenseList__text line">
            <span>{surname}&#160;</span>
            <span>{name}&#160;</span>
            <span>{patronymic}</span>
          </div>
          <div className="DriverLicenseList__text">
            {phone ? regularPhone(phone) : 'Номер телефона отсутствует'}
          </div>
        </div>
      </Link>)}
    </div>
  );
};