import { fetchGet } from './proxy-1C-fetch-auth';

export const getImage = async (sending: any, setSending: any, setErrorImageList: any, setImageList: any, setIsBalloon: any, id: string) => {
  try {
    setSending({ ...sending, sendingImages: true });
    const response = await fetchGet(`/flights/${id}`, {});

    response.response.files.forEach(({ data, name }) => {
      const extension = data.split('/')[1].split(';')[0];

      setImageList(prevState => [...prevState, { nameFile: name, base64File: data, extension }]);
    });

    setSending({ ...sending, sendingImages: false, onImages: false });
    setIsBalloon(true);
  } catch (e) {
    setIsBalloon(true);
    setErrorImageList(true);
    console.log(e);
  }
};