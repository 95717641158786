import React from 'react';
import styles from '../../DispatchingItemPage.module.scss';
import { objDeclensionOfRefuseModal } from '../../../../layouts/Notices/Constans';

const MobileTableDispatching = ({ tableData, validationOfPlanning, setMobileOpenEditPlan, getItemDataEdit }) => {
  function declension(number: number): string {
    let last_num = number % 10;
    if (number > 10 && [11, 12, 13, 14].includes(number % 100)) {
      return 'рейсов';
    } else {
      return objDeclensionOfRefuseModal.flight[last_num];
    }
  }

  return (
    <div className={styles.planningContainer}>
      {tableData?.map(item => (
        <div
          className={styles.mobilePlanningContainer}
          onClick={() => {
            if (validationOfPlanning) {
              setMobileOpenEditPlan(true);
              getItemDataEdit(item);
            }
          }}
        >
          <div className={styles.mobilePlanningDateContainer}>
            <p className={styles.mobilePlanningTitleDate}>Дата</p>
            <p className={styles.mobilePlanningTitleDateNumber}>
              {item?.flightDate.slice(0, 10).split('-').reverse().join('.')}
            </p>
          </div>
          <div className={styles.mobilePlanningInfoContainer}>
            <p className={styles.mobilePlanningInfoTextTitleContainer}>
              {item?.volumeOfMaterial?.flightsSumVolume} {item?.volumeOfMaterial?.unit}
            </p>
            <p className={styles.mobilePlanningInfoTextSubtitleContainer}>
              {item?.flightsNumber} {declension(item?.flightsNumber)}
            </p>
          </div>
          <div className={styles.mobilePlanningInfoContainer}>
            <p className={styles.mobilePlanningInfoTextTitleContainer}>
              {item?.car?.carModel} {item?.car?.carStateNumber}
            </p>
            <p className={styles.mobilePlanningInfoTextSubtitleContainer}>{item?.driver}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileTableDispatching;
