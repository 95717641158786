import React from 'react';
import styles from './MobileCardEmployee.module.scss';
import { IEmployees } from '../../type';
import { typeTranslations } from '../../Constans';
import {regularPhone} from "../../../../common/regular-phone";
interface IProps {
  employee: IEmployees;
}
const MobileCardEmployee = ({ employee }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.nameText}>
        <span>{employee?.fullName?.surname} {employee?.fullName?.name} {employee?.fullName?.patronymic}</span>
      </div>
      <div className={styles.containerContent}>
        <span className={styles.title}>Телефон:</span>
        <span className={styles.textContent}>{regularPhone(employee?.phone)}</span>
      </div>
      <div className={styles.containerContent}>
        <span className={styles.title}>Должность:</span>
        <span className={styles.textContent}> {typeTranslations[employee?.type]}</span>
      </div>
    </div>
  );
};

export default MobileCardEmployee;
