import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { Link, useParams } from 'react-router-dom';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { TransactionsList } from '../../components/TransactionsList';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { SkeletonGsmCard } from '../../components/skeletons/SkeletonGSMCard';
import { CardInformation } from '../../components/CardInformation';
import { toast, ToastContainer } from 'react-toastify';

export type StatusTabsGSM = {
  [key: string]: {
    statusDeterminant: (status: string) => boolean;
    active: boolean;
  };
};

export const periodOptions = [
  { value: 30, label: 'За последние 30 дней', empty: false },
  { value: 7, label: 'За последние 7 дней', empty: false },
  { value: 1, label: 'За сегодня', empty: false },
  { value: 30, label: 'За Всё время', empty: true },
];

const oilOptions = [
  { value: 'ДТ', label: 'ДТ' },
  { value: 'АИ-92', label: 'АИ-92' },
  { value: 'АИ-95', label: 'АИ-95' },
];

export const statuses: StatusTabsGSM = {
  Списание: {
    statusDeterminant: status => status === 'Заправка (списание средств с карты)',
    active: true,
  },
  Поступление: {
    statusDeterminant: status => status !== 'Заправка (списание средств с карты)',
    active: false,
  },
};

export const GsmCardPage = () => {
  const [skeleton, setSkeleton] = useState(false);
  const [more, setMore] = useState<number>(10);
  const [transactions, setTransactions] = useState([]);
  const [period, setPeriod] = useState<{ value: number; label: string; empty: boolean }>(periodOptions[0]);
  const [oil, setOil] = useState<{ value: string; label: string } | undefined | null>(oilOptions[0]);
  const [remainder, setRemainder] = useState<number>(0);
  const [driversList, setDriversList] = useState<{ value: string; label: string }[]>([]);
  const [statusTabs, setStatusTabs] = useState<StatusTabsGSM>(statuses);
  const [pickDriver, setPickDriver] = useState<any>('');
  const [buttonOptions, setButtonOptions] = useState<{ color: string; disable: boolean }>({
    color: 'gray',
    disable: true,
  });

  const { id: ids } = useParams();

  const pinDriver = async () => {
    try {
      const response = await fetchPost(`/fuel_cards/${ids}`, 'PATCH', {
        driverId: pickDriver.value,
      });

      if (response.error) {
        toast.error(response.response);
        setButtonOptions({ color: 'red', disable: false });
        return;
      }

      toast.success('Карта закреплена');
      setButtonOptions({ color: 'green', disable: true });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setSkeleton(true);
        const drivers = await fetchGet('/drivers/list', {});

        const driverList: { value: string; label: string }[] = [];

        for (const { id: value, fullName: label } of drivers.response) {
          driverList.push({ value, label });
        }

        setDriversList(driverList);

        const responseCard = await fetchGet('/fuel_cards', {});

        setRemainder(responseCard.response.find(({ id }) => id == ids));
        setPickDriver(
          Boolean(responseCard.response.find(({ id }) => id == ids).users) && {
            value: responseCard.response.find(({ id }) => id == ids).users.uuid,
            label: responseCard.response.find(({ id }) => id == ids).users.fullName,
          },
        );

        const transactions = await fetchGet('/fuel', {
          startDate: new Date(new Date().setDate(new Date().getDate() - 30))
            .toISOString()
            .split('T')[0]
            .replace(/\./g, '-'),
          finishDate: new Date().toISOString().split('T')[0].replace(/\./g, '-'),
          cardId: ids,
          fullDate: period.empty,
        });

        setTransactions(transactions.response);

        setTimeout(() => {
          setSkeleton(false);
        }, 800);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [period.empty]);

  const sortFlightStatus = statusName => {
    for (const [key, tab] of Object.entries(statusTabs)) {
      tab.active = key === statusName;
    }

    setStatusTabs(prevState => ({ ...prevState, ...statusTabs }));
  };

  const pickRequest = value => {
    sortFlightStatus(value);
    setOil(null);
  };

  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonGsmCard />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <Link className="button-back" to="/gsm">
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            К списку топливных карт
          </Link>
          <div className="GsmCardPage">
            <CardInformation
              buttonColor={setButtonOptions}
              data={remainder}
              driverList={driversList}
              pinDriver={pinDriver}
              setPickDriver={setPickDriver}
              buttonOptions={buttonOptions}
            />
            <div className="GsmCardPage__title">История операций</div>
            <div className="GsmCardPage__requests">
              {Object.entries(statusTabs).map(([statusName, tab]) => (
                <div
                  key={statusName}
                  onClick={() => pickRequest(statusName)}
                  className={`FlightSection__requestBtn ${tab.active ? 'active' : ''}`}
                >
                  {statusName}
                </div>
              ))}
            </div>
            <div className="GsmCardPage__sort sort">
              <div className="sort__period">
                <CustomSelect
                  error={false}
                  placeholder="Период"
                  noOptionsMessage={'Нет вариантов'}
                  value={period}
                  options={periodOptions}
                  setValue={value => setPeriod(value)}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div className={statusTabs.Списание.active ? 'sort__oil' : 'sort__oil_none'}>
                <CustomSelect
                  error={false}
                  placeholder="Вид топлива"
                  noOptionsMessage={'Нет вариантов'}
                  value={oil}
                  setValue={value => setOil(value)}
                  options={oilOptions}
                  isClearable={true}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="GsmCardPage__content">
              <TransactionsList
                data={transactions}
                statusTabs={statusTabs}
                period={period}
                oil={oil}
                more={more}
                setMore={setMore}
              />
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
