import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { SkeletonQuarry } from '../../components/skeletons/SkeletonQuarry';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from '../../components/UI/inputs/InputText';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { quarryData, QuarryData, quarryDataOptions } from '../../common/quarry-type';
import { useMediaQuery } from 'react-responsive';
import { ButtonTextTransparent } from '../../components/UI/buttons/ButtonTextTransparent';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { Button } from '../../components/UI/buttons/Button';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { Basket } from '../../components/UI/svg-icon/Basket';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { TimePicker } from 'antd';
import styles from './SupplierQuarriesPage.module.scss';
import CustomYMap from '../../components/CustomYMap/CustomYMap';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { loadingImages } from '../../common/loading-image.function';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import dayjs from 'dayjs';
import 'react-dadata/dist/react-dadata.css';
import CustomAddressInput from '../../components/newUI/CustomAddressInput/CustomAddressInput';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { removeImage } from '../../common/removeImage';
import { SUBSERVER } from '../../common/sub-server.constant';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { clearObject } from 'src/common/ClearObject.helper';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";

export const SupplierQuarryPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [skeletonQuarry, setSkeletonQuarry] = useState(false);
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [, setDisabled] = useState(false);
  const [, setSending] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorSecond, setIsErrorSecond] = useState(false);
  const [helpPhone, setHelpPhone] = useState(false);
  const [helpPhoneSecond, setHelpPhoneSecond] = useState(false);
  const [quarry, setQuarry] = useState<any>();
  const [images, setImages] = useState<any>([]);
  const [uploadimages, setUploadImages] = useState<any>([]);
  const [showImages, setShowImages] = useState(false);
  const [data, setData] = useState<QuarryData>(quarryData);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const [validateTime, setValidateTime] = useState<'' | 'error' | 'warning'>('');
  const [valueAddress, setValueAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>();
  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: time,
        [inputTime]: time,
      },
    }));
  };

  const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/
        );
  };
  const ValidateErrorFields = ():boolean => {
    return !!(
        (!validateName(data.name) || data.name) &&
        (!validateName(data.responsibleEmployee.person.person) || data.responsibleEmployee.person.person) &&
        validateEmail(data.responsibleEmployee.email.email) &&
        data.quarryType &&
        data?.address?.latitude &&
        data?.address?.longitude &&
        !isError &&
        !isErrorSecond);
    };
  const validateName = (value) => value?.replace(/[\sА-Яа-яЁё\- ]/gi, '');
  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  const toggleImages = () => {
    setShowImages(!showImages);
  };

  const setDayJsFormatDate = time => {
    const correctDate = new Date();
    correctDate.setHours(time?.split(':')[0]);
    correctDate.setMinutes(time?.split(':')[1]);
    return dayjs(correctDate);
  };

  const getData = async () => {
    setSkeletonQuarry(true);
    try {
      const response = await fetchGet(`/quarries/${window.location.href.split('/').reverse()[0]}`, {});

      if (response?.response?.error) {
        console.log('error');
        toast.error('Ошибка получение данных');
        return;
      }
      setQuarry(response?.response);
      const responseFile = await fetch(
        `${SUBSERVER}/api/files/?fileBelongsId=${response.response?.id}&communicationCategory=quarries`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
          credentials: 'include',
        },
      );
      const dataFile = await responseFile.json();

      await setImages(dataFile?.response);

      setData(prevState => ({
        ...prevState,
        name: response?.response?.name,
        address: response.response?.address,
        quarryType: response.response?.quarryType === 'Карьер' ? 'quarry' : 'transshipment',
        dispatcherPhone: response.response?.dispatcher?.phone,
        contactPersons: response?.response?.contactPersons.slice(1).map((item)=>{
          return{
            person: item.person,
            phone:  item.phone,
            email: item.email,
            main:item?.contact_persons_communications?.main

          };
        }),
        responsibleEmployee: {
          person: {
            person: response.response?.contactPersons.filter(
              person => person?.contact_persons_communications?.main === true,
            )[0]?.person,
            error: false,
          },
          phone: {
            phone: response.response?.contactPersons.filter(
              person => person?.contact_persons_communications?.main === true,
            )[0]?.phone,
            error: false,
          },
          email: {
            email: response.response?.contactPersons.filter(
              person => person?.contact_persons_communications?.main === true,
            )[0]?.email,
            error: false,
          },
          main:true
        },
        files: response.response?.files,
        time: response.response?.workSchedulesGraph?.from,
        workingHoursType: {
          name: response.response?.workSchedulesGraph.aroundTheClock ? 'aroundTheClock' : 'daytime',
          workFrom: setDayJsFormatDate(response.response?.workSchedulesGraph.to),
          workTo: setDayJsFormatDate(response.response?.workSchedulesGraph.from),
        },
      }));

      setValueAddress({
        ...valueAddress,
        value: response.response?.address.address,
        data: {
          ...valueAddress?.data,
          geo_lat: response.response?.address.latitude.toString(),
          geo_lon: response.response?.address.longitude.toString(),
        },
      });

      setTimeout(() => {
        setSkeletonQuarry(false);
      }, 800);
    } catch (e) {
      setSkeletonQuarry(false);
      console.log(e);
    }
  };
  const correctionTime =
    data.workingHoursType.name === 'daytime'
      ? {
          workingHoursType: data.workingHoursType.name,
          from: data.workingHoursType.workTo?.format('HH:mm'),
          to: data.workingHoursType.workFrom?.format('HH:mm'),
        }
      : {
          workingHoursType: data.workingHoursType.name,
        };

  const createCorrectionFiles = () => {
    const result = [];

    images.map(item => {
      if (item.hasOwnProperty('value')) {
        result.push(item.value);
      }
    });
    return result;
  };
  const createCorrectionFilesIds = () => {
    const result = [];
    images.map(item => {
      if (!item.hasOwnProperty('value')) {
        result.push(Number(item.id));
      }
    });
    return result;
  };
  const openFile = async (id: number, communicationCategory: string) => {
    try {
      const response = await fetchGet(`/files/${id}`, { category: communicationCategory });
      let pdfWindow = window.open('');
      pdfWindow.document.write('<html<head><title>' + 'fileName' + '</title><style>body{margin: 0;}</style></head>');
      pdfWindow.document.write(
        `<body><embed width='100%' height='100%' src='${encodeURI(response?.response.data)}'></embed></body></html>`,
      );
      pdfWindow.document.close();
    } catch (e) {
      console.log(e);
    }
  };

  const sendQuarryData = async () => {
    setDisabled(true);
    setSending(true);
    const correactData = {
      /*trim убирает пробелы в начале и конце строки, replace() - убирает дубликат пробелов*/
      name: data.name.trim().replace(/ +(?= )/g,''),
      quarryType: data?.quarryType.trim().replace(/ +(?= )/g,''),
      dispatcherPhone: data?.dispatcherPhone,
      time: correctionTime,
      address: data.address,
      contactPersons: [
        {
          person: data.responsibleEmployee.person.person.trim().replace(/ +(?= )/g,''),
          phone: data.responsibleEmployee.phone.phone,
          main:true,
          email: data.responsibleEmployee.email.email,
        },
        ...data?.contactPersons,
      ],
      files: createCorrectionFiles().length ? createCorrectionFiles() : undefined,
      filesId: createCorrectionFilesIds(),
    };
    clearObject(correactData);
    try {
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'quarries',
          fileBelongsId: quarry?.id,
          category: 'Карьеры',
          files: uploadimages?.map(file => file?.value),
          objectUuid: quarry?.uuid || '',
        },
      ];
      if (uploadimages.length) {
        const responseFile = await fetch(`${SUBSERVER}/api/files`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
          credentials: 'include',
          body: JSON.stringify(correctionArrayFilesBody),
        });
      }

      const response = await fetchPost(
        `/quarries/${window.location.href.split('/').reverse()[0]}`,
        'PATCH',
        clearObject(correactData),
      );

      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setSending(false);
        return;
      }

      toast.success('Данные успешно сохранены');
      setUpdatePage(!updatePage);
      setSending(false);
      navigate('/quarries');
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };

  const deleteQuarry = async () => {
    try {
      await fetchPost(`/quarries/${window.location.href.split('/').reverse()[0]}`, 'DELETE', {});
      toast.success('Данные успешно сохранены');
      navigate('/quarries');
    } catch (e) {
      toast.error('Ошибка отправки данных');
      setSending(false);
      console.log(e);
    }
  };
  const addPerson = () => {
    setData({
      ...data,
      contactPersons: [
        ...data?.contactPersons,
        {
          person: '',
          phone: '',
          email: '',
          main:false,
        },
      ],
    });
  };
  const deleteContact = async (id: any, value: number) => {
    try {
      const response = await fetchPost(`/information/contact-person/${id}`, 'DELETE', {
        communicationType: 'quarries',
        typeBelongsId: quarry?.id,
      });
      setData(prevState => {
        const copy = { ...prevState };
        copy.contactPersons = [...copy.contactPersons];
        copy.contactPersons.splice(value, 1);
        return copy;
      });
    } catch (e) {
      console.log(e);
    }
  };
  const changeValueContact = (key, value, input) => {
    setData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.contactPersons.find((el, index) => index === key).person = value;
          break;
        }
        case 'phone': {
          copy.contactPersons.find((el, index) => index === key).phone = value;
          break;
        }
        case 'email': {
          copy.contactPersons.find((el, index) => index === key).email = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };
  const createValidateTime = () => {
    if (data.workingHoursType.workFrom !== '' && data.workingHoursType.workTo !== '') {
      const timeFrom = data.workingHoursType.workFrom?.format('HH:mm');
      const to = data.workingHoursType.workTo?.format('HH:mm');
      const result = timeFrom?.split(':')[0] - to?.split(':')[0] < 4 && timeFrom !== '' && to !== '' ? 'error' : '';
      setValidateTime(result);
    }
  };
  useEffect(() => {
    createValidateTime();
  }, [data.workingHoursType.workTo]);
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  useEffect(() => {
    getData().catch(console.error);
  }, [updatePage]);

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
    }));
  }, [valueAddress]);

  return (
    <>
      {skeletonQuarry ? (
        <PageWrapper>
          <SkeletonQuarry />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <Link className="button-back" to="/quarries">
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            Вернуться к списку карьеров
          </Link>
          <div className={'supplier-quarry-page'}>
            <div className={'supplier-quarry-page__title textH4 textH4_medium'}>{quarry?.name}</div>
            <div className={'page-content-inputs-box'}>
              <div>
                <div style={{ marginBottom:isMobile? '8px' : '0' }} className={'page-content-inputs-box__title textH5 textH5_medium'}>
                  1. Наименование и тип карьера
                  <sup>&nbsp;*</sup>
                </div>
              </div>
              <div>
                <div className={'page-content-inputs-box__input'}>
                  <InputText
                    type={''}
                    disabled={false}
                    isLabel={true}
                    value={data.name}
                    setValue={value => setData({ ...data, name: value })}
                    margin={'0'}
                    widthHelp={isMobile ? 280 : 400}
                    error={true}
                    showError={validateName(data.name)}
                    helpText={'Введите наименование карьера. Например: “Самойловский карьер”'}
                    textError={validateName(data.name) ?
                        'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                    placeholder={'Наименование'}
                  />
                </div>
                <div className={'page-content-inputs-box__select'}>
                  <CustomSelect
                    error={!data.quarryType}
                    options={[
                      { value: 'quarry', label: 'Карьер' },
                      { value: 'transshipment', label: 'Перевалка' },
                    ]}
                    defaultValue={quarryDataOptions.filter(({ value }) => value === data.quarryType)}
                    setValue={value => setData({ ...data, quarryType: value.value })}
                    placeholder={'Тип'}
                    isClearable={false}
                    isSearchable={false}
                    noOptionsMessage={null}
                  />
                </div>
              </div>
            </div>
            <div className={'page-content-inputs-box'}>
              <div>
                <div style={{ marginBottom:isMobile? '4px' : '0' }} className={'page-content-inputs-box__title textH5 textH5_medium'}>
                  2. Адрес карьера
                </div>
              </div>
              <div>
                <div className={'page-content-inputs-box__sub-title textH6'}>
                  Фактический адрес карьера
                  <sup>&nbsp;*</sup>
                </div>
                <div className={'page-content-inputs-box__input'}>
                  <CustomAddressInput
                    placeholder={'Адрес'}
                    valueAddress={valueAddress}
                    setValueAddress={setValueAddress}
                  />
                </div>
                <div className={'page-content-inputs-box__sub-title textH6'}>Широта и Долгота</div>
                <div className={'page-content-inputs-box__inputs'}>
                  <InputText
                    type={'number'}
                    disabled={false}
                    isLabel={true}
                    value={data?.address?.latitude}
                    error={true}
                    setValue={value =>
                      setData(prevState => ({
                        ...prevState,
                        address: { ...data.address, latitude: Number(value) },
                      }))
                    }
                    margin={'0'}
                    widthHelp={260}
                    helpText={'Введите широту адреса карьера. Например: “55.71656”'}
                    textError={'Поле обязательное для заполнения'}
                    placeholder={'Широта'}
                  />
                  <InputText
                    type={'number'}
                    disabled={false}
                    isLabel={true}
                    value={data?.address?.longitude}
                    error={true}
                    setValue={value =>
                      setData(prevState => ({
                        ...prevState,
                        address: { ...data.address, longitude: Number(value) },
                      }))
                    }
                    margin={'0'}
                    widthHelp={260}
                    helpText={'Введите долготу адреса карьера. Например: “55.71656”'}
                    textError={'Поле обязательное для заполнения'}
                    placeholder={'Долгота'}
                  />
                </div>
                <div className={'page-content-inputs-box__map'}>
                  <div style={{ textAlign: 'end' }}>
                    <ButtonTextTransparent
                      onClick={() => setOpenMap(!openMap)}
                      children={openMap ? 'Скрыть карту' : 'Показать карту'}
                      addClass={''}
                    />
                  </div>
                  {openMap && <CustomYMap latitude={data?.address?.latitude} longitude={data?.address?.longitude} />}
                </div>
              </div>
            </div>
            <div className={'page-content-inputs-box'}>
              <div>
                <div style={{ marginBottom:isMobile? '8px' : '0' }} className={'page-content-inputs-box__title textH5 textH5_medium'}>
                  3. График работы и телефон диспетчера
                  <sup>&nbsp;*</sup>
                </div>
              </div>
              <div>
                <div className={'aside-popup-content-box__input'}>
                  <div>
                    <div className={'orders-page-popup-box__radio'}>
                      <RadioBoxCircle
                        check={data.workingHoursType.name === 'aroundTheClock'}
                        onInput={value =>
                          setData({
                            ...data,
                            workingHoursType: { ...data.workingHoursType, name: 'aroundTheClock' },
                          })
                        }
                        name={'TypeOfAcceptance1'}
                        id={'5'}
                        value={'Круглосуточная'}
                      />
                    </div>
                    <div className={'orders-page-popup-box__radio'}>
                      <RadioBoxCircle
                        check={data.workingHoursType.name === 'daytime'}
                        onInput={value =>
                          setData({
                            ...data,
                            workingHoursType: { ...data.workingHoursType, name: 'daytime' },
                          })
                        }
                        name={'TypeOfAcceptance1'}
                        id={'6'}
                        value={'Дневная'}
                      />
                    </div>
                  </div>
                  <div className={'orders-page-popup-box__box-time'}>
                    <div
                      className={'orders-page-popup-box__time'}
                      style={{
                        width: '265px',
                      }}
                    >
                      <TimePicker
                        value={data.workingHoursType.workTo}
                        showNow={false}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(time, timeString) => changeTime(time, timeString, 'workTo', 'workTimeTo')}
                        disabled={data.workingHoursType.name !== 'daytime'}
                        defaultValue={data.workingHoursType.defaultWorkTo}
                        open={openLeftTimer}
                        onClick={() => setOpenLeftTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(true)}
                      />
                    </div>
                    <div className={'orders-page-popup-box__or'}>до</div>
                    <div
                      className={'orders-page-popup-box__time'}
                      style={{
                        width: '265px',
                      }}
                    >
                      <TimePicker
                        showNow={false}
                        value={data.workingHoursType.workFrom}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(time, timeString) => changeTime(time, timeString, 'workFrom', 'workTimeFrom')}
                        disabled={data.workingHoursType.name !== 'daytime'}
                        open={openRightTimer}
                        onClick={() => setOpenRightTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(false)}
                        status={validateTime}
                        defaultValue={data.workingHoursType.defaultWorkFrom}
                      />
                    </div>
                  </div>
                </div>
                <MyPhoneInput
                  disabled={false}
                  setIsError={setIsError}
                  isError={isError}
                  isActive={helpPhone}
                  setIsActive={setHelpPhone}
                  value={data.dispatcherPhone}
                  onChange={value => setData({ ...data, dispatcherPhone: value })}
                  isFunction={null}
                  btnHelp={true}
                />
                {isError && <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>}
              </div>
            </div>
            {
              <div className={'page-content-inputs-box'}>
                <div>
                  <div style={{ marginBottom:isMobile? '8px' : '0' }} className={'page-content-inputs-box__title textH5 textH5_medium'}>
                    4. Ответственный сотрудник
                    <sup>&nbsp;*</sup>
                  </div>
                </div>
                <div>
                  <div className={'page-content-inputs-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={data.responsibleEmployee.person.person}
                      setValue={value =>
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            person: { ...data.responsibleEmployee.person, person: value },
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={validateName(data.responsibleEmployee.person.person)}
                      helpText={''}
                      textError={validateName(data.responsibleEmployee.person.person) ?
                          'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                      placeholder={'Фамилия Имя'}
                    />
                  </div>
                  <div className={'page-content-inputs-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={data.responsibleEmployee.email.email}
                      setValue={value =>
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            email: { ...data.responsibleEmployee.email, email: value?.replace(/\s/g, '') },
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={!validateEmail(data.responsibleEmployee.email.email)}
                      helpText={''}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Электронная почта'}
                    />
                  </div>
                  <MyPhoneInput
                    style={{ marginBottom:isMobile?'8px':'0' }}
                    disabled={false}
                    setIsError={setIsErrorSecond}
                    isError={isErrorSecond}
                    isActive={helpPhoneSecond}
                    setIsActive={setHelpPhoneSecond}
                    value={data.responsibleEmployee.phone.phone}
                    onChange={value =>
                      setData({
                        ...data,
                        responsibleEmployee: {
                          ...data.responsibleEmployee,
                          phone: { ...data.responsibleEmployee.phone, phone: value },
                        },
                      })
                    }
                    isFunction={null}
                    btnHelp={true}
                  />
                  {isErrorSecond && (
                      <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>
                  )}
                </div>
                <div className={'page-content-inputs-box'}>
                  <div>
                    <div className={'page-content-inputs-box__title textH5 textH5_medium'}>5. Доп контакты</div>
                  </div>
                  <div>
                    {data?.contactPersons?.length ? (
                      <div>
                        {data?.contactPersons?.map((el, index) => (
                          <div key={index}>
                            <div className={'objectPage-content__delete'}>
                              <div
                                className={'objectPage-content__delete-title'}
                              >{`Дополнительный контакт № ${index + 1}`}</div>
                              {!isMobile && (
                                <div
                                  className={'objectPage-content__delete-button'}
                                  onClick={() => deleteContact(el.id, index)}
                                >
                                  <span>
                                    <StopSvg />
                                  </span>
                                  Удалить
                                </div>
                              )}
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={false}
                                isLabel={true}
                                value={data.contactPersons[index]?.person}
                                setValue={value => changeValueContact(index, value, 'person')}
                                margin="0"
                                fill={true}
                                error={true}
                                showError={validateName(data.contactPersons[index]?.person)}
                                helpText={''}
                                widthHelp={0}
                                textError={validateName(data.contactPersons[index]?.person)?
                                    'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                                placeholder={'ФИО'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={false}
                                isLabel={true}
                                value={data.contactPersons[index]?.email}
                                setValue={value => changeValueContact(index, value, 'email')}
                                margin="0"
                                fill={true}
                                error={true}
                                showError={!validateEmail(data.contactPersons[index]?.email)}
                                helpText={''}
                                widthHelp={0}
                                textError={'Поле обязательное для заполнения'}
                                placeholder={'Электронная почта'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <MyPhoneInput builtInErrorState={true}
                                disabled={false}
                                placeholder="Номер телефона"
                                setIsError={''}
                                isError={''}
                                isActive={true}
                                setIsActive={null}
                                value={data.contactPersons[index]?.phone}
                                onChange={value => changeValueContact(index, value, 'phone')}
                                isFunction={null}
                                btnHelp={false}
                              />
                            </div>
                            {isMobile && (
                              <div
                                className={'objectPage-content__delete-button'}
                                onClick={() => deleteContact(el.id, index)}
                              >
                                <span>
                                  <StopSvg />
                                </span>
                                Удалить
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={'objectsPage-popup-box__add-button'}>
                      <ButtonFilled
                          widthSvg={'14px'}
                          text={'Добавить контакт'}
                          svg={<CirclePlusSvg />}
                          width={'220px'}
                          onClick={addPerson}/>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={'page-content-inputs-box'}>
               <div style={{ marginBottom:isMobile? '8px' : '0' }} className={'page-content-inputs-box__title textH5 textH5_medium'}>
                 6. Фотографии и схема проезда
               </div>
              <div>
                <div>
                  <div style={{ marginBottom:'40px' }}>
                    <ButtonNotFilled
                        color={'#FF641D'}
                        text={showImages ? 'Скрыть' : 'Показать'}
                        width={'100%'}
                        onClick={toggleImages}/>
                  </div>
                  {showImages && (
                    <div className={'statistics-page-popup__image'}>
                      <InputUploading
                        onChangeFunction={({ target }) => loadingImages(setUploadImages, images, target, 5)}
                        text={'загрузите документы'}
                        subText={'Не более 5 файлов в формате JPG, PNG, GIF или HEIC, до 50 мегабайт'}
                      />
                      {images.map(
                        ({ id, location, name, size, communicationCategory, isSize, date, isDate, mimeType }) => (
                          <div className="image-container__orange-underline" key={id}>
                            <ImageContainer
                              id={id}
                              value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                              name={name}
                              size={size}
                              isSize={isSize}
                              date={date}
                              isDate={isDate}
                              type={mimeType}
                              openPopUp={() =>
                                (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                              }
                            >
                              <ButtonClose
                                id={id}
                                onClick={({ target }) => removeImage(target, images, setImages)}
                                hidden=""
                              />
                            </ImageContainer>
                          </div>
                        ),
                      )}
                      {uploadimages?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container__orange-underline" key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => removeImage(target, images, setImages)}
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className={'page-content-inputs-box__button'}>
                    <ButtonFilled disabled={!ValidateErrorFields()} onClick={sendQuarryData} text={'Сохранить'} width={'100%'}/>
                  </div>
                  <div className={'page-content-inputs-box__button'}>
                    <ButtonNotFilled
                        widthSvg={'18px'}
                        svg={<Basket />}
                        color={'#FF641D'}
                        text={'Удалить карьер'}
                        width={'100%'}
                        onClick={deleteQuarry}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
