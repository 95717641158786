import React from 'react';

const CheckSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="check">
                <path id="Vector" d="M14.3332 4.94L6.33317 12.94L2.6665 9.27333L3.6065 8.33333L6.33317 11.0533L13.3932 4L14.3332 4.94Z" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default CheckSvg;