import React from 'react';

export const SkeletonCarrierProfile = () => {
  return (
    <div className="SkeletonCarrierProfile">
      <div className="SkeletonCarrierProfile__name"></div>
      <div className="SkeletonCarrierProfile__content">
        <div className="SkeletonCarrierProfile__form">
          <div className="SkeletonCarrierProfile__title"></div>
          <div className="SkeletonCarrierProfile__inputs">
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
          </div>
        </div>
        <div className="SkeletonCarrierProfile__line"></div>
        <div className="SkeletonCarrierProfile__form">
          <div className="SkeletonCarrierProfile__title"></div>
          <div className="SkeletonCarrierProfile__inputs">
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
          </div>
        </div>
        <div className="SkeletonCarrierProfile__line"></div>
        <div className="SkeletonCarrierProfile__form">
          <div className="SkeletonCarrierProfile__title"></div>
          <div className="SkeletonCarrierProfile__inputs">
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__input"></div>
            <div className="SkeletonCarrierProfile__button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};