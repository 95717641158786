import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Context } from './context/context';
import AppRoutes from './router/AppRoutes';
import ScrollTop from './common/ScrollTop';
import 'react-dadata/dist/react-dadata.css';
import 'react-calendar/dist/Calendar.css';
import { User } from './common/user/user.type';
import { localStorageRead } from './common/user/local-storage.helper';
import { AUTHSUBSERVER } from './common/sub-server.constant';
import { logout } from './common/logout.mapping';
import { fetchGet } from './common/proxy-1C-fetch-auth';

export function App() {
  const [helpTitle, setHelpTitle] = useState('');
  const [helpDescription, setHelpDescription] = useState('');
  const [openDescription, setOpenDescription] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [globalState, setGlobalState] = useState('');
  const [notices, setNotices] = useState();
  const [user, setUser] = useState<User>(localStorageRead());
  const getData = async () => {
    try {
      const requestOptions: any = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      };
      const responseRefresh = await fetch(`${AUTHSUBSERVER}/api/auth/refresh_v2`, requestOptions);
      if (responseRefresh.status === 401) {
        const responseRefreshSecond = await fetch(`${AUTHSUBSERVER}/api/auth/refresh_v2`, requestOptions);
        if (responseRefreshSecond.status === 401) {
          logout(user, setUser);
        }
      }
      const userLs = await JSON.parse(localStorage.getItem('user'));
      if (userLs?.type !== 'buyer') {
        const responseNotice = await fetchGet('/notices', {});
        await setNotices(responseNotice?.response);
      }
      localStorage.setItem('apiKey', responseRefresh.headers.get('Authorization'));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    const intervalCall = setInterval(() => {
      getData();
    }, 180000);

    return () => {
      clearInterval(intervalCall);
    };
  }, []);
  return (
    <Context.Provider
      value={{
        user,
        setUser,
        notices,
        startTour,
        setStartTour,
        setNotices,
        globalState,
        setGlobalState,
        openDescription,
        setOpenDescription,
        helpTitle,
        setHelpTitle,
        helpDescription,
        setHelpDescription,
      }}
    >
      <BrowserRouter>
        <ScrollTop />
        <AppRoutes />
      </BrowserRouter>
    </Context.Provider>
  );
}
