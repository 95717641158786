import React, { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './CustomRangePicker.css';
import { DatePicker, Space, TimeRangePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import ButtonFilled from '../ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import ButtonNotFilled from '../ButtonNotFilled/ButtonNotFilled';

interface IProps {
  width?: string;
  placeholder?: [string, string];
  open: boolean;
  isPresets?: boolean;
  dateRange: any;
  setDateRange: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sendRequest?: (startDate: any, finishDate: any) => Promise<void>;
  height?: string;
}

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'DD.MM.YYYY';

const CustomRangePicker: React.FC<IProps> = ({
  width = '228px',
  placeholder,
  sendRequest,
  open,
  dateRange,
  setDateRange,
  setOpen,
  isPresets = false,
  height = '48px',
}) => {
  const [rangePickerDate, setRangePickerDate] = useState<any>([dayjs(new Date()), dayjs(new Date())]);
    const isMobile = useMediaQuery({ maxWidth: 768 });
  const createCustomRangePickerFooter = () => {
    return (
      <div className={'containerCustomRangePickerBtn'}>
          <ButtonNotFilled
              width={isMobile?'100%':'140px'}
              text={'Отменить'}
              sizeText={'small'}
              onClick={() => {
                setOpen(false);
                setDateRange(['', '']);
              }}
          />
          <ButtonFilled
              height={'40px'}
              width={isMobile?'100%':'140px'}
              text={'Выбрать'}
              sizeText={'small'}
              onClick={() => {setOpen(false);
              setDateRange(rangePickerDate);
          }}
          />
      </div>
    );
  };
  const onChangeRangePicker = (dates, dateStrings) => {
    setRangePickerDate([
      dates[0].set('hour', 0).set('minute', 0).set('second', 0),
      dates[1].set('hour', 23).set('minute', 59).set('second', 59),
    ]);
  };
  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: '7 дней', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: '14 дней', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: '30 дней', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: '90 дней', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <div style={{ width: width }}>
      <RangePicker
        popupClassName={isPresets ? 'normalWidthRange' : ''}
        style={{ width: width, height: height, paddingLeft: '10px', paddingRight: '5px' }}
        locale={locale}
        presets={isPresets ? rangePresets : []}
        allowClear={false}
        open={open}
        placeholder={placeholder}
        onClick={() => setOpen(true)}
        renderExtraFooter={createCustomRangePickerFooter}
        format={dateFormat}
        value={dateRange}
        onChange={onChangeRangePicker}
      />
    </div>
  );
};

export default CustomRangePicker;
