import React from 'react';

const HandShakeSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="handshake">
                <path id="Vector" d="M17.6343 9.32503H22.6343L28.1177 3.82503C28.2726 3.66881 28.4569 3.54482 28.66 3.46021C28.8631 3.37559 29.081 3.33203 29.301 3.33203C29.521 3.33203 29.7389 3.37559 29.942 3.46021C30.1451 3.54482 30.3294 3.66881 30.4843 3.82503L34.7843 8.1417C35.0947 8.45397 35.269 8.87639 35.269 9.31669C35.269 9.757 35.0947 10.1794 34.7843 10.4917L30.9677 14.325H17.6343V17.6584C17.6343 18.1004 17.4587 18.5243 17.1462 18.8369C16.8336 19.1494 16.4097 19.325 15.9677 19.325C15.5256 19.325 15.1017 19.1494 14.7892 18.8369C14.4766 18.5243 14.301 18.1004 14.301 17.6584V12.6584C14.301 11.7743 14.6522 10.9265 15.2773 10.3013C15.9024 9.67622 16.7503 9.32503 17.6343 9.32503ZM7.63433 17.6584V24.325L3.81766 28.1417C3.50724 28.454 3.33301 28.8764 3.33301 29.3167C3.33301 29.757 3.50724 30.1794 3.81766 30.4917L8.11766 34.8084C8.2726 34.9646 8.45693 35.0886 8.66003 35.1732C8.86313 35.2578 9.08098 35.3014 9.30099 35.3014C9.52101 35.3014 9.73886 35.2578 9.94196 35.1732C10.1451 35.0886 10.3294 34.9646 10.4843 34.8084L17.6343 27.6584H24.301C24.743 27.6584 25.1669 27.4828 25.4795 27.1702C25.7921 26.8576 25.9677 26.4337 25.9677 25.9917V24.325H27.6343C28.0764 24.325 28.5003 24.1494 28.8128 23.8369C29.1254 23.5243 29.301 23.1004 29.301 22.6584V20.9917H30.9677C31.4097 20.9917 31.8336 20.8161 32.1462 20.5035C32.4587 20.191 32.6343 19.7671 32.6343 19.325V17.6584H20.9677V19.325C20.9677 20.2091 20.6165 21.0569 19.9913 21.6821C19.3662 22.3072 18.5184 22.6584 17.6343 22.6584H14.301C13.4169 22.6584 12.5691 22.3072 11.944 21.6821C11.3189 21.0569 10.9677 20.2091 10.9677 19.325V14.325L7.63433 17.6584Z" fill="currentColor"/>
            </g>
        </svg>
    );
};
export default HandShakeSvg;