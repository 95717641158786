import React, { useEffect, useState } from 'react';
import styles from './IndividualMaterialPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    convertNameFromValueMaterial,
    optionsKindOfMaterial,
    svgByMaterial,
} from '../constans';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import { CustomSelect } from '../../../components/UI/select/CustomSelect';
import { useMediaQuery } from 'react-responsive';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { IMaterials, IOptions } from '../type';
import uuid from 'react-uuid';
import FileDocumentSvg from '../../../components/UI/svg-icon/FileDocumentSvg';
import { clearObject } from '../../../common/ClearObject.helper';
import CustomTooltip from "../../../components/newUI/CustomTooltip/CustomTooltip";
import {closePopUp, openPopUp} from "../../../common/open-close-popup.function";
import {ImageContainer} from "../../../components/ImageContainer";
const IndividualMaterialPage = () => {
    const { pathname } = useLocation();
    const currentMaterial = pathname.split('/')[pathname.split('/').length-3];
    const currentShowcaseId = pathname.split('/')[pathname.split('/').length-1];
    const [isFirstRender,setIsFirstRender] = useState<number>(0);
    const [materials, setMaterials] = useState<IMaterials[]>([]);
    const [materialKind,setMaterialKind] = useState<IOptions>(optionsKindOfMaterial[currentMaterial][0]);
    const [image, setImage] = useState('');
    const [popUpActive, setPopUpActive] = useState(false);

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const openPopupWithImg = src => {
        setImage(src);
        setPopUpActive(openPopUp);
    };
    const getMaterialById = async () => {
        const kind =localStorage.getItem('currentKindMaterialForSelect');
        try {
            const requestOption={
                breed:currentMaterial,
                showcaseId:currentShowcaseId,
                kind:!isFirstRender?kind:materialKind.value
            };
            const response = await fetchGet(`/supplier/materials`, clearObject(requestOption));
            const responseMaterial:IMaterials[] = response?.response?.map(material=>{
                return {
                    ...material,
                    idShowCase:currentShowcaseId,
                    minimalPrice:Math.min.apply(Math, (material?.prices?.map(item=>Number(item.value))))
                };
            });
            setMaterials(responseMaterial);
            if (response.error) {
                const errorMessages = response.message.map(message => (
                    <p>
                        {message}
                        <br />
                        <br />
                    </p>
                ));
                toast.error(<div>{errorMessages}</div>);
                return;
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(()=>{
        getMaterialById();
        const kind =localStorage.getItem('currentKindMaterialForSelect');
        if(kind!=='null'){
            setMaterialKind({ value: kind, label: kind },);
        }
    },[]);

    useEffect(()=>{
        if(isFirstRender>=1){
            getMaterialById();
        }
        setIsFirstRender(isFirstRender+1);
    },[materialKind]);

    return (
        <PageWrapper>
            <div className={styles.container}>
                <div className="button-back" onClick={()=>navigate(`/showcases/${currentShowcaseId}`)}>
                    <div className="button-back__svg">
                        <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    Вернуться к витрине
                </div>
                <div className={styles.topTitleContainer}>
                    <div className={styles.titleAndButton}>
                        <p className={styles.titleText}>{convertNameFromValueMaterial[currentMaterial]}</p>
                        {!isMobile &&
                            <ButtonFilled
                                svg={<NewPlusSvg/>}
                                text={'Добавить материал'}
                                width={'195px'}
                                sizeText={'small'}
                                onClick={()=>navigate(`/showcases/materials/${currentMaterial}/${currentShowcaseId}`)}
                            />

                        }
                    </div>
                    <div className={styles.countProductContainer}>
                        Всего {materials?.length} товара
                    </div>
                </div>
                {isMobile &&
                    <ButtonFilled
                        svg={<NewPlusSvg/>}
                        text={'Добавить материал'}
                        width={'100%'}
                        sizeText={'small'}
                        onClick={()=>navigate(`/showcases/materials/${currentMaterial}/${currentShowcaseId}`)}
                    />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.selectAndTextContainer}>
                        <p className={styles.textSelect}>Выберите вид</p>
                        <div className={styles.selectContainer}>
                            <CustomSelect
                                options={optionsKindOfMaterial[currentMaterial]}
                                setValue={setMaterialKind}
                                placeholder={''}
                                isClearable={false}
                                error={false}
                                isSearchable={false}
                                noOptionsMessage={''}
                                isDisabled={false}
                                value={materialKind}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.cardsContainer}>
                    {materials?.map((material)=>(
                        <div id={uuid()} className={styles.cardContainer}
                             onClick={()=>navigate
                             (`/showcases/materials/edit/${material.breed}/${material.id}/${material.idShowCase}`)}
                        >
                            {isMobile ?
                                <>
                                    <div className={styles.cardContent}>
                                        <div className={styles.titleAndShowImageCard}>
                                            <p className={styles.titleCard}>{material.name ? material.name : 
                                                convertNameFromValueMaterial[material.breed] + ' ' +
                                                material?.properties?.find(item=> item.title === 'kind').value.toLowerCase()}
                                            </p>
                                            <CustomTooltip
                                                openTooltipOnClick={true}
                                                svg={<FileDocumentSvg/>}
                                                widthTooltip={'fit-content'}
                                                MobileSideTooltip={'left'}
                                                text={
                                                    <div style={{display:"flex",flexDirection:'column',gap:'8px'}}>
                                                        {material?.files.map((file)=>(
                                                            <>
                                                                {file.category === 'Паспорт' &&
                                                                    <ImageContainer
                                                                        id={file.id}
                                                                        value={
                                                                            file.mimeType === 'image/png' || file.mimeType === 'image/jpeg' ? null : file.location
                                                                        }
                                                                        name={file.name}
                                                                        size={file.size}
                                                                        isSize={file.isSize}
                                                                        date={file.date}
                                                                        isDate={file.isDate}
                                                                        type={file.mimeType}
                                                                        openPopUp={() =>
                                                                            (file.mimeType === 'image/png' || file.mimeType === 'image/jpeg') &&
                                                                            openPopupWithImg(file.location)
                                                                        }
                                                                    >
                                                                    </ImageContainer>
                                                                }
                                                            </>
                                                        ))}
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className={styles.fractionMaterialContainer}>
                                            {material?.properties?.filter(fraction=>fraction.title === 'fraction').map(item=>(
                                                <p className={styles.fractionMaterial}>{item.value}</p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.imageAndVolumeAndPrice}>
                                        <div className={styles.svgMaterialContainer}>{svgByMaterial[material.breed]}</div>
                                        <div className={styles.volumeAndPrice}>
                                            <div className={styles.infoContainer}>
                                                <p className={styles.infoTextTitleMobile}>Остаток:</p>
                                                <p className={styles.infoTextValueMobile}>{material.volume} тн</p>
                                            </div>
                                            <div className={styles.infoContainer}>
                                                <p className={styles.infoTextTitleMobile}>Цена:</p>
                                                <p className={styles.infoTextValueMobile}>от {material.minimalPrice} ₽/тн</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={styles.svgMaterialContainer}>{svgByMaterial[material.breed]}</div>
                                    <div className={styles.cardContent}>
                                        <div className={styles.titleAndShowImageCard}>
                                            <p className={styles.titleCard}>
                                                {material.name ? material.name : convertNameFromValueMaterial[material.breed] + ' ' +
                                                material?.properties?.find(item=> item.title === 'kind').value.toLowerCase()}
                                            </p>
                                            <CustomTooltip
                                                openTooltipOnClick={true}
                                                svg={<FileDocumentSvg/>}
                                                widthTooltip={'fit-content'}
                                                SideTooltip={'up'}
                                                text={
                                                    <div style={{display:"flex",flexDirection:'column',gap:'8px'}}>
                                                        {material?.files.map((file)=>(
                                                            <>
                                                                {file.category === 'Паспорт' &&
                                                                    <ImageContainer
                                                                        id={file.id}
                                                                        value={
                                                                            file.mimeType === 'image/png' || file.mimeType === 'image/jpeg' ? null : file.location
                                                                        }
                                                                        name={file.name}
                                                                        size={file.size}
                                                                        isSize={file.isSize}
                                                                        date={file.date}
                                                                        isDate={file.isDate}
                                                                        type={file.mimeType}
                                                                        openPopUp={() =>
                                                                            (file.mimeType === 'image/png' || file.mimeType === 'image/jpeg') &&
                                                                            openPopupWithImg(file.location)
                                                                        }
                                                                    >
                                                                    </ImageContainer>
                                                                }
                                                            </>
                                                        ))}
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className={styles.fractionMaterialContainer}>
                                            {material?.properties?.filter(fraction=>fraction.title === 'fraction').map(item=>(
                                                <p className={styles.fractionMaterial}>{item.value}</p>
                                            ))}
                                        </div>
                                        <div className={styles.infoContainer}>
                                            <p className={styles.infoText}>Остаток:</p>
                                            <p className={styles.downBorder}/>
                                            <p className={styles.infoText}>{material.volume} тн</p>
                                        </div>
                                        <div className={styles.infoContainer}>
                                            <p className={styles.infoText}>Цена:</p>
                                            <p className={styles.downBorder}/>
                                            <p className={styles.infoText}>от {material.minimalPrice} ₽/тн</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className={popUpActive ? 'Popup active' : 'Popup'}>
                <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
                <div className="Popup__wrapper">
                    <div>
                        <div>
                            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                            <div className="Popup__content">
                                <div className="Popup__box">
                                    <img src={image} alt={''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default IndividualMaterialPage;