import React, { useState } from 'react';

interface StatusDocumentsItemProps {
  status: string;
  color?: string;
}

export const StatusDocumentsItem = ({ status, color }: StatusDocumentsItemProps) => {
  const [colorClass] = useState(color || 'orange');

  return (
    <div>
      <div className={'StatusItem__circle ' + colorClass}></div>
      <div className={'StatusItem ' + colorClass}>{status}</div>
    </div>
  );
};
