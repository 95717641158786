import React from 'react';
import { CardItem } from './CardItem';

type CardListProps = {
  data: {
    cardNumber: number;
    id: number;
    uuid: string;
    company: string;
    balanceAmount: number;
    users: {
      surname: string | null;
      name: string | null;
      patronymic: string | null;
      fullName: string;
    } | null;
    companies: {
      title: string
    } | null
  }[];
  oil: {value: string, label: string} | undefined | null;
}

export const CardList = ({ data, oil }: CardListProps) => {
  const sortedList = data
    .filter(({ company }) => !oil?.value || company === oil.value);

  return (
    <div className="CardList">
      {sortedList.map(({
         cardNumber,
         id,
         uuid,
         company,
         balanceAmount,
         users,
         companies
      }) =>
        <CardItem
          uuid={uuid}
          key={id}
          id={id}
          cardNumber={cardNumber}
          remainder={balanceAmount}
          logo={company}
          link={true}
          nameCompany={`${users?.surname ? users?.fullName : companies?.title}`}
        />
      )}
    </div>
  );
};
