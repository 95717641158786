import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  title: string;
  quantity?: string | number;
  [key: string]: any;
};

export const TitlePage = ({ title, quantity, ...props }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div {...props} className={`title-page ${isMobile ? 'textH4 textH4_medium' : 'textH3 textH3_medium'}`}>
      {title}
      <span className={isMobile ? 'textH4' : 'textH3'}>{quantity}</span>
    </div>
  );
};
