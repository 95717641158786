import React from 'react';

export const MapMarkerSvg = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="map-marker-multiple-outline">
              <path id="Vector" d="M11.5 9C11.5 7.62 12.62 6.5 14 6.5C15.1 6.5 16.03 7.21 16.37 8.19C16.45 8.45 16.5 8.72 16.5 9C16.5 10.38 15.38 11.5 14 11.5C12.91 11.5 12 10.81 11.64 9.84C11.55 9.58 11.5 9.29 11.5 9ZM5 9C5 13.5 10.08 19.66 11 20.81L10 22C10 22 3 14.25 3 9C3 5.83 5.11 3.15 8 2.29C6.16 3.94 5 6.33 5 9ZM14 2C17.86 2 21 5.13 21 9C21 14.25 14 22 14 22C14 22 7 14.25 7 9C7 5.13 10.14 2 14 2ZM14 4C11.24 4 9 6.24 9 9C9 10 9 12 14 18.71C19 12 19 10 19 9C19 6.24 16.76 4 14 4Z" fill="currentColor"/>
          </g>
      </svg>
  );
};

/*<svg width="100%" height="100%" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 7C8.5 5.62 9.62 4.5 11 4.5C12.1 4.5 13.03 5.21 13.37 6.19C13.45 6.45 13.5 6.72 13.5 7C13.5 8.38 12.38 9.5 11 9.5C9.91 9.5 9 8.81 8.64 7.84C8.55 7.58 8.5 7.29 8.5 7ZM2 7C2 11.5 7.08 17.66 8 18.81L7 20C7 20 0 12.25 0 7C0 3.83 2.11 1.15 5 0.29C3.16 1.94 2 4.33 2 7ZM11 0C14.86 0 18 3.13 18 7C18 12.25 11 20 11 20C11 20 4 12.25 4 7C4 3.13 7.14 0 11 0ZM11 2C8.24 2 6 4.24 6 7C6 8 6 10 11 16.71C16 10 16 8 16 7C16 4.24 13.76 2 11 2Z" fill="currentColor"/>
</svg>*/
