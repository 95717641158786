import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../context/context';
import { PageWrapper } from '../../components/PageWrapper';
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { SkeletonQuarries } from '../../components/skeletons/SkeletonQuarries';
import { EmptyList } from '../../components/EmptyList';
import { Notification } from '../../components/Notification';
import { Button } from '../../components/UI/buttons/Button';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { AsidePopup } from '../../components/AsidePopup';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { ButtonTextTransparent } from '../../components/UI/buttons/ButtonTextTransparent';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { loadingImages } from '../../common/loading-image.function';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { Frame } from '../../components/Frame';
import {
  ErrorFields,
  errorFieldsData,
  quarryData,
  QuarryData,
  quarryDataOptions,
  supplierData,
  SupplierQuarriesData,
} from '../../common/quarry-type';
import { FrameDataType } from '../../common/frame-data.type';
import styles from './SupplierQuarriesPage.module.scss';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { TimePicker } from 'antd';
import CustomYMap from '../../components/CustomYMap/CustomYMap';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import CustomAddressInput from '../../components/newUI/CustomAddressInput/CustomAddressInput';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import { SUBSERVER } from '../../common/sub-server.constant';
import { clearObject } from '../../common/ClearObject.helper';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';

export const SupplierQuarriesPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);
  const { user } = useContext(Context);
  const [valueAddress, setValueAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>();
  const [skeletonQuarries, setSkeletonQuarries] = useState(false);
  const [popup, setPopup] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [, setDisabled] = useState(false);
  const [, setSending] = useState(false);
  const [isError, setIsError] = useState(true);
  const [isErrorSecond, setIsErrorSecond] = useState(true);
  const [openMap, setOpenMap] = useState(false);
  const [helpPhone, setHelpPhone] = useState(false);
  const [helpPhoneSecond, setHelpPhoneSecond] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [quarries, setQuarries] = useState<FrameDataType[]>([]);
  const [data, setData] = useState<QuarryData>(quarryData);
  const [supplierQuarriesData, setSupplierQuarriesData] = useState<SupplierQuarriesData>(supplierData);
  const [errorFields,setErrorFields] = useState<ErrorFields>(errorFieldsData);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setSupplierQuarriesData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: timeString,
        [inputTime]: timeString,
      },
    }));
  };
  const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/
        );
  };
  const validateName = (value) => value?.replace(/[А-Яа-яЁё\- ]/gi, '');
  const mainInfoError = Object.values(errorFields).includes(true);
  const additionalInfoError = errorFields.additionalEmployee.map(item=>(
      Object.values(item).includes(true)
  )).includes(true);
  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  const getData = async () => {
    setSkeletonQuarries(true);

    try {
      const response = await fetchGet(`/quarries`, {});
      if (response.response.error) {
        console.log('error');
        toast.error('Ошибка получение данных');
        setSending(false);
        return;
      }

      const quarriesData: FrameDataType[] = [];
      response.response.forEach(
        ({ id, name, address: { address }, quarryType, workSchedulesGraph: { to, from, aroundTheClock } }) => {
          quarriesData.push({
            id: id,
            item: {
              'Наименование карьера': {
                title: {
                  up: name,
                  down: '',
                },
                help: false,
                contentHelp: null,
              },
              'Адрес карьера': {
                title: {
                  up: address,
                  down: '',
                },
                help: false,
                contentHelp: null,
              },
              'Тип карьера': {
                title: {
                  up: quarryType,
                  down: '',
                },
                help: false,
                contentHelp: null,
              },
              'График работы': {
                title: {
                  up: aroundTheClock ? 'Круглосуточно' : ` С ${from} по ${to}`,
                  down: '',
                },
                help: false,
                contentHelp: null,
              },
            },
          });
        },
      );
      setQuarries(quarriesData);
      setTimeout(() => {
        setSkeletonQuarries(false);
      }, 800);
    } catch (e) {
      setSkeletonQuarries(false);
      console.log(e);
    }
  };

  const sendQuarriesData = async () => {
    setDisabled(true);
    setSending(true);

    if (!supplierQuarriesData.name.name || !supplierQuarriesData.quarryType.quarryType.value) {
      setSupplierQuarriesData(prevState => ({
        ...prevState,
        name: { ...prevState.name, error: !supplierQuarriesData.name.name },
        quarryType: { ...prevState.quarryType, error: !supplierQuarriesData.quarryType.quarryType },
        address: {
          ...prevState.address,
          address: { ...prevState.address.address, error: !supplierQuarriesData?.address?.address?.address },
        },
        time: { ...prevState.time, error: !supplierQuarriesData.time.time },
        responsibleEmployee: {
          ...prevState.responsibleEmployee,
          email: {
            ...prevState.responsibleEmployee.email,
            error: !supplierQuarriesData.responsibleEmployee.email.email,
          },
        },
      }));
      if (!data.dispatcherPhone) {
        setIsError(true);
      }
      if (
        !data.responsibleEmployee.phone.phone ||
        !data.responsibleEmployee.person.person ||
        !data.responsibleEmployee.email.email
      ) {
        setIsErrorSecond(true);
        setData({
          ...data,
          responsibleEmployee: {
            ...data.responsibleEmployee,
            email: { ...data.responsibleEmployee.email, error: !data.responsibleEmployee.email.email },
            person: { ...data.responsibleEmployee.person, error: !data.responsibleEmployee.person.person },
          },
        });
      }
      return;
    }

    const correctionTime =
      supplierQuarriesData.workingHoursType.name === 'daytime'
        ? {
            workingHoursType: supplierQuarriesData.workingHoursType.name,
            from: supplierQuarriesData.workingHoursType.workTo,
            to: supplierQuarriesData.workingHoursType.workFrom,
          }
        : {
            workingHoursType: supplierQuarriesData.workingHoursType.name,
          };
    const correctDate = {
      /*trim убирает пробелы в начале и конце строки, replace() - убирает дубликат пробелов*/
      name: supplierQuarriesData.name.name.trim().replace(/ +(?= )/g,''),
      quarryType: supplierQuarriesData.quarryType.quarryType.value.trim().replace(/ +(?= )/g,''),
      dispatcherPhone: data.dispatcherPhone,
      time: correctionTime,
      address: {
        address: valueAddress?.value,
        latitude: Number(data?.address?.latitude),
        longitude: Number(data?.address?.longitude),
      },
      contactPersons: [
        {
          person: data.responsibleEmployee.person.person.trim().replace(/ +(?= )/g,''),
          phone: data.responsibleEmployee.phone.phone,
          main: true,
          email: data.responsibleEmployee.email.email,
        },
        ...data?.contactPersons,
      ],
      files: data.files.length ? data.files : undefined,
    };
    try {
      const response = await fetchPost(`/quarries`, 'POST', clearObject(correctDate));
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'quarries',
          category: 'Карьеры',
          fileBelongsId: response?.response?.id,
          files: images?.map(file => file?.value),
          objectUuid: '',
        },
      ];

      const responseFile = await fetch(`${SUBSERVER}/api/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify(correctionArrayFilesBody),
      });
      if (response.error) {
        const errorMessages = response.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setSending(false);
        return;
      }

      toast.success('Данные успешно сохранены');
      setUpdatePage(!updatePage);
      setSending(false);
      setPopup(closePopUp);
      setData(quarryData);
      setSupplierQuarriesData(supplierData);
      setValueAddress(null);
      setImages([]);
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };
  const addPerson = () => {
    setData({
      ...data,
      contactPersons: [
        ...data.contactPersons,
        {
          person: '',
          phone: '',
          email: '',
          main: false,
          errorPerson:true,
          errorPhone:true,
          errorEmail:true,
        },
      ],
    });
    setErrorFields({
      ...errorFields,
      additionalEmployee: [
          ...errorFields.additionalEmployee,
        {
          errorPerson:true,
          errorEmail:true,
          errorNumber:true,
        },
      ]
    });
  };
  const changeValueContact = (key, value, input) => {
    setData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.contactPersons.find((el, index) => index === key).person = value;
          break;
        }
        case 'phone': {
          copy.contactPersons.find((el, index) => index === key).phone = value;
          break;
        }
        case 'email': {
          copy.contactPersons.find((el, index) => index === key).email = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
    setErrorFields(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.additionalEmployee.find((el, index) => index === key).errorPerson = !!(validateName(data.contactPersons[key]?.person) || !value) ;
          break;
        }
        case 'phone': {
          copy.additionalEmployee.find((el, index) => index === key).errorNumber = (value.length !== 11);
          break;
        }
        case 'email': {
          copy.additionalEmployee.find((el, index) => index === key).errorEmail = !validateEmail(data.contactPersons[key]?.email);
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };
  const deleteContact = (value: number) => {
    setData(prevState => {
      const copy = { ...prevState };
      copy.contactPersons = [...copy.contactPersons];
      copy.contactPersons.splice(value, 1);
      return copy;
    });
    setErrorFields(prevState => {
      const copy = { ...prevState };
      copy.additionalEmployee = [...copy.additionalEmployee];
      copy.additionalEmployee.splice(value, 1);
      return copy;});
  };
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  useEffect(() => {
    const newImages: any = [];
    images.forEach(({ value }) => {
      newImages.push(value);
    });
    setData({ ...data, files: newImages });
  }, [images]);
  useEffect(() => {
    if (!popup) {
      setOpenLeftTimer(false);
      setOpenRightTimer(false);
    }
  }, [popup]);

  useEffect(() => {
    getData().catch(console.error);
  }, [updatePage]);
  useEffect(() => {
    setSupplierQuarriesData(prevState => ({
      ...prevState,
      name: { ...prevState.name, error: false },
      quarryType: { ...prevState.quarryType, error: false },
      address: { ...prevState.address, address: { ...prevState.address.address, error: false } },
      time: { ...prevState.time, error: false },
    }));
  }, [
    supplierQuarriesData.name.name,
    supplierQuarriesData.quarryType.quarryType,
    supplierQuarriesData.address.address.address,
    supplierQuarriesData.time.time,
  ]);
  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
    }));
  }, [valueAddress]);
  return (
    <>
      {skeletonQuarries ? (
        <PageWrapper>
          <SkeletonQuarries />
        </PageWrapper>
      ) : user.active ? (
        <PageWrapper style={{ zIndex: popup ? '9500' : '100' }}>
          <ToastContainer />
          <div className={styles.supplierQuarriesPage}>
            <div className={styles.titleAndAddButton}>
              <TitleMain title={'Карьеры'}/>
              <ButtonFilled text={isMobile? '': 'Добавить карьер'}
                            svg={<NewPlusSvg />}
                            width={isMobile?'32px':'177px'}
                            height={isMobile?'32px':'40px'}
                            onClick={() => setPopup(openPopUp)}
                            sizeText={'small'}
              />
            </div>
            {quarries.length ? (
              <div className={'supplier-quarries-page-content'}>
                <Frame link={'quarries'} items={quarries} gridTemplateColumns={'250px 260px 120px 169px'} />
              </div>
            ) : (
              <div>
                <Notification
                  color={''}
                  text={'В данный момент у вас нет карьеров. Добавьте карьер, чтобы увидеть его в общем списке.'}
                />
                <div className={'supplier-quarries-page'}>
                  <EmptyList
                    title={'Карьеры отсутствуют'}
                    subTitle={'В данный момент у вас нет карьеров. Добавьте карьер, чтобы он отобразился в списке.'}
                  />
                </div>
              </div>
            )}
          </div>
          <AsidePopup open={popup} close={setPopup} >
            <div className={'aside-popup-content'} >
              <div className={'aside-popup-content-header'}>
                <div
                  className={`aside-popup-content-header__title ${isMobile ? 'textH5 textH5_medium' : 'textH3 textH3_medium'}`}
                >
                  Добавление карьера
                </div>
                <div className={'aside-popup-content-header__close'} onClick={() => setPopup(closePopUp)}>
                  <CloseSvg />
                </div>
              </div>
              <div className={'aside-popup-content__wrapper'}>
                <div className={'aside-popup-content-box'}>
                  <div className={'aside-popup-content-box__title textH5 textH5_medium'}>
                    1. Наименование и тип карьера
                    <sup>&nbsp;*</sup>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={supplierQuarriesData?.name?.name}
                      setValue={value => {
                        setSupplierQuarriesData(prevState => ({
                          ...prevState,
                          name: { ...prevState.name, name: value },
                        }));
                        setErrorFields({ ...errorFields, errorName: errorFields.errorName = !!(validateName(value) || !value) });
                      }}
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={validateName(supplierQuarriesData?.name?.name)}
                      helpText={'Введите наименование карьера. Например: “Самойловский карьер”'}
                      textError={validateName(supplierQuarriesData?.name?.name) ?
                          'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                      placeholder={'Наименование'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__select'}>
                    <CustomSelect
                      error={!supplierQuarriesData?.quarryType?.quarryType}
                      options={quarryDataOptions}
                      setValue={value => {
                        setSupplierQuarriesData(prevState => ({
                          ...prevState,
                          quarryType: { ...prevState.quarryType, quarryType: value },
                        }));
                        setErrorFields({ ...errorFields, errorType: errorFields.errorType = !value });
                      }}
                      placeholder={'Тип'}
                      isClearable={false}
                      isSearchable={false}
                      noOptionsMessage={null}
                    />
                  </div>
                </div>
                <div className={'aside-popup-content-box'}>
                  <div className={'aside-popup-content-box__title textH5 textH5_medium'}>2. Адрес карьера</div>
                  <div className={'aside-popup-content-box__sub-title textH6'}>
                    Фактический адрес карьера
                    <sup>&nbsp;*</sup>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <CustomAddressInput
                        placeholder={'Адрес'}
                        valueAddress={valueAddress}
                        setValueAddress={setValueAddress}
                    />
                  </div>
                  <div className={'aside-popup-content-box__sub-title textH6'}>Широта и Долгота</div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={'number'}
                      disabled={false}
                      isLabel={true}
                      value={data?.address?.latitude}
                      error={true}
                      setValue={value => {
                        setData(prevState => ({
                          ...prevState,
                          address: { ...prevState.address, latitude: Number(value) },
                        }));
                        /*setErrorFields({ ...errorFields, errorLatitude: errorFields.errorLatitude = !(String(data?.address?.latitude)) });*/
                      }}
                      margin={'0'}
                      widthHelp={260}
                      helpText={'Введите широту адреса карьера. Например: “55.71656”'}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Широта'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={'number'}
                      disabled={false}
                      isLabel={true}
                      value={data?.address?.longitude}
                      error={true}
                      setValue={value => {
                        setData(prevState => ({
                          ...prevState,
                          address: { ...prevState.address, longitude: Number(value) },
                        }));
                        /*setErrorFields({ ...errorFields, errorLongitude: errorFields.errorLongitude = !(String(data?.address?.longitude)) });*/
                      }}
                      margin={'0'}
                      widthHelp={260}
                      helpText={'Введите долготу адреса карьера. Например: “55.71656”'}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Долгота'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__map'}>
                    <div style={{ textAlign: 'end' }}>
                      <ButtonTextTransparent
                        onClick={() => setOpenMap(!openMap)}
                        children={openMap ? 'Скрыть карту' : 'Показать карту'}
                        addClass={''}
                      />
                    </div>
                    {openMap && <CustomYMap latitude={data?.address?.latitude} longitude={data?.address?.longitude} />}
                  </div>
                </div>
                <div className={'aside-popup-content-box'}>
                  <div className={'aside-popup-content-box__title H4Medium'}>
                    3. График работы и телефон диспетчера
                    <sup>&nbsp;*</sup>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <div>
                      <div className={'orders-page-popup-box__radio'}>
                        <RadioBoxCircle
                          check={supplierQuarriesData.workingHoursType.name === 'aroundTheClock'}
                          onInput={value => {
                            setSupplierQuarriesData({
                              ...supplierQuarriesData,
                              workingHoursType: { ...supplierQuarriesData.workingHoursType, name: 'aroundTheClock' },
                            });
                            setErrorFields({ ...errorFields, errorTimeTo: false, errorTimeFrom:false });
                          }}
                          name={'TypeOfAcceptance1'}
                          id={'5'}
                          value={'Круглосуточная'}
                        />
                      </div>
                      <div className={'orders-page-popup-box__radio'}>
                        <RadioBoxCircle
                          check={supplierQuarriesData.workingHoursType.name === 'daytime'}
                          onInput={value => {
                            setSupplierQuarriesData({
                              ...supplierQuarriesData,
                              workingHoursType: { ...supplierQuarriesData.workingHoursType, name: 'daytime' },
                            });
                            setErrorFields({ ...errorFields,
                              errorTimeTo: !supplierQuarriesData.workingHoursType.workTo, errorTimeFrom:!supplierQuarriesData.workingHoursType.workFrom });
                          }}
                          name={'TypeOfAcceptance1'}
                          id={'6'}
                          value={'Дневная'}
                        />
                      </div>
                    </div>
                    <div className={'orders-page-popup-box__box-time'}>
                      <div className={'orders-page-popup-box__time'}>
                        <TimePicker
                          showNow={false}
                          needConfirm={false}
                          placeholder={'Период времени'}
                          format={'HH:mm'}
                          onChange={(time, timeString) => {
                            changeTime(time, timeString, 'workTo', 'workTimeTo');
                            setErrorFields({ ...errorFields, errorTimeTo: errorFields.errorTimeTo =
                                  !timeString });
                          }}
                          disabled={supplierQuarriesData.workingHoursType.name !== 'daytime'}
                          defaultValue={supplierQuarriesData.workingHoursType.defaultWorkTo}
                          open={openLeftTimer}
                          onClick={() => setOpenLeftTimer(true)}
                          renderExtraFooter={() => customTimePickerButton(true)}
                        />
                      </div>
                      <div className={'orders-page-popup-box__or'}>до</div>
                      <div className={'orders-page-popup-box__time'}>
                        <TimePicker
                          showNow={false}
                          needConfirm={false}
                          placeholder={'Период времени'}
                          format={'HH:mm'}
                          onChange={(time, timeString) => {
                            changeTime(time, timeString, 'workFrom', 'workTimeFrom');
                            setErrorFields({ ...errorFields, errorTimeFrom:
                                  errorFields.errorTimeFrom = !timeString });
                          }}
                          disabled={supplierQuarriesData.workingHoursType.name !== 'daytime'}
                          open={openRightTimer}
                          onClick={() => setOpenRightTimer(true)}
                          renderExtraFooter={() => customTimePickerButton(false)}
                          status={
                            supplierQuarriesData.workingHoursType.workFrom.split(':')[0] -
                              supplierQuarriesData.workingHoursType.workTo.split(':')[0] <
                              4 &&
                            supplierQuarriesData.workingHoursType.workFrom !== '' &&
                            supplierQuarriesData.workingHoursType.workTo !== ''
                              ? 'error'
                              : ''
                          }
                          defaultValue={supplierQuarriesData.workingHoursType.defaultWorkFrom}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <MyPhoneInput
                      disabled={false}
                      setIsError={setIsError}
                      isError={isError}
                      isActive={helpPhone}
                      setIsActive={setHelpPhone}
                      value={data.dispatcherPhone}
                      onChange={value => {
                        setData({ ...data, dispatcherPhone: value });
                        setErrorFields({ ...errorFields, errorNumberOfDispatcher: errorFields.errorNumberOfDispatcher = (value.length !== 11) });
                      }}
                      isFunction={null}
                      btnHelp={true}
                    />
                    {isError && <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>}
                  </div>
                </div>
                <div className={'aside-popup-content-box'}>
                  <div className={'aside-popup-content-box__title H4Medium'}>
                    4. Ответственный сотрудник
                    <sup>&nbsp;*</sup>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={data.responsibleEmployee.person.person}
                      setValue={value => {
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            person: { ...data.responsibleEmployee.person, person: value },
                          },
                        });
                        setErrorFields({
                          ...errorFields, errorPerson: !!(validateName(value) || !value)  });
                      }}
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={validateName(data.responsibleEmployee.person.person)}
                      helpText={''}
                      textError={validateName(data.responsibleEmployee.person.person) ?
                          'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                      placeholder={'Фамилия Имя'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={data.responsibleEmployee.email.email}
                      setValue={value => {
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            email: { ...data.responsibleEmployee.email, email: value.replace(/\s/g, '') },
                          },
                        });
                        setErrorFields({ ...errorFields, errorEmail: !validateEmail(value)  })
                      }}
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={!validateEmail(data.responsibleEmployee.email.email)}
                      helpText={''}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Электронная почта'}
                    />
                  </div>
                  <MyPhoneInput
                    disabled={false}
                    setIsError={setIsErrorSecond}
                    isError={isErrorSecond}
                    isActive={helpPhoneSecond}
                    setIsActive={setHelpPhoneSecond}
                    value={data.responsibleEmployee.phone.phone}
                    onChange={value => {
                      setData({
                        ...data,
                        responsibleEmployee: {
                          ...data.responsibleEmployee,
                          phone: { ...data.responsibleEmployee.phone, phone: value },
                        },
                      });
                      setErrorFields({ ...errorFields, errorNumber: value.length!==11 });
                    }}
                    isFunction={null}
                    btnHelp={true}
                  />
                  {isErrorSecond && (
                    <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>
                  )}
                </div>
                <div className={'aside-popup-content-box'}>
                  <div>
                    <div className={'aside-popup-content-box__title H4Medium'}>5. Доп сотрудник</div>
                  </div>
                  <div>
                    {data.contactPersons.length ? (
                      <div>
                        {data.contactPersons.map((el, index) => (
                          <div key={index}>
                            <div className={'objectPage-content__delete'}>
                              <div
                                className={'objectPage-content__delete-title'}
                              >{`Дополнительный контакт № ${index + 1}`}</div>
                              {!isMobile && (
                                <div
                                  className={'objectPage-content__delete-button'}
                                  onClick={() => deleteContact(index)}
                                >
                                  <span>
                                    <StopSvg />
                                  </span>
                                  Удалить
                                </div>
                              )}
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={false}
                                isLabel={true}
                                value={data.contactPersons[index]?.person}
                                setValue={value => changeValueContact(index, value, 'person')}
                                margin="0"
                                fill={true}
                                error={true}
                                showError={validateName(data.contactPersons[index]?.person)}
                                helpText={''}
                                widthHelp={0}
                                textError={validateName(data.contactPersons[index]?.person) ?
                                    'Допускаются только русские буквы' : 'Поле обязательное для заполнения'}
                                placeholder={'Фамилия Имя'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={false}
                                isLabel={true}
                                value={data.contactPersons[index]?.email}
                                setValue={value => changeValueContact(index, value, 'email')}
                                margin="0"
                                fill={true}
                                error={true}
                                showError={!validateEmail(data.contactPersons[index]?.email)}
                                helpText={''}
                                widthHelp={0}
                                textError={'Поле обязательное для заполнения'}
                                placeholder={'Электронная почта'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <MyPhoneInput builtInErrorState={true}
                                disabled={false}
                                placeholder="Номер телефона"
                                setIsError={''}
                                isError={''}
                                isActive={true}
                                setIsActive={null}
                                value={data.contactPersons[index]?.phone}
                                onChange={value => changeValueContact(index, value, 'phone')}
                                isFunction={null}
                                btnHelp={false}
                              />
                            </div>
                            {isMobile && (
                              <div className={'objectPage-content__delete-button'} onClick={() => deleteContact(index)}>
                                <span>
                                  <StopSvg />
                                </span>
                                Удалить
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={'objectsPage-popup-box__add-button'}>
                      <ButtonFilled
                          widthSvg={'14px'}
                          text={'Добавить контакт'}
                          svg={<CirclePlusSvg />}
                          width={'220px'}
                          onClick={addPerson}/>
                    </div>
                  </div>
                </div>
                <div className={'aside-popup-content-box'}>
                  <div className={'aside-popup-content-box__title H4Medium'}>6. Фотографии и схема проезда</div>
                  <div className={'statistics-page-popup__image'}>
                    <InputUploading
                      onChangeFunction={({ target }) => loadingImages(setImages, images, target, 5)}
                      text={'загрузите документы'}
                      subText={'Не более 5 файлов в формате JPG, PNG, GIF или HEIC, до 50 мегабайт'}
                    />
                    {images.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={type}
                          openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) => removeImage(target, images, setImages)}
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <ButtonFilled
                      width={'100%'}
                      disabled={!(!mainInfoError && !additionalInfoError)}
                      text={'Сохранить'}
                      onClick={sendQuarriesData}/>
                </div>
              </div>
            </div>
          </AsidePopup>
          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className="errorBlock">
            <div className="errorBlock__text">
              Ваш аккаунт не активирован. Чтобы получить доступ к личному кабинету водителя заполните ваши данные
              <span>
                <NavLink to="/profile">здесь</NavLink>
              </span>
            </div>
            <div className="errorBlock__wrapper">
              <div className="errorBlock__content">
                <div className="errorBlock__img">
                  <img src="image/svg/lock.svg" alt="lock" />
                </div>
                {!isMobile && 'Нет доступа'}
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
