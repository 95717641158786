import React from 'react';

export const SkeletonGsm = () => {
  return (
    <div className="SkeletonGSM">
      <div className="SkeletonGSM__title"></div>
      <div className="SkeletonGSM__subTitle"></div>
      <div className="SkeletonGSM__selectBox">
        <div className="SkeletonGSM__select"></div>
      </div>
      <div className="SkeletonGSM__content">
        {[1,2,3,4,5,6,7,8].map((item, index) => <div key={index} className="card">
          <div className="card__titles">
            <div className="card__title"></div>
            <div className="card__subTitle"></div>
          </div>
          <div  className="card__content">
            <div  className="card__text"></div>
            <div className="card__logo"></div>
          </div>
        </div>)}
      </div>
    </div>
  );
};