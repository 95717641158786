import React from 'react';

const HandShakeMobileSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="handshake">
                <path id="Vector" d="M10.5808 5.5958H13.5808L16.8708 2.2958C16.9638 2.20207 17.0744 2.12768 17.1962 2.07691C17.3181 2.02614 17.4488 2 17.5808 2C17.7128 2 17.8435 2.02614 17.9654 2.07691C18.0872 2.12768 18.1978 2.20207 18.2908 2.2958L20.8708 4.8858C21.057 5.07316 21.1616 5.32661 21.1616 5.5908C21.1616 5.85498 21.057 6.10844 20.8708 6.2958L18.5808 8.5958H10.5808V10.5958C10.5808 10.861 10.4754 11.1154 10.2879 11.3029C10.1004 11.4904 9.84601 11.5958 9.58079 11.5958C9.31558 11.5958 9.06122 11.4904 8.87369 11.3029C8.68615 11.1154 8.58079 10.861 8.58079 10.5958V7.5958C8.58079 7.06537 8.79151 6.55666 9.16658 6.18158C9.54165 5.80651 10.0504 5.5958 10.5808 5.5958ZM4.58079 10.5958V14.5958L2.29079 16.8858C2.10454 17.0732 2 17.3266 2 17.5908C2 17.855 2.10454 18.1084 2.29079 18.2958L4.87079 20.8858C4.96376 20.9795 5.07436 21.0539 5.19622 21.1047C5.31808 21.1555 5.44878 21.1816 5.58079 21.1816C5.7128 21.1816 5.84351 21.1555 5.96537 21.1047C6.08723 21.0539 6.19783 20.9795 6.29079 20.8858L10.5808 16.5958H14.5808C14.846 16.5958 15.1004 16.4904 15.2879 16.3029C15.4754 16.1154 15.5808 15.861 15.5808 15.5958V14.5958H16.5808C16.846 14.5958 17.1004 14.4904 17.2879 14.3029C17.4754 14.1154 17.5808 13.861 17.5808 13.5958V12.5958H18.5808C18.846 12.5958 19.1004 12.4904 19.2879 12.3029C19.4754 12.1154 19.5808 11.861 19.5808 11.5958V10.5958H12.5808V11.5958C12.5808 12.1262 12.3701 12.6349 11.995 13.01C11.6199 13.3851 11.1112 13.5958 10.5808 13.5958H8.58079C8.05036 13.5958 7.54165 13.3851 7.16658 13.01C6.79151 12.6349 6.58079 12.1262 6.58079 11.5958V8.5958L4.58079 10.5958Z" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default HandShakeMobileSvg;