import React from 'react';

export const SkeletonProfile = () => {
  return (
    <div className="SkeletonProfile">
      <div className="SkeletonProfile__name"></div>
      <div className="SkeletonProfile__content">
        <div className="SkeletonProfile__form">
          <div className="SkeletonProfile__title"></div>
          <div className="SkeletonProfile__inputs">
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
          </div>
        </div>
        <div className="SkeletonProfile__line"></div>
        <div className="SkeletonProfile__form">
          <div className="SkeletonProfile__title"></div>
          <div className="SkeletonProfile__inputs">
            <div className="SkeletonProfile__checkbox"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
            <div className="SkeletonProfile__input"></div>
          </div>
        </div>
      </div>
    </div>
  );
};