import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SkeletonQuarries = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className={'skeleton-quarries'}>
            <div className={'skeleton-quarries__title'}>
                <div></div>
                <div></div>
            </div>
            <div className={'skeleton-quarries__button'}></div>
            {!isMobile &&
                <div className={'skeleton-quarries__requests'}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div className={'skeleton-quarries__item'}>
                <div></div>
                {isMobile
                    ? <div>
                        <div></div>
                        <div></div>
                    </div>
                    : <div></div>
                }
                {isMobile
                    ? <div>
                        <div></div>
                        <div></div>
                    </div>
                    : <div></div>
                }
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={'skeleton-quarries__item'}>
                <div></div>
                {isMobile
                    ? <div>
                        <div></div>
                        <div></div>
                    </div>
                    : <div></div>
                }
                {isMobile
                    ? <div>
                        <div></div>
                        <div></div>
                    </div>
                    : <div></div>
                }
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};