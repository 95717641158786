import React from 'react';

export const SkeletonObjects = () => {
  return (
    <div className={'skeletonObjects'}>
      <div className={'skeletonObjects__topic animations'}></div>
      <div className={'skeletonObjects__subTopic animations'}></div>
      <div className={'skeletonObjects__titles'}>
        <div className={'skeletonObjects__title animations'}></div>
        <div className={'skeletonObjects__title animations'}></div>
      </div>
      <div className={'skeletonObjects__list'}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map(item =>
          <div key={item} className={'skeletonObjects__item item'}>
            <div className={'item__title animations'}></div>
            <div className={'item__subTitle animations'}></div>
            <div className={'item__icon animations'}></div>
          </div>
        )}
      </div>
      <div className={'skeletonObjects__button animations'}></div>
    </div>
  );
};