import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitlePage } from '../../components/TitlePage';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { Flight } from '../../common/flight-type';
import { NotActiveProfile } from '../../components/NotActiveProfile';
import { CarrierFlightList } from '../../components/CarrierFlightList';
import { StatusTabs } from '../../common/status-tabs.type';
import { Filter } from '../../common/filter.type';
import { ImageContainer } from '../../components/ImageContainer';
import { SuccessSvg } from '../../components/UI/svg-icon/SuccessSvg';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { closePopUp } from '../../common/use-popup.functiopn';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectCustom } from '../../components/UI/select/SelectCustom';
import { SUBSERVER } from '../../common/sub-server.constant';
import useDebounce from '../../CustomHooks/useDebounce';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { loadingImages } from '../../common/loading-image.function';
import { Button } from '../../components/UI/buttons/Button';
import { removeImage } from '../../common/removeImage';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { formPrice } from '../../common/form-price.helper';
import { PaymentStatus1Svg } from '../../components/UI/svg-icon/paymentStatus1Svg';
import { PaymentStatus2Svg } from '../../components/UI/svg-icon/PaymentStatus2Svg';
import { PaymentStatus3Svg } from '../../components/UI/svg-icon/PaymentStatus3Svg';
import { CloseCircleSvg } from '../../components/UI/svg-icon/CloseCircleSvg';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import CustomRangePicker from 'src/components/newUI/CustomRangePicker/CustomRangePicker';
import styles from './CarrierFlightsPage.module.scss';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import {EmptyList} from "../../components/EmptyList";
const statusType = (value: string) => {
  switch (value) {
    case 'Проверен': {
      return (
        <div className="statusType" style={{ color: '#00AEFF' }}>
          <div className="statusTypesvg" style={{ color: '#00AEFF' }}>
            <PaymentStatus1Svg />
          </div>
          Проверен
        </div>
      );
    }

    case 'НаПроверке': {
      return (
        <div className="statusType" style={{ color: '#D000FF' }}>
          <div className="statusTypesvg" style={{ color: '#D000FF' }}>
            <PaymentStatus2Svg />
          </div>
          НаПроверке
        </div>
      );
    }

    case 'ПовторнаяПроверка': {
      return (
        <div className="statusType" style={{ color: '#FF5100' }}>
          <div className="statusTypesvg" style={{ color: '#FF5100' }}>
            <PaymentStatus3Svg />
          </div>
          ПовторнаяПроверка
        </div>
      );
    }

    case 'Отклонен': {
      return (
        <div className="statusType" style={{ color: '#EF4444' }}>
          <div className="statusTypesvg" style={{ color: '#EF4444' }}>
            <CloseCircleSvg />
          </div>
          Отклонен
        </div>
      );
    }
  }
  return '';
};
export const CarrierFlightsPage = () => {
  const statuses: StatusTabs = {
    Активные: {
      statusName: 'active',
      active: true,
    },
    Выполненные: {
      statusName: 'completed',
      active: false,
    },
    Проблемные: {
      statusName: 'problematic',
      active: false,
    },
  };
  const location = useLocation();
  const navigate = useNavigate();
  const optionVolumeActive = [
    { value: 'all', label: 'Все рейсы' },
    { value: 'planned', label: 'Запланированные ' },
    { value: 'in-progress', label: 'В процессе ' },
  ];
  const optionPrice = [
    { value: 'ASC', label: 'Цена по возрастанию ' },
    { value: 'DESC', label: 'Цена по убыванию ' },
  ];
  type TPriceFilter = { value: 'string'; label: 'string' };
  const initialStateSearch =
    localStorage.getItem('filterSearchFieldFlights') === 'null' ? '' : localStorage.getItem('filterSearchFieldFlights');
  const [selectActivityValue, setSelectActivityValue] = useState({ value: 'all', label: 'Все рейсы' });
  const [selectPriceValue, setSelectPriceValue] = useState<'' | TPriceFilter>('');
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState(false);
  const { user } = useContext(Context);
  const [offset, setOffset] = useState<number>(0);
  const [flightsList, setFlightsList] = useState<Flight[]>([]);
  const [images, setImages] = useState<any>([]);
  const [uploadingImages, setUploadingImages] = useState<any>([]);
  const [flightInfo, setFlightInfo] = useState<any>();
  const [skeleton, setSkeleton] = useState(true);
  const [popUpActive, setPopUpActive] = useState(false);
  const [filter, setFilter] = useState<Filter>({
    search: initialStateSearch,
    sort: { field: 'сначала новые', order: 'DESC' },
  });
  const [statusTabs, setStatusTabs] = useState<StatusTabs>(statuses);
  const [status, setStatus] = useState<string>(location.hash ? location.hash.replace('#', '') : 'active');
  const [more, setMore] = useState(10);
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const requestTitles =
    status === 'active'
      ? ['Рейс', 'Загрузка', 'Выгрузка', 'Водитель и ТС', 'Объем и груз', 'Статус рейса', 'Стоимость за рейс']
      : ['Рейс', 'Загрузка', 'Выгрузка', 'Водитель и ТС', 'Объем (Загрузка/выгрузка)', 'Стоимость за рейс'];

  const clearFilters = () => {
    setDateRange(['', '']);
    setSelectActivityValue({ value: 'all', label: 'Все рейсы' });
    setSelectPriceValue('');
    setFilter(prevState => ({
      ...prevState,
      sort: {
        field: 'сначала новые',
        order: 'DESC',
      },
      filter: '',
    }));
  };

  const downloadDocument = async () => {
    try {
      const flights = await fetchPost(`/reports/getFlightsReport`, 'POST', {
        ids: flightsList.map(flight => flight.id),
      });
      const link = document.createElement('a');
      link.download = 'Реестр выполненных рейсов';

      link.href = flights.response;

      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (totalCount > offset) {
      getFlightsList(status, true);
    }
  }, [offset]);
  const getFlightsList = async (status: string, isPagination: boolean) => {
    setSkeleton(true);

    if (!user.apiKey) {
      setSkeleton(false);
      return;
    }
    try {
      const flightsBody = {
        status: status,
        limit: 10,
        searchString: filter.search || '',
        offset: isPagination ? offset : 0,
        flightCombinedStatus: selectActivityValue?.value,
      };

      if (dateRange[0]) {
        flightsBody['startDate'] = dateRange[0].toISOString();
        flightsBody['finishDate'] = dateRange[1].toISOString();
      }
      if (selectPriceValue !== '') {
        flightsBody['orderByAmount'] = selectPriceValue.value;
      }
      const flights = await fetchGet(`/flights`, flightsBody);

      const list: Flight[] = flights.response?.rows.map(item => ({
        ...item,
        date: new Date(item.date),
      }));
      setTotalCount(flights.response.count);
      if (isPagination && offset !== 0) {
        setFlightsList(prevState => [...prevState, ...list]);
      } else {
        setFlightsList(list);
      }

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      console.log(e);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const getFiles = async id => {
    try {
      const responseFile = await fetch(`${SUBSERVER}/api/files/?fileBelongsId=${id}&communicationCategory=flights`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      });
      const dataFile = await responseFile.json();

      await setImages(dataFile?.response);
    } catch (e) {
      console.log(e);
    }
  };

  const sendFiles = async id => {
    setDisabled(true);
    setSending(true);
    if (!images) {
      return;
    }

    try {
      const responsePostFiles = await fetchPost(`/files/`, 'POST', [
        {
          fileBelongsId: Number(id),
          files: uploadingImages?.map(({ value }) => value),
          communicationCategory: 'flights',
          category: 'Транспортные документы',
          objectUuid: flightInfo?.uuid,
        },
      ]);
      const responseRechecking = await fetchPost(`/flights/carrier/re-checking/${id}`, 'PATCH', {});
      if (responseRechecking.error) {
        setDisabled(false);
        setSending(false);
        const errorMessages = responsePostFiles.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      if (responsePostFiles.error) {
        setDisabled(false);
        setSending(false);
        const errorMessages = responsePostFiles.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      getFlightsList(status, false);
      toast.success(`Файл успешно отправлен`);
      setImages([]);
      closePopUp(setPopUpActive);
      setDisabled(false);
      setSending(false);
    } catch (e) {
      setDisabled(false);
      setSending(false);
      console.log(e);
    }
  };
  const deleteFile = async (id, name, communicationCategory) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: flightInfo?.uuid,
      }),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
      const data = await response.json();
      if (data.error) {
        const errorMessages = data.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    clearFilters();
  }, [statusTabs]);

  useEffect(() => {
    setDisabled(!uploadingImages?.length);
  }, [uploadingImages]);

  useEffect(() => {
    setOffset(0);
    getFlightsList(status, false).catch(console.log);
    localStorage.setItem('filterSearchFieldFlights', filter.search);
  }, [status, filter.sort.field, debouncedSearchTerm, selectActivityValue, selectPriceValue, dateRange]);
  const sortFlightStatus = statusName => {
    for (const [key, tab] of Object.entries(statusTabs)) {
      if (key === statusName) {
        setStatus(tab.statusName);
        navigate(`#${tab.statusName}`);
        getFlightsList(tab.statusName, false).catch(console.log);
      }
    }

    setStatusTabs(prevState => ({ ...prevState, ...statusTabs }));
  };
  return (
    <>
      {user.active ? (
        <PageWrapper>
          <ToastContainer />
          <TitleAndSubTitleMain
            title={'Рейсы'}
            subTitle={'Просматривайте ваши рейсы и отслеживайте их статус в реальном времени'}
          />
          <div>
            <div className="FlightSection">
              <div className="FlightSection__requests">
                {Object.entries(statusTabs).map(([statusName, tab]) => (
                  <div
                    key={statusName}
                    onClick={() => sortFlightStatus(statusName)}
                    className={`FlightSection__requestBtn ${tab.statusName === status ? 'active' : ''}`}
                  >
                    {statusName}
                  </div>
                ))}
              </div>
            </div>
            {!isMobile && status === 'completed' && (
              <div className={styles.blockDocument} onClick={() => downloadDocument()}>
                <p className={styles.textDocument}>Сформировать документ</p>
              </div>
            )}
            <div className="FlightSection__content">
              <div>
                <div
                  className={isMobile ? styles.inputsAndSelectorsContainerMobile : styles.inputsAndSelectorsContainer}
                  style={!isMobile ? { margin: '32px 0' } : { margin: '8px 0 24px' }}
                >
                  <div className={styles.inputSearchContainer}>
                    <InputSearch
                      placeholder={'Поиск'}
                      onInput={({ currentTarget: { value: search } }) =>
                        setFilter(prevState => ({ ...prevState, search }))
                      }
                      value={filter.search}
                    />
                  </div>
                  <FilterBox
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                    clearFilters={clearFilters}
                    widthButton={isMobile ? '100%' : '290px'}
                    disabled={!dateRange[0] && selectActivityValue.value === 'all' && selectPriceValue === ''}
                  >
                    {status === 'completed' && (
                      <div>
                        <CustomRangePicker
                          width={'100%'}
                          isPresets={true}
                          open={openCalendar}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          setOpen={setOpenCalendar}
                          placeholder={['Начало', 'Конец']}
                          height="40px"
                        />
                      </div>
                    )}
                    {status === 'active' && (
                      <>
                        {/* <div>
                          <SelectCustom
                            options={optionVolume}
                            maxTagCount={1}
                            maxCount={1}
                            placeholder={'Сначала новые'}
                            mode={null}
                            value={filter.sort.field}
                            setValue={value => {
                              setFilter(prevState => ({
                                ...prevState,
                                sort: {
                                  field: value?.value,
                                  order: value?.value === 'сначала старые' ? 'ASC' : 'DESC',
                                },
                              }));
                            }}
                            disabled={false}
                            status={''}
                            errorText={''}
                          />
                        </div> */}
                        <div>
                          <SelectCustom
                            options={optionVolumeActive}
                            maxTagCount={1}
                            maxCount={1}
                            placeholder={''}
                            mode={null}
                            value={selectActivityValue}
                            setValue={value => setSelectActivityValue(value)}
                            disabled={false}
                            status={''}
                            errorText={''}
                          />
                        </div>
                      </>
                    )}
                    <div>
                      <SelectCustom
                        maxTagCount={1}
                        maxCount={1}
                        placeholder={'По цене'}
                        options={optionPrice}
                        style={{ width: '100%' }}
                        mode={null}
                        value={selectPriceValue ? selectPriceValue : null}
                        setValue={value => setSelectPriceValue(value)}
                        disabled={false}
                        status={''}
                        errorText={''}
                      />
                    </div>
                  </FilterBox>
                </div>
              </div>

              {isMobile && status === 'completed' && (
                <div className={styles.mobileBlockDocument} onClick={() => downloadDocument()}>
                  <p className={styles.textDocument}>Сформировать документ</p>
                </div>
              )}

              {/* <div className="searchContent">
                <div className="searchContent__input">
                  <InputSearchCloseBtn
                    placeholder="Поиск заявки по номеру, статусу и тд"
                    onInput={({ currentTarget: { value: search } }) =>
                      setFilter(prevState => ({ ...prevState, search }))
                    }
                    setValue={setFilter}
                    value={filter.search}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    justifyContent: 'end',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: isMobile ? '100%' : '400px',
                  }}
                >
                  <div className="searchContent__select">
                    <SelectCustom
                      options={optionVolume}
                      maxTagCount={1}
                      maxCount={1}
                      placeholder={'По номенклатуре'}
                      mode={null}
                      value={filter.sort.field}
                      setValue={value => {
                        setFilter(prevState => ({
                          ...prevState,
                          sort: {
                            field: value?.value,
                            order: value?.value === 'сначала старые' ? 'ASC' : 'DESC',
                          },
                        }));
                      }}
                      disabled={false}
                      status={''}
                      errorText={''}
                    />
                  </div>
                  {status === 'active' && (
                    <div className="searchContent__select">
                      <SelectCustom
                        options={optionVolumeActive}
                        maxTagCount={1}
                        maxCount={1}
                        placeholder={''}
                        mode={null}
                        value={selectActivityValue}
                        setValue={value => setSelectActivityValue(value)}
                        disabled={false}
                        status={''}
                        errorText={''}
                      />
                    </div>
                  )}
                </div>
              </div> */}
              {flightsList.length ? (
                <div>
                  <div
                    className={`FlightSection__requestTitle Carrier ${status === 'active' ? 'activeRequestTitle' : 'completedRequestTitle'}`}
                  >
                    {requestTitles.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </div>
                  {skeleton ? (
                    <div className="FlightSection__content">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '485px ',
                        }}
                      >
                        <div>
                          <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CarrierFlightList
                      getFiles={getFiles}
                      more={more}
                      setMore={setMore}
                      flightItems={flightsList}
                      statusTabs={statusTabs}
                      statusName={status}
                      filter={filter}
                      setPopUpActive={setPopUpActive}
                      setFlightInfo={setFlightInfo}
                    />
                  )}
                </div>
              ) : (
                  <EmptyList
                      title={'Рейсы отсутствуют'}
                      subTitle={`В данный момент у вас нет активных рейсов`}
                  />
              )}
            </div>
          </div>

          <div className={popUpActive ? 'PaymentPopUp active' : 'PaymentPopUp'}>
            <div onClick={() => closePopUp(setPopUpActive)} className="PaymentPopUp__white"></div>
            {isMobile && <div className="PaymentPopUp__line"></div>}
            <div className="PaymentPopUp__wrapper">
              <div>
                <div>
                  <div className="PaymentPopUp__title">
                    <div className="number">
                      {flightInfo?.flightName}
                      {statusType(flightInfo?.validationStatus.replace(/\s/g, ''))}
                    </div>
                    <div onClick={() => closePopUp(setPopUpActive)} className="PaymentPopUp__close"></div>
                  </div>
                  <div className="rejectedBlock">
                    <div className="rejectedBlock__text">
                      <div className="rejectedBlock__title">Комментарий диспетчера</div>
                      <div className="rejectedBlock__subTitle">
                        {flightInfo?.validationComment || 'Комментарий отсутствует'}
                      </div>
                    </div>
                    <div className="rejectedBlock__downloaded">
                      <InputUploading
                        onChangeFunction={({ target }) => loadingImages(setUploadingImages, uploadingImages, target, 6)}
                        text={'загрузить накладную'}
                        subText={'В формате JPG, PNG, PDF, HEIC до 50 мегабайт'}
                      />
                    </div>
                    {images?.map(({ id, location, name, size, isSize, date, isDate, mimeType,uuid }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={location}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={mimeType}
                          PopUp={false}
                          openPopUp={() => console.log()}
                          children={
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => {
                                deleteFile(id, name, 'flights');
                                removeImage(target, images, setImages);
                              }}
                              hidden=""
                            />
                          }
                        ></ImageContainer>
                      </div>
                    ))}
                    {uploadingImages?.map(({ id, value, name, size, isSize, date, isDate, type,uuid }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={value}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          PopUp={false}
                          isDate={isDate}
                          type={type}
                          openPopUp={() => console.log()}
                          children={
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => removeImage(target, uploadingImages, setUploadingImages)}
                              hidden=""
                            />
                          }
                        ></ImageContainer>
                      </div>
                    ))}
                    <div className="rejectedBlock__button">
                      <Button
                        left={false}
                        right={false}
                        onClick={() => sendFiles(localStorage.getItem('flightId'))}
                        modifier={''}
                        children={!isMobile ? 'Отправить транспортную накладную' : 'Отправить ТН'}
                        svg={null}
                        colorSvg={''}
                        disabled={disabled}
                        sending={sending}
                        color={''}
                      />
                    </div>
                  </div>

                  <div className="PaymentPopUp__content">
                    <div className="PaymentPopUp__box">
                      <div className="PaymentPopUp__subTitle"> Информация по оплате</div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Общая сумма по рейсу</div>
                        <div className="PaymentPopUp__text">
                          {flightInfo?.volumeDownload && flightInfo?.carrierPrice ? (
                            <span>{formPrice(flightInfo?.volumeDownload * flightInfo?.carrierPrice, 'RUB')}</span>
                          ) : (
                            'Нет данных'
                          )}
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Номенклатура</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument ||
                              'Нет данных'}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Характеристика номенклатуры</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.characteristic ||
                              'Нет данных'}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Количество загрузки</div>
                        <div className="PaymentPopUp__text">
                          <span>{flightInfo?.volumeDownload || 'Нет данных'}</span>
                          <span>{flightInfo?.unitDownload || ''}</span>
                        </div>
                      </div>
                    </div>
                    <div className="PaymentPopUp__box">
                      <div className="PaymentPopUp__subTitle">Информация по документам</div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Номер рейса</div>
                        <div className="PaymentPopUp__text">
                          <span>{flightInfo?.flightName || 'Нет данных'}</span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Дата создания документа</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.dateTn
                              ? flightInfo?.dateTn?.slice(0, 10).split('-').reverse().join('.')
                              : 'Нет данных'}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Номер ТН</div>
                        <div className="PaymentPopUp__text">
                          <span>{flightInfo?.numberTn || 'Нет данных'}</span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Скан ТН</div>
                        <div className="PaymentPopUp__text">
                          <span className={flightInfo?.scan ? 'svgGreen' : 'svgRed'}>
                            {flightInfo?.scan ? <SuccessSvg /> : <CloseSvg />}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Оригинал ТН</div>
                        <div className="PaymentPopUp__text">
                          <span className={flightInfo?.scan ? 'svgGreen' : 'svgRed'}>
                            {flightInfo?.scan ? <SuccessSvg /> : <CloseSvg />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="PaymentPopUp__box">
                      <div className="PaymentPopUp__subTitle">Подробная информация</div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Номер ТС</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.car?.stateNumber
                              ? flightInfo?.car?.carBrand.brand + ' ' + flightInfo?.car?.stateNumber
                              : 'Нет даных'}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">ФИО водителя</div>
                        <div className="PaymentPopUp__text">
                          <span>{flightInfo?.driver?.fullName || 'Нет даных'}</span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Пункт отправления</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.quarry?.address?.address ? flightInfo?.quarry?.address?.address : 'Нет даных'}
                          </span>
                        </div>
                      </div>
                      <div className="PaymentPopUp__item">
                        <div className="PaymentPopUp__text">Пункт назначения</div>
                        <div className="PaymentPopUp__text">
                          <span>
                            {flightInfo?.object?.address?.address ? flightInfo?.object?.address?.address : 'Нет даных'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <TitlePage title={'Рейсы'} quantity={''} />
          <NotActiveProfile role={'перевозчика'} />
        </PageWrapper>
      )}
    </>
  );
};
