import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ClipSvg } from '../svg-icon/ClipSvg';

export const InputUploading = ({ onChangeFunction, text, subText }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className="InputUploading">
            <input
              id="InputUploading"
              type="file"
              accept={'image/, .png,.jpg,.jpeg,.pdf,.heic'}
              onInput={onChangeFunction}
            />
            <label className="InputUploading__label" htmlFor="InputUploading">
                <div className="InputUploading__title">
                    {!isMobile && 'Перетащите файлы сюда или'}
                    <div className="InputUploading__svg">
                        <ClipSvg/>
                    </div>
                    <div className="InputUploading__title InputUploading__title_orange">
                        {text}
                    </div>
                </div>
                <div className="InputUploading__subTitle">
                    {subText}
                </div>
            </label>
        </div>
    );
};