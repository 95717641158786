import React, { Dispatch, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import './CustomDatePicker.css';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs, { Dayjs } from 'dayjs';
import ButtonNotFilled from '../ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  placeholder?: string;
  value?: string|Dayjs;
  setValue: Dispatch<any>;
  disabled?: any;
  width?: string;
  defaultToday?:boolean
  height?: string;
  minDate?:string;
  maxDate?:string
  paddingLeft?: string;
  closingConditionOfCalendar?:any;
}

const CustomDatePicker: React.FC<IProps> = ({
  placeholder,
  value,
  defaultToday=false,
  setValue,
  disabled,
  minDate='',
  maxDate='',
  width = '228px',
  height = '56px',
  paddingLeft = '5px',
  closingConditionOfCalendar
}) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [open, setOpen] = useState<boolean>(false);
    const [valueDate, setValueDate]=useState<string|Dayjs>(dayjs(new Date()));
  const createCustomDatePickerFooter = () => {
    return (
      <div className={'containerCustomRangePickerBtn'}>
          <ButtonNotFilled
              width={isMobile?'100%':'140px'}
              text={'Отменить'}
              sizeText={'small'}
              onClick={() => {
                  setOpen(false);
                  defaultToday?
                      setValue(dayjs(new Date())):
                      setValue(null);
              }}
          />
          <ButtonFilled
              width={isMobile?'100%':'140px'}
              text={'Выбрать'}
              sizeText={'small'}
              onClick={() => {
                  setOpen(false);
                  setValue(valueDate);
              }}
          />
      </div>
    );
  };
    const onChangeRangePicker = (dates, dateStrings) => {
        setValueDate(dates);
  };
    useEffect(()=>{
        setOpen(false);
    },[closingConditionOfCalendar]);
  const dateFormat = 'DD.MM.YYYY';
    const disabledDaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
            let startDate = dayjs(minDate);
            let endDate = dayjs(maxDate);
            return current < startDate.startOf('day') || current > endDate.endOf('day');
    };
  return (
    <div>
      <DatePicker
        disabled={disabled}
        disabledDate={disabledDaysDate}
        style={{ width: width, height: height, paddingLeft: paddingLeft, paddingRight: '5px' ,fontFamily:'Roboto'}}
        open={open}
        format={dateFormat}
        onClick={() => setOpen(true)}
        onChange={onChangeRangePicker}
        value={value}
        placeholder={placeholder}
        locale={locale}
        allowClear={false}
        showToday={false}
        renderExtraFooter={createCustomDatePickerFooter}
      />
    </div>
  );
};

export default CustomDatePicker;
