export const reasonList=[{ value: 'Простой на карьере (очереди)',label: 'Простой на карьере (очереди)' }
    ,{ value: 'Поломка оборудования на карьере', label: 'Поломка оборудования на карьере' },
    { value: 'Плохой заезд на карьер', label: 'Плохой заезд на карьер' },{ value: 'Нет материала на карьере', label: 'Нет материала на карьере' },
    { value: 'Не оплачен материал на карьере', label: 'Не оплачен материал на карьере' },
    { value: 'Не прописан на карьере', label: 'Не прописан на карьере' },
    { value: 'Ограниченное время погрузки на карьере', label: 'Ограниченное время погрузки на карьере' },
    { value: 'Простой на выгрузке - нет приемщика', label: 'Простой на выгрузке - нет приемщика' },
    { value: 'Простой на выгрузке (предыдущий рейс', label: 'Простой на выгрузке (предыдущий рейс)' },
    { value: 'Простой на выгрузке – не качественный материал', label: 'Простой на выгрузке – некачественный материал' },
    { value: 'Простой на выгрузке – неисправно оборудование', label: 'Простой на выгрузке – неисправно оборудование' },
    { value: 'Отказ заказчика от заявки', label: 'Отказ заказчика от заявки' },
    { value: 'Не успевает выполнить рейс до окончания приемки', label: 'Не успевает выполнить рейс до окончания приемки' },
    { value: 'Другая проблема (введите комментарий)', label: 'Другая проблема (введите комментарий)' },
]