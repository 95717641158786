import React from 'react';
import styles from './EmptyContent.module.scss';

type Props = {
    title: string;
    subTitle: string;
    svg: JSX.Element;
}
export const EmptyContent = ({ title, subTitle, svg }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.svg}>{svg}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>
        </div>
    );
};