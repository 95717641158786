// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyContent_container__FSmir {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.EmptyContent_svg__Xam9p {\n  width: 145px;\n}\n\n.EmptyContent_title__7WjOt {\n  color: #373D50;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.EmptyContent_subTitle__DIEUT {\n  color: #6C779C;\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n@media (max-width: 768px) {\n  .EmptyContent_title__7WjOt {\n    font-size: 16px;\n    line-height: 24px;\n  }\n  .EmptyContent_subTitle__DIEUT {\n    font-size: 14px;\n    line-height: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/UI/EmptyContent/EmptyContent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGF;;AADA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAIF;;AAFA;EACE;IACE,eAAA;IACA,iBAAA;EAKF;EAHA;IACE,eAAA;IACA,iBAAA;EAKF;AACF","sourcesContent":[".container{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.svg{\n  width: 145px;\n}\n.title{\n  color: #373D50;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px;\n}\n.subTitle{\n  color: #6C779C;\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n@media (max-width: 768px){\n  .title{\n    font-size: 16px;\n    line-height: 24px;\n  }\n  .subTitle{\n    font-size: 14px;\n    line-height: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmptyContent_container__FSmir",
	"svg": "EmptyContent_svg__Xam9p",
	"title": "EmptyContent_title__7WjOt",
	"subTitle": "EmptyContent_subTitle__DIEUT"
};
export default ___CSS_LOADER_EXPORT___;
