import { StatusTabs } from './status-tabs.type';
import { Filter } from './filter.type';

const defaultOrderStrategy = {
  'ASC': (a, b) => a - b,
  'DESC': (a, b) => b - a
};

export const sortAndFilterListHelper = (
  statusTabs: StatusTabs,
  DeterminantColumnName: string,
  items: Array<Record<string, any>>,
  filter: Filter,
  searchingMechanism,
  orderStrategy = defaultOrderStrategy) => {

  return items
    .filter((item => searchingMechanism(filter.search, item)))
    .sort((a, b) => orderStrategy[filter.sort.order](a[filter.sort.field], b[filter.sort.field]));
};
