import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../context/context';
import { CheckPointItem } from '../../components/UI/CheckPointItem';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { InputText } from '../../components/UI/inputs/InputText';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { FlightState, flightStates } from '../../common/flight-states';
import { SkeletonFlight } from '../../components/skeletons/SkeletonFlight';
import { checkValidation } from '../../common/check-validation.function';
import { ImageContainer } from '../../components/ImageContainer';
import { loadingImages } from '../../common/loading-image.function';
import { SuccessSvg } from '../../components/UI/svg-icon/SuccessSvg';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { toast, ToastContainer } from 'react-toastify';
import { ErrorFullSvg } from '../../components/UI/svg-icon/ErrorFullSvg';
import { Image } from '../../common/image.type';
import { sendDataFlightFunction } from '../../common/send-data-flight.function';
import { validationPhoneNumberFunction } from '../../common/validation-phone-number.function';
import { removeImage } from '../../common/removeImage';
import CustomDatePicker from '../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import QRCode from 'react-qr-code';
import { Modal } from 'antd';
import { SUBSERVER } from '../../common/sub-server.constant';
import { VolumeInput } from '../../components/UI/inputs/VolumeInput';
import styles from './FlightPage.module.scss';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import QrCodeScannerSvg from '../../components/UI/svg-icon/QrCodeScannerSvg';

import { Line } from '../../components/UI/Line';

const volumeCargo = [
  { value: 'тонн', label: 'тн' },
  { value: 'м³', label: 'куб. м.' },
];

export const FlightPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [volumeValue, setVolumeValue] = useState('');
  const [getVolumeValue, setGetVolumeValue] = useState('');
  const [uploadingVolumeValue, setUploadingVolumeValue] = useState('');
  const [skeletonFlight, setSkeletonFlight] = useState(true);
  const [PROPERTIES, setPROPERTIES] = useState<any>();
  const [state, setState] = useState<FlightState>(flightStates['Запланирован']);
  const [images, setImages] = useState<Image[]>([]);
  const [image, setImage] = useState('');
  const [billOfLoadingNumber, setBillOfLoadingNumber] = useState('');
  const [errorBillOfLadingDate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [sendingFiles, setSendingFiles] = useState(false);
  const [sending, setSending] = useState<{
    onImages: boolean;
    sendingImages: boolean;
    sendingData: boolean;
  }>({
    onImages: true,
    sendingImages: false,
    sendingData: false,
  });
  const [error, setError] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [isBalloon, setIsBalloon] = useState(false);
  const [errorImageList, setErrorImageList] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [imageList, setImageList] = useState<any>([]);
  const [car, setCar] = useState<{ value: number; label: string } | null>();
  const [calendar, setCalendar] = useState<any>();
  const [dateRange, setDateRange] = useState<any>(dayjs(new Date()));
  const [unitDownload, setUnitDownload] = useState<string>('');
  const [unitUnloading, setUnitUnloading] = useState<string>('');
  const [isEditVolume, setIsEditVolume] = useState<boolean>(false);
  const [isQr, setIsQr] = useState(false);

  const id = localStorage.getItem('flightId');

  const checkDotVolume = value => {
    let newValue = value.toString();
    if (newValue.length === 2) return newValue + '.';
    return newValue;
  };

  const getFlightData = async () => {
    setSkeletonFlight(true);

    const flight = await fetchGet(`/flights/${id}`, {});

    if (flight.statusCode === 400) {
      return navigate('/flights');
    }
    setGetVolumeValue(flight?.response.unloadingVolume);
    setUploadingVolumeValue(flight?.response.volumeDownload);
    setUnitDownload(flight?.response.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit);
    setUnitUnloading(flight?.response.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit);

    setCar(
      flight.response.car
        ? {
            value: flight.response.car?.id,
            label: `${flight.response.car?.carBrand?.brand || ''} ${flight.response.car?.stateNumber}`,
          }
        : null,
    );
    setPROPERTIES(flight.response);

    if (flight) {
      // @ts-ignore
      const [, currentState] = Object.entries(flightStates).find(([key]) => {
        if (flight.response.status) {
          return key === flight.response.status;
        }

        return key;
      });
      setState(currentState);

      setBillOfLoadingNumber(flight.response.numberTn);
      setDateRange(dayjs(new Date(flight?.response?.dateTn === null ? new Date() : flight.response.dateTn)));

      setSkeletonFlight(false);
    }
  };

  useEffect(() => {
    setCalendar(calendar);
    getFlightData().catch(console.log);
  }, [updatePage]);
  const getFiles = async () => {
    setLoadingFiles(true);
    try {
      const responseFile = await fetch(`${SUBSERVER}/api/files/?fileBelongsId=${id}&communicationCategory=flights`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      });
      const dataFile = await responseFile.json();
      setImageList(dataFile.response);
      setLoadingFiles(false);
    } catch (e) {
      setLoadingFiles(false);
      console.log(e);
    }
  };
  const sendDataFlight = async () => {
    setDisabled(true);
    setSending({ ...sending, sendingData: true });
    setSendingFiles(true);
    try {
      await sendDataFlightFunction({
        id,
        user,
        state,
        setState,
        volumeValue,
        setVolumeValue,
        setDisabled,
        setSending,
        car,
        billOfLadingDate: dateRange,
        billOfLoadingNumber,
        images,
      });
      setUpdatePage(!updatePage);
      setSendingFiles(false);
    } catch (e) {
      console.log(e);
    }
  };

  const sendDataChangedVolumes = async ({
    id,
    volumeDownload,
    unloadingVolume,
  }: {
    id: string;
    volumeDownload: number;
    unloadingVolume: number;
  }) => {
    const responsePatchFlights = await fetchPost(`/flights/driver/volume-correct/${id}`, 'PATCH', {
      volumeDownload,
      unloadingVolume,
    });

    if (responsePatchFlights.error) {
      const errorMessages = responsePatchFlights.message.map(message => (
        <p>
          {message}
          <br />
          <br />
        </p>
      ));
      toast.error(<div>{errorMessages}</div>);
      return;
    }

    toast.success(`Данные объемов загрузки/выгрузки обновлены успешно`);
  };

  const sendChangedVolumes = async () => {
    try {
      await sendDataChangedVolumes({
        id,
        volumeDownload: Number(uploadingVolumeValue),
        unloadingVolume: Number(getVolumeValue),
      });
    } catch (e) {
      console.log(e);
    }
  };
  const changeVolumeValues = () => {
    if (isEditVolume) {
      sendChangedVolumes();
    }
    setIsEditVolume(!isEditVolume);
  };
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  const getVolumeUnit = () => {
    let volumeSelectValue = state.index === 2 ? unitDownload : unitUnloading;
    const volumeSelectValueCheck = volumeSelectValue === 'тонн' || volumeSelectValue === '';
    if (volumeSelectValueCheck) return 'тн';
    return 'куб. м.';
  };
  const volumeUnit = getVolumeUnit();

  let volumeError = volumeValue && Number(volumeValue) < 10;
  let isVolumeUnitTonn = volumeUnit === 'тн';
  let isSupplierUnitTonn = PROPERTIES?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit === 'тонн';
  let isBuyerUnitTonn = PROPERTIES?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit === 'тонн';
  let volumeDownloadError = getVolumeValue && Number(getVolumeValue) < 10;
  let volumeUnloadingError = uploadingVolumeValue && Number(uploadingVolumeValue) < 10;

  const nowDate = new Date();
  const minDate = new Date(nowDate.setDate(nowDate.getDate() - 7)).toISOString().split('T')[0];
  const maxDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (state.index === 2 || state.index === 4) {
      setDisabled(!volumeValue || Number(volumeValue) < 10 || error);
      return;
    }

    if (state.index === 5) {
      setDisabled(!billOfLoadingNumber || !dateRange || !images.length || error || isEditVolume);
    }
  }, [volumeValue, dateRange, billOfLoadingNumber, images, error, isEditVolume, state.index]);

  const requiredInputs = {
    correctionVolume: (
      <div key={'correctionVolume'} className="flightInformation__cargo">
        <VolumeInput
          type={'tel'}
          maskFormatVolume={'99.999'}
          disabled={false}
          isLabel={true}
          value={volumeValue.toString().padEnd(6, '0')}
          setValue={value => setVolumeValue(value.padEnd(6, '0'))}
          margin={''}
          error={volumeError}
          placeholder={'Загружено'}
          errorText={'Неверный формат'}
          isHelp={true}
          textHelp={`Введите число от 10.000 до 99.999`}
        />
        <div className="flightInformation__cargoItem">
          <p>Единица измерения</p>
          <p>{volumeUnit}</p>
        </div>
      </div>
    ),

    unloadingData: (
      <div key={'unloadingData'}>
        {!state.requiredButton && (
          <InputUploading
            onChangeFunction={({ target }) => loadingImages(setImages, images, target, 3)}
            text={'загрузить накладную'}
            subText={'В формате JPG, PNG, PDF, HEIC до 50 мегабайт'}
          />
        )}
        {sendingFiles ? (
          <div style={{ marginTop: '30px' }}>
            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
          </div>
        ) : (
          images.map(({ id, value, name, size, isSize, date, isDate, type }) => (
            <div className="image-container" key={id}>
              <ImageContainer
                id={id}
                value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                name={name}
                size={size}
                isSize={isSize}
                date={date}
                isDate={isDate}
                type={type}
                openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
              >
                {!state.requiredButton && (
                  <ButtonClose id={id} onClick={({ target }) => removeImage(target, images, setImages)} hidden="" />
                )}
              </ImageContainer>
            </div>
          ))
        )}
        {state.requiredButton && (
          <div className="flightInformation__cargoWrapper">
            <div className="flightInformation__title" style={{ marginBottom: '16px' }}>
              Количество материала
            </div>
            <div className="flightInformation__cargo">
              <div className="flightInformation__cargoItem">
                <p>Загружено</p>
                <p>{checkDotVolume(PROPERTIES.volumeDownload).padEnd(6, '0')}</p>
              </div>
              <div className="flightInformation__cargoItem">
                <p>Единица измерения</p>
                <p>{isSupplierUnitTonn ? 'тн' : 'куб. м.'}</p>
              </div>
              <div className="flightInformation__cargoItem">
                <p>Выгружено</p>
                <p>{checkDotVolume(PROPERTIES.unloadingVolume).padEnd(6, '0')}</p>
              </div>
              <div className="flightInformation__cargoItem">
                <p>Единица измерения</p>
                <p>{isBuyerUnitTonn ? 'тн' : 'куб. м.'}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flightInformation__title" style={{ marginBottom: '16px' }}>
          Информация по накладной
        </div>
        {state.index !== 6 && (
          <div>
            <div className="flightInformation__cargo">
              <VolumeInput
                type={'tel'}
                maskFormatVolume={'99.999'}
                disabled={!isEditVolume}
                isLabel={true}
                value={uploadingVolumeValue?.toString().padEnd(6, '0')}
                setValue={value => setUploadingVolumeValue(value.padEnd(6, '0'))}
                margin={''}
                placeholder={'Загружено'}
                errorText={'Неверный формат'}
                isHelp={true}
                textHelp={`Введите число от 10.000 до 99.999`}
                error={volumeUnloadingError}
              />
              <div className="flightInformation__cargoItem">
                <p>Единица измерения</p>
                <p>{isSupplierUnitTonn ? 'тн' : 'куб. м.'}</p>
              </div>
              <VolumeInput
                type={'tel'}
                maskFormatVolume={'99.999'}
                disabled={!isEditVolume}
                isLabel={true}
                value={getVolumeValue?.toString().padEnd(6, '0')}
                setValue={value => setGetVolumeValue(value.padEnd(6, '0'))}
                margin={''}
                placeholder={'Выгружено'}
                errorText={'Неверный формат'}
                isHelp={true}
                textHelp={`Введите число от 10.000 до 99.999`}
                error={volumeDownloadError}
              />
              <div className="flightInformation__cargoItem">
                <p>Единица измерения</p>
                <p>{isBuyerUnitTonn ? 'тн' : 'куб. м.'}</p>
              </div>
            </div>
            <ButtonFilled
              onClick={changeVolumeValues}
              margin={'40px 0 0 0'}
              width={'100%'}
              disabled={isEditVolume ? volumeDownloadError || volumeUnloadingError : false}
              text={isEditVolume ? 'Сохранить объем' : 'Редактировать объем'}
            />
          </div>
        )}
        <div className={'flightInformation__inputs'}>
          <InputText
            type={''}
            disabled={state.requiredButton && true}
            helpText={''}
            widthHelp={0}
            placeholder="Номер транспортной накладной"
            error={true}
            textError="Пожалуйста, укажите номер ТН"
            margin={'16px'}
            value={billOfLoadingNumber}
            setValue={setBillOfLoadingNumber}
            isLabel={true}
          />
          <CustomDatePicker
            maxDate={maxDate}
            minDate={minDate}
            disabled={PROPERTIES?.status === 'Выполнен'}
            value={dateRange}
            setValue={setDateRange}
          />
        </div>
      </div>
    ),
  };

  const requiredButton = {
    defaultButton: (
      <ButtonFilled
        margin={isMobile ? '24px 0 0 0' : '40px 0 0 0'}
        width={'100%'}
        onClick={sendDataFlight}
        text={state.nextState}
        disabled={disabled}
      />
    ),

    doneButton: (
      <ButtonNotFilled
        svg={<SuccessSvg />}
        color={'#45ac81'}
        onClick={closePopUp}
        width={'100%'}
        margin={'40px 0 0 0'}
        text={state.nextState == null ? 'Рейс выполнен' : state.nextState}
      />
    ),
  };
  const handleCancelModal = () => {
    setIsQr(false);
  };
  useEffect(() => {
    switch (state.index) {
      case 2: {
        return setDisabled(!volumeValue);
      }

      case 4: {
        if (PROPERTIES?.volumeDownload) {
          !volumeValue && setVolumeValue(PROPERTIES?.volumeDownload);
          return;
        }

        return setDisabled(!volumeValue);
      }
      default: {
        return;
      }
    }
  }, [state, images.length, billOfLoadingNumber, errorBillOfLadingDate]);
  // @ts-ignore
  return (
    <>
      {skeletonFlight ? (
        <PageWrapper>
          <SkeletonFlight />
        </PageWrapper>
      ) : user.active ? (
        <PageWrapper style={{ zIndex: popUpActive ? '100000' : '100' }}>
          <Modal
            style={{
              maxWidth: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'normal',
              justifyContent: 'flex-end',
              height: '90vh',
              paddingBottom: '0px',
            }}
            title={'Сканировать QR-код'}
            onCancel={handleCancelModal}
            open={isQr}
            footer={null}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <QRCode
                // @ts-ignore
                title="GeeksForGeeks"
                value={localStorage.getItem('flightId')}
                bgColor={'#FFFFFF'}
                fgColor={'#000000'}
                size={256}
              />
              <ButtonFilled width={'100%'} margin={'10px 0 0 0'} onClick={() => setIsQr(false)} text={'Готово'} />
            </div>
          </Modal>
          <ToastContainer />
          <div className={'FlightPage'}>
            <div className="button-back" onClick={() => navigate(-1)}>
              <div className="button-back__svg">
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              К списку рейсов
            </div>
            <div className={'FlightPage__wrapper'}>
              <div className="CheckPoint">
                <div>
                  <div className={styles.titleAndQrContainer}>
                    <div className="CheckPoint__title">{PROPERTIES?.flightName}</div>
                    <ButtonNotFilled
                      sizeText={'small'}
                      margin={isMobile ? '24px 0 0 0' : '0'}
                      width={isMobile ? '100%' : '180px'}
                      onClick={() => setIsQr(!isQr)}
                      text={'Показать QR-код'}
                      svg={<QrCodeScannerSvg />}
                    />
                  </div>
                  <CheckPointItem key={'CheckPointItem'} state={state} isDriver={true} />
                  {state?.requiredButton && !images.length && (
                    <div className="loaded-image">
                      {isBalloon && (
                        <div className={errorImageList ? 'balloon-image balloon-image_error' : 'balloon-image'}>
                          <div className="balloon-image__svg">{errorImageList ? <ErrorFullSvg /> : <SuccessSvg />}</div>
                          <div className="balloon-image__text">
                            {errorImageList
                              ? 'Произошла ошибка при загрузке транспортных накладных'
                              : 'Транспортные накладные были успешно загружены'}
                          </div>
                        </div>
                      )}
                      <ButtonFilled
                        onClick={() => getFiles()}
                        text="Показать накладные"
                        disabled={disabled}
                        width={'100%'}
                      />
                      <div className="list-image">
                        {loadingFiles ? (
                          <div style={{ marginTop: '30px' }}>
                            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                          </div>
                        ) : (
                          imageList.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={mimeType}
                                openPopUp={() =>
                                  (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                                }
                                children={undefined}
                              ></ImageContainer>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {state?.requiredInputs &&
                    Object.entries(state.requiredInputs).map(([flag, value]) => value && requiredInputs[flag])}
                  {state?.requiredButton ? requiredButton.doneButton : requiredButton.defaultButton}
                </div>
              </div>
              {isMobile && <Line />}
              <div className="flightInformation">
                <div
                  className="flightInformation__infoProvider flightInformation__logist-block"
                  style={isMobile ? { margin: '0' } : {}}
                >
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Номер ответственного логиста</p>
                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.logist?.phone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.logist?.phone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <Line />
                <div className="flightInformation__infoProvider">
                  <div className="flightInformation__title">Информация о поставщике</div>
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Дата заказа</p>
                      <p className="sub">{checkValidation(`${new Date(PROPERTIES.date).toLocaleDateString()}`)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Грузоотправитель</p>
                      <p className="sub">{checkValidation(PROPERTIES?.organization)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Карьер</p>
                      <p className="sub">{checkValidation(PROPERTIES?.quarry?.name)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Погрузка</p>
                      <p className="sub">{checkValidation(PROPERTIES?.quarry?.address?.address)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Материал</p>
                      <p className="sub">
                        {checkValidation(
                          PROPERTIES?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
                        )}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>График работы</p>
                      <p className="sub">
                        {PROPERTIES?.quarry?.workSchedulesGraph?.aroundTheClock
                          ? 'Круглосуточно'
                          : `${PROPERTIES?.quarry?.workSchedulesGraph?.from || 'Нет данных'} ${PROPERTIES?.quarry?.workSchedulesGraph?.from ? '-' : ''} ${PROPERTIES?.quarry?.workSchedulesGraph?.to || ''}`}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Диспетчер</p>
                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.supplierInformation?.dispatchersPhone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.quarry?.dispatcher?.phone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <Line />
                <div className="flightInformation__infoProvider">
                  <div className="flightInformation__title">Информация о получателе</div>
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Грузополучатель</p>
                      <p className="sub">{checkValidation(PROPERTIES?.consignee?.title)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Выгрузка</p>
                      <p className="sub">{checkValidation(PROPERTIES?.object?.address?.address)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Материал</p>
                      <p className="sub">
                        {checkValidation(
                          PROPERTIES?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
                        )}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>График приемки</p>
                      <p className="sub">
                        {PROPERTIES?.object?.workSchedulesGraph?.aroundTheClock
                          ? 'Круглосуточно'
                          : `${PROPERTIES?.object?.workSchedulesGraph?.from || ''} -${PROPERTIES?.object?.workSchedulesGraph?.to || ''}`}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Контакт</p>
                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.clientInformation?.contactPhone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.clientInformation?.contactPhone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={''} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className="errorBlock">
            <div className="errorBlock__text">
              Ваш аккаунт не активирован. Чтобы получить доступ к личному кабинету водителя заполните ваши данные
              <span>
                <NavLink to="/profile">здесь</NavLink>
              </span>
            </div>
            <div className="errorBlock__wrapper">
              <div className="errorBlock__content">
                <div className="errorBlock__img">
                  <img src="image/svg/lock.svg" alt="lock" />
                </div>
                {!isMobile && 'Нет доступа'}
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
