import React from 'react';
import { NavLink } from 'react-router-dom';

export const NotActiveProfile = ({ role }) => {
  
  return (
    <div className="NotActiveProfile">
      <div className="NotActiveProfile__text">
        <div className="NotActiveProfile__svg">
          <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z" fill="currentColor"/>
          </svg>
        </div>
        Ваш аккаунт не активирован. Чтобы получить доступ к личному кабинету {role}
        <span><NavLink to="/profile">заполните ваши данные здесь</NavLink></span>
      </div>
      <div className="NotActiveProfile__wrapper">
        <div className="NotActiveProfile__content">
          <div className="NotActiveProfile__lock">
            <svg width="100%" height="100%" viewBox="0 0 166 218" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M82.9997 165.667C71.5297 165.667 62.333 156.367 62.333 145C62.333 133.53 71.5297 124.333 82.9997 124.333C88.4808 124.333 93.7375 126.511 97.6132 130.387C101.489 134.262 103.666 139.519 103.666 145C103.666 150.481 101.489 155.738 97.6132 159.614C93.7375 163.489 88.4808 165.667 82.9997 165.667ZM145 196.667V93.3334H20.9997V196.667H145ZM145 72.6667C150.481 72.6667 155.737 74.8441 159.613 78.7198C163.489 82.5956 165.666 87.8522 165.666 93.3334V196.667C165.666 202.148 163.489 207.404 159.613 211.28C155.737 215.156 150.481 217.333 145 217.333H20.9997C9.52967 217.333 0.333008 208.033 0.333008 196.667V93.3334C0.333008 81.8634 9.52967 72.6667 20.9997 72.6667H31.333V52C31.333 38.2972 36.7764 25.1556 46.4658 15.4662C56.1552 5.77681 69.2968 0.333374 82.9997 0.333374C89.7846 0.333374 96.5032 1.66977 102.772 4.26626C109.04 6.86276 114.736 10.6685 119.534 15.4662C124.331 20.2639 128.137 25.9596 130.733 32.2281C133.33 38.4965 134.666 45.2151 134.666 52V72.6667H145ZM82.9997 21C74.778 21 66.893 24.2661 61.0794 30.0797C55.2657 35.8934 51.9997 43.7783 51.9997 52V72.6667H114V52C114 43.7783 110.734 35.8934 104.92 30.0797C99.1064 24.2661 91.2214 21 82.9997 21Z" fill="currentColor"/>
            </svg>
          </div>
          <div className="description">
            <div className="description__title">Нет доступа</div>
            <div className="description__subTitle">В данный момент у вас нет доступа к этому разделу</div>
          </div>
        </div>
      </div>
    </div>
  );
};