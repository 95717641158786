import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Tabs = ({ tabs, setTabs, updatePage, setUpdatePage }) => {

    const testt = (tabsName) => {
      setUpdatePage(!updatePage);

      sortFlightStatus(tabsName);
    };
    const sortFlightStatus = (statusName) => {
        for (const [key, tab] of Object.entries(tabs)) {
            // @ts-ignore
            tab.active = key === statusName;
        }

        setTabs((prevState) => ({ ...prevState, ...tabs }));
    };

    useEffect(() => {
        sortFlightStatus(decodeURIComponent(window.location.hash).split('#')[1]);
    }, []);

    return (
        <div className='tabs'>
            {Object.entries(tabs).map(([tabsName, tab]: any) => (
                <Link
                    to={`#${tabsName}`}
                    key={tabsName}
                    onClick={() => testt(tabsName)}
                    className={`tabs__button ${(tab.active ? 'active' : '')}`}
                >
                    {tabsName}
                </Link>
            ))}
        </div>
    );
};