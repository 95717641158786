import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const RoleTab = ({ name, description, svg, active, disabled, development, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <button
      {...props}
      className={`RoleTab ${active ? 'active' : ''} ${development ? 'development' : ''}`}
      disabled={disabled}
    >
      {isMobile ? (
        <div>
          <div className="RoleTab__text">
            <div style={{ display: 'flex' }}>
              <div className="RoleTab__svg">{svg}</div>
              <div className="RoleTab__name textH6_bold">{name}</div>
            </div>
            <div className="RoleTab__description">{description}</div>
          </div>
        </div>
      ) : (
        <>
          <div className="RoleTab__svg">{svg}</div>
          <div className="RoleTab__text">
            <div className="RoleTab__name textH6_bold">{name}</div>
            <div className="RoleTab__description">{description}</div>
          </div>
        </>
      )}
    </button>
  );
};
