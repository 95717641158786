import React from 'react';
import styles from './SalaryHistory.module.scss';
import { useNavigate } from 'react-router-dom';
import { formPrice } from '../../../../../common/form-price.helper';
import ButtonNotFilled from "../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled";
interface IProps {
  getPercent: any;

  lastMonthSalary: number;
}
const SalaryHistory = ({ getPercent, lastMonthSalary }: IProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.percentСontainer}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 2609477">
              <path
                id="Vector"
                opacity="0.5"
                d="M15.517 8.05659C17.1508 6.96488 18.8664 6.01801 20.6439 5.22196C25.6858 2.96402 28.2068 1.83505 31.5605 4.00784C34.9143 6.18063 34.9143 9.74483 34.9143 16.8732V22.386C34.9143 29.316 34.9143 32.781 37.0672 34.9339C39.2201 37.0868 42.6851 37.0868 49.6151 37.0868H55.1279C62.2563 37.0868 65.8205 37.0868 67.9933 40.4405C70.166 43.7943 69.0371 46.3153 66.7791 51.3572C65.9831 53.1347 65.0362 54.8503 63.9445 56.4841C60.1081 62.2257 54.6552 66.7008 48.2755 69.3434C41.8957 71.986 34.8756 72.6774 28.1029 71.3302C21.3302 69.983 15.109 66.6578 10.2262 61.7749C5.34333 56.8921 2.01806 50.6709 0.670885 43.8982C-0.676292 37.1255 0.0151273 30.1054 2.65771 23.7256C5.30029 17.3459 9.77534 11.893 15.517 8.05659Z"
                fill="#9ABAFF"
              />
              <path
                id="Vector_2"
                d="M71.4678 17.1258C68.4843 9.55231 62.4477 3.51572 54.8743 0.532173C49.2088 -1.69974 44.1022 3.43256 44.1022 9.52182V24.2226C44.1022 26.2523 45.7477 27.8978 47.7774 27.8978H62.4782C68.5674 27.8978 73.6997 22.7912 71.4678 17.1258Z"
                fill="#9ABAFF"
              />
            </g>
          </svg>
          <div className={styles.percent}>{isNaN(getPercent())?0:getPercent()}%</div>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.ContentBlockTitle}>За прошедший месяц</p>
          <p>{formPrice(lastMonthSalary || 0, 'RUB')}</p>
        </div>
      </div>
      <ButtonNotFilled
          margin={'16px 0 0 0'}
          width={'100%'}
          onClick={() => {
            navigate('/salaryHistory');
          }}
          text={'Подробнее'}
          sizeText={'small'}
      />
    </div>
  );
};

export default SalaryHistory;
