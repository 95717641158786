// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPageDriver_mainSubTitleText__SASJl {\n  color: #9BA1B4;\n  font-family: Roboto;\n  font-size: 18px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: 28px;\n}\n\n.MainPageDriver_firstBlockContent__7X5zZ {\n  margin-right: 40px;\n  width: 380px;\n}\n\n.MainPageDriver_contentContainer__Mvo95 {\n  display: flex;\n}\n\n@media (max-width: 768px) {\n  .MainPageDriver_firstBlockContent__7X5zZ {\n    margin-right: 0;\n    width: 100%;\n  }\n  .MainPageDriver_contentContainer__Mvo95 {\n    width: 100%;\n    gap: 16px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    flex-direction: column;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/MainPages/MainPageDriver/MainPageDriver.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AADA;EACE;IACE,eAAA;IACA,WAAA;EAIF;EAFA;IACE,WAAA;IACA,SAAA;IACA,mBAAA;IACA,uBAAA;IACA,aAAA;IACA,sBAAA;EAIF;AACF","sourcesContent":[".mainSubTitleText {\n  color:#9BA1B4;\n  font-family: Roboto;\n  font-size: 18px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: 28px;\n}\n.firstBlockContent{\n  margin-right: 40px;\n  width:380px ;\n}\n.contentContainer{\n  display: flex;\n}\n@media (max-width: 768px) {\n  .firstBlockContent{\n    margin-right:0;\n    width:100%;\n  }\n  .contentContainer{\n    width: 100%;\n    gap: 16px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSubTitleText": "MainPageDriver_mainSubTitleText__SASJl",
	"firstBlockContent": "MainPageDriver_firstBlockContent__7X5zZ",
	"contentContainer": "MainPageDriver_contentContainer__Mvo95"
};
export default ___CSS_LOADER_EXPORT___;
