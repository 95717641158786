import React from 'react';
import styles from './UserAgreement.module.scss';
import { agreement } from './constans';
import FooterLk from '../../components/newUI/FooterLk/FooterLk';
import { useNavigate } from 'react-router-dom';
import FooterLogoSvg from '../../components/UI/svg-icon/FooterLogoSvg';
import FooterLogoTextSvgAgreement from '../../components/UI/svg-icon/FooterLogoTextSvgAgreement';
import {useMediaQuery} from "react-responsive";
import LogoMobileSvg from "../../components/UI/svg-icon/LogoMobileSvg";

interface IProps {
  agreementType: string;
}
const Agreements = ({ agreementType }: IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    return (
        <div className={styles.pageContainer}>
            <div className={styles.header}>
                <div
                    className={styles.logoContainer}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    {isMobile ?
                        <>
                            <LogoMobileSvg/>
                            <p className={styles.textLogo}>
                                Платформа нерудных
                                <br/>
                                материалов
                            </p>
                        </>
                        :
                        <>
                            <FooterLogoSvg />
                            <FooterLogoTextSvgAgreement />
                        </>
                    }
                </div>
            </div>
            {agreement[agreementType]}
            <FooterLk />
        </div>
  );
};

export default Agreements;
