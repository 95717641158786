import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const ButtonExit = ({ isText, ...props }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <button {...props} className='ButtonExit'>
            <div className='ButtonExit__svgBox'>
                <svg className='ButtonExit__svg' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M16 0H2C0.89 0 0 0.89 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM7.08 12.58L8.5 14L13.5 9L8.5 4L7.08 5.41L9.67 8H0V10H9.67L7.08 12.58Z' fill='currentColor'/>
                </svg>
            </div>
            {isText &&
                isMobile
                    ? 'Выйти'
                    : 'Выйти из личного кабинета'
            }
        </button>
    );
};