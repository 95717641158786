import React, { useState } from 'react';

export const CheckBoxCustom = ({ children, error, check, pick, name, id }) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  return (
    <div className={`check-box-custom ${error ? 'error' : ''}`}>
      <input
        onChange={() => {
          pick(id);
          if (id === 'all') {
            setCheckAll(!checkAll);
          }
        }}
        className={'check-box-custom__input'}
        type="checkbox"
        name={name}
        id={id}
      />
      <label className={`check-box-custom__label ${check || checkAll ? 'active' : ''}`} htmlFor={id}>
        <div className="check-box-custom__check">
          <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.0002 8.66656L10.0002 16.6666L6.3335 12.9999L7.2735
                            12.0599L10.0002 14.7799L17.0602 7.72656L18.0002 8.66656Z"
              fill="white"
            />
          </svg>
        </div>
        {children}
      </label>
    </div>
  );
};
