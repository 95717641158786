import { checkValidation } from '../../common/check-validation.function';
import { formPrice } from '../../common/form-price.helper';
import { validationPhoneNumberFunction } from '../../common/validation-phone-number.function';
import React from 'react';
import { ImageContainer } from '../../components/ImageContainer';
import { parseTimeHelper } from '../../common/ParceTimeHelper';
import styles from './SpecificationPage.module.scss';
import { TypeActivityOfSpecifications } from './type';
import CustomTooltip from '../../components/newUI/CustomTooltip/CustomTooltip';
import QuestionOutlineSvgLowerWidth from '../../components/UI/svg-icon/QuestionOutlineSvgLowerWidth';
import { useMediaQuery } from 'react-responsive';
export const correctionSpecificationsData = (supplier, specification) => {
  if (supplier) {
    return {
      Номер: {
        title: {
          up: checkValidation(specification.name),
          down: null,
        },
        help: false,
        contentHelp: null,
      },
      'Дата начала': {
        title: {
          up: checkValidation(
            specification.startDate
              ? parseTimeHelper(specification.startDate)
              : null,
          ),
          down: null,
        },
        help: false,
        contentHelp: null,
      },
      'Дата окончания': {
        title: {
          up: checkValidation(
            specification.validityPeriod
              ? parseTimeHelper(specification.validityPeriod)
              : null,
          ),
          down: null,
        },
        help: true,
        contentHelp: null,
      },
      Номенклатура: {
        title: {
          up: checkValidation(specification?.nomenclatureSpecification?.content),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
      'Цена за ед.': {
        title: {
          up: checkValidation(formPrice(specification?.nomenclatureSpecification?.price || 0, 'RUB')),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
      'Ед.изм.': {
        title: {
          up: checkValidation(specification?.nomenclatureSpecification?.unit),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
    };
  } else {
    return {
      Номер: {
        title: {
          up: checkValidation(specification.name),
          down: null,
        },
        help: false,
        contentHelp: null,
      },
      Дата: {
        title: {
          up: checkValidation(
            specification.startDate ? parseTimeHelper(specification.startDate) : null,
          ),
          down: null,
        },
        help: false,
        contentHelp: null,
      },
      Номенклатура: {
        title: {
          up: checkValidation(specification?.nomenclatureSpecification?.content),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
      Объем: {
        title: {
          up: checkValidation(specification?.volume),
          down: '',
        },
        help: false,
        helpName: 'volume',
        contentHelp: null,
      },
      'Ед.изм.': {
        title: {
          up: checkValidation(specification?.nomenclatureSpecification?.unit),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
      'Цена за ед.': {
        title: {
          up: checkValidation(formPrice(specification?.nomenclatureSpecification?.price || 0, 'RUB')),
          down: '',
        },
        help: false,
        helpName: 'price',
        contentHelp: null,
      },
      'Стоимость общ.': {
        title: {
          up: checkValidation(
            formPrice(
              isNaN(specification?.nomenclatureSpecification?.price * specification?.volume)
                ? 0
                : specification?.nomenclatureSpecification?.price * specification?.volume,
              'RUB',
            ),
          ),
          down: '',
        },
        help: false,
        contentHelp: null,
      },
    };
  }
};
export const correctionSupplierDate = (supplier, contracts, filesContracts, specifications, openPopupWithImg) => {
  if (supplier) {
    return {
      left: {
        'Дата договора': checkValidation(
          contracts?.response?.date ? new Date(contracts?.response?.date).toLocaleString().split(',')[0] : null,
        ),
        'Дата окончания договора': checkValidation(
          contracts?.response?.expiredDate ? contracts?.response?.expiredDate.toLocaleString().split(',')[0] : null,
        ),
        Организация: checkValidation(contracts?.response?.organization?.title),
        'Количество открытых спецификаций': String(specifications?.response?.length),
        Договор: (
          <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}>
            {filesContracts.map(({ id, location, name, size, isSize, date, isDate, type }) => (
              <div className="image-container" key={id}>
                <ImageContainer
                  id={id}
                  value={type === 'image/png' || type === 'image/jpeg' ? null : location}
                  name={name}
                  size={size}
                  isSize={isSize}
                  date={date}
                  isDate={isDate}
                  type={type}
                  openPopUp={() => openPopupWithImg(location)}
                  children={undefined}
                ></ImageContainer>
              </div>
            ))}
          </div>
        ),
      },
      right: {
        'Ответственный менеджер': contracts?.response?.contactPerson?.person,
        Телефон: (
          <a className={'blue'} href={`tel:+${contracts?.response?.contactPerson?.phone}`}>
            {validationPhoneNumberFunction(contracts?.response?.contactPerson?.phone)}
          </a>
        ),
        'E-mail': (
          <a className={'blue'} href={`mailto:${contracts?.response?.contactPerson?.email}`}>
            {contracts?.response?.contactPerson?.email}
          </a>
        ),
      },
    };
  } else {
    return {
      left: {
        'Дата договора': new Date(contracts?.response?.date).toLocaleString().split(',')[0],
        'Кол-во спецификаций к договору': String(specifications?.response?.length),
        Договор: (
          <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}>
            {filesContracts.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
              <div className="image-container" key={id}>
                <ImageContainer
                  id={id}
                  value={mimeType === 'application/pdf' ? location : null}
                  name={name}
                  size={size}
                  isSize={isSize}
                  date={date}
                  isDate={isDate}
                  type={mimeType}
                  openPopUp={() => mimeType === 'image/jpeg' && openPopupWithImg(location)}
                  children={undefined}
                ></ImageContainer>
              </div>
            ))}
          </div>
        ),
      },
      right: {
        'Ответственный менеджер': contracts?.response?.contactPerson?.person,
        Телефон: (
          <a href={`tel:+${contracts?.response?.contactPerson?.phone}`}>
            {validationPhoneNumberFunction(contracts?.response?.contactPerson?.phone)}
          </a>
        ),
        'E-mail': (
          <a href={`mailto:${contracts?.response?.contactPerson?.email}`}>
            {contracts?.response?.contactPerson?.email}
          </a>
        ),
      },
    };
  }
};
export const statusColor = {
  'На подписании' : styles.brightStatus,
  'Ожидает подписи' : styles.orangeStatus,
  'Подписан' : styles.greenStatus,
  'Закрыт' : styles.redStatus
};
export const statusTextTooltip = {
  'На подписании' : 'Договор на рассмотрении',
  'Ожидает подписи' : 'Договор в вашем ящике ожидает подписи',
  'Подписан' : 'Договор активен',
  'Закрыт' : 'Данный договор закрыт'
};
export const columnsForTableOfSpecifications = {

  carrier: [
    {
      title: 'Номер',
      dataIndex: 'title',
      width: 336,
      key: 'title',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Дата начала',
      dataIndex: 'date',
      width: 360,
      key: 'date',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Вид договора',
      dataIndex: 'contractType',
      width: 360,
      key: 'contractType',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 227,
      key: 'status',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={statusColor[text]}>
              {text}
              <CustomTooltip positionTooltipRight={'-13px'}
                             SideTooltip={'left'}
                             svg={<QuestionOutlineSvgLowerWidth />}
                             positionTooltipBottom={'22px'}
                             text={statusTextTooltip[text]}
                             widthTooltip={'fitContent'}
                             widthSvg={'16px'}
                             styleWindowTooltip={{ whiteSpace:'nowrap' }}
              />
            </p>
          </div>
      ),
    },
  ],

  supplier: [
    {
      title: 'Номер',
      dataIndex: 'title',
      width: 516,
      key: 'title',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Дата начала',
      dataIndex: 'date',
      width: 540,
      key: 'date',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 227,
      key: 'status',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={statusColor[text]}>
              {text}
              <CustomTooltip positionTooltipRight={'-13px'}
                             SideTooltip={'left'}
                             svg={<QuestionOutlineSvgLowerWidth />}
                             positionTooltipBottom={'22px'}
                             text={statusTextTooltip[text]}
                             widthTooltip={'fitContent'}
                             widthSvg={'16px'}
                             styleWindowTooltip={{ whiteSpace:'nowrap' }}
              />
            </p>
          </div>
      ),
    },
  ],

  buyer: [
    {
      title: 'Номер',
      dataIndex: 'title',
      width: 516,
      key: 'title',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.tableText}>
              {text}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Дата начала',
      dataIndex: 'date',
      width: 540,
      key: 'date',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={styles.dateContainer}>
              {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
            </p>
            <div className={styles.border}></div>
          </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 227,
      key: 'status',
      render: text => (
          <div className={styles.columnContainer}>
            <p className={statusColor[text]}>
              {text}
              <CustomTooltip positionTooltipRight={'-13px'}
                             SideTooltip={'left'}
                             svg={<QuestionOutlineSvgLowerWidth />}
                             positionTooltipBottom={'22px'}
                             text={statusTextTooltip[text]}
                             widthTooltip={'fitContent'}
                             widthSvg={'16px'}
                             styleWindowTooltip={{ whiteSpace:'nowrap' }}
              />
            </p>
          </div>
      ),
    },
  ],
};
export const optionsOfActivityOfSpecifications: TypeActivityOfSpecifications = [
  { value: 'open', label: 'Открытые договоры' },
  { value: 'closed', label: 'Закрытые договоры' },
];