import React from 'react';
import { DateRangeSvg } from '../svg-icon/DateRangeSvg';
import Calendar from 'react-calendar';
import { ButtonTransparent } from '../buttons/ButtonTransparent';
import { Button } from '../buttons/Button';

type Props = {
    openCalendar: boolean;
    setOpenCalendar: React.Dispatch<boolean>
    calendar: any;
    setCalendar: React.Dispatch<any>;
    range?: boolean;
    isFunction?: boolean;
    useFunction?: Function;
    dateRange?: string;
    setDateRange?: React.Dispatch<string>
    [key: string]: any;
}

export const CalendarInput = ({ isFunction, sendFunction, openCalendar, setOpenCalendar, calendar, setCalendar, range, dateRange, setDateRange, ...props }: Props) => {
    const handleDateClick = (date: any) => {
        if (!range) {
            return setCalendar(date);
        }

        if (calendar.length < 2) {
            setCalendar([...calendar, date]);
        } else {
            setCalendar([date]);
        }
    };

    const pickDateRange = (pick: any) => {
        if (!pick) {
            setDateRange('Диапазон даты');
            setCalendar([]);
            setOpenCalendar(!openCalendar);
            sendFunction('', '');
            return;
        }

        if (!range) {
            setDateRange(`${calendar.toLocaleString().split(',')[0]}`);
            setOpenCalendar(!openCalendar);
            return;
        }

        setDateRange(calendar.length >= 2 ? `${calendar[0].toLocaleString().split(',')[0]} - ${calendar[1].toLocaleString().split(',')[0]}` : dateRange);
        setOpenCalendar(!openCalendar);

        if (isFunction) {
            sendFunction(calendar[0].toLocaleString().split(',')[0], calendar[1].toLocaleString().split(',')[0]);
        }
    };

    return (
        <div className={'selects__calendar'}>
            <div onClick={() => setOpenCalendar(!openCalendar)} className={`selects__title ${(!calendar.length && !range) ? '' : 'disable'}`}>
                <span>{dateRange}</span>
                <span><DateRangeSvg/></span>
            </div>
            <div className={`selects__input-calendar ${openCalendar ? 'open' : ''}`}>
                <Calendar
                    {...props}
                    onClickDay={handleDateClick}
                    value={calendar}
                />
                <div className={'selects__buttons'}>
                    <div className={'selects__button'}>
                        <ButtonTransparent
                            onClick={() => pickDateRange(false)}
                            children={'Отменить'}
                            isSvg={false}
                            svg={''}
                            color={'gray'}
                            modifier={''}
                            disabled={false}/>
                    </div>
                    <div className={'selects__button'}>
                        <Button
                            left={false}
                            right={false}
                            onClick={() => pickDateRange(true)}
                            sending={false}
                            modifier={''}
                            colorSvg={''}
                            children={'Выбрать'}
                            disabled={range && calendar.length < 2}
                            color={''}
                            svg={''}/>
                    </div>
                </div>
            </div>
        </div>
    );
};