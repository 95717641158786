import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const SkeletonCarrierFlight = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="SkeletonCarrierFlight">
      <div className="SkeletonCarrierFlight__back">
        <div></div>
        <span></span>
      </div>
      <div className="SkeletonCarrierFlight__wrapper">
        <div className="SkeletonCarrierFlight__sides">
          <div className="SkeletonCarrierFlight__box">
            <div className="SkeletonCarrierFlight__flightNumber">
              <span></span>
              <p></p>
            </div>
            <div className="SkeletonCarrierFlight__subFlightNumber"></div>
            <div className="SkeletonCarrierFlight__button SkeletonCarrierFlight__button_first"></div>
            <div className="SkeletonCarrierFlight__button"></div>
            <div className="SkeletonCarrierFlight__button SkeletonCarrierFlight__button_last"></div>
            <div className="SkeletonCarrierFlight__line"></div>
            <div className="SkeletonCarrierFlight__title"></div>
            <div className="SkeletonCarrierFlight__input"></div>
            <div className="SkeletonCarrierFlight__line"></div>
            <div className="SkeletonCarrierFlight__title"></div>
            <div className="SkeletonCarrierFlight__button SkeletonCarrierFlight__button_first"></div>
            <div className="SkeletonCarrierFlight__button"></div>
            <div className="SkeletonCarrierFlight__button SkeletonCarrierFlight__button_last"></div>
          </div>
        </div>
        {isMobile && <div className="SkeletonCarrierFlight__line"></div>}
        <div className="SkeletonCarrierFlight__sides">
          <div className="SkeletonCarrierFlight__box">
            <div className="SkeletonCarrierFlight__subTitle"></div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__line"></div>
            <div className="SkeletonCarrierFlight__subTitle"></div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__itemBox">
              <div className="SkeletonCarrierFlight__itemName"></div>
              <div className="SkeletonCarrierFlight__itemDescription"></div>
            </div>
            <div className="SkeletonCarrierFlight__button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};