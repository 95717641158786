import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { Link } from 'react-router-dom';
import { InfoItem } from '../../components/InfoItem';
import { SelectBox } from '../../components/SelectBox';
import { SelectCustom } from '../../components/UI/select/SelectCustom';
import { Frame } from '../../components/Frame';
import { Tabs } from 'antd';
import { FrameDataType } from '../../common/frame-data.type';
import { AsidePopup } from '../../components/AsidePopup';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { checkValidation } from '../../common/check-validation.function';
import { formPrice } from '../../common/form-price.helper';
import { ArrowBack } from '../../components/UI/svg-icon/ArrowBack';
import { EmptyContent } from '../../components/UI/EmptyContent/EmptyContent';
import { SearchSvg } from '../../components/UI/svg-icon/SearchSvg';
import { QuestionSvg } from '../../components/UI/svg-icon/QuestionSvg';
import { correctionSpecificationsData, correctionSupplierDate } from './utils';
import { ErrorFullSvg } from '../../components/UI/svg-icon/ErrorFullSvg';
import { SuccessSvg } from '../../components/UI/svg-icon/SuccessSvg';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { loadingImages } from '../../common/loading-image.function';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { Button } from '../../components/UI/buttons/Button';
import { FileSvg } from '../../components/UI/svg-icon/FileSvg';
import { FlightState, flightStates } from '../../common/flight-states';
import { toast } from 'react-toastify';
import { Context } from '../../context/context';
import CustomRangePicker from '../../components/newUI/CustomRangePicker/CustomRangePicker';
import { SUBSERVER } from '../../common/sub-server.constant';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { parseTimeHelper } from '../../common/ParceTimeHelper';

export const SpecificationPage = ({ supplier }) => {
  const { user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [more] = useState<number>(isMobile ? 4 : 10);
  const [data, setData] = useState<{
    title: string;
    content: {
      left: {
        [key: string]: string | JSX.Element;
      };
      right: {
        [key: string]: string | JSX.Element;
      };
    };
    nameStatus: string;
    status?: string;
  } | null>();
  const [dataSpecification, setDataSpecification] = useState<any>();
  const [nomenclaturesSelects, setNomenclaturesSelects] = useState<{
    options:
      | {
          value: string | number;
          label: string;
        }[]
      | any;
    value: any | null;
  }>({
    options: [],
    value: null,
  });
  const [tab, setTab] = useState<string>('1');
  const [specifications, setSpecifications] = useState<FrameDataType[]>([]);
  const [specificationsFilter, setSpecificationsFilter] = useState<FrameDataType[]>([]);
  const [nomenclaturesFiles, setNomenclaturesFiles] = useState([]);
  const [popup, setPopup] = useState(false);
  const [balloon, setBalloon] = useState(false);
  const [additionalIdentificationIdFile, setAdditionalIdentificationIdFile] = useState<number>(0);

  const [isBalloon] = useState(false);
  const [errorImageList] = useState(false);
  const [passportImages, setPassportImages] = useState<any>([]);
  const [certificateImages, setCertificateImages] = useState<any>([]);
  const [passportImagesUpload, setPassportImagesUpload] = useState<any>([]);
  const [certificateImagesUpload, setCertificateImageUpload] = useState<any>([]);
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [idSpecifications, setIdSpecifications] = useState<any>([]);
  const [idNomenclatures, setIdNomenclatures] = useState<any>([]);

  const [filesSpecifications, setFilesSpecifications] = useState([]);
  const [filesContracts, setFilesContracts] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [state] = useState<FlightState>(flightStates['Запланирован']);

  const [sending, setSending] = useState(false);

  const [imageList] = useState<
    {
      nameFile: string;
      base64File: string;
      extension: string;
    }[]
  >([]);

  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [sortOrder, setSortOrder] = useState<{
    volume: string;
    price: string;
    sort: string;
  }>({
    sort: 'price',
    volume: 'increase',
    price: 'increase',
  });
  const sortMechanism = {
    volume: (
      <div
        onClick={() =>
          setSortOrder(prevState => ({
            ...prevState,
            volume: prevState.volume === 'increase' ? 'decrease' : 'increase',
            sort: 'volume',
          }))
        }
        style={{
          cursor: 'pointer',
          transform: sortOrder.volume === 'increase' ? 'rotate(90deg)' : 'rotate(-90deg)',
          width: '20px',
          height: '15px',
        }}
      >
        <ArrowBack />
      </div>
    ),
    price: (
      <div
        onClick={() =>
          setSortOrder(prevState => ({
            ...prevState,
            price: prevState.price === 'increase' ? 'decrease' : 'increase',
            sort: 'price',
          }))
        }
        style={{
          cursor: 'pointer',
          transform: sortOrder.price === 'increase' ? 'rotate(90deg)' : 'rotate(-90deg)',
          width: '20px',
          height: '15px',
        }}
      >
        <ArrowBack />
      </div>
    ),
  };

  const openFile = async (id: number, communicationCategory: string) => {
    try {
      setSkeleton(true);
      const response = await fetchGet(`/files/${id}`, { category: communicationCategory });
      let pdfWindow = window.open('');
      pdfWindow.document.write('<html<head><title>' + 'fileName' + '</title><style>body{margin: 0;}</style></head>');
      pdfWindow.document.write(
        `<body><embed width='100%' height='100%' src='${encodeURI(response?.response.data)}'></embed></body></html>`,
      );
      pdfWindow.document.close();

      setSkeleton(false);
    } catch (e) {
      setSkeleton(false);
      console.log(e);
    }
  };

  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };

  const getData = async dateRange => {
    setSkeleton(true);
    const options = dateRange[0]
      ? {
          startDate: new Date(dateRange[0]),
          finishDate: new Date(dateRange[1]),
          contractId: window.location.href.split('/').reverse()[0],
          status: tab === '1' ? 'open' : 'closed',
        }
      : {
          contractId: window.location.href.split('/').reverse()[0],
          status: tab === '1' ? 'open' : 'closed',
        };

    try {
      const contracts = await fetchGet(`/information/contracts/${window.location.href.split('/').reverse()[0]}`, {});
      await setContracts(contracts);
      const responseFile = await fetch(
        `${SUBSERVER}/api/files/?fileBelongsId=${window.location.href.split('/').reverse()[0]}&communicationCategory=contracts`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
          credentials: 'include',
        },
      );
      const dataFile = await responseFile.json();
      await dataFile.response.map(file => {
        setFilesContracts(prevSate =>
          [...prevSate, file].filter(
            (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
          ),
        );
      });

      const specifications = await fetchGet(`/nomenclature/specifications`, options);

      await setData({
        title: contracts?.response?.title,
        content: correctionSupplierDate(supplier, contracts, filesContracts, specifications, openPopupWithImg),
        nameStatus: '',
        status: contracts?.response?.status?.title,
      });
      const specification = [];
      specifications.response.forEach((item: any) => {
        if (specification.find(({ value }) => value === item?.name)) {
          return;
        }

        specification.push({ value: item?.name, label: item?.name });

        setNomenclaturesSelects(prevState => ({ ...prevState, options: specification, value: null }));
      });

      const specificationsData: FrameDataType[] = [];

      specifications.response.forEach((specification: any) => {
        specificationsData.push({
          id: specification.id,
          item: correctionSpecificationsData(supplier, specification),
        });
      });

      setSpecifications(specificationsData);
      setSpecificationsFilter(specificationsData);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };

  const sendDataSpecifications = async id => {
    setDisabled(true);
    setSending(true);

    if (!passportImages && !certificateImages) {
      return;
    }
    try {
      const passportData = {
        communicationCategory: 'nomenclaturePassport',
        additionalIdentificationId: additionalIdentificationIdFile,
        fileBelongsId: Number(idNomenclatures),
        category: 'Паспорт номенклатуры',
        files: [...passportImagesUpload?.map(file => file.value)],
        objectUuid: '',
      };

      const certificateData = {
        communicationCategory: 'nomenclatureCertificate',
        additionalIdentificationId: additionalIdentificationIdFile,
        category: 'Сертификат номенклатуры',
        fileBelongsId: Number(idNomenclatures),
        objectUuid: '',
        files: [...certificateImagesUpload?.map(file => file.value)],
      };
      const requestOption = [passportData, certificateData]?.filter(item => item?.files?.length !== 0);
      const responseDataSpecifications = await fetchPost(`/files/`, 'POST', requestOption);
      if (responseDataSpecifications.error) {
        setDisabled(false);
        setSending(false);
        return toast.error(`При этапе отправки файлов что-то пошло не так`);
      }
      setDisabled(false);
      setSending(false);
      setPassportImagesUpload([]);
      setCertificateImageUpload([]);
      setPopup(closePopUp);
      toast.success('Данные успешно сохранены');
    } catch (e) {
      setDisabled(false);
      setSending(false);
    }
  };
  const deleteFile = async (id, name, communicationCategory) => {
    try {
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify({
          communicationCategory: communicationCategory,
          name: name,
          objectUuid: '',
        }),
      });
      const data = await response.json();
      if (data.error) {
        const errorMessages = data.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getNomenclatures = async (id: number) => {
    const { response } = await fetchGet(`/nomenclature/specifications/${id}`, {
      contractId: window.location.href.split('/').reverse()[0],
    });
    setAdditionalIdentificationIdFile(response.id);
    setIdNomenclatures(response.nomenclatureSpecification.nomenclature.id);
    localStorage.setItem('nomenclaturesFiles', JSON.stringify(response));
    const responseFile = await fetch(
      `${SUBSERVER}/api/files/?fileBelongsId=${id}&communicationCategory=specifications`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      },
    );
    const dataFile = await responseFile.json();

    await dataFile.response.map(async file => {
      await setFilesSpecifications(prevSate =>
        [...prevSate, file].filter(
          (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
        ),
      );
    });
    const fileNomenclaturePassport = await fetch(
      `${SUBSERVER}/api/files/?fileBelongsId=${response.nomenclatureSpecification.nomenclature.id}`+
      `&communicationCategory=nomenclaturePassport&additionalIdentificationId=${response.id}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      },
    );
    const dataFileNomenclaturePassport = await fileNomenclaturePassport.json();
    await dataFileNomenclaturePassport.response.map(async file => {
      await setPassportImages(prevSate =>
        [...prevSate, file].filter(
          (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
        ),
      );
    });
    const nomenclatureCertificate = await fetch(
      `${SUBSERVER}/api/files/?fileBelongsId=${response.nomenclatureSpecification.nomenclature.id}`+
      `&communicationCategory=nomenclatureCertificate&additionalIdentificationId=${response.id}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      },
    );
    const dataFileNomenclatureCertificate = await nomenclatureCertificate.json();
    await dataFileNomenclatureCertificate.response.map(async file => {
      await setCertificateImages(prevSate =>
        [...prevSate, file].filter(
          (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
        ),
      );
    });
    localStorage.setItem('filesSpecification', JSON.stringify(dataFile.response));

    setIdSpecifications(response.nomenclatureSpecification.nomenclature.id);
    setPopup(openPopUp);
  };
  useEffect(() => {
    const storedNomenclaturesFiles = JSON.parse(localStorage.getItem('nomenclaturesFiles'));
    const filesSpecification = JSON.parse(localStorage.getItem('filesSpecification'));
    setDataSpecification({
      title: storedNomenclaturesFiles?.contract?.title,
      content: supplier
        ? {
            'Общая информация': {
              'Номер к договору': checkValidation(storedNomenclaturesFiles?.contract?.title),
              'Дата начала': parseTimeHelper(storedNomenclaturesFiles?.startDate),
              'Дата окончания': parseTimeHelper(storedNomenclaturesFiles?.validityPeriod),
              'Тип перевозки': checkValidation(storedNomenclaturesFiles?.deliveryType),
              'Файл скана спецификации': (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}>
                  {filesSpecification?.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                    <div className="image-container" key={id}>
                      <ImageContainer
                        id={id}
                        value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                        name={name}
                        size={size}
                        isSize={isSize}
                        date={date}
                        isDate={isDate}
                        type={mimeType}
                        openPopUp={() =>
                          (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                        }
                        children={undefined}
                      ></ImageContainer>
                    </div>
                  ))}
                </div>
              ),
            },
            'Информация по оплате': {
              'Условия оплаты': checkValidation(storedNomenclaturesFiles?.paymentTerms),
              'Кол-во дней отсрочки': checkValidation(storedNomenclaturesFiles?.daysOfDelay),
              'Вариант подсчета дней': checkValidation(storedNomenclaturesFiles?.daysCounting),
            },
            'Информация по материалу': {
              Номенклатура: checkValidation(storedNomenclaturesFiles?.nomenclatureSpecification?.content),
              'Ед.изм.': checkValidation(storedNomenclaturesFiles?.nomenclatureSpecification?.unit),
              Стоимость: checkValidation(formPrice(storedNomenclaturesFiles?.nomenclatureSpecification?.price, 'RUB')),
              НДС: `${checkValidation(storedNomenclaturesFiles?.contract?.taxPercentage)}%`,
              'Насыпной коэффициент': checkValidation(storedNomenclaturesFiles?.coefficient),
              'Общий объем': checkValidation(storedNomenclaturesFiles?.volume),
            },
          }
        : {
            'Общая информация': {
              'Номер к договору': checkValidation(storedNomenclaturesFiles?.contract?.title),
              'Дата окончания': parseTimeHelper(storedNomenclaturesFiles?.validityPeriod),
              'Тип перевозки': checkValidation(storedNomenclaturesFiles?.deliveryType),
              'Файл скана спецификации': (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}>
                  {filesSpecification?.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                    <div className="image-container" key={id}>
                      <ImageContainer
                        id={id}
                        value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                        name={name}
                        size={size}
                        isSize={isSize}
                        date={date}
                        isDate={isDate}
                        type={mimeType}
                        openPopUp={() =>
                          (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                        }
                        children={undefined}
                      ></ImageContainer>
                    </div>
                  ))}
                </div>
              ),
            },
            'Информация по оплате': {
              'Условия оплаты': checkValidation(storedNomenclaturesFiles?.paymentTerms),
              'Кол-во дней отсрочки': checkValidation(storedNomenclaturesFiles?.daysOfDelay),
              'Вариант подсчета дней': checkValidation(storedNomenclaturesFiles?.daysCounting),
              'Стоимость общая': checkValidation(
                formPrice(
                  storedNomenclaturesFiles?.nomenclatureSpecification?.price * storedNomenclaturesFiles?.volume,
                  'RUB',
                ),
              ),
            },
            'Информация по материалу': {
              Номенклатура: checkValidation(storedNomenclaturesFiles?.nomenclatureSpecification?.content),
              'Ед.изм.': checkValidation(storedNomenclaturesFiles?.nomenclatureSpecification?.unit),
              'Общий объем': checkValidation(storedNomenclaturesFiles?.volume),
              'Цена за ед.': checkValidation(
                formPrice(storedNomenclaturesFiles?.nomenclatureSpecification?.price, 'RUB'),
              ),
              НДС: `${checkValidation(storedNomenclaturesFiles?.contract?.taxPercentage)}%`,
            },
          },
    });
  }, [popup]);

  const items: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: '1',
      label: 'Открытые',
      children: (
        <>
          <div className={'specification-buyer-page__selects'}>
            <SelectBox gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}>
              {!supplier && (
                <CustomRangePicker
                  width={'300px'}
                  isPresets={true}
                  open={openCalendar}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setOpen={setOpenCalendar}
                />
              )}
              <SelectCustom
                options={nomenclaturesSelects.options}
                maxTagCount={1}
                placeholder={'По номеру спецификации'}
                mode={'tags'}
                value={nomenclaturesSelects?.value ? nomenclaturesSelects?.value : []}
                setValue={(value: string) =>
                  setNomenclaturesSelects(prevState => ({
                    ...prevState,
                    value,
                  }))
                }
                disabled={false}
                status={''}
                errorText={''}
              />
            </SelectBox>
          </div>
          {specificationsFilter.length ? (
            skeleton ? (
              <div className="FlightSection__content">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '485px ',
                  }}
                >
                  <div>
                    <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                  </div>
                </div>
              </div>
            ) : (
              <Frame
                gridTemplateColumns={
                  supplier ? '112px 112px 112px 112px 112px 112px' : '123px 72px 228px 110px 74px 136px 136px'
                }
                link={''}
                items={specificationsFilter.slice(0, more)}
                orderTab={'Открытые'}
                help={false}
                arrow={sortMechanism}
                contentHelp={''}
                popup={popup}
                setPopup={setPopup}
                isFunction={true}
                func={getNomenclatures}
              />
            )
          ) : skeleton ? (
            <div className="FlightSection__content">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '485px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            </div>
          ) : (
            <EmptyContent
              title={'Документов не найдено'}
              subTitle={'Нет документов, удовлетворяющих условиям фильтра'}
              svg={<SearchSvg />}
            />
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Закрытые',
      children: (
        <>
          <div className={'specification-buyer-page__selects'}>
            <SelectBox gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}>
              {!supplier && (
                <CustomRangePicker
                  width={'300px'}
                  isPresets={true}
                  open={openCalendar}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setOpen={setOpenCalendar}
                />
              )}
              <SelectCustom
                options={nomenclaturesSelects.options}
                maxTagCount={1}
                placeholder={'По номеру спецификации'}
                mode={'tags'}
                value={nomenclaturesSelects?.value ? nomenclaturesSelects?.value : []}
                setValue={(value: string) =>
                  setNomenclaturesSelects(prevState => ({
                    ...prevState,
                    value,
                  }))
                }
                disabled={false}
                status={''}
                errorText={''}
              />
            </SelectBox>
          </div>
          {specificationsFilter.length ? (
            skeleton ? (
              <div className="FlightSection__content">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '485px ',
                  }}
                >
                  <div>
                    <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                  </div>
                </div>
              </div>
            ) : (
              <Frame
                gridTemplateColumns={
                  supplier ? '112px 112px 112px 112px 112px  112px' : '123px 72px 228px 110px 74px 136px'
                }
                link={''}
                items={specificationsFilter.slice(0, more)}
                orderTab={'Открытые'}
                help={false}
                arrow={sortMechanism}
                contentHelp={''}
                popup={popup}
                setPopup={setPopup}
                isFunction={true}
                func={getNomenclatures}
              />
            )
          ) : skeleton ? (
            <div className="FlightSection__content">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '485px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            </div>
          ) : (
            <EmptyContent
              title={'Документов не найдено'}
              subTitle={'Нет документов, удовлетворяющих условиям фильтра'}
              svg={<SearchSvg />}
            />
          )}
        </>
      ),
    },
  ];
  const onChange = (key: string) => {
    setTab(key);
  };

  useEffect(() => {
    if (!nomenclaturesSelects.value?.length) {
      setSpecificationsFilter(specifications);
      return;
    }

    const newOrdersList = [];

    nomenclaturesSelects.value.forEach((item: any) => {
      const filterNomenclaturesData = specifications.filter(unit => unit.item['Номер'].title['up'] === item.value);

      if (!filterNomenclaturesData.length) {
        return;
      }

      newOrdersList.push(...filterNomenclaturesData);
    });

    setSpecificationsFilter(newOrdersList);
  }, [nomenclaturesSelects.value]);

  useEffect(() => {
    getData(dateRange).catch(console.error);
  }, [sortOrder.price, sortOrder.volume, dateRange, tab]);

  useEffect(() => {
    setDisabled(!passportImagesUpload.length && !certificateImagesUpload.length);
  }, [passportImagesUpload, certificateImagesUpload]);

  return (
    <>
      <PageWrapper style={{ zIndex: popup ? '9500' : '100' }}>
        <div className={'specification-buyer-page'}>
          <Link className="button-back" to={`/document`}>
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            К списку договоров
          </Link>
          {data && (
            <InfoItem
              item={data}
              contracts={contracts}
              filesContracts={filesContracts}
              specifications={specifications}
              openPopupWithImg={openPopupWithImg}
            />
          )}

          <div className="specifications-buyer-page__title-table">Спецификация к договору</div>
          <div className={'specification-buyer-page__tabs'}>
            <Tabs defaultActiveKey={tab} items={items} onChange={onChange} />
          </div>
          <AsidePopup open={popup} close={setPopup}>
            {dataSpecification ? (
              <div className={'specification-buyer-page-popup'}>
                <div className={'orders-page-popup-header'}>
                  <div
                    className={`orders-page-popup-header__title ${
                      isMobile ? 'textH5 textH5_medium' : 'textH3 textH3_medium'
                    }`}
                  >
                    {dataSpecification.title}
                  </div>
                  <div className={'orders-page-popup-header__close'} onClick={() => setPopup(closePopUp)}>
                    <CloseSvg />
                  </div>
                </div>
                <div className={'orders-page-popup__content'}>
                  <div className={'orders-page-popup-box'}>
                    {Object.entries(dataSpecification.content).map(([title, content], index) => (
                      <div key={index}>
                        <div className={'specification-buyer-page-popup__title textH5 textH5_medium'}>{title}</div>
                        <div className={'specification-buyer-page-popup__content'}>
                          {Object.entries(content).map(([key, value], index) => (
                            <div
                              key={index}
                              className={`specification-buyer-page-popup-item ${
                                key === 'Вариант подсчета дней' ? 'specification-buyer-page-popup-item_sub' : ''
                              }`}
                            >
                              <div className={'specification-buyer-page-popup-item__title textH6'}>{key}</div>
                              <div className={'specification-buyer-page-popup-item__text textH6'}>
                                {key === 'Дата начала' || key === 'Дата окончания' ? value : value}
                                {key === 'Вариант подсчета дней' && (
                                  <p
                                    onClick={() => setBalloon(!balloon)}
                                    className={'specification-buyer-page-popup-item__svg'}
                                  >
                                    <QuestionSvg />
                                  </p>
                                )}
                                {key === 'Вариант подсчета дней' && (
                                  <div
                                    className={`specification-buyer-page-popup-item__balloon 
                                                                            ${balloon ? 'open' : ''}`}
                                  >
                                    <div className={'textLarge'}>К.Д. - календарные дни.</div>
                                    <div className={'textLarge'}>Б.Д. - банковские дни.</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {isBalloon && (
                    <div className={errorImageList ? 'balloon-image balloon-image_error' : 'balloon-image'}>
                      <div className="balloon-image__svg">{errorImageList ? <ErrorFullSvg /> : <SuccessSvg />}</div>
                      <div className="balloon-image__text">
                        {errorImageList ? 'Произошла ошибка при загрузке паспорта' : 'Паспорт были успешно загружен'}
                      </div>
                    </div>
                  )}
                  {user.companyType !== 'buyer' && (
                    <div>
                      <div className="specification-buyer-page-popup__title textH5 textH5_medium">
                        Паспорт материала
                      </div>

                      {nomenclaturesFiles?.length > 0 ? (
                        <div
                          className={'specification-buyer-page-popup-item__text textH6'}
                          style={{ display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'flex-end' }}
                        >
                          {nomenclaturesFiles?.map(({ name, id, communicationCategory }) => (
                            <a
                              key={id}
                              onClick={() => openFile(id, communicationCategory)}
                              target={'_blank'}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </a>
                          ))}
                        </div>
                      ) : (
                        <>
                          <div className={'specification-buyer-page-popup-item__title textH6'}>Паспорт</div>
                          {!state.requiredButton && (
                            <InputUploading
                              onChangeFunction={({ target }) =>
                                loadingImages(setPassportImagesUpload, passportImagesUpload, target, 3)
                              }
                              text={'загрузите паспорт'}
                              subText={'В формате JPG, PNG, PDF, HEIC до 50 мегабайт'}
                            />
                          )}
                          {passportImages.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={mimeType === 'image/png' ? null : location}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={mimeType}
                                openPopUp={() => mimeType === 'image/png' && openPopupWithImg(location)}
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                      deleteFile(id, name, 'nomenclaturePassport');
                                      removeImage(target, passportImages, setPassportImages);
                                    }}
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))}
                          {passportImagesUpload.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={value}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={type}
                                openPopUp={false}
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) =>
                                      removeImage(target, passportImagesUpload, setPassportImagesUpload)
                                    }
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))}
                          <div className={'specification-buyer-page-popup-item__title textH6'}>Сертификат</div>
                          {!state.requiredButton && (
                            <InputUploading
                              onChangeFunction={({ target }) =>
                                loadingImages(setCertificateImageUpload, certificateImagesUpload, target, 3)
                              }
                              text={'загрузите сертификат'}
                              subText={'В формате JPG, PNG, PDF, HEIC до 50 мегабайт'}
                            />
                          )}
                          {certificateImages.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={mimeType === 'image/png' ? null : location}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={mimeType}
                                openPopUp={() => mimeType === 'image/png' && openPopupWithImg(location)}
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                      deleteFile(id, name, 'nomenclatureCertificate');
                                      removeImage(target, certificateImages, setCertificateImages);
                                    }}
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))}
                          {certificateImagesUpload.map(({ id, value, name, size, isSize, date, isDate, mimeType }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={value}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={mimeType}
                                openPopUp={false}
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) =>
                                      removeImage(target, certificateImagesUpload, setCertificateImageUpload)
                                    }
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))}
                        </>
                      )}
                      <div className="loaded-image">
                        <>
                          <Button
                            left={false}
                            right={false}
                            onClick={() => sendDataSpecifications(idSpecifications)}
                            modifier={''}
                            children={'Сохранить'}
                            svg={null}
                            colorSvg={''}
                            disabled={disabled}
                            sending={sending}
                            color={''}
                          />
                        </>

                        <div className="list-image">
                          {imageList.map(({ nameFile, base64File, extension }, index) => (
                            <div
                              onClick={() => extension !== 'pdf' && openPopupWithImg(base64File)}
                              className="list-image__item"
                              key={index}
                            >
                              <div className="list-image__img">
                                {extension === 'pdf' ? (
                                  <a href={base64File} download>
                                    <FileSvg />
                                  </a>
                                ) : (
                                  <>
                                    <img src={base64File} alt="" />
                                    <div className="list-image__svg">
                                      <FileSvg />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="list-image__name">{nameFile || 'Фото'}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={popUpActive ? 'Popup active' : 'Popup'}>
                    <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
                    <div className="Popup__wrapper">
                      <div>
                        <div>
                          <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                          <div className="Popup__content">
                            <div className="Popup__box">
                              <img src={image} alt={'Транспортная накладная'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </AsidePopup>
        </div>
      </PageWrapper>
    </>
  );
};
