import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { ErrorText } from '../../components/ErrorText';
import { InputNumber } from '../../components/UI/inputs/InputNumber';
import { DateInput } from '../../components/UI/inputs/DateInput';
import { Button } from '../../components/UI/buttons/Button';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { DeleteProfile } from '../../components/UI/svg-icon/DeleteProfile';
import { SkeletonDriver } from '../../components/skeletons/SkeletonDriver';
import { DeletePopUp } from '../../components/DeletePopUp';
import { profileData } from '../../common/profile.type';
import { closePopUp, openPopUp } from '../../common/use-popup.functiopn';
import { toast, ToastContainer } from 'react-toastify';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import { SUBSERVER } from '../../common/sub-server.constant';
import { validationPassport, validationPassportSeries } from './DriversLicensePage';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import CustomDatePicker from '../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import { validateEmail } from 'src/common/validation-email.function';
import { upperCaseFirstLetter } from 'src/common/uppercase-first-letter.helper';

export const DriverLicensePage = () => {
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState({
    passportDateError: false,
    birthDateError: false,
    driversLicenseDateError: false,
  });
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [updateDataDriver, setUpdateDataDriver] = useState(false);
  const [profile, setProfile] = useState(profileData);
  const [uploadImagesPassport, setUploadImagesPassport] = useState<any>([]);
  const [imagesPassport, setImagesPassport] = useState<any>([]);
  const [uploadImagesDriverLicense, setUploadImagesDriverLicense] = useState<any>([]);
  const [imagesDriverLicense, setImagesDriverLicense] = useState<any>([]);
  const [image, setImage] = useState('');
  const idDriver = localStorage.getItem('idDriver');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const getFiles = async id => {
    try {
      const responseFile = await fetch(`${SUBSERVER}/api/files/?fileBelongsId=${id}&communicationCategory=drivers`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
      });
      const dataFile = await responseFile.json();
      dataFile?.response.forEach(file => {
        if (file.category === 'Паспорт') {
          setImagesPassport(prevState =>
            [...prevState, file].filter(
              (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
            ),
          );
          setUploadImagesPassport([]);
        } else {
          setImagesDriverLicense(prevState =>
            [...prevState, file].filter(
              (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
            ),
          );
          setUploadImagesDriverLicense([]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const dataDriver = async () => {
    setSkeleton(true);
    try {
      const {
        response: { surname, name, patronymic, phone, passport, profile, driverLicense, uuid },
      } = await fetchGet(`/drivers/${idDriver}`, {});

      setProfile({
        ...profile,
        surname,
        uuid,
        name,
        patronymic,
        phone,
        email: profile.email,
        passportSeries: passport?.passportSeries,
        passportNumber: passport?.passportNumber,
        passportDate: dayjs(passport?.passportDate),
        birthDate: dayjs(profile?.birthDate),
        passportIssued: passport?.passportIssued,
        birthPlace: profile?.birthPlace,
        driversLicenseSeries: driverLicense?.driversLicenseSeries,
        driversLicenseNumber: driverLicense?.driversLicenseNumber,
        driversLicenseDate: dayjs(driverLicense?.driversLicenseDate),
      });
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }

    setTimeout(() => {
      setSkeleton(false);
    }, 800);
  };

  useEffect(() => {
    dataDriver().catch(e => console.log(e));
    getFiles(idDriver).catch(e => console.log(e));
  }, [updateDataDriver]);

  useEffect(() => {
    const isErrorDate = error.birthDateError || error.driversLicenseDateError || error.passportDateError;
    setDisabled(!profile.name || !profile.surname || !profile.patronymic || !profile.phone || isErrorDate);
  }, [profile.name, profile.surname, profile.patronymic, profile.phone, error]);

  const backAndClose = () => {
    closePopUp(setPopUpActive);
    navigate('/driverLicense');
  };
  function add1DayToDate(date: string) {
    let correctDate = new Date(date);
    return new Date(correctDate.setDate(correctDate.getDate() + 1));
  }

  const deleteFile = async (id, name, communicationCategory) => {
    const requestOptions: any = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      }),
    };
    try {
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
      const data = await response.json();
      if (data.error) {
        const errorMessages = data.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const sendDataDriver = async deleteDriver => {
    setSending(true);
    setDisabled(true);

    if (!profile.surname || !profile.name || !profile.patronymic || !profile.phone) {
      toast.error('Заполните обязательные поля');
      setDisabled(false);
      setSending(false);
      return;
    }
    try {
      const patchDrivers = await fetchPost(`/drivers/${idDriver}`, 'PATCH', {
        surname: profile.surname.trim(),
        name: profile.name.trim(),
        patronymic: profile.patronymic.trim(),
        phone: profile.phone,
        profile: {
          birthDate: new Date(profile.birthDate),
          birthPlace: profile.birthPlace,
          email: profile.email,
        },
        passport: {
          passportSeries: profile.passportSeries,
          passportNumber: profile.passportNumber,
          passportDate: new Date(profile.passportDate),
          passportIssued: profile.passportIssued,
        },
        driverLicense: {
          driversLicenseSeries: profile.driversLicenseSeries,
          driversLicenseNumber: profile.driversLicenseNumber,
          driversLicenseDate: new Date(profile.driversLicenseDate),
        },
        active: deleteDriver,
      });
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'drivers',
          category: 'Паспорт',
          fileBelongsId: Number(idDriver),
          files: uploadImagesPassport?.map(file => file?.value),
          objectUuid: profile.uuid || '',
        },
        {
          communicationCategory: 'drivers',
          category: 'Водительское удостоверение',
          fileBelongsId: Number(idDriver),
          files: uploadImagesDriverLicense?.map(file => file?.value),
          objectUuid: profile.uuid || '',
        },
      ];
      if (patchDrivers.error) {
        const errorMessages = patchDrivers.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setDisabled(false);
        setSending(false);
        return;
      }
      if (patchDrivers?.response[0]?.id) {
        patchDrivers.response.forEach(({ flightName }) =>
          toast.error(`Завершите данный рейс (${flightName}) перед удалением водителя`),
        );
        setDisabled(false);
        setSending(false);
        return;
      }

      if (patchDrivers?.response[0] === 1) {
        deleteDriver && toast.success('Данные успешно обновлены');
        await fetch(`${SUBSERVER}/api/files`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
          credentials: 'include',
          body: JSON.stringify(correctionArrayFilesBody.filter(file => file.files.length)),
        });
        setSending(false);
        setDisabled(false);
        setUpdateDataDriver(!updateDataDriver);
        return;
      }
      setPopUpActive(false);
      setSending(false);
      setDisabled(false);
    } catch (e) {
      setSending(false);
      setDisabled(false);
      console.log(e);
    }
  };
  const sendDeleteDataDriver = async deleteDriver => {
    setSending(true);
    setDisabled(true);

    try {
      const patchDrivers = await fetchPost(`/drivers/${idDriver}`, 'DELETE', {});
      if (patchDrivers?.error) {
        toast.error(patchDrivers?.message[0]);
        setDisabled(false);
        setSending(false);
        return;
      }
      if (patchDrivers?.response[0]?.id) {
        patchDrivers.response.forEach(({ flightName }) =>
          toast.error(`Завершите данный рейс (${flightName}) перед удалением водителя`),
        );
        setDisabled(false);
        setSending(false);
        return;
      }

      if (patchDrivers?.response[0] !== 1) {
        toast.error(patchDrivers.response);
        setDisabled(false);
        setSending(false);
        return;
      }

      if (patchDrivers?.response[0] === 1) {
        setDeleted(true);
        deleteDriver && toast.success('Данные успешно обновлены');
        setSending(false);
        setDisabled(false);
        return;
      }

      setSending(false);
      setDisabled(false);
    } catch (e) {
      setSending(false);
      setDisabled(false);
      console.log(e);
    }
  };
  const openPopupWithImg = src => {
    setImage(src);
    openPopUp(setPopUpActiveImg);
  };
  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonDriver />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <div className="DriverLicensePage">
            <div>
              <Link className="button-back" to="/driverLicense">
                <div className="button-back__svg">
                  <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                Вернуться к списку водителей
              </Link>
            </div>
            <div className="DriverLicensePage__title">Редактировать данные водителя</div>
            <div className="DriverLicensePage__content">
              <div className="inputs">
                <div className="inputs__title">Личные данные водителя</div>
                <div className="inputs__content">
                  <InputText
                    type={''}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Фамилия'}
                    textError={'Поле обязательное для заполнения'}
                    value={profile.surname}
                    setValue={value => setProfile({ ...profile, surname: upperCaseFirstLetter(value) })}
                    isLabel={true}
                    error={!profile.surname || profile.surname === ''}
                    margin={''}
                    disabled={false}
                  />
                  <InputText
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Имя'}
                    textError={'Поле обязательное для заполнения'}
                    value={profile?.name}
                    setValue={value => setProfile({ ...profile, name: upperCaseFirstLetter(value) })}
                    isLabel={true}
                    error={!profile.name || profile.name === ''}
                    margin={''}
                    disabled={false}
                  />
                  <InputText
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Отчество'}
                    textError={'Поле обязательное для заполнения'}
                    value={profile?.patronymic}
                    setValue={value => setProfile({ ...profile, patronymic: upperCaseFirstLetter(value) })}
                    isLabel={true}
                    error={!profile.patronymic || profile.patronymic === ''}
                    fill={true}
                    margin={''}
                    disabled={false}
                  />
                  <InputText
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'E-mail'}
                    textError={'Введите корректный E-mail'}
                    value={profile.email}
                    setValue={value => setProfile({ ...profile, email: value })}
                    isLabel={true}
                    error={Boolean(profile.email) && !validateEmail(profile.email)}
                    fill={true}
                    margin={''}
                    showError={Boolean(profile.email) && !validateEmail(profile.email)}
                    disabled={false}
                  />
                  <MyPhoneInput
                    disabled={false}
                    setIsError={setIsErrorPhone}
                    isError={isErrorPhone}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    value={profile.phone}
                    onChange={value => setProfile({ ...profile, phone: value })}
                    isFunction={null}
                    btnHelp={false}
                  />
                  <ErrorText
                    isError={isErrorPhone}
                    empty={false}
                    emptyText={''}
                    correctText="Вы ввели некорректный номер телефона"
                  />
                </div>
              </div>
              <div className="inputs">
                <div className="inputs__title">Паспортные данные</div>
                <div className="inputs__content">
                  <InputNumber
                    bgError={profile.passportSeries && profile.passportSeries.length >= 2}
                    error={profile.passportSeries.length < 2 && profile.passportSeries.length !== 0}
                    fill={profile.passportSeries.length <= 0}
                    placeholder={'Серия'}
                    textError={'Неверный формат'}
                    margin={''}
                    isValue={profile?.passportSeries}
                    setIsValue={value =>
                      validationPassport(
                        value.toUpperCase(),
                        setProfile,
                        { ...profile, passportSeries: value.toUpperCase() },
                        4,
                      )
                    }
                  />
                  <InputNumber
                    bgError={profile.passportNumber && profile.passportNumber.length >= 6}
                    textError={'Неверный формат'}
                    error={profile.passportNumber && profile.passportNumber.length < 6}
                    fill={profile.passportNumber.length <= 0}
                    placeholder={'Номер'}
                    margin={''}
                    isValue={profile?.passportNumber}
                    setIsValue={value =>
                      validationPassport(
                        value.toUpperCase(),
                        setProfile,
                        { ...profile, passportNumber: value.toUpperCase() },
                        9,
                      )
                    }
                  />
                  <CustomDatePicker
                    width={'100%'}
                    placeholder={'Дата рождения'}
                    value={profile?.passportDate}
                    setValue={passportDate => setProfile({ ...profile, passportDate })}
                  />
                  <CustomDatePicker
                    width={'100%'}
                    placeholder={'Дата рождения'}
                    value={profile?.birthDate}
                    setValue={value => setProfile({ ...profile, birthDate: value })}
                  />
                  <InputText
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Кем выдан'}
                    textError={'Поле обязательное для заполнения'}
                    value={profile.passportIssued}
                    setValue={value => setProfile({ ...profile, passportIssued: value })}
                    isLabel={true}
                    error={true}
                    fill={false}
                    margin={''}
                    disabled={false}
                  />
                  <InputText
                    type={''}
                    secondError={false}
                    helpText={''}
                    widthHelp={0}
                    placeholder={'Место рождения'}
                    textError={'Поле обязательное для заполнения'}
                    value={profile.birthPlace}
                    setValue={value => setProfile({ ...profile, birthPlace: value })}
                    isLabel={true}
                    error={true}
                    fill={false}
                    margin={''}
                    disabled={false}
                  />
                  <div>
                    <ProfilePageUploading
                      name={'Паспорт'}
                      desc={'Разворот с фото, разворот с пропиской'}
                      setImages={setUploadImagesPassport}
                      images={uploadImagesPassport}
                      count={2 - Number(imagesPassport?.length)}
                    />
                    {imagesPassport.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? '' : location}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={mimeType}
                          openPopUp={() =>
                            (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                          }
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) => {
                              deleteFile(id, name, 'drivers');
                              removeImage(target, imagesPassport, setImagesPassport);
                            }}
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                    {uploadImagesPassport?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                      <div key={id}>
                        <ImageContainer
                          id={id}
                          value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={type}
                          openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) => removeImage(target, uploadImagesPassport, setUploadImagesPassport)}
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="inputs">
                <div className="inputs__title">Водительское удостоверение</div>
                <div className="inputs__content">
                  <InputNumber
                    maxLength={4}
                    bgError={profile.driversLicenseSeries && profile.driversLicenseSeries.length >= 2}
                    fill={profile.driversLicenseSeries.length <= 0}
                    placeholder={'Серия'}
                    margin={''}
                    textError={'Неверный формат'}
                    error={profile.driversLicenseSeries.length < 2 && profile.driversLicenseSeries.length !== 0}
                    isValue={profile.driversLicenseSeries}
                    setIsValue={value =>
                      validationPassport(
                        value.toUpperCase(),
                        setProfile,
                        { ...profile, driversLicenseSeries: value.toUpperCase() },
                        4,
                      )
                    }
                  />
                  <InputNumber
                    maxLength={9}
                    bgError={profile.driversLicenseNumber && profile.driversLicenseNumber.length >= 6}
                    textError={'Неверный формат'}
                    error={profile.driversLicenseNumber && profile.driversLicenseNumber.length < 6}
                    fill={profile.driversLicenseNumber.length <= 0}
                    placeholder={'Номер'}
                    margin={''}
                    isValue={profile.driversLicenseNumber}
                    setIsValue={value =>
                      validationPassport(
                        value.toUpperCase(),
                        setProfile,
                        { ...profile, driversLicenseNumber: value.toUpperCase() },
                        9,
                      )
                    }
                  />
                  <CustomDatePicker
                    width={'100%'}
                    placeholder={'Дата рождения'}
                    value={profile.driversLicenseDate}
                    setValue={value => setProfile({ ...profile, driversLicenseDate: value })}
                  />
                  <div>
                    <ProfilePageUploading
                      name={'Водительское удостоверение'}
                      desc={'Лицевая и оборотная сторона'}
                      setImages={setUploadImagesDriverLicense}
                      images={uploadImagesDriverLicense}
                      count={2 - Number(imagesDriverLicense?.length)}
                    />
                    {imagesDriverLicense.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                      <div className="image-container" key={id}>
                        <ImageContainer
                          id={id}
                          value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? '' : location}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={mimeType}
                          openPopUp={() =>
                            (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                          }
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) => {
                              deleteFile(id, name, 'drivers');
                              removeImage(target, imagesDriverLicense, setImagesDriverLicense);
                            }}
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                    {uploadImagesDriverLicense?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                      <div key={id}>
                        <ImageContainer
                          id={id}
                          value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                          name={name}
                          size={size}
                          isSize={isSize}
                          date={date}
                          isDate={isDate}
                          type={type}
                          openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                        >
                          <ButtonClose
                            id={id}
                            onClick={({ target }) =>
                              removeImage(target, uploadImagesDriverLicense, setUploadImagesDriverLicense)
                            }
                            hidden=""
                          />
                        </ImageContainer>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="inputs">
                <div className="inputs__title"></div>
                <div className="inputs__content">
                  <ButtonFilled
                    width={'100%'}
                    text={'Сохранить'}
                    onClick={() => sendDataDriver(true)}
                    disabled={disabled}
                  />
                  <ButtonNotFilled
                    width={'100%'}
                    text={'Удалить водителя'}
                    disabled={disabled}
                    onClick={() => openPopUp(setPopUpActive)}
                  />
                </div>
              </div>
            </div>
          </div>
          <DeletePopUp
            title={[
              'Подтвердите удаление водителя',
              `Водитель ${profile.surname} ${profile.name} ${profile.patronymic} успешно удалён`,
            ]}
            svg={<DeleteProfile />}
            popUpActive={popUpActive}
            sendData={sendDeleteDataDriver}
            name={`${profile.surname} ${profile.name} ${profile.patronymic}`}
            numberTC={profile.phone}
            setPopUpActive={setPopUpActive}
            closeWindow={backAndClose}
            deleted={deleted}
          />
          <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
            <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
