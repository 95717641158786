// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DispatchingPage_filterContainer__1DB\\+A {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  border-radius: 8px;\n  margin-bottom: 32px;\n}\n\n.DispatchingPage_filterSearch__2rfvE {\n  width: 100%;\n}\n\n.DispatchingPage_filterSelect__80rGG {\n  width: 240px;\n}\n\n.DispatchingPage_blockRegister__y3Tdc {\n  padding: 16px;\n  background-color: #EFF6FF;\n  border-radius: 12px;\n}\n\n@media (max-width: 768px) {\n  .DispatchingPage_filterSearch__2rfvE {\n    margin-bottom: 10px;\n  }\n  .DispatchingPage_filterSelect__80rGG {\n    width: 100%;\n  }\n  .DispatchingPage_filterContainer__1DB\\+A {\n    display: block;\n    align-items: center;\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/DispatchingPages/DispatchingPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AACA;EACE,WAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AAIF;;AADA;EACE;IACE,mBAAA;EAIF;EAFA;IACE,WAAA;EAIF;EAFA;IACE,cAAA;IACA,mBAAA;IACA,mBAAA;EAIF;AACF","sourcesContent":[".filterContainer{\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  border-radius: 8px;\n  margin-bottom: 32px;\n}\n.filterSearch{\n  width: 100%;\n}\n.filterSelect{\n  width: 240px;\n}\n.blockRegister{\n  padding: 16px;\n  background-color: #EFF6FF;\n  border-radius: 12px;\n}\n\n@media (max-width: 768px) {\n  .filterSearch{\n    margin-bottom: 10px;\n  }\n  .filterSelect{\n    width: 100%;\n  }\n  .filterContainer{\n    display: block;\n    align-items: center;\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "DispatchingPage_filterContainer__1DB+A",
	"filterSearch": "DispatchingPage_filterSearch__2rfvE",
	"filterSelect": "DispatchingPage_filterSelect__80rGG",
	"blockRegister": "DispatchingPage_blockRegister__y3Tdc"
};
export default ___CSS_LOADER_EXPORT___;
