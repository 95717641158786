import React, {  useState } from 'react';
import styles from './InputsByMaterial.module.scss';
import {  ICheckBox, ITypeInputsOfMaterial } from '../../../type';
import { CustomSelect } from '../../../../../components/UI/select/CustomSelect';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { arrayInputsOfMaterial } from '../../../constans';
import TextArea from 'antd/es/input/TextArea';
import { ProfilePageUploading } from '../../../../../components/ProfilePageUploading';
import { ImageContainer } from '../../../../../components/ImageContainer';
import { ButtonClose } from '../../../../../components/UI/buttons/buttonClose';
import { removeImage } from '../../../../../common/removeImage';
import { closePopUp, openPopUp } from '../../../../../common/open-close-popup.function';
import { Image } from '../../../../../common/image.type';
import AddArea from '../AddArea/AddArea';
import { SUBSERVER } from '../../../../../common/sub-server.constant';
import { toast } from 'react-toastify';
interface IProps {
    valueOfInputsOfMaterial:ITypeInputsOfMaterial
    objectForCompare:ITypeInputsOfMaterial
    setValueOfInputsOfMaterial:React.Dispatch<React.SetStateAction<ITypeInputsOfMaterial>>
    valueMaterial:string
    imagesPassport:Image[]
    setImagesPassport:React.Dispatch<React.SetStateAction<Image[]>>
    imagesMaterial:Image[]
    setImagesMaterial:React.Dispatch<React.SetStateAction<Image[]>>
    uploadPassport:Image[]
    setUploadPassport:React.Dispatch<React.SetStateAction<Image[]>>
    setErrorAfterSend:React.Dispatch<React.SetStateAction<boolean>>
    errorAfterSend:boolean
    updateDate:boolean
    uploadMaterial:Image[]
    setUploadMaterial:React.Dispatch<React.SetStateAction<Image[]>>
    setDisableButtonOfEdit:any
    edit:boolean
    fixedPriceCheckBox:ICheckBox
    setFixedPriceCheckBox:React.Dispatch<React.SetStateAction<ICheckBox>>
}
const InputsByMaterial = (
    { errorAfterSend,setErrorAfterSend,updateDate,
        valueOfInputsOfMaterial, setValueOfInputsOfMaterial, valueMaterial,
        imagesPassport,setImagesPassport,imagesMaterial,setImagesMaterial,
        uploadPassport,setUploadPassport,uploadMaterial,setUploadMaterial,
        setDisableButtonOfEdit,edit,objectForCompare,fixedPriceCheckBox,setFixedPriceCheckBox
}:IProps) => {
    const [popUpActive, setPopUpActive] = useState(false);
    const [image, setImage] = useState('');
    const openPopupWithImg = src => {
        setImage(src);
        setPopUpActive(openPopUp);
    };
    const deleteFile = async (id, name, communicationCategory,uuid) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
            credentials: 'include',
            body: JSON.stringify({
                communicationCategory: communicationCategory,
                name: name,
                objectUuid: uuid||'',
            }),
        };
        try {
            // @ts-ignore
            const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
            const data = await response.json();
            if (data.error) {
                const errorMessages = data.message.map(message => (
                    <p>
                        {message}
                        <br />
                        <br />
                    </p>
                ));
                toast.error(<div>{errorMessages}</div>);
                return;
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <>
            <div className={styles.contentDynamicInputs}>
                {/*Мэпаем массив объект динамически изменяемых инпутов, в зависимости от материала*/}
                {arrayInputsOfMaterial(valueOfInputsOfMaterial,setValueOfInputsOfMaterial)[valueMaterial].map((item,index)=>(
                    <div key={index} className={styles.titleAndInputContainer}>
                        <div className={styles.titleContainer}>
                            {item.title}
                        </div>

                        {item.input === 'select' ?
                            <CustomSelect
                                validateErrorWithSend={true}
                                updateDate={updateDate}
                                errorAfterSend={errorAfterSend}
                                setErrorAfterSend={setErrorAfterSend}
                                value={typeof item.value === 'string' && item.value !== '' ?{ value:item.value,label:item.value } : item.value}
                                menuPosition={'fixed'}
                                isDisabled={item.disabled}
                                error={!item.value}
                                options={item.options}
                                setValue={item.onChange}
                                placeholder={'Выберите из списка'}
                                isClearable={false}
                                isSearchable={false}
                                noOptionsMessage={'Нет вариантов'}
                            />
                            :
                            <CustomInput
                                error={item.error}
                                clearButton={true}
                                value={item.value}
                                placeholder={'Введите значение'}
                                setValue={item.onChange}
                                disabled={false}/>
                        }
                    </div>
                ))}
            </div>
            {/*Добавляем к нашему списку статичные инпуты, которые есть во всех материалах*/}
            <div className={styles.titleAndInputContainer}>
                <p className={styles.titleContainer}>
                    {arrayInputsOfMaterial(valueOfInputsOfMaterial,setValueOfInputsOfMaterial)[valueMaterial].length+1}. Стоимость за единицу, ₽/т
                </p>
                {/*Добавление области(инпуты) по кнопке*/}
                <AddArea
                    setValueOfInputsOfMaterial={setValueOfInputsOfMaterial}
                    valueMaterial={valueMaterial}
                    valueOfInputsOfMaterial={valueOfInputsOfMaterial}
                    edit={edit}
                    fixedPriceCheckBox={fixedPriceCheckBox}
                    setFixedPriceCheckBox={setFixedPriceCheckBox}
                />
            </div>
            <div className={styles.titleAndInputContainer}>
                <p className={styles.titleContainer}>
                    {arrayInputsOfMaterial(valueOfInputsOfMaterial,setValueOfInputsOfMaterial)[valueMaterial].length+2}. Описание материала
                </p>
                <TextArea value={valueOfInputsOfMaterial[valueMaterial].description}
                    className={styles.textAreaContainer}
                    showCount
                    maxLength={300}
                    placeholder={'Краткое описание материала'}
                    onChange={e => setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial]:
                            { ...prevState[valueMaterial],description:e.target.value } }))}
                    disabled={false}
                />
            </div>
            <div className={styles.contentStaticInputs}>
                <div className={styles.titleAndInputContainer}>
                    <p className={styles.titleContainer}>
                        {arrayInputsOfMaterial(valueOfInputsOfMaterial,setValueOfInputsOfMaterial)[valueMaterial].length+3}. Паспорт материала
                    </p>
                    <ProfilePageUploading
                        name={'Паспорт'}
                        desc={'Загрузите скан паспорта материала'}
                        setImages={setUploadPassport}
                        images={uploadPassport}
                        count={2 - Number(imagesPassport?.length)}
                    />
                    {uploadPassport.map(({ id, value, name, size, isSize, date, isDate, type,uuid }) => (
                        <div className="image-container" key={id}>
                            <ImageContainer
                                id={id}
                                value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={type}
                                openPopUp={() =>
                                    (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                }
                            >
                                <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                        removeImage(target, uploadPassport, setUploadPassport);
                                    }}
                                    hidden=""
                                />
                            </ImageContainer>
                        </div>
                    ))}
                    {imagesPassport.map(
                        ({
                             id,
                             location,
                             name,
                             size,
                             isSize,
                             date,
                             isDate,
                             mimeType,
                             uuid,
                         }) => (
                            <div className="image-container" key={id}>
                                <ImageContainer
                                    id={id}
                                    value={
                                        mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location
                                    }
                                    name={name}
                                    size={size}
                                    isSize={isSize}
                                    date={date}
                                    isDate={isDate}
                                    type={mimeType}
                                    openPopUp={() =>
                                        (mimeType === 'image/png' || mimeType === 'image/jpeg') &&
                                        openPopupWithImg(location)
                                    }
                                >
                                    <ButtonClose
                                        id={id}
                                        onClick={({ target }) => {
                                            deleteFile(id, name, 'materials',uuid);
                                            removeImage(target, imagesPassport, setImagesPassport);
                                        }}
                                        hidden=""
                                    />
                                </ImageContainer>
                            </div>
                        ),
                    )}
                </div>
                <div className={styles.titleAndInputContainer}>
                    <p className={styles.titleContainer}>
                        {arrayInputsOfMaterial(valueOfInputsOfMaterial,setValueOfInputsOfMaterial)[valueMaterial].length+4}. Фотография материала
                    </p>
                    <ProfilePageUploading
                        name={'Фотография материала'}
                        desc={'Загрузите фотографию материала в хорошем качестве.'}
                        setImages={setUploadMaterial}
                        images={uploadMaterial}
                        count={4 - Number(imagesMaterial?.length)}
                    />
                    {uploadMaterial.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container" key={id}>
                            <ImageContainer
                                id={id}
                                value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={type}
                                openPopUp={() =>
                                    (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                }
                            >
                                <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                        removeImage(target, uploadMaterial, setUploadMaterial);
                                    }}
                                    hidden=""
                                />
                            </ImageContainer>
                        </div>
                    ))}
                    {imagesMaterial.map(
                        ({
                             id,
                             location,
                             name,
                             size,
                             isSize,
                             date,
                             isDate,
                             mimeType,
                             uuid
                         }) => (
                            <div className="image-container" key={id}>
                                <ImageContainer
                                    id={id}
                                    value={
                                        mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location
                                    }
                                    name={name}
                                    size={size}
                                    isSize={isSize}
                                    date={date}
                                    isDate={isDate}
                                    type={mimeType}
                                    openPopUp={() =>
                                        (mimeType === 'image/png' || mimeType === 'image/jpeg') &&
                                        openPopupWithImg(location)
                                    }
                                >
                                    <ButtonClose
                                        id={id}
                                        onClick={({ target }) => {
                                            deleteFile(id, name, 'materials',uuid);
                                            removeImage(target, imagesMaterial, setImagesMaterial);
                                        }}
                                        hidden=""
                                    />
                                </ImageContainer>
                            </div>
                        ),
                    )}
                </div>
            </div>
            <div className={popUpActive ? 'Popup active' : 'Popup'}>
                <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
                <div className="Popup__wrapper">
                    <div>
                        <div>
                            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                            <div className="Popup__content">
                                <div className="Popup__box">
                                    <img src={image} alt={'Транспортная накладная'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InputsByMaterial;