export type Flight = {
  [key: string]: any;

  flightName: string;

  supplier: {
    title: string;
    address: string
  };

  client: {
    title: string;
    address: string
  };

  status: string;

  planDate: Date;

  nomenclature: {
    content: string
  };

  car: {
    carBrandId: string,
    stateNumber: string
  };
  unloadingVolume:number,
  carrierPrice:number,

  user: {
    surname: string
  };
};

const standardFind = (search, fieldValue) => fieldValue && fieldValue.toLowerCase().includes(search.toLowerCase());

export const flightFieldSearchingMechanism = {

  'Рейс': (search, filed) => standardFind(search, filed?.Рейс),

  'Поставщик': (search, filed) => standardFind(search, filed?.Наименование),

  'ДополнительнаяИнформацияПоПоставщику': (search, filed) => standardFind(search, filed?.АдресКарьера),

  'Клиент': (search, filed) => standardFind(search, filed?.Наименование),

  'ДополнительнаяИнформацияПоКлиенту': (search, filed) => standardFind(search, filed?.АдресВыгрузки),

  'СтатусРейса': (search, filed) => standardFind(search, filed?.СтатусРейса),

  'Номенклатура': (search, filed) => standardFind(search, filed?.Содержание),

  'Транспорт': (search, filed) => standardFind(search, filed?.МаркаТС),

  'Водитель': (search, filed) => standardFind(search, filed?.ФИО),
};

export const flightFieldSearchingMechanism2 = {

  'flightName': (search, filed) => standardFind(search, filed),

  'supplier': (search, filed) => standardFind(search, filed?.title) || standardFind(search, filed?.address),

  'client': (search, filed) => standardFind(search, filed?.title) || standardFind(search, filed?.address),

  'status': (search, filed) => standardFind(search, filed),

  'nomenclature': (search, filed) => standardFind(search, filed?.content),

  'car': (search, filed) => standardFind(search, filed?.carBrand?.brand) || standardFind(search, filed?.stateNumber),

  'user': (search, filed) => standardFind(search, filed?.surname),
};
