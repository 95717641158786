export const objDeclension = {
  driver: {
    1: 'новый рейс',
    2: 'новых рейса',
    3: 'новых рейса',
    4: 'новых рейса',
    5: 'новых рейсов',
    6: 'новых рейсов',
    7: 'новых рейсов',
    8: 'новых рейсов',
    9: 'новых рейсов',
    0: 'новых рейсов',
  },
  supplier: {
    1: 'новая отгрузка',
    2: 'новые отгрузки',
    3: 'новые отгрузки',
    4: 'новые отгрузки',
    5: 'новых отгрузок',
    6: 'новых отгрузок',
    7: 'новых отгрузок',
    8: 'новых отгрузок',
    9: 'новых отгрузок',
    0: 'новых отгрузок',
  },
};
export const objDeclensionOfRefuseModal = {
  flight: {
    1: 'рейс',
    2: 'рейса',
    3: 'рейса',
    4: 'рейса',
    5: 'рейсов',
    6: 'рейсов',
    7: 'рейсов',
    8: 'рейсов',
    9: 'рейсов',
    0: 'рейсов',
  },
  flightTakeOff: {
    1: 'будет с вас снят',
    2: 'будет с вас снято',
    3: 'будет с вас снято',
    4: 'будет с вас снято',
    5: 'будет с вас снято',
    6: 'будет с вас снято',
    7: 'будет с вас снято',
    8: 'будет с вас снято',
    9: 'будет с вас снято',
    0: 'будет с вас снято',
  },
};
