import React from 'react';

export const RadioBoxCircle = ({ id, value, name, check, ...props }) => {

  return (
    <div className={'radioBoxCircle'}>
      <label className={`radioBoxCircle__label largeR ${check ? 'active' : ''}`} htmlFor={id}>
        <input
          className={'radioBoxCircle__input'}
          {...props}
          id={id}
          type={'radio'}
          value={value}
          name={name}/>
        <div className={`radioBoxCircle__circle ${check ? 'active' : ''}`}><span></span></div>
        {value}
      </label>
    </div>
  );
};