import React from 'react';

export const RosneftLogo = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 118 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5151 39.951H8.08008V22.7314H11.5151V39.951Z" fill="#FFD300"/>
      <path d="M15.5776 39.9514H12.1426V19.2197H15.5776V39.9514Z" fill="#FFD300"/>
      <path d="M16.207 39.951H19.642V22.7314H16.207V39.951Z" fill="#FFD300"/>
      <path d="M3.43501 23.4142L0 20.341V11.5605H3.43501V23.4142Z" fill="#2B2A29"/>
      <path d="M7.45109 27.268L4.06445 23.8533V7.6582H7.45109V27.268Z" fill="#2B2A29"/>
      <path d="M11.5151 22.195H8.08008V3.85352H11.5151V22.195Z" fill="#2B2A29"/>
      <path d="M15.5776 18.439H12.1426V0H15.5776V18.439Z" fill="#2B2A29"/>
      <path d="M24.2871 23.4142L27.7221 20.341V11.5605H24.2871V23.4142Z" fill="#2B2A29"/>
      <path d="M20.2715 27.268L23.6581 23.8533V7.6582H20.2715V27.268Z" fill="#2B2A29"/>
      <path d="M16.207 22.195H19.642V3.85352H16.207V22.195Z" fill="#2B2A29"/>
      <path d="M43.1546 34.5855C43.1546 34.0977 43.0579 33.7562 42.8643 33.5123C42.6708 33.2684 42.2354 33.1709 41.5097 33.1709H40.0583C39.4293 33.1709 38.9939 33.3172 38.752 33.5611C38.5585 33.805 38.4133 34.1465 38.4133 34.5855V36.5855C38.4133 37.0245 38.5101 37.366 38.752 37.6099C38.9455 37.8538 39.3809 37.9513 40.0583 37.9513H41.5097C42.187 37.9513 42.6224 37.8538 42.816 37.6099C43.0095 37.366 43.1062 37.0245 43.1062 36.5367C43.1546 36.5855 43.1546 34.5855 43.1546 34.5855ZM45.1382 39.3172C44.7028 39.7562 43.9287 39.9513 42.7192 39.9513H38.8971C37.736 39.9513 36.9135 39.7562 36.4781 39.3172C36.0427 38.8782 35.8008 38.244 35.8008 37.4148V33.6587C35.8008 32.8294 36.0427 32.1952 36.4781 31.7562C36.9135 31.3172 37.736 31.1221 38.8971 31.1221H42.7192C43.8803 31.1221 44.7028 31.3172 45.1382 31.7562C45.5736 32.1952 45.7672 32.8294 45.7672 33.6587V37.4148C45.7672 38.244 45.5736 38.8782 45.1382 39.3172Z" fill="#2B2A29"/>
      <path d="M56.6538 39.3172C56.2184 39.7562 55.4443 39.9513 54.2348 39.9513H50.4128C49.2516 39.9513 48.4292 39.7562 47.9937 39.3172C47.5583 38.8782 47.3164 38.244 47.3164 37.4148V33.6587C47.3164 32.8294 47.5583 32.1952 47.9937 31.7562C48.4292 31.3172 49.2516 31.1221 50.4128 31.1221H54.2348C55.3959 31.1221 56.2184 31.3172 56.6538 31.7562C57.0893 32.1952 57.2828 32.8294 57.2828 33.6587V34.5367H54.6702C54.6702 34.0489 54.5735 33.7074 54.38 33.4635C54.1864 33.2196 53.751 33.1221 53.0253 33.1221H51.5739C50.9449 33.1221 50.5095 33.2684 50.2676 33.5123C50.0741 33.7562 49.9289 34.0977 49.9289 34.5367V36.5855C49.9289 37.0245 50.0257 37.366 50.2676 37.6099C50.4611 37.8538 50.8966 37.9513 51.5739 37.9513H53.0253C53.7026 37.9513 54.1381 37.8538 54.38 37.6099C54.5735 37.366 54.6702 37.0245 54.6702 36.5367H57.2828V37.4148C57.2828 38.244 57.0893 38.8782 56.6538 39.3172Z" fill="#2B2A29"/>
      <path d="M61.4426 40.0002H58.8301V37.4636V33.7075V31.1709H61.4426V34.5368H66.1839V31.1709H68.7965V33.7075V37.4636V40.0002H66.1839V36.5368H61.4426V40.0002Z" fill="#2B2A29"/>
      <path d="M73.7328 36.5368H73.0071C73.0071 37.0246 73.1522 37.366 73.3457 37.6099C73.5393 37.8538 73.9747 37.9514 74.652 37.9514H76.1034H80.3609V40.0002H77.3129H73.4909C72.3297 40.0002 71.5073 39.805 71.0719 39.366C70.6364 38.927 70.3945 38.2929 70.3945 37.4636V33.7075C70.3945 32.8782 70.6364 32.2441 71.0719 31.805C71.5073 31.366 72.3297 31.1709 73.4909 31.1709H77.3129H80.3609V33.1709H76.1034H74.652C74.0231 33.1709 73.5876 33.3172 73.3457 33.5611C73.1522 33.805 73.0071 34.1465 73.0071 34.5855H73.7328H80.3125V36.5855L73.7328 36.5368Z" fill="#2B2A29"/>
      <path d="M100.486 33.1709H96.9062V31.1709H99.9542H103.776H106.873V33.1709H103.099V40.0002H100.486V33.1709Z" fill="#2B2A29"/>
      <path d="M84.3746 35.5611C84.3746 34.4391 85.2938 34.5855 85.2938 34.5855H87.9547V36.4391H85.2938C85.2454 36.4879 84.3746 36.4879 84.3746 35.5611ZM93.1798 36.4879H90.5189V34.6343H93.1798C93.1798 34.6343 94.0991 34.5367 94.0991 35.6099C94.0991 36.4879 93.1798 36.4879 93.1798 36.4879ZM94.5829 32.7318H90.5189V31.1221H87.9064V32.7318H83.7456C83.7456 32.7318 81.375 32.7806 81.375 35.5123C81.375 38.1952 83.794 38.244 83.794 38.244H87.9064V40.0001H90.5189V38.244H94.5345C94.5345 38.244 96.9535 38.244 96.9535 35.5123C96.9535 32.8294 94.5829 32.7318 94.5829 32.7318Z" fill="#2B2A29"/>
      <path d="M30.7701 34.927H26.8997V33.1709H30.7701C31.2539 33.1709 31.6409 33.5611 31.6409 34.0489C31.6409 34.5368 31.2539 34.927 30.7701 34.927ZM33.6245 31.805C33.1891 31.366 32.415 31.1709 31.2055 31.1709H24.2871V40.0002H26.8997V36.9758H31.2055C32.3666 36.9758 33.1891 36.7807 33.6245 36.3416C34.06 35.9026 34.2535 35.2685 34.2535 34.4392V33.7563C34.2535 32.8782 34.06 32.2441 33.6245 31.805Z" fill="#2B2A29"/>
      <path d="M115.387 37.0734C115.387 37.5612 115 37.9514 114.516 37.9514H110.646V36.1953H114.516C115 36.1953 115.387 36.5856 115.387 37.0734ZM118 37.4636V36.7807C118 35.9514 117.806 35.3173 117.371 34.8783C116.935 34.4392 116.161 34.2441 114.952 34.2441H110.646V31.2197H108.033V40.0002H114.952C116.113 40.0002 116.935 39.8051 117.371 39.3661C117.806 38.8783 118 38.2441 118 37.4636Z" fill="#2B2A29"/>
    </svg>
  );
};