import { toast } from 'react-toastify';
import heic2any from 'heic2any';

const extensions = ['application/pdf', 'image/jpeg', 'image/png', 'image/heif', ''];

export const loadingImages = async (setImages, images, target, count) => {
  let [file] = target.files;
  if (!extensions.includes(file.type)) {
    return toast.error(`Не верный формат фото`);
  }

  if (['', 'image/heif'].includes(file.type)) {
    try {
      file = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.5,
      });
    } catch (error) {
      return toast.error(`Ошибка загрузки файла`);
    }
  }

  const result = await new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = console.log;
    reader.readAsDataURL(file);
  });

  target.value = null;

  if (images.length >= count) return;

  setImages((prevValue) => [...prevValue, {
    id: `img${prevValue.length}`,
    value: result as string,
    name: file.name,
    size: Math.round(file.size / 1024),
    isSize: true,
    isDate: false,
    date: '',
    type: file.type
  }]);

};
