export const carNumber = (carNumber, setCarNumber) => {
  const cleaned = ('' + carNumber);

  return setCarNumber([
    /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXaeopcyx]{1}$/g.exec(cleaned[0])  ? cleaned[0].toUpperCase() : '',
    /[0-9]/.exec(cleaned[1])  ? cleaned[1].toUpperCase() : '',
    /[0-9]/.exec(cleaned[2])  ? cleaned[2].toUpperCase() : '',
    /[0-9]/.exec(cleaned[3])  ? cleaned[3].toUpperCase() : '',
    /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXaeopcyx]{1}$/g.exec(cleaned[4])  ? cleaned[4].toUpperCase() : '',
    /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXaeopcyx]{1}$/g.exec(cleaned[5])  ? cleaned[5].toUpperCase() : '',
    /[0-9]/.exec(cleaned[6])  ? cleaned[6].toUpperCase() : '',
    /[0-9]/.exec(cleaned[7])  ? cleaned[7].toUpperCase() : '',
    /[0-9]/.exec(cleaned[8])  ? cleaned[8].toUpperCase() : '',
  ].join(''));
};
