import React from 'react';

export const MapMarkerSvgActive = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="map-marker-multiple">
              <path id="Vector" d="M14 11.5C14.3283 11.5 14.6534 11.4353 14.9567 11.3097C15.26 11.1841 15.5356 10.9999 15.7678 10.7678C15.9999 10.5356 16.1841 10.26 16.3097 9.95671C16.4353 9.65339 16.5 9.3283 16.5 9C16.5 8.33696 16.2366 7.70107 15.7678 7.23223C15.2989 6.76339 14.663 6.5 14 6.5C13.337 6.5 12.7011 6.76339 12.2322 7.23223C11.7634 7.70107 11.5 8.33696 11.5 9C11.5 9.3283 11.5647 9.65339 11.6903 9.95671C11.8159 10.26 12.0001 10.5356 12.2322 10.7678C12.7011 11.2366 13.337 11.5 14 11.5ZM14 2C17.86 2 21 5.13 21 9C21 14.25 14 22 14 22C14 22 7 14.25 7 9C7 7.14348 7.7375 5.36301 9.05025 4.05025C10.363 2.7375 12.1435 2 14 2ZM5 9C5 13.5 10.08 19.66 11 20.81L10 22C10 22 3 14.25 3 9C3 5.83 5.11 3.15 8 2.29C6.16 3.94 5 6.33 5 9Z" fill="currentColor"/>
          </g>
      </svg>
  );
};


/*
<svg width="100%" height="100%" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 9.5C11.3283 9.5 11.6534 9.43534 11.9567 9.3097C12.26 9.18406 12.5356 8.99991 12.7678 8.76777C12.9999 8.53562 13.1841 8.26002 13.3097 7.95671C13.4353 7.65339 13.5 7.3283 13.5 7C13.5 6.33696 13.2366 5.70107 12.7678 5.23223C12.2989 4.76339 11.663 4.5 11 4.5C10.337 4.5 9.70107 4.76339 9.23223 5.23223C8.76339 5.70107 8.5 6.33696 8.5 7C8.5 7.3283 8.56466 7.65339 8.6903 7.95671C8.81594 8.26002 9.00009 8.53562 9.23223 8.76777C9.70107 9.23661 10.337 9.5 11 9.5ZM11 0C14.86 0 18 3.13 18 7C18 12.25 11 20 11 20C11 20 4 12.25 4 7C4 5.14348 4.7375 3.36301 6.05025 2.05025C7.36301 0.737498 9.14348 0 11 0ZM2 7C2 11.5 7.08 17.66 8 18.81L7 20C7 20 0 12.25 0 7C0 3.83 2.11 1.15 5 0.29C3.16 1.94 2 4.33 2 7Z" fill="currentColor"/>
</svg>*/
