import React from 'react';

export const SkeletonGsmCard = () => {

  return (
    <div className="SkeletonGsmCard">
      <div className="SkeletonGsmCard__backBtn"></div>
      <div className="SkeletonCardInformation">
        <div className="SkeletonCardInformation__box SkeletonCardInformation__box_sub">
          <div className="SkeletonCardInformation__title"></div>
          {[1,2,3,4,5].map((item, index) => <div key={index} className="SkeletonCardInformation__item">
            <div className="SkeletonCardInformation__text"></div>
            <div className="SkeletonCardInformation__text"></div>
          </div>)}
        </div>
        <div className="SkeletonCardInformation__box">
          <div className="SkeletonCardInformation__title"></div>
          <div className="SkeletonCardInformation__button"></div>
          <div className="SkeletonCardInformation__button"></div>
        </div>
      </div>
      <div className="SkeletonGsmCard__title"></div>
      <div className="SkeletonGsmCard__requestBtn">
        <div></div>
        <div></div>
      </div>
      <div className="SkeletonGsmCard__select">
        <div></div>
        <div></div>
      </div>
      <div className="SkeletonGsmCard__content">
        <div className="SkeletonGsmCard__transactionsItem transactionsItem transactionsItem_title">
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box transactionsItem__box_sub">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
        <div className="SkeletonGsmCard__transactionsItem transactionsItem">
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
          <div className="transactionsItem__box">
            <div className="transactionsItem__title"></div>
            <div className="transactionsItem__text"></div>
          </div>
        </div>
      </div>
    </div>
  );
};