import React, { useState } from 'react';
import { convertActualVisionUnit } from 'src/common/convertActualVisionUnit.helper';
import styles from './MobileShipped.module.scss';
import { IDataResponseShipped } from '../../type';

interface IProps {
  flight: IDataResponseShipped;
}
const MobileShippedCard = ({ flight }: IProps) => {
  const [openFullCard, setOpenFullCard] = useState(false);

  return (
    <div>
      {openFullCard ? (
        /*Открытая карточка*/
        <div className={styles.mobileTableContainer} onClick={() => setOpenFullCard(!openFullCard)}>
          <div className={styles.mobileTableContent}>
            <div className={styles.mobileFlightNameAndDownload}>
              <div className={styles.mobileDownloadContainer}>
                <p className={styles.mobileDownloadText}>
                  {flight?.flight?.dateUnloading?.slice(0, 10).split('-').reverse().join('.')}
                </p>
              </div>
              <div className={styles.mobileFlightContainer}>
                <p className={styles.mobileFlightText}>{flight?.flight?.flightName}</p>
              </div>
            </div>
            <div className={styles.mobileVehicleAndLoadContainer}>
              <div className={styles.mobileContainerTitle}>
                <p className={styles.mobileStrongText}>Транспорт:</p>
              </div>
              <div>
                <div className={styles.mobileBlockFirstText}>
                  <p className={styles.mobileStrongText}>
                    {flight?.vehicle?.car} {flight?.vehicle?.number}
                  </p>
                </div>
                <div>
                  <p className={styles.mobileSoftText}>{flight?.vehicle?.driver}</p>
                </div>
              </div>
            </div>
            <div className={styles.mobileVehicleAndLoadContainer}>
              <div className={styles.mobileContainerTitle}>
                <p className={styles.mobileStrongText}>Загрузка:</p>
              </div>
              <div>
                <div className={styles.mobileBlockFirstText}>
                  <p className={styles.mobileStrongText}>{flight?.loading?.organization}</p>
                </div>
                <div>
                  <p className={styles.mobileSoftText}>{flight?.loading?.title}</p>
                </div>
              </div>
            </div>
            <div className={styles.mobileActualLoadingContainer}>
              <div className={styles.mobileActualLoadingTitleContainer}>
                <p className={styles.mobileSoftNomenclatureText}>{flight?.volumeAndLoad?.nomenclature}</p>
              </div>
              <div className={styles.mobileVActualAndPlanContainer}>
                <div className={styles.mobileContainerTitle}>
                  <p className={styles.mobileStrongText}>Запланированный Объем:</p>
                </div>
                <p className={styles.mobileSoftText}>
                  {flight?.volumeAndLoad?.volume} {convertActualVisionUnit(flight?.volumeAndLoad?.unit)}
                </p>
              </div>
              <div className={styles.mobileVActualAndPlanContainer}>
                <div className={styles.mobileContainerTitle}>
                  <p className={styles.mobileStrongText}>Фактический Объем:</p>
                </div>
                <p className={styles.mobileSoftText}>
                  {flight?.actualLoading?.supplierFilledVolume}{' '}
                  {convertActualVisionUnit(flight?.actualLoading?.unitDownload)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*Закрытая карточка*/
        <div className={styles.mobileTableContainer} onClick={() => setOpenFullCard(!openFullCard)}>
          <div className={styles.mobileTableContent}>
            <div className={styles.mobileFlightNameAndDownload}>
              <div className={styles.mobileDownloadContainer}>
                <p className={styles.mobileDownloadText}>
                  {flight?.flight?.dateUnloading?.slice(0, 10).split('-').reverse().join('.')}
                </p>
              </div>
              <div className={styles.mobileFlightContainer}>
                <p className={styles.mobileFlightText}>{flight?.flight?.flightName}</p>
              </div>
            </div>
            <div className={styles.mobileVehicleAndLoadLastCardContainer}>
              <div className={styles.mobileContainerTitle}>
                <p className={styles.mobileStrongText}>Транспорт:</p>
              </div>
              <div>
                <div className={styles.mobileBlockFirstText}>
                  <p className={styles.mobileStrongText}>
                    {flight?.vehicle?.car} {flight?.vehicle?.number}
                  </p>
                </div>
                <div>
                  <p className={styles.mobileSoftText}>{flight?.vehicle?.driver}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileShippedCard;
