import React from 'react';

export const CarrierRoleSvg = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.3327 5.33398H30.666H27.3327H23.9993V15.334H2.33268V20.334H3.99935C3.99935 21.6601 4.52613 22.9318 5.46381 23.8695C6.4015 24.8072 7.67327 25.334 8.99935 25.334C10.3254 25.334 11.5972 24.8072 12.5349 23.8695C13.4726 22.9318 13.9993 21.6601 13.9993 20.334H23.9993C23.9993 21.6601 24.5261 22.9318 25.4638 23.8695C26.4015 24.8072 27.6733 25.334 28.9993 25.334C30.3254 25.334 31.5972 24.8072 32.5349 23.8695C33.4726 22.9318 33.9993 21.6601 33.9993 20.334H37.3327V12.0007L32.3327 5.33398ZM8.99935 22.834C8.33631 22.834 7.70042 22.5706 7.23158 22.1017C6.76274 21.6329 6.49935 20.997 6.49935 20.334C6.49935 19.6709 6.76274 19.0351 7.23158 18.5662C7.70042 18.0974 8.33631 17.834 8.99935 17.834C9.66239 17.834 10.2983 18.0974 10.7671 18.5662C11.236 19.0351 11.4993 19.6709 11.4993 20.334C11.4993 20.997 11.236 21.6329 10.7671 22.1017C10.2983 22.5706 9.66239 22.834 8.99935 22.834ZM28.9993 22.834C28.3363 22.834 27.7004 22.5706 27.2316 22.1017C26.7627 21.6329 26.4993 20.997 26.4993 20.334C26.4993 19.6709 26.7627 19.0351 27.2316 18.5662C27.7004 18.0974 28.3363 17.834 28.9993 17.834C29.6624 17.834 30.2983 18.0974 30.7671 18.5662C31.236 19.0351 31.4993 19.6709 31.4993 20.334C31.4993 20.997 31.236 21.6329 30.7671 22.1017C30.2983 22.5706 29.6624 22.834 28.9993 22.834ZM27.3327 12.0007V7.83398H31.4993L34.766 12.0007H27.3327ZM28.9993 3.66732H22.3327V13.6673H3.99935L1.61602 5.33398H0.666016V2.00065H20.666L22.3327 0.333984H28.9993V3.66732Z"
        fill="currentColor"
      />
    </svg>
  );
};
