import React, { useEffect, useState } from 'react';

/*const status2ReadableView = status => (status === 'Запланирован' ? 'Новый рейс' : status);*/

export const StatusItem = ({ status, ...props }) => {
  const [colorClass, setColorClass] = useState('orange');

  useEffect(() => {
    status === 'Запланирован' && setColorClass('blue');
    status === 'Проверен' && setColorClass('green');
    status === 'Рейс отменен' && setColorClass('red');
  }, [colorClass]);

  return (
    <div {...props} className={'StatusItem ' + colorClass}>
      {status}
    </div>
  );
};
