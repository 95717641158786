import React, { ReactNode} from 'react';
import  '../NewRoleTad/NewRoleTab.scss';
import { useMediaQuery } from 'react-responsive';
interface IProps {
    name:string
    description:string
    svg:ReactNode
    onClick: () => void;
    active:boolean
    disabled:boolean
    development:boolean
}
export const NewRoleTab = ({ name, description, svg, active, disabled, development, ...props }:IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div style={{ zIndex:'20' }}>
            {isMobile? <button
                {...props}
                className={`MobileNewRoleTab ${active ? 'active' : ''}`}
                disabled={disabled}
            >
                    <div className="MobileNewRoleTab__svgAndName">
                        <div className="MobileNewRoleTab__svg">
                            {svg}
                        </div>
                        <div className={'MobileNewRoleTab__name'}>
                            {name}
                        </div>
                    </div>
                    <div className={'MobileNewRoleTab__description'}>
                        {description}
                    </div>
            </button>
                : /*компьютерная версия*/
                <button
                {...props}
                className={`NewRoleTab ${active ? 'active' : ''}`}
                disabled={disabled}
            >
                <div className="NewRoleTab__svg">
                    {svg}
                </div>
                <div className="NewRoleTab__textContainer">
                    <div className={'NewRoleTab__name'}>
                        {name}
                    </div>
                    <div className={'NewRoleTab__description'}>
                        {description}
                    </div>
                </div>
            </button>}
        </div>

    );
};
