import React from 'react';

export const SkeletonOrder = () => {
    return (
        <div className={'skeleton-order'}>
           <div className={'skeleton-order__title'}></div>
            <div className={'skeleton-order-content'}>
                <div className={'skeleton-order-content-left'}>
                    <div className={'skeleton-order-content-left__title'}></div>
                    <div className={'skeleton-order-content-left__sub-title'}></div>
                    <div className={'skeleton-order-content-left-progress'}>
                        <div className={'skeleton-order-content-left-progress-content'}>
                            <div>
                                <div className={'skeleton-order-content-left-progress-content__title'}></div>
                                <div className={'skeleton-order-content-left-progress-content__sub-title'}></div>
                            </div>
                            <div>
                                <div className={'skeleton-order-content-left-progress-content__title'}></div>
                                <div className={'skeleton-order-content-left-progress-content__sub-title'}></div>
                            </div>
                        </div>
                        <div className={'skeleton-order-content-left-progress__bar'}></div>
                        <div className={'skeleton-order-content-left-progress__sub-bar'}></div>
                    </div>
                </div>
                <div className={'skeleton-order-content-right'}>
                    <div className={'skeleton-order-content-right__title'}></div>
                    {[1,2,3,4,5,6].map((item, index) =>
                        <div key={index} className={'skeleton-order-content-right__item'}>
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </div>
            </div>
            {[1,2,3].map((item, index) =>
                <div key={index} className={'skeleton-order-collapse'}>
                    <div></div>
                    <div></div>
                </div>
            )}
        </div>
    );
};