import React from 'react';

export const SkeletonCar = () => {
  return (
    <div className="SkeletonCar">
      <div className="SkeletonCar__back"></div>
      <div className="SkeletonCar__name"></div>
      <div className="SkeletonCar__content">
        <div className="SkeletonCar__form">
          <div className="SkeletonCar__titles">
            <div className="SkeletonCar__title"></div>
          </div>
          <div className="SkeletonCar__inputs">
            <div className="SkeletonCar__input"></div>
            <div className="SkeletonCar__input"></div>
            <div className="SkeletonCar__input"></div>
            <div className="SkeletonCar__button"></div>
            <div className="SkeletonCar__button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};