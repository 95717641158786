import React from 'react';
import { IItemData, ISelect } from '../../types';
import { Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import styles from '../PlanModals/AddPlanModal.module.scss';

interface IProps {
  carsList: ISelect[];
  infoData: IItemData;
  name: any;
  data: any;
}
const VolumePerFlight = ({ carsList, name, data, infoData }: IProps) => {
    console.log(data);
    let unit = infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const inputValue = isNaN(carsList.find(item => item.value === data[name]?.carId)?.volumeBody)?0:carsList.find(item => item.value === data[name]?.carId)?.volumeBody;
    return (
    <>
      <Input
        value={unit === 'тонн' ? Math.ceil(inputValue * 1.45) : inputValue}
        disabled={true}
        placeholder="0"
        style={{
          width: isMobile ? `100%` : `121px`,
          fontSize: '16px',
          fontWeight: '400',
          color: 'black',
          marginBottom: isMobile ? '12px' : '0',
        }}
        className={styles.allVolumeOfFlight}
      />
    </>
  );
};

export default VolumePerFlight;
