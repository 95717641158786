import { fetchPost } from './proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { flightStates } from './flight-states';
import React from 'react';

export const sendDataFlightFunction = async ({
  id,
  state,
  setState,
  volumeValue,
  setVolumeValue,
  setDisabled,
  setSending,
  billOfLadingDate,
  billOfLoadingNumber,
  images,
}: any) => {
  const correctBillOfLadingDate = new Date(billOfLadingDate);
  const patchStructure = {
    'Выехал на загрузку': {
      datetimeStartFlight: state?.transitionConditions?.flightStartDate && new Date(),
      status: state.nextState,
    },
    'Начал загрузку': {
      dateStartDownload: state?.transitionConditions?.downloadStartDate && new Date(),
      status: state.nextState,
    },
    Загрузился: {
      dateEndDownload: state?.transitionConditions?.downloadEndDate && new Date(),
      volumeDownload: state?.transitionConditions?.loadingVolume && Number(volumeValue),
      status: state.nextState,
    },
    'Начал выгрузку': {
      dateStartUnloading: state?.transitionConditions?.unloadingStartDate && new Date(),
      status: state.nextState,
    },
    Выгрузился: {
      dateEndUnloading: state?.transitionConditions?.unloadingEndDate && new Date(),
      unloadingVolume: state?.transitionConditions?.unloadingVolume && Number(volumeValue),
      status: state.nextState,
    },
    Выполнен: {
      numberTn: state?.transitionConditions?.billOfLoadingNumber && billOfLoadingNumber,
      status: state.nextState,
      data: state?.transitionConditions?.unloadingPhoto ? images.map(({ value }) => value) : [],
      dateTn: state?.transitionConditions?.unloadingDate ? new Date(correctBillOfLadingDate.setHours(3)) : new Date(),
    },
  };

  const responsePatchFlights = await fetchPost(
    `/flights/${id}`,
    'PATCH',
    patchStructure[state.nextState || 'Выехал на загрузку'],
  );

  if (responsePatchFlights.error) {
    const errorMessages = responsePatchFlights.message.map(message => (
      <p>
        {message}
        <br />
        <br />
      </p>
    ));
    toast.error(<div>{errorMessages}</div>);
    setDisabled(false);
    setSending(false);
    return;
  }

  toast.success(`${state.nextState} успешно`);
  setDisabled(false);
  setSending(false);

  if (!responsePatchFlights.error) {
    // @ts-ignore
    const [, updatedState] = Object.entries(flightStates).find(([key]) => key === state.nextState);
    setState(updatedState);
  }
};
