import React, { useEffect, useState } from 'react';
import styles from './CustomInputValueAndCheckBox.module.scss';
import { InputNumber } from '../../UI/inputs/InputNumber';
import CheckSvg from '../../UI/svg-icon/CheckSvg';
import { fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';

interface IProps {
  unit: string;
  id: number;
  dataResponse?: any;
  setTab?: React.Dispatch<React.SetStateAction<string>>;
  setDataResponse?: React.Dispatch<React.SetStateAction<any>>;
  setIsQr?: React.Dispatch<React.SetStateAction<boolean>>;
}
const CustomInputValueAndCheckBox = ({ unit, id, dataResponse = [], setDataResponse, setIsQr, setTab }: IProps) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue('');
  }, [id]);
  const sendData = async () => {
    try {
      const response = await fetchPost(`/shipments/${id}`, 'PATCH', {
        volume: Number(value),
      });
      if (dataResponse.length) {
        const filterDataResponse = dataResponse.filter(item => {
          return item?.flight?.id !== id;
        });
        setDataResponse(filterDataResponse);
      }
      if (response.error) {
        return toast.error(response?.message);
      }
      toast.success(`Данные успешно отправлены`);
      setValue('');
      setIsQr(false);
      setTab('2');
    } catch (e) {
      setIsQr(false);
      console.log(e);
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.inputAndUnit}>
          <div className={styles.inputContainer}>
            <InputNumber
              bgError={true}
              fill={false}
              placeholder={'Объем'}
              margin={''}
              isValue={value}
              setIsValue={setValue}
            />
          </div>
          <div className={styles.unitOfMeasurement}>{unit}</div>
        </div>
        <div onClick={sendData} className={value.length > 0 ? styles.checkBoxActive : styles.checkBox}>
          <div className={value.length > 0 ? styles.pointerCheckBoxActive : styles.pointerCheckBox}>
            <CheckSvg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomInputValueAndCheckBox;
