import React, { useEffect, useState } from 'react';
import { DevelopmentSvg } from '../../../../components/UI/svg-icon/DevelopmentSvg';
import { SelectBox } from '../../../../components/SelectBox';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import { SkeletonDocuments } from '../../../../components/skeletons/SkeletonDocuments';
import { Frame } from '../../../../components/Frame';
import { formPrice } from '../../../../common/form-price.helper';
import { Notification } from '../../../../components/Notification';
import { SelectCustom } from '../../../../components/UI/select/SelectCustom';
import { EmptyList } from '../../../../components/EmptyList';
import CustomRangePicker from '../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import { StatusDocumentsItem } from '../../../../components/UI/StatusDocumentsItem/StatusDocumentsItem';
import { clearObject } from '../../../../common/ClearObject.helper';
import dayjs, { Dayjs } from 'dayjs';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';

export const statusColor = {
  Проверен: 'green',
  Отклонен: 'red',
  'На проверке': 'orange',
  'Повторная проверка': 'blue',
};
interface IProps {
  tab: string;
}
export const Registry = ({ tab }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [inDevelopment, setInDevelopment] = useState(false);
  const [isDevelopment] = useState(false);

  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<string[] | Dayjs>(['', '']);
  const [isFirstRender, setIsFirstRender] = useState<number>(0);
  const [selectStatus, setSelectStatus] = useState<
    {
      value: string;
      label: string;
    }[]
  >();

  const [skeleton, setSkeleton] = useState(true);
  const [more, setMore] = useState(11);
  const [registriesList, setRegistriesList] = useState<any>([]);
  const [filterRegistriesList, setFilterRegistriesList] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);

  const statusOptions = [
    {
      value: 'Проверен',
      label: 'Проверен',
    },
    {
      value: 'Отклонен',
      label: 'Отклонен',
    },
    {
      value: 'На проверке',
      label: 'На проверке',
    },
    {
      value: 'Повторная проверка',
      label: 'Повторная проверка',
    },
  ];
  const getDocuments = async (startDate, finishDate) => {
    try {
      const requestData = {
        startDate: startDate[0] ? new Date(startDate[0]) : undefined,
        finishDate: startDate[1] ? new Date(startDate[1]) : undefined,
        status: selectStatus?.[0]?.value ? selectStatus?.[0]?.value : undefined,
      };
      const registriesResponse = await fetchGet('/registries', clearObject(requestData));
      const documentsResponse = await fetchGet('/flights/documents', { operation: 'create' });

      const registriesDta: {}[] = [];

      for (const data of registriesResponse.response) {
        registriesDta.push({
          id: data.id,
          item: isMobile
            ? {
                'Номер реестра': {
                  title: {
                    up: (
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ color: 'black', fontWeight: '600' }}>{data?.registryNumber}</div>
                        <StatusDocumentsItem
                          status={data.verifications.length ? data.verifications[0].status.title : 'На проверке'}
                          color={
                            statusColor[data.verifications.length ? data.verifications[0].status.title : 'На проверке']
                          }
                        />
                      </div>
                    ),
                    down: null,
                  },
                  help: false,
                  contentHelp: null,
                },
                Дата: {
                  title: {
                    up: new Date(data.registryDate).toLocaleString().split(',')[0],
                    down: null,
                  },
                  help: false,
                  contentHelp: null,
                },
                Контрагент: {
                  title: {
                    up: data.company,
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
                Объем: {
                  title: {
                    up: String(data.volume),
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
                Сумма: {
                  title: {
                    up: formPrice(Number(data.documentAmount) || 0, 'RUB'),
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
              }
            : {
                'Номер реестра': {
                  title: {
                    up: data.registryNumber,
                    down: null,
                  },
                  help: false,
                  contentHelp: null,
                },
                Дата: {
                  title: {
                    up: new Date(data.registryDate).toLocaleString().split(',')[0],
                    down: null,
                  },
                  help: false,
                  contentHelp: null,
                },
                Контрагент: {
                  title: {
                    up: data.company,
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
                Объем: {
                  title: {
                    up: String(data.volume),
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
                Сумма: {
                  title: {
                    up: formPrice(Number(data.documentAmount) || 0, 'RUB'),
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
                Статус: {
                  title: {
                    status: data.verifications[0]?.status.title,
                    up: (
                      <StatusDocumentsItem
                        status={data.verifications.length ? data.verifications[0].status.title : 'На проверке'}
                        color={
                          statusColor[data.verifications.length ? data.verifications[0].status.title : 'На проверке']
                        }
                      />
                    ),
                    down: '',
                  },
                  help: false,
                  contentHelp: null,
                },
              },
        });
      }

      setRegistriesList(registriesDta);
      setFilterRegistriesList(registriesDta);
      setDocumentList(documentsResponse.response);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };

  useEffect(() => {
    getDocuments('', '').catch(console.log);
    setIsFirstRender(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (tab === 'registryAndUpd' && isFirstRender > 0) {
      getDocuments(dateRange, '').catch(console.log);
    }
  }, [dateRange, tab, selectStatus]);

  return (
    <>
      {inDevelopment ? (
        <>
          <h1>Документы</h1>
          <div className="development">
            <div className="development__banner banner">
              <div className="banner__svg">
                <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="banner__text">
                Раздел находится в разработке и будет доступен чере
                <span onClick={() => setInDevelopment(!inDevelopment)}>з</span> некоторое время
              </div>
            </div>
            <div className="development__content item">
              <div className="item__svg">
                <DevelopmentSvg />
              </div>
              <div className="item__text">
                <div className="item__title">В разработке</div>
                <div className="item__subTitle">Этот раздел разрабатывается</div>
              </div>
            </div>
          </div>
        </>
      ) : skeleton ? (
        <>
          <SkeletonDocuments />
        </>
      ) : (
        <>
          <div className={'documents-page'}>
            <div className={`development-wrapper ${isDevelopment ? 'blur' : ''}`}>
              {!documentList?.length && (
                <div className={'documents-page__notification'}>
                  <Notification text={'На данный момент Вы не можете сформировать реестр.'} color={''} />
                </div>
              )}
              <div className={'documents-page__selects'}>
                <SelectBox gridTemplateColumns={isMobile ? '1fr' : '312px 312px'}>
                  <CustomRangePicker
                    width={isMobile ? '100%' : '300px'}
                    open={openCalendar}
                    isPresets={true}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setOpen={setOpenCalendar}
                    placeholder={['Начало период', 'Конец периода']}
                  />
                  <SelectCustom
                    style={{ width: isMobile ? '100%' : '408px', height: '55px' }}
                    options={statusOptions}
                    maxCount={1}
                    placeholder={'По статусу'}
                    mode={'tags'}
                    showSearch={false}
                    value={selectStatus ? selectStatus : []}
                    setValue={setSelectStatus}
                    disabled={false}
                    status={''}
                    errorText={''}
                  />
                </SelectBox>
              </div>
              <div className={'documents-page__content'}>
                <>
                  {filterRegistriesList.length ? (
                    <Frame
                      items={filterRegistriesList.slice(0, more)}
                      gridTemplateColumns={'200px 105px 200px 105px 200px 200px'}
                      link={'document/registry'}
                    />
                  ) : (
                    <EmptyList title={'Документов не найдено'} subTitle={'В данном разделе пока нет Документов'} />
                  )}
                  {filterRegistriesList?.length > more - 1 && (
                    <ButtonNotFilled
                      margin={'28px 0 0 0'}
                      onClick={() => setMore(more + 10)}
                      text={'Показать ещё'}
                      width={'100%'}
                      color={'#ff5100'}
                    />
                  )}
                </>
              </div>
            </div>
            {isDevelopment && (
              <div className={'development balloon'}>
                <div className="development__content item">
                  <div className="item__svg">
                    <DevelopmentSvg />
                  </div>
                  <div className="item__text">
                    <div className="item__title">В разработке</div>
                    <div className="item__subTitle">Этот раздел разрабатывается</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
