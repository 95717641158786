import React, { useState } from 'react';
import styles from './MobileAwaitingShipment.module.scss';
import TextBoxSearchOutlineSvg from '../../../../components/UI/svg-icon/textBoxSearchOutlineSvg';
import CustomInputValueAndCheckBox from '../../../../components/newUI/CustomInputValueAndCheckBox/CustomInputValueAndCheckBox';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { convertActualVisionUnit } from 'src/common/convertActualVisionUnit.helper';
import { IDataResponseAwaitingShipped } from '../../type';
interface IProps {
    flight:IDataResponseAwaitingShipped
}
const MobileAwaitingShipmentCard = ({ flight }:IProps) => {
    const [openFullCard,setOpenFullCard] = useState<boolean>(false);
    const setOpenFullCardFunction = () => {
        setOpenFullCard(!openFullCard);
    };
    const downloadRegistry = async (id:number) => {
        try {
            const response  = await fetchPost(`/attorneys/generate`, 'POST',{ id } );
            if (response.error) {
                const errorMessages = response.message.map(message => (
                    <p>
                        {message}
                        <br />
                        <br />
                    </p>
                ));
                toast.error(<div>{errorMessages}</div>);
                return;
            }

            const link = document.createElement('a');
            link.download = response?.response?.name;

            link.href = response?.response?.file;

            link.click();

            return;

        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div>
            {openFullCard? (
            /*Открытая карточка*/
            <div className={styles.mobileTableContainer} onClick={setOpenFullCardFunction}>
                <div className={styles.mobileTableContent}>
                    <div className={styles.mobileFlightNameAndDownload}>
                        <div className={styles.mobileSvgContainer} onClick={(e)=> {
                            downloadRegistry(flight?.flight?.id);
                            e.stopPropagation();
                        }}>
                            <TextBoxSearchOutlineSvg />
                        </div>
                        <div className={styles.mobileFlightContainer}>
                            <p className={styles.mobileFlightText}>{flight?.flight?.flightName}</p>
                        </div>
                    </div>
                    <div className={styles.mobileVehicleAndLoadContainer}>
                        <div className={styles.mobileContainerTitle}>
                            <p className={styles.mobileStrongText}>
                                Транспорт:
                            </p>
                        </div>
                        <div>
                            <div className={styles.mobileBlockFirstText}>
                                <p className={styles.mobileStrongText}>
                                    {flight?.vehicle?.car} {flight?.vehicle?.number}
                                </p>
                            </div>
                            <div>
                                <p className={styles.mobileSoftText}>
                                    {flight?.vehicle?.driver}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobileVehicleAndLoadContainer}>
                        <div className={styles.mobileContainerTitle}>
                            <p className={styles.mobileStrongText}>
                                Загрузка:
                            </p>
                        </div>
                        <div>
                            <div className={styles.mobileBlockFirstText}>
                                <p className={styles.mobileStrongText}>
                                    {flight?.loading?.organization}
                                </p>
                            </div>
                            <div>
                                <p className={styles.mobileSoftText}>
                                    {flight?.loading?.title}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobileVolumeAndLoadContainer}>
                        <div className={styles.mobileSoftNomenclatureContainer}>
                            <p className={styles.mobileSoftNomenclatureText}>
                                {flight?.volumeAndLoad?.nomenclature}
                            </p>
                        </div>
                        <div className={styles.mobileVolumePlanAndUnitContainer}>
                            <div className={styles.mobileContainerTitle}>
                                <p className={styles.mobileStrongText}>
                                    Объем:
                                </p>
                            </div>
                            <div>
                                <p className={styles.mobileSoftText}>
                                    {flight?.volumeAndLoad?.volume} {convertActualVisionUnit(flight?.volumeAndLoad?.unit)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobileActualLoadingContainer}>
                        <div className={styles.mobileActualLoadingTitleContainer}>
                            <p className={styles.mobileStrongText}>
                                Фактическая погрузка с ед. измерения:
                            </p>
                        </div>
                        <div onClick={(e)=>e.stopPropagation()}>
                            <CustomInputValueAndCheckBox id={flight?.actualLoading?.id} unit={convertActualVisionUnit(flight?.actualLoading?.unit)}/>
                        </div>
                    </div>
                </div>
            </div>
            ):(
            /*Закрытая карточка*/
            <div className={styles.mobileTableContainer} onClick={setOpenFullCardFunction}>
                <div className={styles.mobileTableContent}>
                    <div className={styles.mobileFlightNameAndDownload}>
                        <div className={styles.mobileSvgContainer} onClick={(e)=> {
                            downloadRegistry(flight?.flight?.id);
                            e.stopPropagation();
                        }}>
                            <TextBoxSearchOutlineSvg />
                        </div>
                        <div className={styles.mobileFlightContainer}>
                            <p className={styles.mobileFlightText}>{flight?.flight?.flightName}</p>
                        </div>
                    </div>
                    <div className={styles.mobileVolumeAndLoadLastStringOfSmallContainer}>
                        <div className={styles.mobileSoftNomenclatureContainer}>
                            <p className={styles.mobileSoftNomenclatureText}>
                                {flight?.volumeAndLoad?.nomenclature}
                            </p>
                        </div>
                        <div className={styles.mobileVolumePlanAndUnitContainer}>
                            <div className={styles.mobileContainerTitle}>
                                <p className={styles.mobileStrongText}>
                                    Объем:
                                </p>
                            </div>
                            <div>
                                <p className={styles.mobileSoftText}>
                                    {flight?.volumeAndLoad?.volume} {convertActualVisionUnit(flight?.volumeAndLoad?.unit)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default MobileAwaitingShipmentCard;