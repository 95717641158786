import React, { useContext, useEffect, useState } from 'react';
import styles from './PaymentPaid.module.scss';
import { InputSearch } from '../../../../components/UI/inputs/InputSearch';
import CustomRangePicker from '../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import { Dayjs } from 'dayjs';
import CustomTable from '../../../../components/newUI/CustomTable/CustomTable';
import { columnsForTableOfPaymentPagePaid } from '../../constans';
import { Context } from '../../../../context/context';
import { formPrice } from '../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
import MobileCardPaid from '../MobileCard/MobileCardPaid';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { clearObject } from '../../../../common/ClearObject.helper';
import { IDataResponsePaymentPaid } from '../../type';
interface IProps {
  tab: string;
}

const PaymentPaid = ({ tab }: IProps) => {
  const [searchString, setSearchString] = useState<string>('');
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [dataResponse, setDataResponse] = useState<IDataResponsePaymentPaid[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateRange, setDateRange] = useState<string[] | Dayjs>(['', '']);
  const [sumByPeriod, setSumByPeriod] = useState<number>(0);

  const { user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const correctOffsetGetData = () => {
    setOffset(0);
    if (tab === '2') {
      getDataTablePaid(false);
    }
  };
  useEffect(() => {
    setOpenCalendar(false);
  }, [tab]);

  useEffect(() => {
    correctOffsetGetData();
  }, [tab, searchString, dateRange]);
  const getDataTablePaid = async (isPagination: boolean) => {
    const requestOption = {
      startDate: dateRange[0] ? new Date(dateRange[0]) : undefined,
      finishDate: dateRange[1] ? new Date(dateRange[1]) : undefined,
      searchString: searchString ? searchString : undefined,
      limit: 10,
      offset: isPagination ? offset : 0,
    };
    clearObject(requestOption);
    try {
      const response = await fetchGet(`/reports/paid-flights`, requestOption);
      const responseTableData = response?.response?.flights?.map(item => {
        return {
          ...item,
          loading: {
            name: item?.supplier?.title,
            address: item?.quarry?.address?.address,
          },
          car: {
            carModel: item?.car?.carBrand?.brand,
            stateNumber: item?.car?.stateNumber,
          },
          driver: item?.driver?.fullName,
          volumeAndUnit: {
            volume: item?.volume,
            unit: item?.specification?.nomenclatureSpecification?.nomenclature?.unit,
            nomenclature: item?.specification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
          },
          sum: item?.specification?.nomenclatureSpecification?.total,
        };
      });

      setTotalCount(response?.response?.count);
      setSumByPeriod(response?.response?.result);
      if (isPagination && offset !== 0) {
        setDataResponse(prevState => [...prevState, ...responseTableData]);
      } else {
        setDataResponse(responseTableData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (tab === '2' && totalCount > dataResponse?.length) {
      getDataTablePaid(true);
    }
  }, [offset]);
  const correctUserForColumn = (): string => {
    if (user.companyType === 'carrier' || user.companyType === 'buyer') {
      return 'carrierAndBuyer';
    } else {
      return 'supplier';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <InputSearch
          value={searchString}
          onInput={({ currentTarget: { value: search } }) => setSearchString(search)}
          placeholder={'Поиск'}
        />
        <CustomRangePicker
          width={isMobile ? '100%' : '290px'}
          open={openCalendar}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setOpen={setOpenCalendar}
        />
      </div>
      <div className={styles.sumContainer}>
        {dateRange[0] === '' || dateRange[1] === '' ? (
          <>
            <p className={styles.valueSumText}>Выберите период, чтобы отобразить общую сумму</p>
          </>
        ) : (
          <>
            <p className={styles.infoSumText}>Общая сумма за выбранный период:</p>&nbsp;
            <p className={styles.valueSumText}>{formPrice(sumByPeriod, 'RUB')}</p>
          </>
        )}
      </div>
      {isMobile ? (
        <>
          <MobileCardPaid data={dataResponse} />
        </>
      ) : (
        <div className={styles.tableContainer}>
          <CustomTable
            link={false}
            columns={columnsForTableOfPaymentPagePaid[correctUserForColumn()]}
            data={dataResponse}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentPaid;
