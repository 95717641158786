import React, { useContext, useState } from 'react';
import styles from './PaymentProgress.module.scss';
import FiltersProcessingOfPaymentProcess from '../FiltersProcessingOfPaymentProcess/FiltersProcessingOfPaymentProcess';
import CustomTable from '../../../../components/newUI/CustomTable/CustomTable';
import { columnsForTableOfPaymentPageProgress } from '../../constans';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { Context } from '../../../../context/context';
import { IDataResponsePaymentProgress } from '../../type';
import { useMediaQuery } from 'react-responsive';
import MobileCardProgress from '../MobileCard/MobileCardProgress';
interface IProps {
  tab: string;
}
const PaymentProgress = ({ tab }: IProps) => {
  const [dataResponse, setDataResponse] = useState<IDataResponsePaymentProgress[]>([]);
  const [activityFilter, setActivityFilter] = useState<boolean>(false); /*проверяет, есть ли выбранный фильтр*/
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const getDataTable = async (resultBy: string, date) => {
    const requestOption = {
      date: new Date(date.set('hour', 23).set('minute', 59).set('second', 59)),
      resultBy: resultBy,
    };
    setDataResponse([]);
    setActivityFilter(!!resultBy);
    if (resultBy) {
      setLoading(true);
      try {
        const response = await fetchGet(`/reports/calculations-details`, requestOption);
        const responseTableData = response.response.map(item => {
          return {
            ...item,
            loading: {
              name: item?.supplier,
              address: item?.quarry,
            },
            car: {
              carModel: item?.carModel,
              stateNumber: item?.stateNumber,
              driver: item?.driver,
            },
            volumeAndUnit: {
              unit: item?.unit,
              nomenclature: item?.nomenclature,
              volume: item?.volume,
            },
          };
        });
        setDataResponse(responseTableData);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setActivityFilter(!!resultBy);
      }
    }
  };
  return (
    <div className={styles.container}>
      <FiltersProcessingOfPaymentProcess tab={tab} getDataTable={getDataTable} />
      {isMobile ? (
        <>
          <MobileCardProgress data={dataResponse} loading={loading} activityFilter={activityFilter} />
        </>
      ) : (
        <div className={styles.tableContainer}>
          <CustomTable
            emptyFilterTitle={
              user.companyType === 'buyer' ? 'Сформируйте дебиторскую задолженность' : 'Сформируйте историю расчетов'
            }
            loading={loading}
            activityFilter={activityFilter}
            link={false}
            columns={columnsForTableOfPaymentPageProgress[user.companyType]}
            data={dataResponse}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentProgress;
